import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import { IDiscoveryGroupOverview } from 'types/discovery-group';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  id: string;
  idProject: string;
}

interface Result extends IRequestHookResult<IDiscoveryGroupOverview> {}

export const useLoadDiscoveryGroupOverview: IRequestHook<Props, Result> = ({
  stopWhen,
  id,
  idProject,
}) => {
  const path = `/admin/projects/${idProject}/koc/discovery/groups/${id}/overview`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const result = response
    ? (response.data as IDiscoveryGroupOverview)
    : undefined;

  return {
    mutate,
    result,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadDiscoveryGroupOverview;
