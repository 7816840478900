import { IEmployee } from 'types/project';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  id: string;
}

interface Result extends IRequestHookResult<IEmployee[]> {}

export const useLoadEmployeeList: IRequestHook<Props, Result> = ({ id, stopWhen }) => {
  const path = `/admin/projects/${id}/internal-accounts`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const employees = response ? (response.data as IEmployee[]) : [];

  return {
    mutate,
    result: employees,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadEmployeeList;
