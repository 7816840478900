import { AutocompleteRenderOptionState, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { renderOption as renderCheckboxOption } from '.';

export const CUSTOM_RANGE_OPTION = 'CUSTOM_RANGE_OPTION';
export const CUSTOM_TEXT = 'Custom Range'

type Props = {
  children?: string | ReactElement;
  onClick?: () => void;
};
export const CustomAutocompleteNoOption: FC<Props> = ({
  children = CUSTOM_TEXT,
  onClick,
}) => {
  return (
    <Typography
      sx={{ '&:hover': { cursor: 'pointer' } }}
      color="primary"
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

export const renderOptionWithCustomRange = (
  onClick: () => void,
  label: string | ReactElement = CUSTOM_TEXT,
  renderOption: Function = renderCheckboxOption,
) => {
  return (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
  ) => {
    if (option.id === CUSTOM_RANGE_OPTION) {
      const newProps = { ...props, onClick };
      return (
        <li {...newProps}>
          <CustomAutocompleteNoOption>{label}</CustomAutocompleteNoOption>
        </li>
      );
    }
    return renderOption(props, option, state);
  };
};
