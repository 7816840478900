import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

interface DataType {
  idProject: string;
  idProduct: number;
  type: string;
  seletedPhotoIds: number[];
}

export const useAddPhotoToProductPhotoType = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function addPhotoToProductPhotoType(data: DataType) {
    return requester({
      method: 'put',
      path: `/admin/projects/${data.idProject}/products/${data.idProduct}/photos`,
      data: {
        type: data.type,
        medias: (data.seletedPhotoIds || []).map((id: string) => {
          return {
            id,
          };
        }),
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useAddPhotoToProductPhotoType;
