export interface ISMSTemplate {
  id: string;
  code: string;
  title: string;
  content: string;
  status: SMSTemplateStatus;
  brand: string;
  updatedAt: string;
  deletedAt: string;
}

export enum SMSTemplateStatus {
  NEW = 'NEW',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

