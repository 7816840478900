export const typoXl = {
  h1: {
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '58px',
  },
  h2: {
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '36px',
  },
  h3: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
  },
  h4: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
  },
  h5: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
  },
  title1: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
  },
  title2: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '26px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
  },
  label: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  body1: {
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
  },
  body2: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
  },
  caption1: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  caption2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
  },
  caption3: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  caption4: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },
  pageHeader: {
    fontFamily: '"IBM Plex Sans"',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '36px',
  },
};