import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logo from 'assets/logo.png'; 

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        align-items: center;
        text-decoration: none;
        height: 100%;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(`
        width: fit-content;
        height: 32px;        
`);

function Logo() {
  return (
    <LogoWrapper to="/">
      <LogoSignWrapper>
      <img src={logo} alt="logo" width={200} />
      </LogoSignWrapper>
    </LogoWrapper>
  );
}

export default Logo;
