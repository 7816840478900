import { IAudience } from 'types/audience';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import { IResponseLongPolling, IMetaLongPolling } from 'types/response';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  id: string;
  refreshInterval: number;
}
interface Result extends IRequestHookResult<{
  data: IAudience;
  meta: IMetaLongPolling;
}> {}

export const useLoadKOCAudienceDetails: IRequestHook<Props, Result> = ({idProject, id, refreshInterval, stopWhen}) => {
  const path = `/admin/projects/${idProject}/koc/audiences/${id}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen, refreshInterval });

  let outputData;
  if (response) {
    const responseData = response.data as IResponseLongPolling;
    outputData = {
      data: responseData.data,
      meta: responseData.meta,
    };
  }

  return {
    mutate,
    result: outputData,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadKOCAudienceDetails;
