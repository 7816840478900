import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

export const useDeActivelKOC = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function deactiveKOC(idProject: string, idKocCustomer: string) {
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}/koc/official-customers/${idKocCustomer}/deactivate`,
      getToken: getAccessTokenSilently,
    });
  };
};

export default useDeActivelKOC;
