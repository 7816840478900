import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

export const useDeletePotentialKoc = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function deleteScheme(idProject: string, idKocCustomer: string) {
    return requester({
      method: 'delete',
      path: `/admin/projects/${idProject}/koc/potential-customers/${idKocCustomer}`,
      getToken: getAccessTokenSilently,
    });
  };
};

export default useDeletePotentialKoc;
