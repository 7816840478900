import {
  Typography,
  IconButton,
  Link,
  Box,
  Tooltip,
  Theme,
  Button,
} from '@mui/material';
import React from 'react';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format } from 'date-fns';
import _capitalize from 'lodash/capitalize';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { checkIsAllow } from 'components/Permission/Permission';
import { DEFAULT_ITEM_EACH_ROW } from 'constants/common';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebookv2.svg';
import { nFormatter } from 'utils/common';
import { ICustomerAudience, IKOCCustomer } from 'types/customer';
import {
  ROUTE_PROJECT_KOC_AUDIENCE_DETAILS,
  ROUTE_PROJECT_KOC_AUDIENCE_PAGE_DETAILS,
  ROUTE_PROJECT_KOC_AUDIENCE_GROUP_DETAILS,
} from 'constants/routes';
import { IKOCAudiencePageGroup, IKOCAudiencePost } from 'types/koc';
import { IAudienceMetaData } from 'types/audience';
import CustomChip from 'components/CustomChip';
import { usePermissionContext } from 'contexts/permissionContext';
import { designThemeColors } from 'theme/schemes/PurpleLightTheme';

export interface IProfileAction {
  onClick: () => void;
  icon: any;
  text: string;
  color?: any;
}

export const RenderCustomerCell = (
  selector: string,
  value: string | number | ICustomerAudience[] | undefined,
  row: IKOCCustomer,
  isAllowSaveFilter: boolean,
  idProject: string,
  metaData: IAudienceMetaData | undefined,
  setRemovingKOC: (data: IKOCCustomer) => void,
  setIsOpenConfirmDialog: (data: boolean) => void,
  savePotentialKOC: (data: IKOCCustomer) => void,
  setIsOpen: (data: boolean) => void,
  dTypeAudienceState: any,
  setDTypeAudienceState: (data: any) => void,
  nTypePageState: any,
  setNTypePageState: (data: any) => void,
  nTypePostState: any,
  setNTypePostState: (data: any) => void,
  firstAudienceState: any,
  setFirstAudienceState: (data: any) => void,
  setSelectedPost: (data: IKOCAudiencePost) => void,
  setAddTagCustomer: (data: IKOCCustomer) => void,
  setRejectCustomer: (data: IKOCCustomer) => void,
  isShowRejectedCustomers: boolean,
  onUndoRejectCustomer: (data: IKOCCustomer) => void,
  handleClickProfileActions: (
    event: React.MouseEvent<HTMLButtonElement>,
    actions: IProfileAction[],
  ) => void,
) => {
  const { permissions = [] } = usePermissionContext();
  switch (selector) {
    case 'actions': {
      const actions: IProfileAction[] = [];
      if (
        checkIsAllow(permissions, [
          'project.koc_discovery.discovery_group.reject_user',
        ]) &&
        isShowRejectedCustomers
      ) {
        actions.push({
          onClick: () => {
            onUndoRejectCustomer(row);
          },
          icon: <UndoOutlinedIcon />,
          text: 'Undo rejection',
        });
      }
      if (
        checkIsAllow(permissions, [
          'project.koc_discovery.discovery_list.remove_from_discovery',
        ]) &&
        row.isPotentialKoc &&
        !isShowRejectedCustomers
      ) {
        actions.push({
          onClick: () => {
            setRemovingKOC(row);
            setIsOpenConfirmDialog(true);
          },
          icon: <BookmarkIcon />,
          text: 'Remove from Discovery List',
        });
      }
      if (
        checkIsAllow(permissions, [
          'project.koc_discovery.discovery_group.add_to_discovery',
        ]) &&
        !row.isPotentialKoc &&
        !isShowRejectedCustomers
      ) {
        actions.push({
          onClick: () => {
            savePotentialKOC(row);
          },
          icon: <BookmarkBorderOutlinedIcon />,
          text: 'Add to Discovery List',
        });
      }
      if (
        checkIsAllow(permissions, [
          'project.koc_discovery.discovery_group.add_tag',
        ])
      ) {
        actions.push({
          onClick: () => {
            setAddTagCustomer(row);
          },
          icon: <LocalOfferOutlinedIcon />,
          text: 'Add tag',
        });
      }
      if (
        checkIsAllow(permissions, [
          'project.koc_discovery.discovery_group.reject_user',
        ]) &&
        !row.isPotentialKoc &&
        !isShowRejectedCustomers
      ) {
        actions.push({
          onClick: () => {
            setRejectCustomer(row);
          },
          icon: <RemoveCircleOutlineOutlinedIcon />,
          text: 'Reject',
          color: 'error',
        });
      }

      const isDisabled =
        row && (isAllowSaveFilter || (row.isProjectKoc && !isAllowSaveFilter));

      const renderMainButton = (action) => (
        <Button
          disabled={isDisabled}
          variant="contained"
          size="small"
          onClick={action.onClick}
          sx={{
            px: 1,
            borderRight:
              actions.length > 1
                ? `1px solid ${designThemeColors.neutral[70]} !important`
                : '',
            borderTopRightRadius: actions.length > 1 ? 0 : 4,
            borderBottomRightRadius: actions.length > 1 ? 0 : 4,
            minWidth: actions.length > 1 ? '210px' : '246.5px',
          }}
        >
          {action.text}
        </Button>
      );

      const actionButtons = actions.length > 0 && (
        <Box display="flex">
          {renderMainButton(actions[0])}
          {actions.length > 1 && (
            <IconButton
              disabled={isDisabled}
              size="small"
              sx={{
                background: designThemeColors.neutral[100],
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                ':hover': {
                  background: designThemeColors.primary.main,
                },
                ':disabled': {
                  background: designThemeColors.neutral[20],
                },
              }}
              onClick={(event) => {
                handleClickProfileActions(event, actions.slice(1));
              }}
            >
              <ArrowDropDownOutlinedIcon
                sx={{
                  color: isDisabled ? designThemeColors.neutral[50] : '#fff',
                }}
              />
            </IconButton>
          )}
        </Box>
      );

      return actionButtons;
    }
    case 'uid':
      return (
        <Link
          href={`https://www.facebook.com/${value}`}
          target="__blank"
          sx={{ mr: '4px' }}
        >
          <FacebookIcon width={20} />
        </Link>
      );
    case 'name':
      return (
        <Box>
          <Box width={150} display="flex" alignItems="center">
            <Typography
              component="span"
              style={{ fontWeight: isShowRejectedCustomers ? 600 : 400 }}
            >
              {value}
            </Typography>
            {row && row.isKoc && (
              <Tooltip title="Other project’s KOC" arrow placement="top">
                <CheckCircleIcon
                  color="primary"
                  sx={{ width: 14, height: 14, ml: 1 }}
                />
              </Tooltip>
            )}
          </Box>
          {isShowRejectedCustomers && (
            <Typography color="error">Rejected</Typography>
          )}
        </Box>
      );
    case 'friends':
    case 'follow':
    case 'averageEngagement':
    case 'totalPost':
    case 'totalEngagement':
    case 'totalReaction':
    case 'totalComment':
    case 'totalShare':
      return nFormatter(value, 1);
    case 'lastPostedTime':
      return (
        <>
          {value && typeof value === 'string' && (
            <Box>
              <Typography variant="body2">
                {format(new Date(value), 'dd/MM/yyyy')}
              </Typography>
              <Typography
                variant="body2"
                color={(theme) => theme.palette.grey[500]}
              >
                {format(new Date(value), 'h:mm a')}
              </Typography>
            </Box>
          )}
        </>
      );
    case 'dTypeAudiences':
      return (
        <Box>
          {((row.dTypeAudiences as ICustomerAudience[]) ?? []).map(
            (item: ICustomerAudience, index: number) => (
              <span key={item.socialId}>
                {index < DEFAULT_ITEM_EACH_ROW && (
                  <Link
                    component={RouterLink}
                    target="_blank"
                    to={generatePath(ROUTE_PROJECT_KOC_AUDIENCE_DETAILS, {
                      idProject,
                      id: item.socialId,
                    })}
                  >
                    {item.name}
                    <br />
                  </Link>
                )}
                {index > DEFAULT_ITEM_EACH_ROW - 1 &&
                  dTypeAudienceState[row.id] && (
                    <Link
                      component={RouterLink}
                      target="_blank"
                      to={generatePath(ROUTE_PROJECT_KOC_AUDIENCE_DETAILS, {
                        idProject,
                        id: item.socialId,
                      })}
                    >
                      {item.name}
                      <br />
                    </Link>
                  )}
              </span>
            ),
          )}
          {row.dTypeAudiences?.length > DEFAULT_ITEM_EACH_ROW && (
            <Typography
              sx={{
                fontStyle: 'italic',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                const tempState = { ...dTypeAudienceState };
                tempState[row.id] = Boolean(!dTypeAudienceState[row.id]);
                setDTypeAudienceState(tempState);
              }}
            >
              {dTypeAudienceState[row.id] ? 'See less' : 'See more'}
            </Typography>
          )}
        </Box>
      );
    case 'nTypePage':
      return (
        <Box>
          {((row.pages as IKOCAudiencePageGroup[]) ?? []).map(
            (item: IKOCAudiencePageGroup, index: number) => (
              <span key={item.id}>
                {index < DEFAULT_ITEM_EACH_ROW && (
                  <Link
                    component={RouterLink}
                    target="_blank"
                    to={
                      item.type === 'PAGE'
                        ? generatePath(
                            ROUTE_PROJECT_KOC_AUDIENCE_PAGE_DETAILS,
                            {
                              idProject,
                              idPage: item?.id || '',
                            },
                          )
                        : generatePath(
                            ROUTE_PROJECT_KOC_AUDIENCE_GROUP_DETAILS,
                            {
                              idProject,
                              idGroup: item?.id || '',
                            },
                          )
                    }
                  >
                    {item?.name}
                    <br />
                  </Link>
                )}
                {index > DEFAULT_ITEM_EACH_ROW - 1 && nTypePageState[row.id] && (
                  <Link
                    component={RouterLink}
                    target="_blank"
                    to={
                      item.type === 'PAGE'
                        ? generatePath(
                            ROUTE_PROJECT_KOC_AUDIENCE_PAGE_DETAILS,
                            {
                              idProject,
                              idPage: item?.id || '',
                            },
                          )
                        : generatePath(
                            ROUTE_PROJECT_KOC_AUDIENCE_GROUP_DETAILS,
                            {
                              idProject,
                              idGroup: item?.id || '',
                            },
                          )
                    }
                  >
                    {item?.name}
                    <br />
                  </Link>
                )}
              </span>
            ),
          )}
          {row.pages?.length > DEFAULT_ITEM_EACH_ROW && (
            <Typography
              sx={{
                fontStyle: 'italic',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                const tempState = { ...nTypePageState };
                tempState[row.id] = Boolean(!nTypePageState[row.id]);
                setNTypePageState(tempState);
              }}
            >
              {nTypePageState[row.id] ? 'See less' : 'See more'}
            </Typography>
          )}
        </Box>
      );
    case 'nTypePost':
      return (
        <Box>
          {((row.posts as IKOCAudiencePost[]) ?? []).map(
            (item: IKOCAudiencePost, index: number) => (
              <span key={item.id}>
                {index < DEFAULT_ITEM_EACH_ROW && (
                  <Typography
                    onClick={() => {
                      setSelectedPost(item);
                      setIsOpen(true);
                    }}
                    sx={{
                      color: (theme: Theme) => theme.colors.primary.main,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {item.caption?.length > 30
                      ? `${item.caption.substring(0, 30)}...`
                      : item.caption || '--'}
                    <br />
                  </Typography>
                )}
                {index > DEFAULT_ITEM_EACH_ROW - 1 && nTypePostState[row.id] && (
                  <Typography
                    onClick={() => {
                      setSelectedPost(item);
                      setIsOpen(true);
                    }}
                    sx={{
                      color: (theme: Theme) => theme.colors.primary.main,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {item.caption?.length > 30
                      ? `${item.caption.substring(0, 30)}...`
                      : item.caption || '--'}
                    <br />
                  </Typography>
                )}
              </span>
            ),
          )}
          {row.posts?.length > DEFAULT_ITEM_EACH_ROW && (
            <Typography
              sx={{
                fontStyle: 'italic',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                const tempState = { ...nTypePostState };
                tempState[row.id] = Boolean(!nTypePostState[row.id]);
                setNTypePostState(tempState);
              }}
            >
              {nTypePostState[row.id] ? 'See less' : 'See more'}
            </Typography>
          )}
        </Box>
      );
    case 'firstAudiences':
      return (
        <Box>
          {((row.firstAudiences as ICustomerAudience[]) ?? []).map(
            (item: ICustomerAudience, index: number) => (
              <span key={item.socialId}>
                {index < DEFAULT_ITEM_EACH_ROW && (
                  <Link
                    component={RouterLink}
                    target="_blank"
                    to={generatePath(ROUTE_PROJECT_KOC_AUDIENCE_DETAILS, {
                      idProject,
                      id: item.socialId,
                    })}
                  >
                    {item.name}
                    <br />
                  </Link>
                )}
                {index > DEFAULT_ITEM_EACH_ROW - 1 &&
                  firstAudienceState[row.id] && (
                    <Link
                      component={RouterLink}
                      target="_blank"
                      to={generatePath(ROUTE_PROJECT_KOC_AUDIENCE_DETAILS, {
                        idProject,
                        id: item.socialId,
                      })}
                    >
                      {item.name}
                      <br />
                    </Link>
                  )}
              </span>
            ),
          )}
          {row.firstAudiences?.length > DEFAULT_ITEM_EACH_ROW && (
            <Typography
              sx={{
                fontStyle: 'italic',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                const tempState = { ...firstAudienceState };
                tempState[row.id] = Boolean(!firstAudienceState[row.id]);
                setFirstAudienceState(tempState);
              }}
            >
              {firstAudienceState[row.id] ? 'See less' : 'See more'}
            </Typography>
          )}
        </Box>
      );
    case 'phone':
      return <Typography textAlign="center">{value}</Typography>;
    case 'idCity':
      return (
        <span>
          {metaData?.cities?.find((item) => item.id === value)?.name || ''}
        </span>
      );
    case 'idHometown':
      return (
        <span>
          {metaData?.hometowns?.find((item) => item.id === value)?.name || ''}
        </span>
      );
    case 'sex':
      return (
        <>
          {value && typeof value === 'string' && (
            <span>{_capitalize(value)}</span>
          )}
        </>
      );
    case 'idRelationship':
      return (
        <span>
          {metaData?.relations?.find((item) => item.id === value)?.name}
        </span>
      );
    case 'product': {
      const MAX_LENGTH = 10;
      const renderChip = (label: string) => {
        if (label.length <= MAX_LENGTH) {
          return (
            <CustomChip
              color="default"
              label={label}
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          );
        }
        return (
          <Tooltip arrow title={label}>
            <CustomChip
              color="default"
              label={`${label.substring(0, MAX_LENGTH)}...`}
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          </Tooltip>
        );
      };
      return <>{row.product && renderChip(row.product.name)}</>;
    }
    case 'useCase': {
      const MAX_LENGTH = 10;
      const renderChip = (label: string) => {
        if (label.length <= MAX_LENGTH) {
          return (
            <CustomChip
              color="default"
              label={label}
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          );
        }
        return (
          <Tooltip arrow title={label}>
            <CustomChip
              color="default"
              label={`${label.substring(0, MAX_LENGTH)}...`}
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          </Tooltip>
        );
      };
      return <>{row?.useCase && renderChip(row.useCase.name)}</>;
    }
    case 'otherTag': {
      const MAX_LENGTH = 10;
      const renderChip = (label: string) => {
        if (label.length <= MAX_LENGTH) {
          return (
            <CustomChip
              color="default"
              label={label}
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          );
        }
        return (
          <Tooltip arrow title={label}>
            <CustomChip
              color="default"
              label={`${label.substring(0, MAX_LENGTH)}...`}
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          </Tooltip>
        );
      };
      return <>{row.other && renderChip(row.other)}</>;
    }
    case 'reason': {
      const MAX_REASON_LENGTH = 25;
      if (typeof value === 'string' && value.length > MAX_REASON_LENGTH) {
        return (
          <Tooltip arrow title={value}>
            <span>{`${value.substring(0, MAX_REASON_LENGTH)}...`}</span>
          </Tooltip>
        );
      }
      return value;
    }
    default:
      return value;
  }
};
