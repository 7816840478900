import { FC, useMemo, useState } from 'react';
import {
  Box,
  DialogContent,
  TextField,
  Typography,
  Autocomplete,
  Divider,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Dialog, { DialogActions } from 'components/Dialog';
import { getErrorMessage } from 'utils/common';
import { IKOCCustomer } from 'types/customer';
import { useRejectCustomer } from 'requests/koc';
import { useLoadReasons } from 'requests/reason';
import { IDiscoveryGroup } from 'types/discovery-group';
import { CustomAutocompleteNoOption } from 'components/CustomAutocomplete';
import { usePermissionContext } from 'contexts/permissionContext';
import { checkIsAllow } from 'components/Permission/Permission';
import ManageReasonDialog from './ManageReasonDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customer: IKOCCustomer;
  groupSelected: IDiscoveryGroup;
}

interface FormValues {
  reason?: string | null;
  otherReason?: string | null;
}

const otherReason = {
  id: 'other',
  name: 'Other',
};
const manageReason = {
  id: 'add',
  name: 'Add/Edit reason',
};

const schema = yup.object().shape({
  reason: yup.string().required('Required'),
  otherReason: yup.string().when('reason', (reason, otherReasonSchema) => {
    return reason === otherReason.name
      ? otherReasonSchema.required('Required')
      : otherReasonSchema.nullable();
  }),
});

const RejectDialog: FC<Props> = ({
  isOpen,
  onClose,
  onSuccess,
  customer,
  groupSelected,
}) => {
  const { idProject } = useParams();
  const { permissions } = usePermissionContext();
  const canManageReason = checkIsAllow(permissions, [
    'project.koc_discovery.discovery_group.manage_reason',
  ]);

  const rejectCustomer = useRejectCustomer();
  const { enqueueSnackbar } = useSnackbar();
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [isUpdateReasons, setIsUpdateReasons] = useState<boolean>(false);
  const { result: reasons, isLoading, mutate } = useLoadReasons({});
  const reasonsWithOther = useMemo(() => {
    if (!canManageReason) {
      return reasons ? [...reasons, otherReason] : [otherReason];
    }
    return reasons
      ? [...reasons, otherReason, manageReason]
      : [otherReason, manageReason];
  }, [reasons, canManageReason]);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      reason: customer?.other || null,
    },
  });

  const handleClose = (
    event?: any,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (onClose && reason !== 'backdropClick') {
      resetValue();
      onClose();
    }
  };

  const resetValue = () => {
    setIsUpdateReasons(false);
    reset({ reason: null, otherReason: null });
  };

  const onSubmitSuccess = (values: FormValues) => {
    setIsRequesting(true);
    rejectCustomer({
      idProject,
      idCustomer: customer.id,
      reason: values.otherReason || values.reason,
      idDiscoveryGroup: groupSelected.id,
      isRejected: true,
    })
      .then(() => {
        onSuccess();

        enqueueSnackbar(`Reject successfully`, {
          variant: 'success',
        });
        setIsRequesting(false);
        handleClose();
      })
      .catch((error) => {
        console.error('Reject customer: ', error);

        enqueueSnackbar(getErrorMessage(error, 'Something went wrong'), {
          variant: 'error',
        });
        setIsRequesting(false);
      });
  };

  const onSubmitError = (err) => {
    console.error('err', err);
  };

  const onOpenOtherDialog = () => {
    setValue('reason', otherReason.name);
  };

  const isOpenOtherDialog = watch('reason') === otherReason.name;

  return (
    <>
      <Dialog
        open={isOpen}
        title="Reject profile"
        subtitle="Select a reason why you reject this profile"
        onClose={handleClose}
      >
        <DialogContent>
          <Box my={3}>
            <Typography variant="label" mb={0.5}>
              Reason
            </Typography>
            <Controller
              name="reason"
              key={isOpenOtherDialog || isUpdateReasons ? 'other' : 'reason'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Autocomplete
                  loading={isLoading}
                  getOptionLabel={(option) => option.name}
                  options={reasonsWithOther}
                  fullWidth
                  value={reasonsWithOther.find((item) => item.name === value)}
                  onChange={(_, newValue) => {
                    onChange(newValue?.name || null);
                  }}
                  renderOption={(optionProps, option) => {
                    if (option.id !== manageReason.id) {
                      return (
                        <Box component="li" {...optionProps} key={option.id}>
                          {option.name}
                        </Box>
                      );
                    }
                    return (
                      <>
                        <Divider />
                        <Box
                          component="li"
                          {...optionProps}
                          style={{ paddingTop: '12px', paddingBottom: '12px' }}
                          key={option.id}
                          onClick={() => {
                            setIsUpdateReasons(true);
                          }}
                        >
                          <AddOutlinedIcon sx={{ mr: 1 }} />
                          {option.name}
                        </Box>
                      </>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        placeholder="Select a reason"
                        error={!!errors.reason}
                        helperText={errors.reason && 'Required'}
                      />
                    );
                  }}
                  noOptionsText={
                    <CustomAutocompleteNoOption onClick={onOpenOtherDialog}>
                      <Typography color="text.primary">
                        {otherReason.name}
                      </Typography>
                    </CustomAutocompleteNoOption>
                  }
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions
          onCancel={handleClose}
          onOk={handleSubmit(onSubmitSuccess, onSubmitError)}
          isLoading={isRequesting}
          okText="Confirm"
        />
      </Dialog>
      {isOpenOtherDialog && (
        <Dialog
          open={isOpenOtherDialog}
          title="Other reason"
          onClose={resetValue}
        >
          <DialogContent>
            <Box my={3}>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter Other"
                multiline
                minRows={3}
                error={!!errors.otherReason}
                helperText={errors.otherReason && 'Required'}
                InputProps={{ ...register('otherReason') }}
              />
            </Box>
          </DialogContent>
          <DialogActions
            onCancel={resetValue}
            onOk={handleSubmit(onSubmitSuccess, onSubmitError)}
            isLoading={isRequesting}
          />
        </Dialog>
      )}
      {isUpdateReasons && (
        <ManageReasonDialog
          isOpen={isUpdateReasons}
          onClose={resetValue}
          onSuccess={() => {
            setIsUpdateReasons(false);
            mutate();
          }}
        />
      )}
    </>
  );
};

export default RejectDialog;
