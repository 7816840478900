import { useAuth0 } from '@auth0/auth0-react';
import { AxiosResponse } from 'axios';
import { requester } from '../index';
import { IDiscoveryGroup } from '../../types/discovery-group';

export const useUpdateDiscoveryGroup = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function setUpdateGroup(
    idProject: number,
    id: number,
    dataUpdate: {
      isDefault?: boolean;
      name?: string;
      data?: any;
    },
  ): Promise<AxiosResponse<IDiscoveryGroup>> {
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}/koc/discovery/groups/${id}`,
      data: dataUpdate,
      getToken: getAccessTokenSilently,
    });
  };
};

export default useUpdateDiscoveryGroup;
