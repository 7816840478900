export const typoLg = {
    h1: {
      fontWeight: 700,
      fontSize: '36px',
      lineHeight: '44px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '28px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '24px',
    },
    h5: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '22px',
    },
    title1: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px',
    },
    title2: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    label: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px'
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
    },
    caption1: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
    },
    caption2: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    caption3: {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '14px',
    },
    caption4: {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '14px',
    },
    pageHeader: {
      fontFamily: '"IBM Plex Sans"',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
    },
  };