import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

export const useDeleteBrief = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function deleteBrief(idProject: string, idBrief: string) {
    return requester({
      method: 'delete',
      path: `/admin/projects/${idProject}/briefs/${idBrief}`,
      getToken: getAccessTokenSilently,
    });
  };
};

export default useDeleteBrief;
