import { useAuth0 } from '@auth0/auth0-react';
import { IProject } from 'types/project';
import { requester } from '../index';

interface DataType {
  idBrand: string;
  duplicateFromId?: string;
  name: string;
  notes: string;
  image?: string;
  brandInfo: string;
  campaignInfo: string;
  campaignPhotoTmp?: string;
}

export const useCreateProject = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function createProject(data: DataType) {
    const params: any = {
        id_brand: data.idBrand,
        name: data.name,
        notes: data.notes,
        image: data.image,
        brand_info: data.brandInfo,
        campaign_info: data.campaignInfo,
        campaign_photo_tmp: data.campaignPhotoTmp
    }
    if(data.duplicateFromId) {
      params.duplicate_from_id = data.duplicateFromId
    }
    return requester({
      method: 'post',
      path: '/admin/projects',
      data: params,
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IProject>(response.data));
  };
};

export default useCreateProject;
