import { FC, useEffect, useMemo } from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';
import CreateRangeDialog from 'components/Customers/CreateRangeDialog';
import {
  CustomAutocompleteNoOption,
  renderOptionWithCustomRange,
} from 'components/CustomAutocomplete';
import { useCustomAutocomplete } from 'hooks/useAutocomplete';
import { IOption, mapOptions, renderName, socialInteractionOptions } from './utils';

interface IAutoCompleteProp {
  defaultValue: string;
  onChange: (value: string) => void;
}

export const CustomRangeAutocomplete: FC<IAutoCompleteProp> = ({
  defaultValue,
  onChange,
}) => {
  const {
    isOpenDialog,
    onOpenDialog,
    onCloseDialog,
    selectedValues,
    setSelectedValues,
    items,
    setItems,
  } = useCustomAutocomplete<IOption>(
    socialInteractionOptions,
    useMemo(() => {
      return defaultValue ? mapOptions(defaultValue) : [];
    }, [defaultValue]),
  );

  useEffect(() => {
    if (defaultValue) {
      const appendedItems: IOption[] = [
        ...mapOptions(defaultValue),
        ...items,
      ].filter(
        (value, index, self) =>
          self.findIndex((m) => m.id === value.id) === index,
      );
      setItems(appendedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleCustomRange = (from: number | null, to: number | null) => {
    const option: IOption = {
      id: `${from || ''}-${to || ''}`,
      name: renderName(from, to),
    };
    setItems([option, ...socialInteractionOptions]);
    setSelectedValues([...selectedValues, option]);
    onChange(
      [...selectedValues, option]?.map((item) => item.id)?.toString() || '',
    );
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Autocomplete
        size="small"
        multiple
        fullWidth
        limitTags={3}
        value={selectedValues}
        options={items}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          setSelectedValues(newValue);
          onChange(newValue?.map((item) => item.id)?.toString() || '');
        }}
        renderInput={(params) => <TextField {...params} />}
        renderOption={renderOptionWithCustomRange(onOpenDialog)}
        noOptionsText={<CustomAutocompleteNoOption onClick={onOpenDialog} />}
      />
      <CreateRangeDialog
        open={isOpenDialog}
        onClose={onCloseDialog}
        onSuccess={(from, to) => handleCustomRange(from, to)}
      />
    </Box>
  );
};
