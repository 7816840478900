import { useMemo } from 'react';
import { IContent } from 'types/koc';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import { mapContentStatus } from 'utils/common';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  idKocCustomer: string;
  id: string;
}

interface Result extends IRequestHookResult<IContent> {}

export const useLoadKOContentDetail: IRequestHook<Props, Result> = ({
  idProject,
  idKocCustomer,
  id,
  stopWhen,
}) => {
  const path = `/admin/projects/${idProject}/koc/official-customers/${idKocCustomer}/contents/${id}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const responseData = useMemo(() => {
    if (response) {
      const result = response.data as IContent;
      result.status = mapContentStatus(result.status);
      return result;
    }
    return undefined;
  }, [response]);

  return {
    result: responseData,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadKOContentDetail;
