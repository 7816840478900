/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import _map from 'lodash/map';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Box,
  Typography,
  Theme,
  IconButton,
  Autocomplete,
  TextField,
  Divider,
  Button,
  Card,
  CardHeader,
  CardContent,
  ListItem,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Stack,
} from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { useSnackbar } from 'notistack';
import { getErrorMessage } from 'utils/common';
import { IAudienceMetaData } from 'types/audience';
import { useLoadKOCCustomerUnique } from 'requests/koc';
import {
  IKOCAudience,
  IKOCRank,
  IKOCCategory,
  IKOCAudiencePageGroup,
  IKOCAudiencePost,
} from 'types/koc';
import useModalState from 'hooks/useModalState';
import { useUpdateDiscoveryGroup } from 'requests/audiences';
import { CircularLoading } from 'components/loading';
import CustomChip from 'components/CustomChip';
import { CustomOption } from 'components/CustomAutocomplete';
import CustomDateRangeFilter, {
  ICreatedTimeOption,
} from 'components/CustomDateRangeFilter';
import useDateRangeFilter from 'hooks/useDateRangeFilter';
import { IUseCase } from 'types/use-case';
import { IProduct } from 'types/product';
import { IDataFilter, IDiscoveryGroup } from 'types/discovery-group';
import Permission, { checkIsAllow } from 'components/Permission/Permission';
import { usePermissionContext } from 'contexts/permissionContext';
import ConfirmDialog from 'components/ConfirmDialog';
import { getLabelText } from '../utils';
import CreateDiscoveryGroupDialog from './CreateDiscoveryGroupDialog';
import CustomNoOptionAutocomplete from './CustomNoOptionAutocomplete';
import SelectFieldDialog from './SelectFieldDialog';
import AddFilterTypeDialog from './AddFilterTypeDialog';
import CustomAutocomplete from './CustomAutocomplete';
import DFirstAudienceAutocomplete from './DFirstAudienceAutocomplete';

const CDPItems = [
  { name: 'City', key: 'cdpCities', group: 'Popular' },
  { name: 'Gender', key: 'cdpGenders', group: 'Popular' },
  { name: 'Age', key: 'cdpAges', group: 'Popular' },
  { name: 'Recency Score', key: 'cdpRecencies', group: 'Popular' },
  { name: 'Frequency Score', key: 'cdpFrequencies', group: 'Popular' },
  { name: 'Monetary Score', key: 'cdpMonetaries', group: 'Popular' },
];
const SocialItems = [
  { name: 'Number of Followers', key: 'follows', group: 'Popular' },
  { name: 'Number of Friends', key: 'friends', group: 'Popular' },
  { name: 'Average Engagement', key: 'averageEngagement', group: 'Popular' },
  { name: 'Age', key: 'ages', group: 'Popular' },
  { name: 'City', key: 'cities', group: 'Popular' },
  { name: 'Gender', key: 'genders', group: 'Others' },
  { name: 'Relationship', key: 'relations', group: 'Others' },
  { name: 'Hometown', key: 'hometowns', group: 'Others' },
  { name: 'D-type Audience', key: 'dTypeAudiences', group: 'Others' },
  { name: 'N-type Page/Group', key: 'pages', group: 'Others' },
  { name: 'N-type Post', key: 'posts', group: 'Others' },
  { name: 'First Party Data', key: 'firstAudiences', group: 'Others' },
  { name: 'KOC Category', key: 'kocCategories', group: 'Others' },
  { name: 'Total Post', key: 'totalPost', group: 'Others' },
  { name: 'Total Engagement', key: 'totalEngagement', group: 'Others' },
  { name: 'Total Reaction', key: 'totalReaction', group: 'Others' },
  { name: 'Total Comment', key: 'totalComment', group: 'Others' },
  { name: 'Total Share', key: 'totalShare', group: 'Others' },
  { name: 'Last Posted Time', key: 'lastPostedTime', group: 'Others' },
  { name: 'Product', key: 'product', group: 'Others' },
  { name: 'Use Case', key: 'useCase', group: 'Others' },
];

interface IOption {
  name: string;
  id: string;
}

const FilterConditions: FC<{
  metaData: IAudienceMetaData;
  hometowns: IOption[];
  rankData: IKOCRank;
  categories: IKOCCategory[];
  isLoadingMetaData: boolean;
  isLoadingRankData: boolean;
  isLoadingCategories: boolean;
  dTypeAudiences: IKOCAudience[];
  isLoadingDTypeAudiences: boolean;
  pages: IKOCAudiencePageGroup[];
  isLoadingNTypePages: boolean;
  posts: IKOCAudiencePost[];
  isLoadingNTypePosts: boolean;
  firstAudiences: IKOCAudience[];
  isLoadingFirstAudiences: boolean;
  isAllowSaveFilter?: boolean;
  audienceFilters: IDataFilter;
  displayFilterByAudience: boolean;
  groupSelected?: IDiscoveryGroup;
  filterChange: (conditions: IDataFilter) => void;
  onChangeDiscoveryGroup?: (data: IDiscoveryGroup) => void;
  onSaveFilter?: (conditions: IDataFilter) => void;
  products: IProduct[];
  isLoadingProducts?: boolean;
  useCases: IUseCase[];
  isLoadingUseCases?: boolean;
  disabled?: boolean;
}> = ({
  metaData,
  hometowns,
  dTypeAudiences,
  pages,
  posts,
  firstAudiences,
  rankData,
  categories,
  isLoadingMetaData,
  isLoadingDTypeAudiences,
  isLoadingNTypePages,
  isLoadingNTypePosts,
  isLoadingFirstAudiences,
  isLoadingRankData,
  isLoadingCategories,
  isAllowSaveFilter,
  audienceFilters,
  displayFilterByAudience,
  groupSelected,
  filterChange,
  onChangeDiscoveryGroup,
  onSaveFilter,
  products,
  isLoadingProducts,
  useCases,
  isLoadingUseCases,
  disabled = false,
}) => {
  const { idProject } = useParams();
  const getCustomerUnique = useLoadKOCCustomerUnique();
  const [filterType, setFilterType] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAddMore, setIsOpenAddMore] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>('');
  const [selectedSocialFields, setSelectedSocialFields] = useState<string[]>(
    [],
  );
  const [selectedCDPFields, setSelectedCDPFields] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isDisableSaveFilterButton, setIsDisableSaveFilterButton] =
    useState<boolean>(true);
  const [isLoadingUnique, setIsLoadingUnique] = useState<boolean>(false);
  const [conditionsToFilter, setConditionsToFilter] =
    useState<IDataFilter>(audienceFilters);
  const { filterOptions, setFilter, filter } = useDateRangeFilter();
  const [uniqueData, setUniqueData] = useState<number | null>(null);
  const [isConfirmLeave, setIsConfirmUpdate] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const updateDiscoveryGroup = useUpdateDiscoveryGroup();
  const {
    isOpen: isOpenFilterDialog,
    open,
    closeAndClearData,
  } = useModalState();
  useEffect(() => {
    setIsDisableSaveFilterButton(!isAllowSaveFilter);
  }, [isAllowSaveFilter]);
  useEffect(() => {
    setIsDisableSaveFilterButton(true);
  }, [groupSelected]);
  useEffect(() => {
    setConditionsToFilter({ ...audienceFilters });
    setFilter(audienceFilters.lastPostedTime);
  }, [audienceFilters]);

  const cities = metaData.cities || [];
  const friends = metaData.friends || [];
  const follows = metaData.follows || [];
  const genders = metaData.genders || [];
  const ages = metaData.ages || [];
  const relations = metaData.relations || [];

  const cdpRankList = [
    { name: '5', id: 'rank_1' },
    { name: '4', id: 'rank_2' },
    { name: '3', id: 'rank_3' },
    { name: '2', id: 'rank_4' },
    { name: '1', id: 'rank_5' },
  ];

  const {
    selectedCities,
    selectedFriends,
    selectedFollows,
    selectedGenders,
    selectedAges,
    selectedRelations,
    selectedCategories,
    selectedCDPCities,
    selectedCDPGenders,
    selectedCDPAges,
    selectedDTypeAudiences,
    selectedNTypePages,
    selectedNTypePosts,
    selectedFirstAudiences,
    selectedTotalPost,
    selectedTotalEngagement,
    selectedTotalReaction,
    selectedTotalComment,
    selectedTotalShare,
    selectedAverageEngagement,
    selectedHomeTowns,
    selectedProducts,
    selectedUseCases,
  } = useMemo(() => {
    return {
      selectedCities: audienceFilters.cities || [],
      selectedFriends: audienceFilters.friends || [],
      selectedFollows: audienceFilters.follows || [],
      selectedGenders: audienceFilters.genders || [],
      selectedAges: audienceFilters.ages || [],
      selectedRelations: audienceFilters.relations || [],
      selectedCategories: audienceFilters.kocCategories || [],
      selectedCDPCities: audienceFilters.cdpCities || [],
      selectedCDPGenders: audienceFilters.cdpGenders || [],
      selectedCDPAges: audienceFilters.cdpAges || [],
      selectedDTypeAudiences: (audienceFilters.dTypeAudiences || []).map(
        (item) => {
          return (
            dTypeAudiences.find(({ socialId }) => socialId === item.socialId) ??
            item
          );
        },
      ),
      selectedNTypePages: (audienceFilters.pages || []).map((item) => {
        return pages.find(({ id }) => id === item.id) ?? item;
      }),
      selectedNTypePosts: (audienceFilters.posts || []).map((item) => {
        return posts.find(({ id }) => id === item.id) ?? item;
      }),
      selectedFirstAudiences: (audienceFilters.firstAudiences || []).map(
        (item) => {
          return (
            firstAudiences.find(({ socialId }) => socialId === item.socialId) ??
            item
          );
        },
      ),
      selectedTotalPost: audienceFilters.totalPost || { from: null, to: null },
      selectedTotalEngagement: audienceFilters.totalEngagement || {
        from: null,
        to: null,
      },
      selectedTotalReaction: audienceFilters.totalReaction || {
        from: null,
        to: null,
      },
      selectedTotalComment: audienceFilters.totalComment || {
        from: null,
        to: null,
      },
      selectedTotalShare: audienceFilters.totalShare || {
        from: null,
        to: null,
      },
      selectedAverageEngagement: audienceFilters.averageEngagement || {
        from: null,
        to: null,
      },
      selectedHomeTowns: audienceFilters.hometowns || [],
      selectedProducts: audienceFilters.products || [],
      selectedUseCases: audienceFilters.useCases || [],
    };
  }, [audienceFilters, dTypeAudiences, pages, posts, firstAudiences]);

  const handleClearFilters = (type: string) => {
    const temp = filterType.filter((item) => item !== type);
    setFilterType(temp);
    let conditions = { ...audienceFilters };
    if (type === 'social') {
      setSelectedSocialFields([]);
      SocialItems.forEach((item) => {
        conditions = {
          ...conditions,
          [item.key]: [
            'averageEngagement',
            'totalPost',
            'totalEngagement',
            'totalReaction',
            'totalComment',
            'totalShare',
          ].includes(item.key)
            ? { from: null, to: null }
            : [],
        };
      });
    }
    if (type === 'cdp') {
      setSelectedCDPFields([]);
      CDPItems.forEach((item) => {
        conditions = { ...conditions, [item.key]: [] };
      });
    }
    filterChange(conditions);
    setConditionsToFilter(conditions);
    setUniqueData(null);
  };

  useEffect(() => {
    if (groupSelected) {
      if (groupSelected.data.filterType) {
        setFilterType(groupSelected.data.filterType);
      }
      if (groupSelected.data.selectedSocialFields) {
        setSelectedSocialFields(groupSelected.data.selectedSocialFields);
      }
      if (groupSelected.data.selectedCdpFields) {
        setSelectedCDPFields(groupSelected.data.selectedCdpFields);
      }
      setGroupName(groupSelected?.name || '');
    } else {
      setFilterType([]);
      setSelectedSocialFields([]);
      setSelectedCDPFields([]);
      setGroupName('');
    }
    setIsUpdating(false);
    setUniqueData(null);
  }, [groupSelected]);

  const removeFilterField = (key: string, type: string) => {
    filterChange({
      ...conditionsToFilter,
      [key]: [
        'averageEngagement',
        'totalPost',
        'totalEngagement',
        'totalReaction',
        'totalComment',
        'totalShare',
      ].includes(key)
        ? { from: null, to: null }
        : [],
    });
    setConditionsToFilter({ ...conditionsToFilter, [key]: [] });
    if (type === 'social') {
      const index = selectedSocialFields.findIndex(
        (item: string) => item === key,
      );
      const resultValues = [...selectedSocialFields];
      resultValues.splice(index, 1);
      setSelectedSocialFields(resultValues);
    }
    if (type === 'cdp') {
      const index = selectedCDPFields.findIndex((item: string) => item === key);
      const resultValues = [...selectedCDPFields];
      resultValues.splice(index, 1);
      setSelectedCDPFields(resultValues);
    }
  };

  const renderRecency = (option: IOption) => {
    let result = '';
    let from: number | null = null;
    let to: number | null = null;
    const item = rankData?.recency?.data[option.id];
    if (item) {
      from = item.from
        ? Math.round(
            (new Date().getTime() - new Date(item.from).getTime()) /
              (1000 * 3600 * 24),
          )
        : null;
      to = item.to
        ? Math.round(
            (new Date().getTime() - new Date(item.to).getTime()) /
              (1000 * 3600 * 24),
          )
        : null;
    }
    if ((from || from === 0) && (to || to === 0)) {
      result = `${to} - ${from} day(s) ago`;
    }
    return result;
  };

  const renderFrequency = (option: IOption) => {
    let result = '';
    const item = rankData?.frequency?.data[option.id];
    if (item && (item.from || item.to)) {
      result = `${item.from || '--'} - ${item.to || '--'} times`;
    }
    return result;
  };

  const renderMonetary = (option: IOption) => {
    let from = '';
    let to = '';
    const item = rankData?.monetary?.data[option.id];
    if (item?.from?.length - 3 < 4) {
      from = item.from;
    }
    if (item?.to?.length - 3 < 4) {
      to = item.to;
    }

    if (item?.from?.length - 3 > 3 && item?.from?.length - 3 < 7) {
      from = `${item.from / 1000}K`;
    }
    if (item?.to?.length - 3 > 3 && item?.to?.length - 3 < 7) {
      to = `${item.to / 1000}K`;
    }

    if (item?.from?.length - 3 > 6 && item?.from?.length - 3 < 10) {
      from = `${item.from / 1000000}M`;
    }
    if (item?.to?.length - 3 > 6 && item?.to?.length - 3 < 10) {
      to = `${item.to / 1000000}M`;
    }

    if (item?.from?.length - 3 > 9) {
      from = `${item.from / 1000000000}B`;
    }
    if (item?.to?.length - 3 > 9) {
      to = `${item.to / 1000000000}B`;
    }

    return from || to ? `${from} - ${to}` : '';
  };

  const onChangeCheckDefault = async () => {
    if (groupSelected && groupSelected.idProject && groupSelected.id) {
      setIsUpdating(true);
      try {
        const res = await updateDiscoveryGroup(
          groupSelected?.idProject,
          groupSelected?.id,
          {
            isDefault: !groupSelected?.isDefault,
          },
        );
        setIsUpdating(false);
        if (onChangeDiscoveryGroup) onChangeDiscoveryGroup(res.data);
      } catch (e) {
        setIsUpdating(false);
        // TODO Handle error
      }
    }
  };

  const onUpdateGroup = async () => {
    if (groupSelected && groupSelected.idProject && groupSelected.id) {
      setIsUpdating(true);
      try {
        const res = await updateDiscoveryGroup(
          groupSelected?.idProject,
          groupSelected?.id,
          {
            name: groupName,
            data: {
              filterType,
              selectedSocialFields,
              selectedCDPFields,
              dataFilter: conditionsToFilter,
            },
          },
        );
        setIsUpdating(false);
        if (onChangeDiscoveryGroup) onChangeDiscoveryGroup(res.data);
        enqueueSnackbar(`Updated successfully`, {
          variant: 'success',
        });
      } catch (e) {
        setIsUpdating(false);
        // TODO Handle error
      }
    }
  };

  const handleNumberFieldChange = (
    objKey: string,
    key: string,
    value: string,
  ) => {
    const temFilterData = {
      ...conditionsToFilter,
    };
    if (
      temFilterData &&
      Object.prototype.hasOwnProperty.call(temFilterData, objKey)
    ) {
      temFilterData[objKey][key] =
        value !== undefined && value !== null ? Number(value) : null;
    } else {
      temFilterData[objKey] = {
        [key]: value !== undefined && value !== null ? Number(value) : null,
        [key === 'from' ? 'to' : 'from']: null,
      };
    }
    filterChange({ ...temFilterData });
    setConditionsToFilter({ ...temFilterData });
  };

  const loadUniqueAudience = () => {
    setIsLoadingUnique(true);
    const filterLastPostedTime: any = {
      from: undefined,
      to: undefined,
      time: undefined,
    };
    if (
      filterOptions.some(
        (option) =>
          conditionsToFilter?.lastPostedTime &&
          option.id === conditionsToFilter.lastPostedTime.id,
      )
    ) {
      filterLastPostedTime.time =
        conditionsToFilter?.lastPostedTime?.id || undefined;
    }
    filterLastPostedTime.from = conditionsToFilter?.lastPostedTime?.from
      ? new Date(conditionsToFilter.lastPostedTime.from).toISOString()
      : undefined;
    filterLastPostedTime.to = conditionsToFilter?.lastPostedTime?.to
      ? new Date(conditionsToFilter.lastPostedTime.to).toISOString()
      : undefined;
    const castedValue = {
      cities: _map(conditionsToFilter.cities, 'id'),
      friends: _map(conditionsToFilter.friends, 'id'),
      follows: _map(conditionsToFilter.follows, 'id'),
      genders: _map(conditionsToFilter.genders, 'id'),
      ages: _map(conditionsToFilter.ages, 'id'),
      relations: _map(conditionsToFilter.relations, 'id'),
      dTypeAudiences: _map(conditionsToFilter.dTypeAudiences, 'socialId'),
      firstAudiences: _map(conditionsToFilter.firstAudiences, 'socialId'),
      pages: _map(conditionsToFilter.pages, 'id'),
      posts: _map(conditionsToFilter.posts, 'id'),
      uids: [],
      phones: [],
      cdpCities: _map(conditionsToFilter.cdpCities, 'id'),
      cdpGenders: _map(conditionsToFilter.cdpGenders, 'id'),
      cdpAges: _map(conditionsToFilter.cdpAges, 'id'),
      cdpRecencies: conditionsToFilter.cdpRecencies,
      cdpFrequencies: conditionsToFilter.cdpFrequencies,
      cdpMonetaries: conditionsToFilter.cdpMonetaries,
      kocCategories: _map(conditionsToFilter.kocCategories, 'id'),
      totalPost: conditionsToFilter.totalPost,
      totalEngagement: conditionsToFilter.totalEngagement,
      totalReaction: conditionsToFilter.totalReaction,
      totalComment: conditionsToFilter.totalComment,
      totalShare: conditionsToFilter.totalShare,
      fromPostedTime: filterLastPostedTime?.from,
      toPostedTime: filterLastPostedTime?.to,
      postedTime: filterLastPostedTime?.time,
      hometowns: _map(conditionsToFilter.hometowns, 'id'),
      averageEngagement: conditionsToFilter.averageEngagement,
      products: _map(conditionsToFilter.products, 'id'),
      useCases: _map(conditionsToFilter.useCases, 'id'),
    };
    getCustomerUnique({ idProject, ...castedValue })
      .then((res: { total: number }) => {
        setUniqueData(res.total);
        setIsLoadingUnique(false);
      })
      .catch((err) => {
        setIsLoadingUnique(false);
        if (err?.status > 499) {
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        } else {
          enqueueSnackbar(getErrorMessage(err, 'Something went wrong'), {
            variant: 'error',
          });
        }
        console.error('unique err', err);
      });
  };

  const { permissions } = usePermissionContext();
  const canUpdateGroup = checkIsAllow(permissions, [
    'project.koc_discovery.discovery_group.edit',
  ]);
  return (
    <Box
      sx={{
        mb: 3,
        p: 2,
        pt: 0,
        border: '1px solid',
        borderColor: (theme: Theme) => theme.palette.grey[400],
        width: '100%',
        borderRadius: 1,
        position: 'relative',
      }}
    >
      {isUpdating && (
        <CircularLoading
          sx={{
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#0000001a',
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 1,
        }}
      >
        <Typography sx={{ fontWeight: '500 !important' }}>FILTER</Typography>
        {groupSelected && (
          <Permission
            permissions={['project.koc_discovery.discovery_group.edit']}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={groupSelected.isDefault}
                    onChange={onChangeCheckDefault}
                    disabled={disabled}
                  />
                }
                label="Default View"
              />
            </Box>
          </Permission>
        )}
      </Box>
      <Box sx={{ borderTop: '1px solid rgba(34, 51, 84, 0.1)', pt: 2 }}>
        {groupSelected && !canUpdateGroup && (
          <Box
            sx={{
              mb: 2,
            }}
          >
            <Typography component="span" variant="body2">
              Group Name: {groupSelected.name}
            </Typography>
          </Box>
        )}
        {groupSelected && canUpdateGroup && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '200px',
              mb: 2,
            }}
          >
            <Typography component="span" variant="body2" width={128}>
              Group Name *
            </Typography>
            <Box width="100%">
              <TextField
                fullWidth
                value={groupName}
                size="small"
                onChange={(e) => {
                  setGroupName(e.target.value);
                  setIsDisableSaveFilterButton(false);
                }}
                minRows={1}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 255,
                }}
                placeholder="Group name..."
                disabled={disabled}
              />
            </Box>
          </Box>
        )}
        {filterType?.length > 0 && (
          <Permission
            permissions={[
              'project.koc_discovery.discovery_group.estimate_size',
            ]}
          >
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              spacing={1}
              fontSize="1rem"
            >
              <Typography variant="subtitle2" component="span" pl={1} mr={1}>
                Estimated Audience size:
              </Typography>
              <Typography variant="subtitle2" component="span">
                {uniqueData === null || isLoadingUnique
                  ? '--'
                  : new Intl.NumberFormat('en-US', {}).format(uniqueData)}
              </Typography>
            </Stack>
          </Permission>
        )}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          marginBottom={1}
        >
          {filterType.includes('social') && (
            <Grid item xs={6}>
              <Card variant="outlined">
                <CardHeader
                  sx={{ '.MuiCardHeader-action': { alignSelf: 'center' } }}
                  title={
                    <Typography>
                      Filter for <b>3rd Party Data</b>
                    </Typography>
                  }
                  action={
                    <Button
                      variant="text"
                      sx={{
                        p: 0,
                        mr: 0,
                        color: '#223354',
                        fontWeight: 'normal',
                        '&:hover': { background: 'white' },
                      }}
                      onClick={() => handleClearFilters('social')}
                      startIcon={
                        <CloseTwoToneIcon fontSize="small" color="error" />
                      }
                      disabled={disabled}
                    >
                      Clear filters
                    </Button>
                  }
                />
                <Divider />
                <CardContent>
                  {selectedSocialFields.includes('follows') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomNoOptionAutocomplete
                        disabled={disabled}
                        label="Number of Followers"
                        objKey="follows"
                        isLoading={isLoadingMetaData}
                        values={selectedFollows}
                        options={follows}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('follows', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('friends') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomNoOptionAutocomplete
                        disabled={disabled}
                        label="Number of Friends"
                        objKey="friends"
                        isLoading={isLoadingMetaData}
                        values={selectedFriends}
                        options={friends}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('friends', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('averageEngagement') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      mb={1}
                    >
                      <Box>
                        <CardHeader
                          sx={{
                            '.MuiCardHeader-action': { alignSelf: 'center' },
                            p: 0,
                          }}
                          title={
                            <Typography
                              variant="label"
                              sx={{ fontWeight: '400 !important' }}
                            >
                              Average Engagement
                            </Typography>
                          }
                          action={
                            <Button
                              variant="text"
                              disabled={
                                (!selectedAverageEngagement.from &&
                                  !selectedAverageEngagement.to) ||
                                disabled
                              }
                              sx={{ p: 0, mr: 0 }}
                              onClick={() => {
                                const temFilterData = {
                                  ...conditionsToFilter,
                                  averageEngagement: {
                                    from: null,
                                    to: null,
                                  },
                                };
                                filterChange({ ...temFilterData });
                                setConditionsToFilter({ ...temFilterData });
                              }}
                            >
                              <Typography variant="caption2">Clear</Typography>
                            </Button>
                          }
                        />
                        <CardContent
                          sx={{
                            p: '0 !important',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.averageEngagement?.from ||
                                  ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.averageEngagement?.from &&
                                    conditionsToFilter?.averageEngagement
                                      ?.from < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.averageEngagement?.from &&
                                  conditionsToFilter?.averageEngagement?.from <
                                    0
                                    ? 'From must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'averageEngagement',
                                    'from',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography>to</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.averageEngagement?.to ||
                                  ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.averageEngagement?.to &&
                                    conditionsToFilter?.averageEngagement?.to <
                                      0,
                                )}
                                helperText={
                                  conditionsToFilter?.averageEngagement?.to &&
                                  conditionsToFilter?.averageEngagement?.to < 0
                                    ? 'To must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'averageEngagement',
                                    'to',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Box>
                      <IconButton
                        onClick={() =>
                          removeFilterField('averageEngagement', 'social')
                        }
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          mt: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('ages') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomNoOptionAutocomplete
                        disabled={disabled}
                        label="Age"
                        objKey="ages"
                        isLoading={isLoadingMetaData}
                        values={selectedAges}
                        options={ages}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('ages', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('cities') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomAutocomplete
                        disabled={disabled}
                        label="City"
                        objKey="cities"
                        values={selectedCities}
                        options={cities}
                        isLoading={isLoadingMetaData}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('cities', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('genders') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomAutocomplete
                        disabled={disabled}
                        label="Gender"
                        objKey="genders"
                        values={selectedGenders}
                        options={genders}
                        isLoading={isLoadingMetaData}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('genders', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('relations') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomAutocomplete
                        disabled={disabled}
                        label="Relationship"
                        objKey="relations"
                        values={selectedRelations}
                        options={relations}
                        isLoading={isLoadingMetaData}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('relations', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('hometowns') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomAutocomplete
                        disabled={disabled}
                        label="Hometown"
                        objKey="hometowns"
                        values={selectedHomeTowns}
                        options={hometowns}
                        isLoading={isLoadingMetaData}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('hometowns', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {displayFilterByAudience &&
                    selectedSocialFields.includes('dTypeAudiences') && (
                      <DFirstAudienceAutocomplete
                        disabled={disabled}
                        label="D-type Audience"
                        objKey="dTypeAudiences"
                        values={selectedDTypeAudiences}
                        options={dTypeAudiences || []}
                        isLoading={isLoadingDTypeAudiences}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                        }}
                        setConditionsToFilter={(data: IDataFilter) => {
                          setConditionsToFilter(data);
                        }}
                        removeFilterField={(data: string) => {
                          removeFilterField(data, 'social');
                        }}
                      />
                    )}
                  {displayFilterByAudience &&
                    selectedSocialFields.includes('pages') && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="end"
                        mb={1}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="start"
                          width="100%"
                        >
                          <CustomAutocomplete
                            disabled={disabled}
                            label="N-type Page/Group"
                            objKey="pages"
                            values={selectedNTypePages}
                            limitTags={3}
                            options={pages || []}
                            isLoading={isLoadingNTypePages}
                            audienceFilters={conditionsToFilter}
                            filterChange={(data: IDataFilter) => {
                              filterChange(data);
                              setConditionsToFilter(data);
                            }}
                            renderCustomOption={(
                              props,
                              option,
                              { selected },
                            ) => (
                              <CustomOption
                                key={option.id}
                                label={
                                  <Typography>
                                    {option.name} (
                                    <b>{option.totalUniqueProfiles || 0}</b>)
                                  </Typography>
                                }
                                selected={selected}
                                {...props}
                              />
                            )}
                            renderCustomTags={(
                              value: readonly IKOCAudiencePageGroup[],
                              getTagProps,
                            ) =>
                              value.map(
                                (option: IKOCAudiencePageGroup, index) => {
                                  const existOption = (pages || []).some(
                                    ({ id }) => id === option.id,
                                  );
                                  return (
                                    <CustomChip
                                      {...getTagProps({ index })}
                                      color="primary"
                                      label={getLabelText(
                                        <>
                                          {option.name}(
                                          <b>
                                            {option.totalUniqueProfiles || 0}
                                          </b>
                                          )
                                        </>,
                                        existOption,
                                      )}
                                    />
                                  );
                                },
                              )
                            }
                          />
                        </Box>
                        <IconButton
                          onClick={() => removeFilterField('pages', 'social')}
                          color="secondary"
                          size="small"
                          sx={{
                            m: 0,
                            ml: 1,
                            p: 1,
                            pr: 0,
                            '&:hover': { background: 'white' },
                          }}
                          disabled={disabled}
                        >
                          <CloseTwoToneIcon color="error" />
                        </IconButton>
                      </Box>
                    )}
                  {displayFilterByAudience &&
                    selectedSocialFields.includes('posts') && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="end"
                        mb={1}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="start"
                          width="100%"
                        >
                          <CustomAutocomplete
                            disabled={disabled}
                            label="N-type Post"
                            objKey="posts"
                            values={selectedNTypePosts}
                            limitTags={3}
                            options={posts || []}
                            isLoading={isLoadingNTypePosts}
                            audienceFilters={conditionsToFilter}
                            filterChange={(data: IDataFilter) => {
                              filterChange(data);
                              setConditionsToFilter(data);
                            }}
                            renderCustomOption={(
                              props,
                              option,
                              { selected },
                            ) => (
                              <CustomOption
                                key={option.id}
                                label={
                                  <Typography>
                                    {option.caption?.substring(0, 60)} (
                                    <b>{option.totalUniqueProfiles || 0}</b>)
                                  </Typography>
                                }
                                selected={selected}
                                {...props}
                              />
                            )}
                            renderCustomTags={(
                              value: readonly IKOCAudiencePost[],
                              getTagProps,
                            ) =>
                              value.map((option: IKOCAudiencePost, index) => (
                                <CustomChip
                                  {...getTagProps({ index })}
                                  color="primary"
                                  label={getLabelText(
                                    <>
                                      {option.caption?.substring(0, 40)}...(
                                      <b>{option.totalUniqueProfiles || 0}</b>)
                                    </>,
                                    posts.some(({ id }) => id === option.id),
                                  )}
                                />
                              ))
                            }
                          />
                        </Box>
                        <IconButton
                          onClick={() => removeFilterField('posts', 'social')}
                          color="secondary"
                          size="small"
                          sx={{
                            m: 0,
                            ml: 1,
                            p: 1,
                            pr: 0,
                            '&:hover': { background: 'white' },
                          }}
                          disabled={disabled}
                        >
                          <CloseTwoToneIcon color="error" />
                        </IconButton>
                      </Box>
                    )}
                  {displayFilterByAudience &&
                    selectedSocialFields.includes('firstAudiences') && (
                      <DFirstAudienceAutocomplete
                        disabled={disabled}
                        label="First Party Data"
                        objKey="firstAudiences"
                        values={selectedFirstAudiences}
                        options={firstAudiences || []}
                        isLoading={isLoadingFirstAudiences}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                        }}
                        setConditionsToFilter={(data: IDataFilter) => {
                          setConditionsToFilter(data);
                        }}
                        removeFilterField={(data: string) => {
                          removeFilterField(data, 'social');
                        }}
                      />
                    )}
                  {selectedSocialFields.includes('products') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                      width="100%"
                    >
                      <CustomAutocomplete
                        disabled={disabled}
                        label="Product"
                        objKey="products"
                        values={selectedProducts}
                        options={products}
                        isLoading={!!isLoadingProducts}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                        renderCustomTags={(
                          value: readonly IProduct[],
                          getTagProps,
                        ) =>
                          value.map((option: IProduct, index) => {
                            return (
                              <CustomChip
                                {...getTagProps({ index })}
                                color="primary"
                                label={
                                  option.name.length > 30
                                    ? `${option.name.substring(0, 30)}...`
                                    : option.name
                                }
                              />
                            );
                          })
                        }
                      />
                      <IconButton
                        onClick={() => removeFilterField('products', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('useCases') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomAutocomplete
                        disabled={disabled}
                        label="Use case"
                        objKey="useCases"
                        values={selectedUseCases}
                        options={useCases}
                        isLoading={!!isLoadingUseCases}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('useCases', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('kocCategories') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomAutocomplete
                        disabled={disabled}
                        label="KOC Category"
                        objKey="kocCategories"
                        values={selectedCategories}
                        options={categories}
                        isLoading={isLoadingCategories}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() =>
                          removeFilterField('kocCategories', 'social')
                        }
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('totalPost') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      mb={1}
                    >
                      <Box>
                        <CardHeader
                          sx={{
                            '.MuiCardHeader-action': { alignSelf: 'center' },
                            p: 0,
                          }}
                          title={
                            <Typography
                              variant="label"
                              sx={{ fontWeight: '400 !important' }}
                            >
                              Total Post
                            </Typography>
                          }
                          action={
                            <Button
                              variant="text"
                              disabled={
                                (!selectedTotalPost.from &&
                                  !selectedTotalPost.to) ||
                                disabled
                              }
                              sx={{ p: 0, mr: 0 }}
                              onClick={() => {
                                const temFilterData = {
                                  ...conditionsToFilter,
                                  totalPost: { from: null, to: null },
                                };
                                filterChange({ ...temFilterData });
                                setConditionsToFilter({ ...temFilterData });
                              }}
                            >
                              <Typography variant="caption2">Clear</Typography>
                            </Button>
                          }
                        />
                        <CardContent
                          sx={{
                            p: '0 !important',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.totalPost?.from || ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.totalPost?.from &&
                                    conditionsToFilter?.totalPost?.from < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalPost?.from &&
                                  conditionsToFilter?.totalPost?.from < 0
                                    ? 'From must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalPost',
                                    'from',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography>to</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={conditionsToFilter?.totalPost?.to || ''}
                                error={Boolean(
                                  conditionsToFilter?.totalPost?.to &&
                                    conditionsToFilter?.totalPost?.to < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalPost?.to &&
                                  conditionsToFilter?.totalPost?.to < 0
                                    ? 'To must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalPost',
                                    'to',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Box>
                      <IconButton
                        onClick={() => removeFilterField('totalPost', 'social')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          mt: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('totalEngagement') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      mb={1}
                    >
                      <Box>
                        <CardHeader
                          sx={{
                            '.MuiCardHeader-action': { alignSelf: 'center' },
                            p: 0,
                          }}
                          title={
                            <Typography
                              variant="label"
                              sx={{ fontWeight: '400 !important' }}
                            >
                              Total Engagement
                            </Typography>
                          }
                          action={
                            <Button
                              variant="text"
                              disabled={
                                (!selectedTotalEngagement.from &&
                                  !selectedTotalEngagement.to) ||
                                disabled
                              }
                              sx={{ p: 0, mr: 0 }}
                              onClick={() => {
                                const temFilterData = {
                                  ...conditionsToFilter,
                                  totalEngagement: { from: null, to: null },
                                };
                                filterChange({ ...temFilterData });
                                setConditionsToFilter({ ...temFilterData });
                              }}
                            >
                              <Typography variant="caption2">Clear</Typography>
                            </Button>
                          }
                        />
                        <CardContent
                          sx={{
                            p: '0 !important',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.totalEngagement?.from ||
                                  ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.totalEngagement?.from &&
                                    conditionsToFilter?.totalEngagement?.from <
                                      0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalEngagement?.from &&
                                  conditionsToFilter?.totalEngagement?.from < 0
                                    ? 'From must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalEngagement',
                                    'from',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography>to</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.totalEngagement?.to || ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.totalEngagement?.to &&
                                    conditionsToFilter?.totalEngagement?.to < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalEngagement?.to &&
                                  conditionsToFilter?.totalEngagement?.to < 0
                                    ? 'To must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalEngagement',
                                    'to',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Box>
                      <IconButton
                        onClick={() =>
                          removeFilterField('totalEngagement', 'social')
                        }
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          mt: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('totalReaction') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      mb={1}
                    >
                      <Box>
                        <CardHeader
                          sx={{
                            '.MuiCardHeader-action': { alignSelf: 'center' },
                            p: 0,
                          }}
                          title={
                            <Typography
                              variant="label"
                              sx={{ fontWeight: '400 !important' }}
                            >
                              Total Reaction
                            </Typography>
                          }
                          action={
                            <Button
                              variant="text"
                              disabled={
                                (!selectedTotalReaction.from &&
                                  !selectedTotalReaction.to) ||
                                disabled
                              }
                              sx={{ p: 0, mr: 0 }}
                              onClick={() => {
                                const temFilterData = {
                                  ...conditionsToFilter,
                                  totalReaction: { from: null, to: null },
                                };
                                filterChange({ ...temFilterData });
                                setConditionsToFilter({ ...temFilterData });
                              }}
                            >
                              <Typography variant="caption2">Clear</Typography>
                            </Button>
                          }
                        />
                        <CardContent
                          sx={{
                            p: '0 !important',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.totalReaction?.from || ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.totalReaction?.from &&
                                    conditionsToFilter?.totalReaction?.from < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalReaction?.from &&
                                  conditionsToFilter?.totalReaction?.from < 0
                                    ? 'From must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalReaction',
                                    'from',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography>to</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.totalReaction?.to || ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.totalReaction?.to &&
                                    conditionsToFilter?.totalReaction?.to < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalReaction?.to &&
                                  conditionsToFilter?.totalReaction?.to < 0
                                    ? 'To must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalReaction',
                                    'to',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Box>
                      <IconButton
                        onClick={() =>
                          removeFilterField('totalReaction', 'social')
                        }
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          mt: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('totalComment') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      mb={1}
                    >
                      <Box>
                        <CardHeader
                          sx={{
                            '.MuiCardHeader-action': { alignSelf: 'center' },
                            p: 0,
                          }}
                          title={
                            <Typography
                              variant="label"
                              sx={{ fontWeight: '400 !important' }}
                            >
                              Total Comment
                            </Typography>
                          }
                          action={
                            <Button
                              variant="text"
                              disabled={
                                (!selectedTotalComment.from &&
                                  !selectedTotalComment.to) ||
                                disabled
                              }
                              sx={{ p: 0, mr: 0 }}
                              onClick={() => {
                                const temFilterData = {
                                  ...conditionsToFilter,
                                  totalComment: { from: null, to: null },
                                };
                                filterChange({ ...temFilterData });
                                setConditionsToFilter({ ...temFilterData });
                              }}
                            >
                              <Typography variant="caption2">Clear</Typography>
                            </Button>
                          }
                        />
                        <CardContent
                          sx={{
                            p: '0 !important',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.totalComment?.from || ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.totalComment?.from &&
                                    conditionsToFilter?.totalComment?.from < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalComment?.from &&
                                  conditionsToFilter?.totalComment?.from < 0
                                    ? 'From must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalComment',
                                    'from',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography>to</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.totalComment?.to || ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.totalComment?.to &&
                                    conditionsToFilter?.totalComment?.to < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalComment?.to &&
                                  conditionsToFilter?.totalComment?.to < 0
                                    ? 'To must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalComment',
                                    'to',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Box>
                      <IconButton
                        onClick={() =>
                          removeFilterField('totalComment', 'social')
                        }
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          mt: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('totalShare') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      mb={1}
                    >
                      <Box>
                        <CardHeader
                          sx={{
                            '.MuiCardHeader-action': { alignSelf: 'center' },
                            p: 0,
                          }}
                          title={
                            <Typography
                              variant="label"
                              sx={{ fontWeight: '400 !important' }}
                            >
                              Total Share
                            </Typography>
                          }
                          action={
                            <Button
                              variant="text"
                              disabled={
                                (!selectedTotalShare.from &&
                                  !selectedTotalShare.to) ||
                                disabled
                              }
                              sx={{ p: 0, mr: 0 }}
                              onClick={() => {
                                const temFilterData = {
                                  ...conditionsToFilter,
                                  totalShare: { from: null, to: null },
                                };
                                filterChange({ ...temFilterData });
                                setConditionsToFilter({ ...temFilterData });
                              }}
                            >
                              <Typography variant="caption2">Clear</Typography>
                            </Button>
                          }
                        />
                        <CardContent
                          sx={{
                            p: '0 !important',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            justifyContent="space-between"
                            alignItems="start"
                          >
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={
                                  conditionsToFilter?.totalShare?.from || ''
                                }
                                error={Boolean(
                                  conditionsToFilter?.totalShare?.from &&
                                    conditionsToFilter?.totalShare?.from < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalShare?.from &&
                                  conditionsToFilter?.totalShare?.from < 0
                                    ? 'From must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalShare',
                                    'from',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Typography>to</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={5}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TextField
                                disabled={disabled}
                                fullWidth
                                size="small"
                                label=""
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                  },
                                }}
                                value={conditionsToFilter?.totalShare?.to || ''}
                                error={Boolean(
                                  conditionsToFilter?.totalShare?.to &&
                                    conditionsToFilter?.totalShare?.to < 0,
                                )}
                                helperText={
                                  conditionsToFilter?.totalShare?.to &&
                                  conditionsToFilter?.totalShare?.to < 0
                                    ? 'To must be greater than or equal to 0'
                                    : ''
                                }
                                onChange={(event: any) => {
                                  handleNumberFieldChange(
                                    'totalShare',
                                    'to',
                                    event?.target?.value,
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Box>
                      <IconButton
                        onClick={() =>
                          removeFilterField('totalShare', 'social')
                        }
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          mt: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedSocialFields.includes('lastPostedTime') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      mb={1}
                    >
                      <CustomDateRangeFilter
                        disabled={disabled}
                        label="Last Posted Time"
                        onChange={(value) => {
                          setFilter(value as ICreatedTimeOption);
                          const temFilterData = {
                            ...conditionsToFilter,
                            lastPostedTime: value as ICreatedTimeOption,
                          };
                          filterChange({ ...temFilterData });
                          setConditionsToFilter({ ...temFilterData });
                        }}
                        createdTimes={filterOptions}
                        initValue={filter}
                      />
                      <IconButton
                        onClick={() =>
                          removeFilterField('lastPostedTime', 'social')
                        }
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          mt: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  <Button
                    variant="text"
                    color="primary"
                    sx={{
                      float: 'right',
                      mt: 1,
                      mb: 1,
                      p: 0,
                      '&:hover': { background: 'white' },
                    }}
                    onClick={() => {
                      setSelectedType('social');
                      setIsOpenAddMore(true);
                    }}
                    disabled={disabled}
                  >
                    Add more
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
          {filterType.includes('cdp') && (
            <Grid item xs={6}>
              <Card variant="outlined">
                <CardHeader
                  sx={{ '.MuiCardHeader-action': { alignSelf: 'center' } }}
                  title={
                    <Typography>
                      Filter for <b>1st Party Data</b>
                    </Typography>
                  }
                  action={
                    <Button
                      variant="text"
                      sx={{
                        p: 0,
                        mr: 0,
                        color: '#223354',
                        fontWeight: 'normal',
                        '&:hover': { background: 'white' },
                      }}
                      onClick={() => handleClearFilters('cdp')}
                      startIcon={
                        <CloseTwoToneIcon fontSize="small" color="error" />
                      }
                      disabled={disabled}
                    >
                      Clear filters
                    </Button>
                  }
                />
                <Divider />
                <CardContent>
                  {selectedCDPFields.includes('cdpCities') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomAutocomplete
                        disabled={disabled}
                        label="City"
                        objKey="cdpCities"
                        values={selectedCDPCities}
                        options={cities}
                        isLoading={isLoadingMetaData}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('cdpCities', 'cdp')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedCDPFields.includes('cdpGenders') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomAutocomplete
                        disabled={disabled}
                        label="Gender"
                        objKey="cdpGenders"
                        values={selectedCDPGenders}
                        options={genders}
                        isLoading={isLoadingMetaData}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('cdpGenders', 'cdp')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedCDPFields.includes('cdpAges') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <CustomNoOptionAutocomplete
                        disabled={disabled}
                        label="Age"
                        objKey="cdpAges"
                        values={selectedCDPAges}
                        isLoading={isLoadingMetaData}
                        options={ages}
                        audienceFilters={conditionsToFilter}
                        filterChange={(data: IDataFilter) => {
                          filterChange(data);
                          setConditionsToFilter(data);
                        }}
                      />
                      <IconButton
                        onClick={() => removeFilterField('cdpAges', 'cdp')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedCDPFields.includes('cdpRecencies') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        width="100%"
                      >
                        <Typography>Recency Score</Typography>
                        <Autocomplete
                          disabled={disabled}
                          size="small"
                          multiple
                          fullWidth
                          disableClearable
                          loading={isLoadingRankData}
                          limitTags={6}
                          value={cdpRankList.filter((item) =>
                            conditionsToFilter?.cdpRecencies?.includes(item.id),
                          )}
                          options={cdpRankList || []}
                          sx={{ minWidth: 112 }}
                          getOptionLabel={(option) => option.name}
                          onChange={(_event, newValue) => {
                            const conditions = {
                              ...conditionsToFilter,
                              cdpRecencies: newValue?.map((item) => item.id),
                            };
                            filterChange(conditions);
                            setConditionsToFilter(conditions);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') e.preventDefault();
                              }}
                            />
                          )}
                          renderOption={(props, option, { selected }) => {
                            return (
                              <>
                                {renderRecency(option) && (
                                  <ListItem
                                    {...props}
                                    key={`${option.id}-cdpRecencies`}
                                    sx={{
                                      '&.MuiAutocomplete-option': {
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      },
                                    }}
                                  >
                                    <Checkbox
                                      sx={{ mr: 1 }}
                                      checked={selected}
                                    />
                                    <Typography>{option.name}</Typography>
                                    <Typography color="secondary">
                                      {renderRecency(option)}
                                    </Typography>
                                  </ListItem>
                                )}
                              </>
                            );
                          }}
                          renderTags={(
                            value: readonly IOption[],
                            getTagProps,
                          ) =>
                            value.map((option: IOption, index) => (
                              <CustomChip
                                {...getTagProps({ index })}
                                color="primary"
                                label={`${option.name} ${renderRecency(
                                  option,
                                )}`}
                              />
                            ))
                          }
                        />
                      </Box>
                      <IconButton
                        onClick={() => removeFilterField('cdpRecencies', 'cdp')}
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedCDPFields.includes('cdpFrequencies') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        width="100%"
                      >
                        <Typography>Frequency Score</Typography>
                        <Autocomplete
                          disabled={disabled}
                          size="small"
                          multiple
                          fullWidth
                          disableClearable
                          loading={isLoadingRankData}
                          limitTags={6}
                          value={cdpRankList.filter((item) =>
                            conditionsToFilter?.cdpFrequencies?.includes(
                              item.id,
                            ),
                          )}
                          options={cdpRankList || []}
                          sx={{ minWidth: 112 }}
                          getOptionLabel={(option) => option.name}
                          onChange={(_event, newValue) => {
                            const conditions = {
                              ...conditionsToFilter,
                              cdpFrequencies: newValue?.map((item) => item.id),
                            };
                            filterChange(conditions);
                            setConditionsToFilter(conditions);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') e.preventDefault();
                              }}
                            />
                          )}
                          renderOption={(props, option, { selected }) => {
                            return (
                              <>
                                {renderFrequency(option) && (
                                  <ListItem
                                    {...props}
                                    key={`${option.id}-cdpFrequencies`}
                                    sx={{
                                      '&.MuiAutocomplete-option': {
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      },
                                    }}
                                  >
                                    <Checkbox
                                      sx={{ mr: 1 }}
                                      checked={selected}
                                    />
                                    <Typography>{option.name}</Typography>
                                    <Typography color="secondary">
                                      {renderFrequency(option)}
                                    </Typography>
                                  </ListItem>
                                )}
                              </>
                            );
                          }}
                          renderTags={(
                            value: readonly IOption[],
                            getTagProps,
                          ) =>
                            value.map((option: IOption, index) => (
                              <CustomChip
                                {...getTagProps({ index })}
                                color="primary"
                                label={`${option.name} ${renderFrequency(
                                  option,
                                )}`}
                              />
                            ))
                          }
                        />
                      </Box>
                      <IconButton
                        onClick={() =>
                          removeFilterField('cdpFrequencies', 'cdp')
                        }
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  {selectedCDPFields.includes('cdpMonetaries') && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="end"
                      mb={1}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        width="100%"
                      >
                        <Typography>Monetary Score</Typography>
                        <Autocomplete
                          disabled={disabled}
                          size="small"
                          multiple
                          fullWidth
                          disableClearable
                          loading={isLoadingRankData}
                          limitTags={6}
                          value={cdpRankList.filter((item) =>
                            conditionsToFilter?.cdpMonetaries?.includes(
                              item.id,
                            ),
                          )}
                          options={cdpRankList || []}
                          sx={{ minWidth: 112 }}
                          getOptionLabel={(option) => option.name}
                          onChange={(_event, newValue) => {
                            const conditions = {
                              ...conditionsToFilter,
                              cdpMonetaries: newValue?.map((item) => item.id),
                            };
                            filterChange(conditions);
                            setConditionsToFilter(conditions);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') e.preventDefault();
                              }}
                            />
                          )}
                          renderOption={(props, option, { selected }) => {
                            return (
                              <>
                                {renderMonetary(option) && (
                                  <ListItem
                                    {...props}
                                    key={`${option.id}-cdpMonetaries`}
                                    sx={{
                                      '&.MuiAutocomplete-option': {
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      },
                                    }}
                                  >
                                    <Checkbox
                                      sx={{ mr: 1 }}
                                      checked={selected}
                                    />
                                    <Typography>{option.name}</Typography>
                                    <Typography color="secondary">
                                      {renderMonetary(option)}
                                    </Typography>
                                  </ListItem>
                                )}
                              </>
                            );
                          }}
                          renderTags={(
                            value: readonly IOption[],
                            getTagProps,
                          ) =>
                            value.map((option: IOption, index) => (
                              <CustomChip
                                {...getTagProps({ index })}
                                color="primary"
                                label={`${option.name} ${renderMonetary(
                                  option,
                                )}`}
                              />
                            ))
                          }
                        />
                      </Box>
                      <IconButton
                        onClick={() =>
                          removeFilterField('cdpMonetaries', 'cdp')
                        }
                        color="secondary"
                        size="small"
                        sx={{
                          m: 0,
                          ml: 1,
                          p: 1,
                          pr: 0,
                          '&:hover': { background: 'white' },
                        }}
                        disabled={disabled}
                      >
                        <CloseTwoToneIcon color="error" />
                      </IconButton>
                    </Box>
                  )}
                  <Button
                    variant="text"
                    color="primary"
                    sx={{
                      float: 'right',
                      mt: 1,
                      mb: 1,
                      p: 0,
                      '&:hover': { background: 'white' },
                    }}
                    onClick={() => {
                      setSelectedType('cdp');
                      setIsOpenAddMore(true);
                    }}
                  >
                    Add more
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Button
            variant="text"
            color="primary"
            disabled={
              (filterType?.includes('social') && filterType?.includes('cdp')) ||
              disabled
            }
            sx={{ mt: 1, p: 0, '&:hover': { background: 'white' } }}
            onClick={() => setIsOpen(true)}
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            Filter type
          </Button>
          {groupSelected && (
            <Permission
              permissions={['project.koc_discovery.discovery_group.edit']}
            >
              <Button
                variant="contained"
                disabled={isDisableSaveFilterButton || !groupName || disabled}
                sx={{ mt: 1, ml: 2 }}
                onClick={() => {
                  if (groupSelected) {
                    setIsConfirmUpdate(true);
                    // onUpdateGroup();
                    // if (onSaveFilter) {
                    //   onSaveFilter(conditionsToFilter);
                    // }
                  } else {
                    if (onSaveFilter) {
                      onSaveFilter(conditionsToFilter);
                    }
                    open();
                  }
                }}
                startIcon={<BookmarkBorderOutlinedIcon fontSize="small" />}
              >
                Save
              </Button>
            </Permission>
          )}
          {!groupSelected && filterType?.length > 0 && (
            <Permission
              permissions={['project.koc_discovery.discovery_group.create']}
            >
              <Tooltip
                placement="top"
                title="Click here to create a group of filters for later use"
                arrow
              >
                <Button
                  variant="contained"
                  color="neutral"
                  sx={{ mt: 1, ml: 2 }}
                  disabled={
                    (!isAllowSaveFilter && isDisableSaveFilterButton) ||
                    disabled
                  }
                  onClick={() => {
                    open();
                  }}
                >
                  Save a New Discovery Group
                </Button>
              </Tooltip>
            </Permission>
          )}
          {(groupSelected || (!groupSelected && filterType?.length > 0)) && (
            <Permission
              permissions={[
                'project.koc_discovery.discovery_group.estimate_size',
              ]}
            >
              <Tooltip
                placement="top"
                title="Click here to view Estimated size of Discovery group"
                arrow
              >
                <LoadingButton
                  onClick={loadUniqueAudience}
                  loading={isLoadingUnique}
                  variant="outlined"
                  color="neutral"
                  sx={{ mt: 1, ml: 2 }}
                  disabled={
                    (!isAllowSaveFilter && isDisableSaveFilterButton) ||
                    disabled
                  }
                >
                  Estimate
                </LoadingButton>
              </Tooltip>
            </Permission>
          )}
        </Box>
        {isAllowSaveFilter && !isDisableSaveFilterButton && (
          <Typography color="error">
            Click <b>Save</b> to make changes to your filters and view its
            results.
          </Typography>
        )}
      </Box>
      {isOpen && (
        <AddFilterTypeDialog
          open={isOpen}
          cdpItems={CDPItems}
          socialItems={SocialItems}
          selectedSocialFields={selectedSocialFields}
          selectedCDPFields={selectedCDPFields}
          filterType={filterType}
          onClose={() => setIsOpen(false)}
          onSelectedFilterFields={(socialFields, cdpFields) => {
            setIsOpen(false);
            const types = [...filterType];
            if (socialFields && socialFields.length > 0) {
              types.push('social');
            }
            setSelectedSocialFields(Array.from(new Set([...socialFields])));
            if (cdpFields && cdpFields.length > 0) {
              types.push('cdp');
            }
            setSelectedCDPFields(Array.from(new Set([...cdpFields])));
            setFilterType(types.filter((v, i, a) => a.indexOf(v) === i));
          }}
        />
      )}
      {selectedType && (
        <SelectFieldDialog
          open={isOpenAddMore}
          type={selectedType}
          items={selectedType === 'social' ? SocialItems : CDPItems}
          itemsToFilter={
            selectedType === 'social' ? selectedSocialFields : selectedCDPFields
          }
          onClose={() => {
            setSelectedType('');
            setIsOpenAddMore(false);
          }}
          onSuccess={(values, type) => {
            setSelectedType('');
            setIsOpenAddMore(false);
            if (type === 'social') {
              setSelectedSocialFields([...selectedSocialFields, ...values]);
            } else {
              setSelectedCDPFields([...selectedCDPFields, ...values]);
            }
          }}
        />
      )}
      <CreateDiscoveryGroupDialog
        open={isOpenFilterDialog}
        onClose={closeAndClearData}
        data={{
          filterType,
          selectedSocialFields,
          selectedCDPFields,
          dataFilter: conditionsToFilter,
        }}
        onSuccess={(data: IDiscoveryGroup) => {
          if (onSaveFilter) onSaveFilter(data.data.dataFilter);
          if (onChangeDiscoveryGroup) onChangeDiscoveryGroup(data);
          enqueueSnackbar(`Created successfully`, {
            variant: 'success',
          });
        }}
      />

      <ConfirmDialog
        open={isConfirmLeave}
        title="Save Discovery Group"
        message={
          <Box>
            <Typography sx={{ mb: 2 }}>
              Are you sure you want to make changes for this Discovery group?
            </Typography>
            <Typography>
              This action may result in adjustments to the list of rejected
              profiles associated with it. Rejected profiles that no longer meet
              the new criteria will be remove from this group.
            </Typography>
          </Box>
        }
        onConfirm={() => {
          setIsConfirmUpdate(false);
          onUpdateGroup();
          if (onSaveFilter) {
            onSaveFilter(conditionsToFilter);
          }
        }}
        onCancel={() => setIsConfirmUpdate(false)}
      />
    </Box>
  );
};

export default FilterConditions;
