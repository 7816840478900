import { IProduct } from 'types/product';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';

import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  idProduct: string | number;
}

interface Result extends IRequestHookResult<IProduct> {}

export const useLoadProductDetails: IRequestHook<Props, Result> = ({
  idProject,
  idProduct,
  stopWhen,
}) => {
  const path = `/admin/projects/${idProject}/products/${idProduct}`;

  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useCustomSWR(path, { stopWhen });

  const result = response ? (response.data as IProduct) : undefined;

  return {
    result,
    error,
    isLoading: !error && !response,
    isValidating,
    mutate,
  };
};

export default useLoadProductDetails;
