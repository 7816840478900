import React, { FC, useState } from 'react';
import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  AutocompleteRenderOptionState,
} from '@mui/material';
import {
  CustomAutocompleteNoOption,
  renderOptionWithCustomRange,
} from 'components/CustomAutocomplete';
import { useCustomAutocomplete } from 'hooks/useAutocomplete';
import { IDataFilter } from '../../../../types/discovery-group';
import CreateRangeDialog from './CreateRangeDialog';

interface IProps {
  label: string;
  objKey: string;
  values: any[];
  options: any[];
  isLoading: boolean;
  audienceFilters: IDataFilter;
  filterChange: (conditions: IDataFilter) => void;
  renderCustomOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: IOption,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  disabled?: boolean;
}

interface IOption {
  id: string;
  name: string;
}

const CustomNoOptionAutocomplete: FC<IProps> = ({
  label,
  objKey,
  values,
  options,
  isLoading,
  audienceFilters,
  filterChange,
  renderCustomOption,
  disabled = false,
}) => {
  const {
    isOpenDialog,
    onOpenDialog,
    onCloseDialog,
    selectedValues,
    setSelectedValues,
    items,
    setItems,
  } = useCustomAutocomplete<IOption>(options, values);

  const renderName = (from: number | null, to: number | null) => {
    if (from && to) {
      return `${from || ''}-${to || ''}`;
    }
    if (from && !to) {
      return `>${from}`;
    }
    if (!from && to) {
      return `<${to}`;
    }
    return `${from || ''}-${to || ''}`;
  };

  const [inputValue, setInputValue] = useState<string>('');
  const handleCustomRange = (from: number | null, to: number | null) => {
    const option: IOption = {
      id: `${from || ''}-${to || ''}`,
      name: renderName(from, to),
    };
    setItems([option, ...items]);
    setSelectedValues([...selectedValues, option]);
    const conditions = {
      ...audienceFilters,
      [objKey]: [...selectedValues, option],
    };
    filterChange(conditions);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography>{label}</Typography>
      <Autocomplete
        size="small"
        multiple
        fullWidth
        limitTags={3}
        disableClearable
        value={selectedValues}
        inputValue={inputValue}
        onInputChange={(event, value, reason) => {
          if (event && event.type === 'blur') {
            setInputValue('');
          } else if (reason !== 'reset') {
            setInputValue(value);
          }
        }}
        disableCloseOnSelect
        options={items}
        loading={isLoading}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          setSelectedValues(newValue);
          const conditions = { ...audienceFilters, [objKey]: newValue };
          filterChange(conditions);
        }}
        renderInput={(params) => <TextField {...params} />}
        renderOption={
          renderCustomOption ?? renderOptionWithCustomRange(onOpenDialog)
        }
        noOptionsText={<CustomAutocompleteNoOption onClick={onOpenDialog} />}
        disabled={disabled}
      />
      <CreateRangeDialog
        open={isOpenDialog}
        onClose={onCloseDialog}
        onSuccess={(from, to) => handleCustomRange(from, to)}
      />
    </Box>
  );
};

export default CustomNoOptionAutocomplete;
