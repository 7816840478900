import { IOfficialKoc } from 'types/koc';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  idKOC: string;
}

interface Result
  extends IRequestHookResult<{
    data: IOfficialKoc;
  }> {}

export const useLoadKOCOfficialDetail: IRequestHook<Props, Result> = ({
  idProject,
  idKOC,
  stopWhen,
}) => {
  const path = `/admin/projects/${idProject}/koc/official-customers/${idKOC}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  let outputData;
  if (response) {
    const responseData = response.data as IOfficialKoc;
    outputData = {
      data: responseData,
    };
  }

  return {
    result: outputData,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadKOCOfficialDetail;
