import { FC, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { DialogProps } from '@mui/material/Dialog/Dialog';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { LoadingButton } from '@mui/lab';

interface FormValues {
  from: number | null;
  to: number | null;
}

const defaultValues = {
  from: null,
  to: null,
};

interface IProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
  onSuccess: (from: number | null, to: number | null) => void;
}

const schema = yup.object().shape({});

const CreateRangeDialog: FC<IProps> = ({
  onSuccess,
  onClose,
  ...dialogProps
}) => {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const { handleSubmit, register, watch, reset } =
    useForm<FormValues>({
      resolver: yupResolver(schema),
      defaultValues: { ...defaultValues },
    });

  const onSubmitSuccess = (values: FormValues) => {
    setIsRequesting(true);
    if (values.from || values.to) {
      onSuccess(values.from, values.to);
      setIsRequesting(false);
      resetValue();
    } else {
      setIsFirstLoad(false);
      setIsRequesting(false);
    }
  };

  const onSubmitError = (err) => {
    console.error('err', err);
  };

  const resetValue = () => {
    setIsFirstLoad(true);
    reset();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps} onClose={resetValue}>
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          Custom range
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }}>From</Typography>
              <TextField
                fullWidth
                size="small"
                label=""
                type="number"
                error={!isFirstLoad && !watch('from') && !watch('to')}
                helperText={
                  !isFirstLoad && !watch('from') && !watch('to')
                    ? 'From or To should have value'
                    : ''
                }
                InputProps={{ ...register('from') }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }}>To</Typography>
              <TextField
                fullWidth
                size="small"
                label=""
                type="number"
                error={!isFirstLoad && !watch('from') && !watch('to')}
                helperText={
                  !isFirstLoad && !watch('from') && !watch('to')
                    ? 'From or To should have value'
                    : ''
                }
                InputProps={{ ...register('to') }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetValue}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmitSuccess, onSubmitError)}
          loading={isRequesting}
          variant="contained"
          type="submit"
        >
          Apply
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRangeDialog;
