import { IKOCRank } from 'types/koc';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
}

interface Result extends IRequestHookResult<IKOCRank> {}

export const useLoadProjectKOCRankFilter: IRequestHook<Props, Result> = ({
  idProject,
  stopWhen,
}) => {
  const path = `/admin/projects/${idProject}/koc/customers/cdp/ranks`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const metaData = response ? (response.data as IKOCRank) : undefined;

  return {
    mutate,
    result: metaData,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadProjectKOCRankFilter;
