import { Autocomplete, TextField } from '@mui/material';
import { get, union } from 'lodash';
import { FC, useEffect } from 'react';
import CreateRangeDialog from 'components/Customers/CreateRangeDialog';
import { useCustomAutocomplete } from 'hooks/useAutocomplete';
import {
  CustomAutocompleteNoOption,
  renderOptionWithCustomRange,
} from 'components/CustomAutocomplete';
import { renderName, socialInteractionOptions } from './utils';

export const SocialInteractionAutocomplete: FC<any> = ({
  setSocialInteractions,
  socialInteractions,
}) => {
  const {
    isOpenDialog,
    onOpenDialog,
    onCloseDialog,
    selectedValues,
    setSelectedValues,
    items,
    setItems,
  } = useCustomAutocomplete<any>(
    socialInteractionOptions,
    socialInteractions || [],
  );

  useEffect(() => {
    if (socialInteractions) {
      const itemsArrayParse = items.map((item) => item?.id);

      const itemsEditArrayParse = union(
        socialInteractions,
        itemsArrayParse,
      ).map((item) => ({
        id: item,
        name: item,
      }));

      setItems(itemsEditArrayParse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialInteractions]);

  const handleCustomRange = (from: number | null, to: number | null) => {
    const option: any = {
      id: `${from || ''}-${to || ''}`,
      name: renderName(from, to),
    };

    setItems([option, ...items]);
    setSelectedValues([...selectedValues, get(option, 'name')]);
    setSocialInteractions([...selectedValues, get(option, 'name')]);
  };

  return (
    <>
      <Autocomplete
        size="small"
        fullWidth
        multiple
        options={items}
        getOptionLabel={(option) => option.name}
        value={items?.filter(
          (item) =>
            selectedValues?.includes(item?.id) ||
            selectedValues?.includes(item),
        )}
        onChange={(_, newValue) => {
          setSelectedValues(
            newValue
              ?.map((item) => item?.id)
              ?.filter((v, i, a) => a.indexOf(v) === i) || [],
          );
          setSocialInteractions(
            newValue
              ?.map((item) => item?.id)
              ?.filter((v, i, a) => a.indexOf(v) === i) || [],
          );
        }}
        renderInput={(params) => <TextField {...params} label="" />}
        renderOption={renderOptionWithCustomRange(onOpenDialog)}
        noOptionsText={<CustomAutocompleteNoOption onClick={onOpenDialog} />}
      />
      <CreateRangeDialog
        open={isOpenDialog}
        onClose={onCloseDialog}
        onSuccess={(from, to) => handleCustomRange(from, to)}
      />
    </>
  );
};
