import { useAuth0 } from '@auth0/auth0-react';
import { IKOCAudience } from 'types/koc';
import { requester } from '../index';

export const useCreateKOCNTypePost = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function createKOCNTypePost(
    idProject: string,
    postUrl: string,
    type: string,
    selectedAudienceId: string | null,
    isPersonalPost: boolean,
  ) {
    return requester({
      method: 'post',
      path: `/admin/projects/${idProject}/koc/audiences/export-data/add-post`,
      data: {
        url: postUrl,
        ...(selectedAudienceId &&
        (type === 'N_TYPE_PAGE' || type === 'N_TYPE_GROUP')
          ? { id_page: selectedAudienceId }
          : {}),
        is_personal_post: isPersonalPost || false,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IKOCAudience>(response.data));
  };
};

export default useCreateKOCNTypePost;
