import { useAuth0 } from '@auth0/auth0-react';
import { requester } from 'requests';

interface IRequestBodyAsset {
  id: number;
}
interface IRequestBody {
  idProject: string;
  idProduct: number;
  photoType: string;
  medias: IRequestBodyAsset[];
}

export const useMovePhotoToSelectedPhotoType = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function movePhotoToSelectedPhotoType(requestBody: IRequestBody) {
    return requester({
      method: 'put',
      path: `/admin/projects/${requestBody.idProject}/products/${requestBody.idProduct}/photos`,
      data: {
        type: requestBody.photoType,
        medias: requestBody.medias,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useMovePhotoToSelectedPhotoType;
