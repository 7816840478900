import { IUseCase } from 'types/use-case';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  keyword: string;
}

interface Result extends IRequestHookResult<IUseCase[]> {}

export const useLoadUseCaseList: IRequestHook<Props, Result> = ({
  idProject,
  keyword,
  stopWhen,
}) => {
  const path = `/admin/projects/${idProject}/use-cases?keyword=${keyword}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const usecases = response ? (response.data as IUseCase[]) : [];
  return {
    result: usecases,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadUseCaseList;
