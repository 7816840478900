import { FC } from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  Theme,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Linkify from 'react-linkify';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import { useParams } from 'react-router-dom';
import { IKOCAudiencePost } from 'types/koc';
import defaultThumbnail from 'assets/images/thumbnail-default.png';
import { formatDateTime, nFormatter } from 'utils/common';
import useKeypress from 'hooks/useKeypress';
import { useLoadNTypePostDetails } from 'requests/koc';

interface IProps extends Omit<DialogProps, 'onClose'> {
  post: IKOCAudiencePost;
  pageName: string;
  pagePhoto: string;
  open: boolean;
  onClose: () => void;
}

const KOCAudiencePostDialog: FC<IProps> = ({
  open,
  post,
  pageName,
  pagePhoto,
  onClose,
}) => {
  const classes = useStyles();
  const { idProject } = useParams();
  const { result } = useLoadNTypePostDetails({
    idProject,
    socialPostId: post.id,
  });

  useKeypress('Escape', () => {
    onClose();
  });
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      scroll="paper"
      sx={{
        '& .MuiDialog-paper': {
          overflowY: 'initial',
          // height: "calc(100% - 64px)",
        },
      }}
    >
      <Box component={DialogTitle} overflow="hidden" position="relative">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <img
            src={result?.page?.pagePhoto || pagePhoto || defaultThumbnail}
            alt="Facebook Page Thumbnail"
            className={classes.image}
          />
          <Box flex={1}>
            <Typography component="h2" variant="h3">
              {result?.pageInfo?.name || pageName || '--'}
            </Typography>
            <Typography fontSize="0.87rem" sx={{ mr: 1 }}>
              {result?.createdAt ? formatDateTime(result.createdAt) : '--'}
            </Typography>
          </Box>
        </Box>
        <Box
          component={IconButton}
          position="absolute"
          top={0}
          right={0}
          aria-label="close"
          onClick={onClose}
        >
          <HighlightOffIcon />
        </Box>
      </Box>
      <DialogContent>
        <Linkify>
          <Typography sx={{ whiteSpace: 'pre-wrap' }} component="span">
            {result?.caption}
          </Typography>
        </Linkify>
        <Box className={classes.containerimage}>
          <img
            src={result?.postImg || defaultThumbnail}
            alt="Facebook Post Placeholder Thumbnail"
            className={classes.contentimage}
          />
        </Box>
        <Box display="flex" mt={1}>
          <Typography color="secondary" sx={{ width: 'calc(100% / 3)' }}>
            {nFormatter(result?.totalReactions, 1)} reactions
          </Typography>
          <Typography
            color="secondary"
            sx={{ width: 'calc(100% / 3)', textAlign: 'center' }}
          >
            {nFormatter(result?.totalComments, 1)} comments
          </Typography>
          <Typography
            color="secondary"
            sx={{ width: 'calc(100% / 3)', textAlign: 'end' }}
          >
            {nFormatter(result?.totalShares, 1)} shares
          </Typography>
        </Box>
        <Divider sx={{ mt: '4px', mb: 1 }} />
        <Box display="flex" mt={1}>
          <Typography
            color="secondary"
            sx={{
              width: 'calc(100% / 3)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ThumbUpOffAltIcon sx={{ mr: 1 }} />
            Reaction
          </Typography>
          <Typography
            color="secondary"
            sx={{
              width: 'calc(100% / 3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ModeCommentOutlinedIcon />
            Comments
          </Typography>
          <Typography
            color="secondary"
            sx={{
              width: 'calc(100% / 3)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <ReplyIcon sx={{ mr: 1 }} />
            Share
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: 56,
    height: 56,
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    borderRadius: '50%',
    objectFit: 'cover',
  },
  borderShadow: {
    display: 'flex',
    boxShadow:
      '0px 4px 8px rgb(159 162 191 / 18%), 0px 2px 2px rgb(159 162 191 / 32%)',
  },
  boxDisplayFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  containerimage: {
    height: 300,
    overflow: 'hidden',
  },
  contentimage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

export default KOCAudiencePostDialog;
