import React, { useState, useMemo, FC, forwardRef, useEffect } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderOptionState,
} from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';

import { useDebounce } from 'use-debounce';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';

interface CustomAutocompleteProps
  extends Omit<
    AutocompleteProps<string, undefined, undefined, undefined>,
    'options'
  > {}

interface IOption {
  id: string | number;
  name: string;
  email?: string;
  size?: number;
}

interface IListParams extends IRequestHookParams {
  keyword: string;
  stopWhen?: boolean;
}

interface IListResult extends IRequestHookResult<IOption[]> {}

interface IDetailsParams extends IRequestHookParams {
  id: string | number;
}

interface IDetailsResult extends IRequestHookResult<IOption | null> {}

interface ICustomAutocomplete extends CustomAutocompleteProps {
  minCharToSearch?: number;
  cacheItems?: boolean;
  useLoadListByKeyword: IRequestHook<IListParams, IListResult>;
  useLoadDetails?: IRequestHook<IDetailsParams, IDetailsResult>;
  renderCustomOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: IOption,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
}

export const CustomAutocomplete: FC<ICustomAutocomplete> = forwardRef(
  (
    {
      value,
      onChange,
      useLoadListByKeyword,
      useLoadDetails,
      renderCustomOption,
      minCharToSearch = 0,
      cacheItems,
      ...props
    },
    ref,
  ) => {
    const [keyword, setKeyword] = useState<string>('');
    const [realKeyword] = useDebounce(keyword, 300);
    const { result: list = [], isLoading } = useLoadListByKeyword({
      keyword: realKeyword,
      stopWhen: realKeyword?.length < minCharToSearch || cacheItems,
    });

    const [selectedObj, setSelectedObj] = useState<IOption>();
    const { result: initialObj } = usePerformHook(
      {
        id: value || '',
        stopWhen: !value,
      },
      useLoadDetails,
    );

    useEffect(() => {
      if (initialObj) {
        setSelectedObj(initialObj);
      }
    }, [initialObj]);

    const objMap = useMemo(() => {
      const all = selectedObj ? [selectedObj, ...list] : list;
      return all.reduce<Record<string, IOption>>((acc, org) => {
        acc[org.id] = org;
        return acc;
      }, {});
    }, [list, selectedObj]);

    const options = useMemo(() => list.map(({ id }) => id), [list]);

    return (
      <Autocomplete
        ref={ref}
        {...props}
        loading={isLoading}
        selectOnFocus
        filterOptions={(x) => x}
        getOptionLabel={(id) => objMap[id]?.name || ''}
        options={options}
        value={value}
        onChange={(event, newValue, c, d) => {
          if (newValue) {
            const newOption = objMap[newValue];
            setSelectedObj(newOption);
          }
          if (onChange) {
            onChange(event, newValue, c, d);
          }
        }}
        onFocus={() => setKeyword('')}
        onInputChange={(e, newValue) => setKeyword(newValue)}
        renderOption={
          renderCustomOption
            ? (optionProps, option, state) => {
                const obj = objMap[option];
                return renderCustomOption(optionProps, obj, state);
              }
            : (optionProps, option) => {
                const obj = objMap[option];
                return (
                  <li {...optionProps} key={obj.id}>
                    {obj.name}
                  </li>
                );
              }
        }
        popupIcon={<ExpandMoreTwoToneIcon sx={{ fill: '#1A1A1A' }} />}
      />
    );
  },
);

const mockHook = () => ({ result: null, isLoading: false });

const usePerformHook = (
  params: IDetailsParams,
  useLoadDetailsHook: IRequestHook<IDetailsParams, IDetailsResult> = mockHook,
) => {
  return useLoadDetailsHook(params);
};
export default CustomAutocomplete;
