import { useAuth0 } from '@auth0/auth0-react';
import { requester } from 'requests';

interface IRequestBodyAsset {
  id?: string;
  filePath: string;
  title: string;
}
interface IRequestBody {
  idProject: string;
  idProduct: number;
  photoType?: string;
  medias: IRequestBodyAsset[];
}

const useUploadSampleProductPhotos = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function uploadFile(requestBody: IRequestBody) {
    const medias = requestBody?.medias?.map((item) => {
      return {
        file_path: item?.filePath,
        title: item?.title,
      };
    });
    return requester({
      method: 'post',
      path: `/admin/projects/${requestBody.idProject}/products/${requestBody.idProduct}/photos`,
      data: {
        type: requestBody.photoType || '',
        medias,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useUploadSampleProductPhotos;
