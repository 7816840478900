import { IKOCScheme } from './scheme';

export interface IUser {
  id: string;
  name: string;
  email: string;
}

export interface IProduct {
  id: number;
  name: string;
}

export interface IOffer {
  id: number;
  value: string;
  name: string;
  description: string;
  kocScheme?: IKOCScheme;
}

export interface IAgreement {
  id: string;
  code: string;
  status: AgreementStatus;
  createdBy: IUser;
  createdAt: string;
  updatedAt: string;
  idProduct: number;
  productDescription: string;
  product: IProduct;
  idProjectKocScheme: number;
  projectKocScheme: IOffer;
  content: string;
  website: string;
  campaignTimelineFrom: string;
  campaignTimelineTo: string;
  campaignGuideline: string;
  contentGuideline: string;
  productPhotoUrl: string;
  productPhotoPath: string;
  requireDeliveryInfo: number;
  isDuplicated?: boolean;
  label?: string;
  reason?: string;
}

export enum AgreementStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  SENT = 'AGREEMENT_SENT',
  VIEWED = 'AGREEMENT_VIEWED',
  REJECTED = 'AGREEMENT_REJECTED',
  APPROVED = 'AGREEMENT_APPROVED',
}
