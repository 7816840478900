import { LoadingButton } from '@mui/lab';
import { Button, DialogActions as MuiDialogActions } from '@mui/material';
import { FC } from 'react';

interface Props {
  onCancel: () => void;
  onOk: () => void;
  isLoading?: boolean;
  okText?: string;
  okIcon?: any;
  hasOKBtn?: boolean;
}

export const DialogActions: FC<Props> = ({
  onCancel,
  onOk,
  isLoading,
  okText = 'Save',
  okIcon,
  hasOKBtn = true,
}) => {
  return (
    <MuiDialogActions>
      <Button
        variant="outlined"
        sx={{
          '&.MuiButton-outlinedNeutral': {
            borderColor: (theme) => theme.colors.alpha.black[40],
          },
        }}
        onClick={onCancel}
      >
        Cancel
      </Button>
      {hasOKBtn && (
        <LoadingButton
          onClick={onOk}
          loading={isLoading}
          variant="contained"
          type="submit"
        >
          {okIcon && <>{okIcon}&nbsp;</>}
          {okText}
        </LoadingButton>
      )}
    </MuiDialogActions>
  );
};
