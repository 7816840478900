import { useCallback, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useAuth0 } from '@auth0/auth0-react';
import qs from 'qs';
import { IAnotherPotentialKoc } from 'types/koc';
import { IListResponse } from 'types/response';
import { IRequestHookParams } from 'types/request';
import { fetcher } from 'requests/index';

interface IProps extends IRequestHookParams {
  idProject: string;
  pageSize: number;
  searchKeyword?: string;
}

export const useLoadAnotherPotentialCustomers = ({
  idProject,
  pageSize,
  searchKeyword,
}: IProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const customFetcher = useCallback(
    (url: string) =>
      fetcher(getAccessTokenSilently)(url).then((response) => response.data as IListResponse),
    [getAccessTokenSilently]
  );

  const getKey = useCallback(
    (pageIndex: number, previousPageData: any) => {
      // reached the end
      if (
        previousPageData &&
        previousPageData?.meta?.pagination?.currentPage ===
          previousPageData?.meta?.pagination?.totalPages
      )
        return null;

      const { currentPage } = (previousPageData as IListResponse)?.meta?.pagination || {};

      const queryString = qs.stringify({
        limit: pageSize,
        page: currentPage ? currentPage + 1 : 1,
        q: searchKeyword,
      });

      return `/admin/projects/${idProject}/koc/another-official-customers?${queryString}`;
    },
    [idProject, pageSize, searchKeyword]
  );

  const {
    data: pages,
    error,
    size,
    setSize,
    isValidating,
    mutate,
  } = useSWRInfinite<IListResponse<IAnotherPotentialKoc>>(getKey, customFetcher);

  const isLoadingInitialData = !pages && !error;
  const isLoadingMore =
    isLoadingInitialData || (size > 0 && !!pages && typeof pages[size - 1] === 'undefined');
  const hasMore =
    !!pages &&
    pages[size - 1]?.meta.pagination.currentPage < pages[size - 1]?.meta.pagination.totalPages;
  const isRefreshing = isValidating && pages && pages.length === size;
  const result = useMemo(() => getResult(pages), [pages]);
  const loadMore = useCallback(() => setSize((s) => s + 1), [setSize]);

  return {
    result,
    loadMore,
    isLoadingMore,
    error,
    hasMore,
    isRefreshing,
    mutate,
  };
};

const getResult = (pages?: IListResponse<IAnotherPotentialKoc>[]) => {
  if (!pages || pages.length <= 0) {
    return [];
  }
  const result = pages.reduce<IAnotherPotentialKoc[]>((acc, page) => {
    const histories = page.data;
    return [acc, histories].flat();
  }, []);
  return result.reduce<IAnotherPotentialKoc[]>(
    (items, item) =>
      items.find((x) => x.idKocCustomer === item.idKocCustomer) ? [...items] : [...items, item],
    []
  );
};

export default useLoadAnotherPotentialCustomers;
