import { IRequestHook, IRequestHookParams, IRequestHookResult } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Result extends IRequestHookResult<any> {}

export const useLoadUserProfile: IRequestHook<IRequestHookParams, Result> = ({ stopWhen }) => {
  const path = `/admin/profile`;

  const { data: response, error } = useCustomSWR(path, {
    stopWhen,
  });

  return {
    result: response?.data,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadUserProfile;
