import { useCallback, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useAuth0 } from '@auth0/auth0-react';
import qs from 'qs';
import { ICursorListResponse } from 'types/response';
import { IRequestHookParams } from 'types/request';
import { fetcher } from 'requests/index';
import { INotification } from 'types/notification';

interface Props extends IRequestHookParams {
  pageSize: number;
  resolved: string;
  isRead?: string | undefined;
}

export const useLoadUserNotificationsInfinite = ({
  pageSize,
  resolved,
  isRead,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();

  const customFetcher = useCallback(
    (url: string) =>
      fetcher(getAccessTokenSilently)(url).then(
        (response) => response.data as ICursorListResponse,
      ),
    [getAccessTokenSilently],
  );

  const getKey = useCallback(
    (pageIndex: number, previousPageData: any) => {
      // reached the end
      if (previousPageData && !previousPageData.meta.pagination.hasMorePages)
        return null;

      const { nextCursor } =
        (previousPageData as ICursorListResponse)?.meta?.pagination || {};
      const query = qs.stringify({
        is_read: isRead !== undefined ? isRead : undefined,
        limit: pageSize,
        cursor: nextCursor || undefined,
      });

      return `/admin/notifications?${query}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageSize, resolved, isRead],
  );

  const {
    data: pages,
    error,
    size,
    setSize,
    isValidating,
    mutate,
  } = useSWRInfinite<ICursorListResponse<INotification>>(getKey, customFetcher);

  const isLoadingInitialData = !pages && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && !!pages && typeof pages[size - 1] === 'undefined');
  const hasMore = !!pages && pages[size - 1]?.meta.pagination.hasMorePages;
  const isRefreshing = isValidating && pages && pages.length === size;
  const result = useMemo(() => getResult(pages), [pages]);
  const loadMore = useCallback(() => setSize((s) => s + 1), [setSize]);

  return {
    result,
    loadMore,
    isLoadingMore,
    error,
    hasMore,
    isRefreshing,
    mutate,
  };
};

const getResult = (pages?: ICursorListResponse<INotification>[]) => {
  if (!pages || pages.length <= 0) {
    return [];
  }
  const result = pages.reduce<INotification[]>((acc, page) => {
    const notifications = page.data;
    return [acc, notifications].flat();
  }, []);
  return result.reduce<INotification[]>(
    (items, item) =>
      items.find((x) => x.id === item.id) ? [...items] : [...items, item],
    [],
  );
};

export default useLoadUserNotificationsInfinite;
