import qs from 'qs';
import { isNil } from 'lodash';
import { IKOCCustomer } from 'types/customer';
import { IMetaCursorPagination, ICursorListResponse } from 'types/response';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import useCustomSWR from 'requests/useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  idDiscoveryGroup: number;
  pageSize: number;
  cursor: string;
  isRejected?: boolean;
}

interface Result
  extends IRequestHookResult<{
    data: IKOCCustomer[];
    pagination: IMetaCursorPagination;
  }> {}

export const useLoadDiscoveryGroupCustomerList: IRequestHook<Props, Result> = ({
  idProject,
  idDiscoveryGroup,
  pageSize,
  cursor,
  stopWhen,
  isRejected,
}) => {
  const queries: Record<string, any> = {
    limit: pageSize,
    cursor,
  };
  if (!isNil(isRejected)) {
    queries.is_rejected = isRejected;
  }
  const queryString = qs.stringify(queries);
  const path = `/admin/projects/${idProject}/koc/customers/discovery-group/${idDiscoveryGroup}?${queryString}`;
  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  let result;

  if (response) {
    const responseData = response.data as ICursorListResponse;
    result = {
      data: responseData.data,
      pagination: responseData.meta.pagination,
      error,
    };
  }

  return {
    result,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadDiscoveryGroupCustomerList;
