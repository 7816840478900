import { IKOCAudience } from 'types/koc';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  idPage: string;
  refreshInterval: number;
}
interface Result
  extends IRequestHookResult<IKOCAudience> {}

export const useLoadKOCAudiencePageDetails: IRequestHook<Props, Result> = ({idProject, idPage, stopWhen, refreshInterval}) => {
  const path = `/admin/projects/${idProject}/koc/social-pages/${idPage}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen, refreshInterval });

  return {
    mutate,
    result: response ? response.data as IKOCAudience : undefined,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadKOCAudiencePageDetails;
