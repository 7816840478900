import { useAuth0 } from "@auth0/auth0-react";
import { requester } from "../index";

export const useUpdateKOCPost = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function updateNote(
    idProject: string,
    socialPostId: string,
    hashtags: string[],
    keywords: string[]
  ) {
    return requester({
      method: "put",
      path: `/admin/projects/${idProject}/koc/social-posts/${socialPostId}`,
      data: {
        hashtags,
        keywords,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useUpdateKOCPost;
