import { FC, useState } from 'react';

import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

interface IOption {
  name: string;
  key: string;
}

interface IProps {
  items: IOption[];
  itemsToFilter: string[];
  onDataChange: (values: string[]) => void;
}

const SelectField1PDataTab: FC<IProps> = ({
  items,
  itemsToFilter,
  onDataChange,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    itemsToFilter || [],
  );

  const onChange = (checked: boolean, element: IOption) => {
    const index = selectedValues.findIndex(
      (item: string) => item === element.key,
    );
    let resultValues = [...selectedValues];
    if (index === -1 && checked) {
      resultValues = [...resultValues, element.key];
    }
    if (index > -1 && !checked) {
      resultValues.splice(index, 1);
    }
    setSelectedValues([...resultValues]);
    onDataChange([...resultValues]);
  };

  return (
    <Box mt={2}>
      {items.map((element) => (
        <Box key={element.key}>
          <Checkbox
            checked={selectedValues.includes(element.key)}
            onClick={(event: any) => onChange(event.target?.checked, element)}
          />
          <Typography component="span" sx={{ color: '#20202099' }}>
            {element.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SelectField1PDataTab;
