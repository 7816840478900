import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { CssBaseline } from '@mui/material';
import 'nprogress/nprogress.css';
import './custom.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CookiesProvider } from 'react-cookie';
import AuthWrapper from 'components/AuthWrapper';
import { ModalsProvider } from 'contexts/modalsContext';
import ThemeProvider from 'theme/ThemeProvider';
import { SidebarProvider } from 'contexts/SidebarContext';
import { PermissionContextProvider } from 'contexts/permissionContext';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';
const redirectUri = window.location.origin;

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider>
        <SidebarProvider>
          <BrowserRouter>
            <CssBaseline />

            <Auth0Provider
              domain={domain}
              clientId={clientId}
              redirectUri={redirectUri}
              audience={audience}
            >
              <CookiesProvider>
                <ModalsProvider>
                  <AuthWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <PermissionContextProvider>
                        <App />
                      </PermissionContextProvider>
                    </LocalizationProvider>
                  </AuthWrapper>
                </ModalsProvider>
              </CookiesProvider>
            </Auth0Provider>
          </BrowserRouter>
        </SidebarProvider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
