import qs from 'qs';
import { IProject } from 'types/project';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import { IMetaPagination, IListResponse } from 'types/response';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  pageSize: number;
  pageIndex?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  searchKeyword?: string;
}

interface Result
  extends IRequestHookResult<{
    data: IProject[];
    pagination: IMetaPagination;
  }> {}

export const useLoadProjectList: IRequestHook<Props, Result> = ({
  pageSize,
  pageIndex = 0,
  stopWhen,
  searchKeyword,
  sortBy,
  sortOrder,
}) => {
  const queryString = qs.stringify({
    limit: pageSize,
    page: pageIndex + 1,
    sort_by: sortBy,
    sort_order: sortOrder,
    filter: {
      name: searchKeyword,
    },
  });

  const path = `/admin/projects?${queryString}`;
  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  let outputData;
  if (response) {
    const responseData = response.data as IListResponse;
    outputData = {
      data: responseData.data,
      pagination: responseData.meta.pagination,
    };
  }

  return {
    result: outputData,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadProjectList;
