import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Result
  extends IRequestHookResult<{
    cloudFrontPolicy: string;
    cloudFrontSignature: string;
    cloudFrontKeyPairId: string;
  }> {}

export const useLoadSignedCookies: IRequestHook<IRequestHookParams, Result> = ({
  stopWhen,
  isAnonymous,
}) => {
  const path = 'cloudfront/cookies';
  const {
    data: response,
    error,
    mutate,
  } = useCustomSWR(path, { stopWhen, anonymous: isAnonymous });

  return {
    result: response?.data,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadSignedCookies;
