import { useAuth0 } from '@auth0/auth0-react';
import { IKOCAudience } from 'types/koc';
import { requester } from '../index';

export const useDeleteProduct = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function deleteProduct(idProject: string, id: number) {
    return requester({
      method: 'delete',
      path: `/admin/projects/${idProject}/products`,
      data: {
        id,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IKOCAudience>(response.data));
  };
};

export default useDeleteProduct;
