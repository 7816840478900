import { useAuth0 } from "@auth0/auth0-react";
import { requester } from "../index";

interface DataType {
  idProject: string;
  name: string;
  data: any;
}

export const useCreateSavedFilter = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function createSavedFilter(data: DataType) {
    return requester({
      method: "post",
      path: `/admin/projects/${data.idProject}/koc/discovery/groups`,
      data: {
        name: data.name,
        data: data.data,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useCreateSavedFilter;
