export const ROUTE_HOME = '/';
export const ROUTE_COMING_SOON = 'coming-soon'; // IMPORTANT: NO SLASH '/' AT THE BEGINNING

export const ROUTE_EMPLOYEES = '/accounts';
export const ROUTE_EMPLOYEE_DETAILS = '/accounts/:idEmployee';
export const ROUTE_EMPLOYEE_EDIT = '/accounts/:idEmployee/edit';
export const ROUTE_EMPLOYEE_CREATE = '/accounts/create';
export const ROUTE_DATA_CUSTOMER_IMPORT =
  '/data-management/data-customer-import';
export const ROUTE_PROJECTS = '/projects';
export const ROUTE_PROJECTS_DETAILS = '/projects/:idProject';
export const ROUTE_PROJECTS_EDIT = '/projects/:idProject/edit';
export const ROUTE_PROJECTS_EDIT_REPORTS = '/projects/:idProject/edit/reports';
export const ROUTE_PROJECTS_EDIT_INFO = '/projects/:idProject/edit/info';
export const ROUTE_PROJECTS_METRICS = '/projects/:idProject/metrics';
export const ROUTE_PROJECTS_ACCOUNTS = '/projects/:idProject/accounts';
export const ROUTE_PROJECTS_OVERVIEW_DETAILS =
  '/projects/:idProject/metrics/overview/:id';
export const ROUTE_PROJECTS_KPI_DETAILS =
  '/projects/:idProject/metrics/kpi/:id';
export const ROUTE_PROJECTS_AUDIENCES = '/projects/:idProject/audiences';
export const ROUTE_PROJECTS_EDIT_AUDIENCE =
  '/projects/:idProject/audiences/:id/edit';
export const ROUTE_PROJECT_AUDIENCE_DETAILS =
  '/projects/:idProject/audiences/:id';
export const ROUTE_PROJECTS_CREATE = '/projects/create';
export const ROUTE_PROJECTS_DUPPLICATE = '/projects/dupplicate/:idProject';
export const ROUTE_PROJECTS_KOC_SCHEME = '/projects/:idProject/koc-scheme';

export const ROUTE_ORGANIZATIONS = '/organizations';
export const ROUTE_ORGANIZATIONS_DETAILS = '/organizations/:idOrg';
export const ROUTE_ORGANIZATIONS_EDIT = '/organizations/:idOrg/edit';
export const ROUTE_ORGANIZATIONS_CREATE = '/organizations/create';

export const ROUTE_BRANDS = '/brands';
export const ROUTE_BRANDS_DETAILS = '/brands/:idBrand';
export const ROUTE_BRANDS_EDIT = '/brands/:idBrand/edit';
export const ROUTE_BRANDS_CREATE = '/brands/create';

export const ROUTE_SHOOTING = '/projects/:idProject/production';
export const ROUTE_PROJECT_REPORT = '/projects/:idProject/report/:idReport';
export const ROUTE_PROJECT_BRIEFING = '/projects/:idProject/briefing';
export const ROUTE_PROJECT_BRIEFING_DETAILS =
  '/projects/:idProject/briefing/:id?tab=details';

export const ROUTE_PROJECT_KOC_DISCOVERY = '/projects/:idProject/koc-discovery';
export const ROUTE_PROJECT_KOC_DISCOVERY_DETAIL = '/projects/:idProject/koc-discovery/:id';
export const ROUTE_PROJECT_KOC_DATA_SOURCES =
  '/projects/:idProject/koc-data-sources';
export const ROUTE_PROJECT_DISCOVERY_GROUP =
  '/projects/:idProject/discovery-group';
export const ROUTE_PROJECT_KOC_LIST = '/projects/:idProject/koc-list';
export const ROUTE_PROJECT_KOC_AUDIENCE_DETAILS =
  '/projects/:idProject/koc-discovery/audience/:id';
export const ROUTE_PROJECT_KOC_AUDIENCE_PAGE_DETAILS =
  '/projects/:idProject/koc-data-sources/page/:idPage';
export const ROUTE_PROJECT_KOC_AUDIENCE_GROUP_DETAILS =
  '/projects/:idProject/koc-data-sources/group/:idGroup';

export const ROUTE_LOGIN = '/login';

export const ROUTE_KOC_SCHEME_TYPE = '/koc-scheme-type';

export const ROUTE_KOC_CATEGORY = '/koc-category';
export const ROUTE_KOC_CATEGORY_DETAIL = '/koc-category/:idKOCCategory';

export const ROUTE_FB_CATEGORY_MAPPING = '/FB-category-mapping';

export const ROUTE_CONTENT_GUIDE = '/content-guide';

export const ROUTE_PUBLIC_LINK = '/public-link/:id';

export const ROUTE_ROLES_PERMISSIONS = '/roles-permissions';

export const ROUTE_PROJECT_KOC_DETAIL = '/projects/:idProject/koc-list/:id';

export const ROUTE_PROJECT_CONTENT_LIBRARY =
  '/projects/:idProject/content-library';
export const ROUTE_PROJECT_CONTENT_DETAILS =
  '/projects/:idProject/koc-list/:id/content-details/:idContent';
export const ROUTE_PROJECT_UGC_CONTENT_DETAILS =
  '/projects/:idProject/content-details/:idContent';

export const ROUTE_PROJECT_CONTENT_MIXER = '/projects/:idProject/content-mixer';

export const ROUTE_PROJECT_LINK_MANAGEMENT =
  '/projects/:idProject/link-management';

export const ROUTE_PROJECT_USECASE_PRODUCT_MANAGEMENT =
  '/projects/:idProject/usecase-product-management';

export const ROUTE_PROJECT_PRODUCT_DETAILS =
  '/projects/:idProject/products/:idProduct';

export const ROUTE_PROJECT_AGREEMENT_MANAGEMENT = '/projects/:idProject/agreement-management';

export const ROUTE_AGREEMENT_TEMPLATE =
  '/template-management/agreement-template';

export const ROUTE_MESSAGE_TEMPLATE =
  '/template-management/message-template';

