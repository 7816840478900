
import { useCallback, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useAuth0 } from '@auth0/auth0-react';
import qs from 'qs';
import { IHistory } from 'types/history';
import { ICursorListResponse } from 'types/response';
import { IRequestHookParams } from 'types/request';
import { fetcher } from 'requests/index';

interface IProps extends IRequestHookParams {
  idProject: string;
  pageSize: number;
  idBriefs: string;
}

export const useLoadHistoriesProjectBriefing = ({ idProject, idBriefs, pageSize }: IProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const customFetcher = useCallback(
    (url: string) =>
      fetcher(getAccessTokenSilently)(url).then((response) => response.data as ICursorListResponse),
    [getAccessTokenSilently]
  );

  const getKey = useCallback(
    (pageIndex: number, previousPageData: any) => {
      // reached the end
      if (previousPageData && !previousPageData.meta.pagination.hasMorePages) return null;

      const { nextCursor } = (previousPageData as ICursorListResponse)?.meta?.pagination || {};

      const query = qs.stringify({
        limit: pageSize,
        cursor: nextCursor || undefined,
      });
      return `/admin/projects/${idProject}/briefs/${idBriefs}/histories?${query}`;
    },
    [idProject, pageSize, idBriefs]
  );

  const {
    data: pages,
    error,
    size,
    setSize,
    isValidating,
    mutate,
  } = useSWRInfinite<ICursorListResponse<IHistory>>(getKey, customFetcher);

  const isLoadingInitialData = !pages && !error;
  const isLoadingMore =
    isLoadingInitialData || (size > 0 && !!pages && typeof pages[size - 1] === 'undefined');
  const hasMore = !!pages && pages[size - 1]?.meta.pagination.hasMorePages;
  const isRefreshing = isValidating && pages && pages.length === size;
  const result = useMemo(() => getResult(pages), [pages]);
  const loadMore = useCallback(() => setSize((s) => s + 1), [setSize]);

  return {
    result,
    loadMore,
    isLoadingMore,
    error,
    hasMore,
    isRefreshing,
    mutate,
  };
};

const getResult = (pages?: ICursorListResponse<IHistory>[]) => {
  if (!pages || pages.length <= 0) {
    return [];
  }
  const result = pages.reduce<IHistory[]>((acc, page) => {
    const histories = page.data;
    return [acc, histories].flat();
  }, []);
  return result.reduce<IHistory[]>((items, item) => items.find(x => x.id === item.id) ? [...items] : [...items, item], [])
};

export default useLoadHistoriesProjectBriefing;
