import React, { FC, useMemo } from 'react';
import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  AutocompleteRenderOptionState,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { subMonths, subDays, format, endOfDay, startOfDay } from 'date-fns';
import CreateDateRangeDialog from 'components/CreateDateRangeDialog';
import {
  CustomAutocompleteNoOption,
  renderOptionWithCustomRange,
} from 'components/CustomAutocomplete';
import { useCustomAutocomplete } from 'hooks/useAutocomplete';
import { designTypo, designThemeColors } from 'theme/schemes/PurpleLightTheme';

export interface ICreatedTimeOption {
  id: string;
  name: string;
  from: string;
  to: string;
}

interface IProps {
  label: string;
  onChange: (value: ICreatedTimeOption | null) => void;
  renderCustomOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: ICreatedTimeOption,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  createdTimes: ICreatedTimeOption[];
  initValue?: ICreatedTimeOption;
  alter?: boolean;
  sx?: SxProps;
  disabled?: boolean;
}

const CustomDateRangeFilter: FC<IProps> = ({
  label,
  onChange,
  renderCustomOption,
  createdTimes,
  initValue,
  alter,
  sx,
  disabled = false,
  ...props
}) => {
  const {
    isOpenDialog,
    onOpenDialog,
    onCloseDialog,
    selectedValues,
    setSelectedValues,
    items,
    setItems,
  } = useCustomAutocomplete<ICreatedTimeOption>(
    createdTimes,
    useMemo(() => (initValue ? [initValue] : []), [initValue]),
  );

  const renderOption = renderOptionWithCustomRange(
    onOpenDialog,
    undefined,
    (optionProps, option) => (
      <li {...optionProps} key={option.id}>
        {option.name ?? ''}
      </li>
    ),
  );

  const renderName = (from: string | null, to: string | null) => {
    if (from && to) {
      return `${format(new Date(from), 'dd/MM/yyyy')}-${
        format(new Date(to), 'dd/MM/yyyy') || ''
      }`;
    }
    if (from && !to) {
      return `From ${format(new Date(from), 'dd/MM/yyyy')} to Present`;
    }
    if (!from && to) {
      return `Past to ${format(new Date(to), 'dd/MM/yyyy')}`;
    }
    return `${from || ''}-${to || ''}`;
  };

  const handleCustomRange = (from: string, to: string) => {
    const option: ICreatedTimeOption = {
      id: `${from || ''}-${to || ''}`,
      name: renderName(from, to),
      from: from ? startOfDay(new Date(from)).toISOString() : '',
      to: to ? endOfDay(new Date(to)).toISOString() : '',
    };
    setItems([option, ...items]);
    setSelectedValues([option]);
    onChange(option);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      sx={
        alter
          ? {
              ...(sx ? { sx } : {}),
              maxWidth: 240,
              div: {
                background: '#fff',
                borderRadius: 1,
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: `${designThemeColors.neutral[100]} !important`,
                borderRadius: '4px',
              },
              '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                ...designTypo.caption2,
                minWidth: '7rem !important',
                width: 'fit-content !important',
              },
              '.MuiAutocomplete-clearIndicator': {
                mr: 0,
                svg: { height: '0.8rem', width: '0.8rem' },
              },
              '.MuiAutocomplete-inputRoot': {
                height: 36,
                pl: '8px !important',
                py: '1px !important',
                pr: '2.5rem !important',
              },
            }
          : { ...(sx ? { ...sx } : {}) }
      }
      {...props}
    >
      <Typography component="p">{label}</Typography>
      <Autocomplete
        disabled={disabled}
        size="small"
        className="date-range-auto-complete"
        fullWidth
        value={selectedValues[0] ? { ...selectedValues[0] } : null}
        options={items}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, newValue) => {
          setSelectedValues(newValue ? [newValue] : []);
          onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={label || 'Filter by Created Date'}
          />
        )}
        renderOption={renderCustomOption ?? renderOption}
        noOptionsText={<CustomAutocompleteNoOption onClick={onOpenDialog} />}
      />
      <CreateDateRangeDialog
        open={isOpenDialog}
        onClose={onCloseDialog}
        onSuccess={(from, to) => handleCustomRange(from, to)}
      />
    </Box>
  );
};

export const getOption = (
  number: number,
  type: 'days' | 'months',
): ICreatedTimeOption => {
  return {
    id: `${number}_${type}`,
    name: `Last ${number} ${type}`,
    to: new Date().toISOString(),
    from: (type === 'days' ? subDays : subMonths)(
      new Date(),
      number,
    ).toISOString(),
  };
};

export default CustomDateRangeFilter;
