import qs from 'qs';
import { IOfficialKoc } from 'types/koc';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import { IMetaPagination, IListResponse } from 'types/response';
import { IProduct } from 'types/product';
import { IUseCase } from 'types/use-case';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  searchKeyword?: string;
  pageSize: number;
  pageIndex?: number;
  showDeactivate: string;
  deliveryStatus: string;
  deliveryStage: string;
  deliveryStatusDuration: {
    from: number | null;
    to: number | null;
  };
  products?: IProduct[];
  useCases?: IUseCase[];
}

interface Result
  extends IRequestHookResult<{
    data: IOfficialKoc[];
    pagination: IMetaPagination;
  }> {}

export const useLoadKOCOfficialList: IRequestHook<Props, Result> = ({
  idProject,
  searchKeyword,
  pageSize,
  pageIndex = 0,
  showDeactivate,
  deliveryStatus,
  deliveryStage,
  deliveryStatusDuration,
  products,
  useCases,
  stopWhen,
}) => {
  const queryString = qs.stringify({
    q: searchKeyword,
    limit: pageSize,
    page: pageIndex + 1,
    show_deactivate: showDeactivate,
    delivery_status: deliveryStatus,
    delivery_stage: deliveryStage,
    from_delivery_status_duration: deliveryStatusDuration.from,
    to_delivery_status_duration: deliveryStatusDuration.to,
    id_product: products?.map((item) => item.id) || [],
    id_use_case: useCases?.map((item) => item.id) || [],
  });

  const path = `/admin/projects/${idProject}/koc/official-customers?${queryString}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  let outputData;
  if (response) {
    const responseData = response.data as IListResponse;
    outputData = {
      data: responseData.data,
      pagination: responseData.meta.pagination,
    };
  }

  return {
    result: outputData,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadKOCOfficialList;
