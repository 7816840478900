import { useCookies } from 'react-cookie';
import { useLoadSignedCookies } from 'requests/general';

const useSignedCookies = (isAnonymous?: boolean) => {
  const { result } = useLoadSignedCookies({ isAnonymous });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies([
    'CloudFront-Policy',
    'CloudFront-Signature',
    'CloudFront-Key-Pair-Id',
  ]);

  if (result) {
    setCookie('CloudFront-Policy', result.cloudFrontPolicy, {
      domain: 'mightywow.ai',
      path: '/',
    });
    setCookie('CloudFront-Signature', result.cloudFrontSignature, {
      domain: 'mightywow.ai',
      path: '/',
    });
    setCookie('CloudFront-Key-Pair-Id', result.cloudFrontKeyPairId, {
      domain: 'mightywow.ai',
      path: '/',
    });
  }
};

export default useSignedCookies;
