import { format } from 'date-fns';
import { COLORS } from 'constants/colors';
import { ITypeModel } from 'types/assignment';
import { IAudienceStatusStyle, IStatusChipStyle } from 'types/audience';
import { ITechMaxFileSize } from 'types/contentguides';
import parseJwt from 'utils/parseJwt';
import {
  ContentStatus,
  OriginStatus,
  IKOCAudiencePostProcess,
} from 'types/koc';
import { DataImportStatus } from 'types/data-import';
import { TemplateStatus } from 'types/template';
import { AgreementStatus } from 'types/agreement';
import { SMSTemplateStatus } from 'types/message-template';

export const getErrorMessage = (serverError: any, defaultMessage: string) => {
  return serverError?.data?.messages?.[0]?.message || defaultMessage;
};
export const getErrorMessageCode = (serverError: any) => {
  return serverError?.data?.messages?.[0]?.messageCode;
};

export const getTypeModelName = (typeMode: ITypeModel) => {
  const map: Record<ITypeModel, string> = {
    ORG: 'Organization',
    BRAND: 'Brand',
    PROJECT: 'Project',
  };
  const name = map[typeMode];
  if (!name) {
    console.error('Invalid typeModel');
  }
  return name;
};

export const convertTimeToString = (
  backDate: string,
  isShortMinute: boolean = false,
) => {
  const refMilestrones = [
    {
      name: 'year(s)',
      value: 3600 * 24 * 365,
    },
    {
      name: 'week(s)',
      value: 3600 * 24 * 7,
    },
    {
      name: 'day(s)',
      value: 3600 * 24,
    },
    {
      name: 'hour(s)',
      value: 3600,
    },
    {
      name: isShortMinute ? 'm' : 'minute(s)',
      value: 60,
    },
  ];
  const timeStamp = new Date().getTime() - new Date(backDate).getTime();
  const milestone = refMilestrones.find(
    (item) => item.value <= timeStamp / 1000,
  );
  if (milestone) {
    return milestone.name === 'm'
      ? `${Math.round(timeStamp / 1000 / milestone.value)}${milestone.name}`
      : `${Math.round(timeStamp / 1000 / milestone.value)} ${milestone.name}` ||
      '';
  }
  return 'Just now';
};

interface RequesterParams {
  getToken?: () => Promise<string>;
}

export const GetUserRole = async (params: RequesterParams) => {
  const { getToken } = params;

  const jwtToken = getToken ? await getToken() : '';
  const token = parseJwt(jwtToken);

  return token?.['https://mightymix.ai/roles'] || ['Employee'];
};

export const getUrlExtension = (url: string) => {
  return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim() || '';
};

export const getFileNameWithoutExtension = (fileName: string): string => {
  return fileName ? fileName.replace(/\.[^/.]+$/, '') : '';
};

export const checkTypeMedia = (ext: string) => {
  const types = [
    {
      key: 'image',
      types: 'tif|tiff|bmp|jpg|jpeg|png|webp',
    },
    {
      key: 'video',
      types: 'mp4|webm|ogg|quicktime',
    },
  ];
  if (ext === 'gif' || ext === 'image/gif') return { key: 'gif', types: 'gif' };

  return (
    types.find((item) => {
      const ptt = new RegExp(`${item.types}`, 'gi');

      return ptt.test(ext);
    }) || { key: 'others', types: '' }
  );
};

export const getOverviewValueType = (key: string) => {
  switch (key) {
    case 'PERM_REACH':
    case 'PERM_FRE':
    case 'PERM_IMP':
    case 'PP_PG_ENGA':
    case 'PP_PAGE_LIKE':
    case 'PP_POST_COM':
    case 'PP_POST_ENGA':
    case 'PP_POST_REACT':
    case 'PP_POST_SHARE':
    case 'PP_PHOTO_VIEW':
    case 'PP_EVENT_RESP':
    case 'PP_100_IN_V_IMP':
    case 'PP_100_IN_V_RCH':
    case 'ME_UQ_C_2S_PL':
    case 'ME_C_2S_PL':
    case 'ME_3S_PL':
    case 'ME_THRUPLAY':
    case 'ME_PLAY':
    case 'ME_PLAY_25PC':
    case 'ME_PLAY_50PC':
    case 'ME_PLAY_75PC':
    case 'ME_PLAY_95PC':
    case 'ME_PLAY_100PC':
    case 'CL_EST_CA_CF':
    case 'CL_LINK':
    case 'CL_UQ_LINK':
    case 'CL_CTR':
    case 'CL_UQ_CTR':
    case 'CL_OB_CTR':
    case 'CL_UQ_OB_CTR':
    case 'CL_ALL':
    case 'CL_UQ_ALL':
    case 'CL_CTR_ALL':
    case 'CL_UQ_CTR_ALL':
    case 'AW_EST_AD_RT':
    case 'CON_ADD_CARD':
    case 'CON_ADD_WL':
    case 'CON_APP_ACTIVE':
    case 'CON_APP_INSTALL':
    case 'CON_AD_IMP':
    case 'CON_LA_PG_VIEW':
    case 'CON_LEAD':
    case 'CON_PURCHASE':
    case 'CON_RT_SUBMIT':
    case 'CON_REG_COMP':
    case 'CON_SUBS':
    case 'CON_TRIAL_START':
    case 'CON_TUT_COMP':
    case 'CON_AD_CLICK':
      return 'interger';
    case 'COST_P_RESULT':
    case 'COST_P_1K_RCH':
    case 'COST_P_1K_IMP':
    case 'CM_P_2S_VIDEO':
    case 'CM_P_3S_VIDEO':
    case 'CM_P_THRUPLAY':
    case 'CCL_P':
    case 'CCL_P_UQ':
    case 'CCL_P_OB':
    case 'CCL_P_UQ_OB':
    case 'CCL_CPC_ALL':
    case 'CCL_P_UQ_ALL':
    case 'AW_EST_AD_PP':
    case 'COST_AW_EST_PP':
      return '2-digits';
    default:
      return 'interger';
  }
};

export const getAudienceStatusName = (status: number) => {
  const map: Record<number, string> = {
    0: 'Pending',
    1: 'Processing',
    2: 'Processed',
    3: 'Importing',
    210: 'Drafting',
  };
  const name = map[status];
  if (!name) {
    return 'Drafting';
  }
  return name;
};

export const getAudienceStatusStyle = (status: string) => {
  const map: Record<string, IAudienceStatusStyle> = {
    NEW: {
      background: '#ffffff',
      border: '#E8EAED',
      secondaryBg: '#ff000024',
      secondaryBorder: '#e80909',
    },
    PENDING: {
      background: '#f6e5d8',
      border: '#d98b4b',
      secondaryBg: '#fff0b3',
      secondaryBorder: '#172b4d',
    },
    PROCESSING: {
      background: '#d9e3f5',
      border: '#5c91da',
      secondaryBg: '#deebff',
      secondaryBorder: '#0747a6',
    },
    PROCESSED: {
      background: '#ffffff',
      border: '#E8EAED',
      secondaryBg: '#e8eaee',
      secondaryBorder: '#0747a6',
    },
    PROCESSED_FAIL: {
      background: '#ff643b4f',
      border: '#ff643b',
      secondaryBg: '#e8eaee',
      secondaryBorder: '#0747a6',
    },
    IMPORTING: {
      background: '#f6e5d8',
      border: '#d98b4b',
      secondaryBg: '#fff0b3',
      secondaryBorder: '#172b4d',
    },
  };
  const style = map[status];
  if (!style) {
    return {
      background: '#ffffff',
      border: '#EFEFEF',
      secondaryBg: 'grey',
      secondaryBorder: '#e8eaed',
    };
  }
  return style;
};

export const getChipStyleByAudienceStatus = (status: string) => {
  const map: Record<string, IStatusChipStyle> = {
    NEW: {
      background: '#F6F3FD',
      color: '#8C64DC',
    },
    PENDING: {
      background: '#F5F5F5',
      color: '#424242',
    },
    PROCESSING: {
      background: '#FFF8EB',
      color: '#FFB41F',
    },
    PROCESSED: {
      background: '#edfff2',
      color: '#53c479',
    },
    PROCESSED_FAIL: {
      background: '#FFF1F4',
      color: '#EF1446',
    },
    IMPORTING: {
      background: '#FFF8EB',
      color: '#FFB41F',
    },
    AGREEMENT_SENT: {
      background: '#fbf1ff',
      color: '#b384c4',
    },
    WAITING_FOR_APPROVAL: {
      background: '#f0f5ff',
      color: '#002c7d',
    },
    AGREEMENT_VIEWED: {
      background: '#f0f5ff',
      color: '#002c7d',
    },
    REJECTED: {
      background: '#fef1f2',
      color: '#ca4c50',
    },
    AGREEMENT_REJECTED: {
      background: '#fef1f2',
      color: '#ca4c50',
    },
    APPROVED: {
      background: '#edfff2',
      color: '#14aa42',
    },
    AGREEMENT_APPROVED: {
      background: '#edfff2',
      color: '#14aa42',
    }
  };
  const style = map[status];
  if (!style) {
    return {
      background: '#F6F3FD',
      color: '#8C64DC',
    };
  }
  return style;
};

export const replaceAt = (text: string, index: number, replacement: string) => {
  return (
    text.substr(0, index) +
    replacement +
    text.substr(index + replacement.length)
  );
};

export const handleRenderMineType = (data) => {
  const check = data?.split('/')?.[0];
  if (check === 'image' && data?.split('/')?.[1] !== 'gif') return 'image';
  if (
    check === 'video' ||
    (check === 'image' && data?.split('/')?.[1] === 'gif')
  )
    return 'video';
  return '';
};

export const handleRenderType = (data) => {
  if (data === 'IMAGE') return 'image';
  if (data === 'VIDEO') return 'video';
  return 'SOCIAL_POST';
};

export const handleRenderName = (data) => {
  return data || '';
};

export const handleCheckApproveDisabled = (media) => {
  const mediaCheckArray = media?.find((item) => item?.isApproved === true);

  if (mediaCheckArray) return true;

  return false;
};

export const parseSizeFromContentGuide = (techSizeObj: ITechMaxFileSize) => {
  let size = parseInt(techSizeObj.value, 10) || 0;
  let sizeInKB = 0;
  let { unit } = techSizeObj;
  switch (techSizeObj && techSizeObj.unit) {
    case 'KB':
      sizeInKB = size;
      break;
    case 'MB':
      sizeInKB = size * 1024;
      break;
    case 'GB':
      sizeInKB = size * 1024 * 1024;
      break;

    default:
      sizeInKB = 0;
      break;
  }
  if (sizeInKB / 1024 > 100) {
    size = 100;
    sizeInKB = 100 * 1024;
    unit = 'MB';
  }
  return {
    name: `${new Intl.NumberFormat('en-US', {}).format(size)}${unit}`,
    size: sizeInKB * 1024,
  };
};
export function getFirstLetters(name: string) {
  const words: string[] = name.split(' ').map((word) => word[0]);
  const text =
    words.length > 1 ? `${words[0]}${words[words.length - 1]}` : words[0];

  return text.toUpperCase();
}

export function stringAvatar(name: string) {
  return {
    sx: {
      fontSize: '21px',
      fontWeight: 'normal',
      background: `#624CC7 !important`,
      // background: `#${randomColor} !important`,
    },
    children: getFirstLetters(name),
  };
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

export function hexToRGB(string) {
  let color = string;
  color = `${color}0x${color
    .slice(1)
    .replace(color.length < 5 && /./g, '$&$&')}`;
  const r = color >> 16; // eslint-disable-line no-bitwise
  const g = (color >> 8) & 255; // eslint-disable-line no-bitwise
  const b = color & 255; // eslint-disable-line no-bitwise

  return { r, g, b };
}

export function stringToRGB(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  const rgb = [0, 0, 0];
  /* eslint-disable no-plusplus */
  for (let index = 0; index < 3; index++) {
    const value = (hash >> (index * 8)) & 255;
    rgb[index] = value;
  }
  return { r: rgb[0], g: rgb[1], b: rgb[2] };
}

export function isLightColor(string) {
  const { r, g, b } = stringToRGB(string);
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5;
}

export function getColorByIndex(index: number) {
  const idx = index < COLORS.length ? index : index % COLORS.length;
  return COLORS[idx];
}

export function toDateStr(dateStr: string) {
  const date = new Date(dateStr);
  return date?.toString() !== 'Invalid Date'
    ? format(date, 'dd/MM/yyyy')
    : 'Invalid Date';
}

export function toDateTimeStr(
  dateStr: string,
  dateFormat = 'dd/MM/yyyy HH:mm',
) {
  const date = new Date(dateStr);
  return date?.toString() !== 'Invalid Date'
    ? format(date, dateFormat)
    : 'Invalid Date';
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(({ value }) => {
      return num >= value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}
export function formatCurrency(num, brief = false): string {
  if (brief) {
    return `${nFormatter(num, 1)} VND`;
  }
  return Number(`${num}`).toLocaleString('vi-VN', {
    style: 'currency',
    currencyDisplay: 'code',
    currency: 'VND',
  });
}

export function stringLimit(str: string, n: number): string {
  if (!str) return str;
  return str.length > n ? `${str.slice(0, n - 1)}...` : str;
}

export const generateVideoThumbnail = (file: File): Promise<File | null> => {
  return new Promise((resolve) => {
    const canvas = document.createElement(`canvas`);
    const video = document.createElement(`video`);
    video.src = URL.createObjectURL(file);
    video.currentTime = 5;
    video.addEventListener(
      'seeked',
      () => {
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        const context = canvas.getContext('2d');
        context?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        canvas.toBlob((blob) => {
          const thumbnail = blob
            ? new File(
              [blob],
              `${file.name}-${new Date().getTime()}-thumbnail`,
              {
                lastModified: new Date().getTime(),
              },
            )
            : null;
          return resolve(thumbnail);
        });
      },
      false,
    );
  });
};
export const formatDateTime = (dateStr: string | Date) => {
  let date;
  if (typeof dateStr === 'string') {
    date = new Date(dateStr);
  } else {
    date = dateStr;
  }
  return date?.toString() !== 'Invalid Date'
    ? format(date, 'dd/MM/yyyy h:mm a')
    : 'Invalid Date';
};

export const mapContentStatus = (status: string): ContentStatus => {
  switch (status) {
    case OriginStatus.New:
      return ContentStatus.New;
    case OriginStatus.Published:
      return ContentStatus.Published;
    default:
      return status as ContentStatus;
  }
};

export const getPostErrorMessage = (
  process: IKOCAudiencePostProcess[],
): string[] => {
  return (
    process
      ?.filter((pro: IKOCAudiencePostProcess) => pro.active === 1)
      ?.map((item: IKOCAudiencePostProcess) => {
        if (item.status === 'PROCESSED_FAIL') {
          switch (item.processName) {
            case 'PROCESS_GET_REACT':
              return `User Reacted: ${item.error}`;
            case 'PROCESS_GET_COMMENT':
              return `User Commented: ${item.error}`;
            case 'PROCESS_GET_SHARE':
              return `User Shared: ${item.error}`;
            case 'PROCESS_GET_PERFORMANCE':
              return `Post Performance: ${item.error}`;
            default:
              return '';
          }
        }
        return '';
      }) || []
  );
};

export const getPostErrorMessageByType = (
  process: IKOCAudiencePostProcess[],
  type: string,
): string => {
  const errorActiveProccess = process?.find(
    (pro: IKOCAudiencePostProcess) =>
      pro.active === 1 && pro.processName?.includes(type),
  );
  if (errorActiveProccess) {
    switch (type) {
      case 'REACT':
        return `User Reacted: ${errorActiveProccess.error}`;
      case 'COMMENT':
        return `User Commented: ${errorActiveProccess.error}`;
      case 'SHARE':
        return `User Shared: ${errorActiveProccess.error}`;
      case 'PERFORMANCE':
        return `Post Performance: ${errorActiveProccess.error}`;
      default:
        return '';
    }
  }
  return '';
};

export const getImportDataCustomerStatus = (
  status: DataImportStatus,
): string => {
  switch (status) {
    case DataImportStatus.PENDING:
      return 'Pending';
    case DataImportStatus.IMPORTING:
      return 'Importing';
    case DataImportStatus.COMPLETED:
      return 'Success';
    case DataImportStatus.FAILED:
      return 'Failed';
    case DataImportStatus.PARTIALLY_COMPLETED:
      return 'Partially Completed';
    default:
      return 'Unknown';
  }
};

export const getTemplateStatus = (status: TemplateStatus): string => {
  switch (status) {
    case TemplateStatus.DRAFT:
      return 'Draft';
    case TemplateStatus.PUBLISHED:
      return 'Published';
    case TemplateStatus.UNPUBLISHED:
      return 'Unpublished';
    case TemplateStatus.PREVIEW:
      return 'Preview';
    default:
      return 'Unknown';
  }
};

export const getAgreementStatus = (status: AgreementStatus): string => {
  switch (status) {
    case AgreementStatus.DRAFT:
      return 'Draft';
    case AgreementStatus.PUBLISHED:
      return 'Published';
    case AgreementStatus.UNPUBLISHED:
      return 'Unpublished';
    case AgreementStatus.SENT:
      return 'Agreement Sent';
    case AgreementStatus.VIEWED:
      return 'Agreement Viewed';
    case AgreementStatus.REJECTED:
      return 'Agreement Rejected';
    case AgreementStatus.APPROVED:
      return 'Agreement Accepted';
    default:
      return 'Unknown';
  }
};

export const getSMSTemplateStatus = (status: SMSTemplateStatus): string => {
  switch (status) {
    case SMSTemplateStatus.NEW:
      return 'New';
    case SMSTemplateStatus.WAITING_FOR_APPROVAL:
      return 'Waiting for approval';
    case SMSTemplateStatus.APPROVED:
      return 'Approved';
    case SMSTemplateStatus.REJECTED:
      return 'Rejected';
    default:
      return 'Unknown';
  }
};

export const formatNumber = (value?: number): string | undefined =>
  (value || value === 0)
    ? new Intl.NumberFormat('en-US', {}).format(value)
    : undefined