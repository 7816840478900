import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

interface DataType {
  idProject: string;
  idKocCustomer: string;
  contactStage: string;
  contactRequestDate: string;
  contactStatus: string;
  contactChannel: string;
  contactDeclineReason?: string | null;
}

export const useEditPotentialKOCStatus = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function editPotentialKOCStatus(data: DataType) {
    return requester({
      method: 'put',
      path: `/admin/projects/${data.idProject}/koc/potential-customers/${data.idKocCustomer}/status`,
      data: {
        contact_status: data.contactStatus,
        contact_stage: data.contactStage,
        contact_request_date: data.contactRequestDate,
        contact_channel: data.contactChannel || 'ZALO',
        ...(data.contactDeclineReason
          ? { contact_decline_reason: data.contactDeclineReason }
          : {}),
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useEditPotentialKOCStatus;
