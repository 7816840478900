export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_ITEM_EACH_ROW = 3;

export const RECOMMENDED_PLATFORM = [
  { id: 'FACEBOOK', name: 'Facebook' },
  { id: 'INSTAGRAM', name: 'Instagram' },
  { id: 'TIKTOK', name: 'Tiktok' },
];

export const ACCEPT_IMAGES = [
  'image/bmp',
  'image/gif',
  'image/ief',
  'image/jpeg',
  'image/pipeg',
  'image/tiff',
  'image/png',
  'image/webp',
];