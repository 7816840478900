import { IProductPhotoType } from 'types/product';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  idProduct: number;
}

interface Result extends IRequestHookResult<IProductPhotoType[]> {}

export const useLoadProjectProductPhotoTypeList: IRequestHook<Props, Result> =
  ({ idProject, idProduct, stopWhen }) => {
    const path = `/admin/projects/${idProject}/products/${idProduct}/photo-types`;

    const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

    const types = response ? (response.data as IProductPhotoType[]) : [];
    return {
      result: types,
      error,
      isLoading: !error && !response,
      mutate,
    };
  };

export default useLoadProjectProductPhotoTypeList;
