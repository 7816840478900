import { useRef, useState } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import { Avatar, Box, Button, Divider, Hidden, Popover } from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useModalsContext } from 'contexts/modalsContext';
import { useLoadUserProfile } from 'requests/profile';
import {
  CustomIconButton,
  MenuUserBox,
  UserBoxButton,
  UserBoxDescription,
  UserBoxLabel,
  UserBoxText,
} from './styled';

function HeaderUserbox() {
  const { showConfirm } = useModalsContext();
  const { user: authUser, logout } = useAuth0();
  const user = authUser as User;
  const { result } = useLoadUserProfile({});

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      const confirmLogout = await showConfirm({
        message: 'Are you sure you want to logout?',
      });
      if (confirmLogout) {
        logout({ returnTo: window.location.origin });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="circular"
          alt={user.name}
          src={user.picture || user.avatar}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="caption1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="caption4">
              {result?.role?.name || '--'}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <CustomIconButton ml={1.5}>
            <ExpandMoreTwoToneIcon sx={{ fontSize: '12px' }} />
          </CustomIconButton>
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="caption1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="caption4">
              {result?.role?.name || '--'}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
