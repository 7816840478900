import { useAuth0 } from '@auth0/auth0-react';
import { fetcher } from 'requests';

const customFieldExportMap = {
  phone: 'phone',
  email: 'email',
  friends: 'friends',
  averageEngagement: 'average_engagement',
  totalPost: 'total_post',
  totalEngagement: 'total_engagement',
  lastPostedTime: 'last_posted_time',
  dTypeAudiences: 'd_type_audiences',
  posts: 'post',
  pages: 'page',
  firstAudiences: 'first_audiences',
  stage: 'contact_stage',
  status: 'contact_status',
  statusDuration: 'status_duration',
  discoveryGroup: 'discovery_group',
  recommendedPlatforms: 'recommended_platforms',
  cost: 'cost',
  format: 'formats',
  city: 'city',
  hometown: 'hometown',
  sex: 'gender',
  relationship: 'relationship',
  professional: 'professional',
  socialInteractions: 'social_interactions',
  socialViews: 'social_views',
  contentOverview: 'content_overview',
  totalReaction: 'total_reaction',
  totalComment: 'total_comment',
  totalShare: 'total_share',
  category: 'categories',
  note: 'note',
  tag: 'tag',
};

const useFetchXLSLData = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function fetchXLSLData(idProject: string, fields?: string[]) {
    let query = '';
    if (fields && fields.length > 0) {
      query = `?${fields
        .map(
          (f) =>
            customFieldExportMap[f] && `fields[]=${customFieldExportMap[f]}`,
        )
        .filter((f) => !!f)
        .join('&')}`;
    }
    return fetcher(getAccessTokenSilently)(
      `/admin/projects/${idProject}/koc/potential-customers/export${query}`,
      {
        responseType: 'blob',
        accept: 'application/vnd.ms-excel',
        contentType: 'application/json',
      },
    ).then((response) => response);
  };
};

export default useFetchXLSLData;

export const downloadFile = (dataToExport: any, name: string) => {
  if (dataToExport) {
    const url = window.URL.createObjectURL(new Blob([dataToExport]));
    const linkDown = document.createElement('a');
    linkDown.href = url;
    linkDown.download = name;
    linkDown.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 100);
  } else {
    // eslint-disable-next-line no-alert
    alert("Can't download file from server!");
  }
};
