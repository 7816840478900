import { forwardRef } from 'react';
import {
  TextField,
  AutocompleteProps,
  ListItem,
  ListItemButton,
  styled,
  Typography,
} from '@mui/material';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { ROUTE_PROJECTS_DETAILS } from 'constants/routes';
import {
  useLoadProjectbyKeyword,
  useLoadProjectDetails,
} from 'requests/projects';
import CustomAutocomplete from 'components/CustomAutocomplete';

const CustomTextField = styled(TextField)(
  ({ theme }) => `
  input {
    font-size: 1.25rem;
    
    @media (max-width: ${theme.breakpoints.values.lg}px) {
      font-size: 1rem;
    }
    @media (max-width: ${theme.breakpoints.values.md}px) {
      font-size: 0.825rem;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiOutlinedInput-root:hover {
    background-color: ${theme.colors.primary.lighter};
  },
`,
);

interface ProjectAutocompleteProps
  extends Omit<
    AutocompleteProps<string, undefined, undefined, undefined>,
    'options' | 'renderInput'
  > {}

export const ProjectAutocomplete = forwardRef(
  (props: ProjectAutocompleteProps, ref) => {
    const { idProject } = useParams();
    return (
      <CustomAutocomplete
        ref={ref}
        {...props}
        value={idProject || null}
        sx={{ minWidth: 300 }}
        useLoadDetails={useLoadProjectDetails}
        useLoadListByKeyword={useLoadProjectbyKeyword}
        renderInput={(params) => {
          return (
            <CustomTextField
              {...params}
              size="small"
              placeholder="Select project"
            />
          );
        }}
        renderCustomOption={(renderProps, option) => {
          return (
            <ListItem {...renderProps} key={option.id}>
              <ListItemButton
                component={RouterLink}
                to={generatePath(ROUTE_PROJECTS_DETAILS, {
                  idProject: option.id,
                })}
              >
                <Typography variant="body1">{option.name}</Typography>
              </ListItemButton>
            </ListItem>
          );
        }}
      />
    );
  },
);

export default ProjectAutocomplete;
