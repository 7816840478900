import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import { IOption } from 'types/audience';
import useCustomSWR from '../useCustomSWR';

interface Result extends IRequestHookResult<IOption[]> {}

export const useLoadHometownList: IRequestHook<IRequestHookParams, Result> = ({
  stopWhen,
}) => {
  const path = `/admin/sample/hometowns`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const hometowns = response ? (response.data as IOption[]) : [];
  return {
    result: hometowns,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadHometownList;
