import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

export const useRefreshKOCAudiencePage = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function refreshAudiences(idProject: string, idPage: string) {
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}/koc/social-pages/${idPage}/refresh`,
      data: {},
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useRefreshKOCAudiencePage;
