import { useAuth0 } from '@auth0/auth0-react';
import _get from 'lodash/get';
import { IProjectScheme } from 'types/project';
import { requester } from '../index';

interface DataType {
  kocScheme: any;
  value: number;
  name: string;
  description?: string;
}

export const useCreateProjectKOCScheme = ({ idP }) => {
  const { getAccessTokenSilently } = useAuth0();

  return function createProjectKOCScheme(data: DataType) {
    return requester({
      method: 'post',
      path: `/admin/projects/${idP}/koc/schemes`,
      data: {
        id_koc_scheme: _get(data, 'kocScheme.id'),
        name: _get(data, 'name'),
        description: _get(data, 'description', ''),
        value: _get(data, 'value'),
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IProjectScheme>(response.data));
  };
};

export default useCreateProjectKOCScheme;
