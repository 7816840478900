import qs from 'qs';
import { IPotentialKoc } from 'types/koc';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import { IMetaPagination, IListResponse } from 'types/response';
import { IFilter } from 'pages/KOCNurturing/KOCDiscovery/components/FilterMenu';
import { IProduct } from 'types/product';
import { IUseCase } from 'types/use-case';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  pageSize: number;
  pageIndex?: number;
  searchKeyword?: string;
  showIncompleteProfile?: boolean;
  statusDuration?: IFilter;
  status?: string;
  stage?: string;
  products?: IProduct[];
  useCases?: IUseCase[];
}

interface Result
  extends IRequestHookResult<{
    data: IPotentialKoc[];
    pagination: IMetaPagination;
  }> {}

export const useLoadKOCPotentialList: IRequestHook<Props, Result> = ({
  idProject,
  pageSize,
  pageIndex = 0,
  stopWhen,
  searchKeyword,
  showIncompleteProfile,
  statusDuration,
  status,
  stage,
  products,
  useCases,
}) => {
  const queryString = qs.stringify({
    limit: pageSize,
    page: pageIndex + 1,
    q: searchKeyword,
    from_status_duration: statusDuration?.from,
    to_status_duration: statusDuration?.to,
    status,
    stage,
    id_product: products?.map((item) => item.id) || [],
    id_use_case: useCases?.map((item) => item.id) || [],
  });

  const path = showIncompleteProfile
    ? `/admin/projects/${idProject}/koc/potential-customers?${queryString}&show_incomplete_profile=1`
    : `/admin/projects/${idProject}/koc/potential-customers?${queryString}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  let outputData;
  if (response) {
    const responseData = response.data as IListResponse;
    outputData = {
      data: responseData.data,
      pagination: responseData.meta.pagination,
    };
  }

  return {
    result: outputData,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadKOCPotentialList;
