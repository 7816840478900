import useSWR, { Key } from 'swr';
import { useAuth0 } from '@auth0/auth0-react';

import { fetcher } from './index';

type Value = any | (() => any);

interface Options {
  fetcher?: any;
  swrConfig?: any;
  stopWhen?: Value;
  anonymous?: boolean;
  refreshInterval?: number;
}

const useCustomSWR = (key: Key, options: Options = {}) => {
  const { getAccessTokenSilently } = useAuth0();

  const stopWhen = getValue(options?.stopWhen);
  return useSWR(
    stopWhen === true ? null : key,
    fetcher(options.anonymous ? undefined : getAccessTokenSilently), {
      revalidateOnFocus: false,
      refreshInterval: options.refreshInterval || 0
    }
  );
};

const getValue = (value: Value | undefined) => {
  if (typeof value === 'function') {
    return value();
  }
  return value;
};

export default useCustomSWR;
