import { useCallback, useContext, useMemo } from 'react';
import { Link as RouterLink, useParams, generatePath } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  Box,
  Drawer,
  Button,
  useTheme,
  IconButton,
  Typography,
  Tooltip,
  ClickAwayListener,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import logoMini from 'assets/logo-mini.png';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as HideSideBarIcon } from 'assets/icons/hideSideBar.svg';
import Logo from 'components/LogoSign';
import { SidebarContext } from 'contexts/SidebarContext';

import { ROUTE_PROJECTS_EDIT } from 'constants/routes';
import Permission, { checkIsAllow } from 'components/Permission/Permission';
import { usePermissionContext } from 'contexts/permissionContext';
import SidebarMenu from './SidebarMenu';
import {
  CustomLink,
  SidebarWrapper,
  SubSidebarWrapper,
  TopSection,
} from './styled';

interface Props {
  isProject?: boolean;
}

function Sidebar({ isProject }: Props) {
  const { idProject } = useParams();
  const theme = useTheme();
  const { isSidebarOpen, toggleSidebar, sidebarWidth, submenu } =
    useContext(SidebarContext);

  const { permissions } = usePermissionContext();
  const canEditProject = checkIsAllow(permissions, ['project.dashboard.edit']);

  const renderTopLogo = useCallback(() => {
    if (isSidebarOpen) {
      return (
        <TopSection display="flex" alignItems="center" mb={isProject ? 2 : 0}>
          <Logo />
          <IconButton
            sx={{ padding: 0, marginLeft: '24px' }}
            onClick={toggleSidebar}
          >
            <HideSideBarIcon />
          </IconButton>
        </TopSection>
      );
    }
    return (
      <Box
        sx={{
          mx: 2,
          my: 3,
          pb: 3,
          borderBottom: `1px solid ${theme.colors.alpha.black[40]}`,
        }}
      >
        <RouterLink to="/">
          <img src={logoMini} alt="logo" width={32} />
        </RouterLink>
        <IconButton
          sx={{
            mt: 1.5,
            padding: 0,
            svg: {
              transform: 'rotate(180deg)',
            },
          }}
          onClick={toggleSidebar}
        >
          <HideSideBarIcon />
        </IconButton>
      </Box>
    );
  }, [isSidebarOpen, toggleSidebar, theme, isProject]);

  const renderBottomButtons = useCallback(() => {
    if (isProject) {
      if (isSidebarOpen) {
        return (
          <>
            <Permission permissions={['project.dashboard.edit']}>
              <Button
                startIcon={<SettingsIcon />}
                component={RouterLink}
                to={generatePath(ROUTE_PROJECTS_EDIT, { idProject })}
                variant="contained"
                color="neutral"
                sx={{ mt: 'auto', mx: 2, mb: 1, fontSize: '1rem' }}
              >
                Project Settings
              </Button>
            </Permission>
            <Button
              startIcon={<HomeIcon />}
              component={RouterLink}
              to="/"
              sx={{ mt: 'auto', mx: 2, mb: 2, fontSize: '1rem' }}
            >
              Back to home
            </Button>
          </>
        );
      }

      return (
        <>
          <Permission permissions={['project.dashboard.edit']}>
            <Tooltip placement="right" title="Project Settings" arrow>
              <CustomLink to={generatePath(ROUTE_PROJECTS_EDIT, { idProject })}>
                <IconButton color="neutral">
                  <SettingsIcon />
                </IconButton>
              </CustomLink>
            </Tooltip>
          </Permission>
          <CustomLink to="/">
            <Tooltip placement="right" title="Back to home" arrow>
              <IconButton>
                <HomeIcon />
              </IconButton>
            </Tooltip>
          </CustomLink>
        </>
      );
    }

    return null;
  }, [isProject, idProject, isSidebarOpen]);

  const sidebar = useMemo(
    () => (
      <SidebarWrapper width={sidebarWidth}>
        {renderTopLogo()}
        <Scrollbars
          autoHide
          style={{
            height: `calc(100% - ${canEditProject ? '110px' : '60px'} ${
              isProject ? '- 96px' : ''
            })`,
          }}
        >
          <SidebarMenu isProject={isProject} />
        </Scrollbars>
        {renderBottomButtons()}
      </SidebarWrapper>
    ),
    [
      renderTopLogo,
      renderBottomButtons,
      isProject,
      sidebarWidth,
      canEditProject,
    ],
  );

  const onCloseSubmenu = useCallback(() => {
    submenu.setCollapsedSubmenu(undefined, undefined);
  }, [submenu]);

  return (
    <>
      {sidebar}
      <Drawer
        anchor="left"
        open={!!submenu.collapsedSubmenu}
        variant="temporary"
        elevation={9}
      >
        <Box display="flex" height="100%">
          {sidebar}

          <ClickAwayListener onClickAway={onCloseSubmenu}>
            <SidebarWrapper width="205px">
              <Box
                mx={2}
                py={2}
                mb={1.5}
                borderBottom={`1px solid ${theme.colors.alpha.black[40]}`}
              >
                <Typography variant="label">{submenu.label}</Typography>
              </Box>
              <SubSidebarWrapper>{submenu.collapsedSubmenu}</SubSidebarWrapper>
            </SidebarWrapper>
          </ClickAwayListener>
        </Box>
      </Drawer>
    </>
  );
}

export default Sidebar;
