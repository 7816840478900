import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  refreshInterval: number;
}

interface Result extends IRequestHookResult<any> {}

export const useLoadSavedFilter: IRequestHook<Props, Result> = ({
  stopWhen,
  idProject,
  refreshInterval = 0,
}) => {
  const path = `/admin/projects/${idProject}/koc/discovery/groups`;

  const {
    data: response,
    error,
    mutate,
  } = useCustomSWR(path, { stopWhen, refreshInterval });

  const data = response ? (response.data as any) : undefined;

  return {
    mutate,
    result: data,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadSavedFilter;
