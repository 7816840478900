import { useAuth0 } from '@auth0/auth0-react';
import { IKOCAudience } from 'types/koc';
import { requester } from '../index';

export const usePublishedKOCContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function publishedKOCContent(
    idProject: string,
    idKOC: string,
    postUrl: string,
    isPersonalPost: boolean,
  ) {
    return requester({
      method: 'post',
      path: `/admin/projects/${idProject}/koc/official-customers/${idKOC}/contents/publish`,
      data: {
        post_link: postUrl,
        is_personal_post: isPersonalPost || false,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IKOCAudience>(response.data));
  };
};

export default usePublishedKOCContent;
