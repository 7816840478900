import {
  FC,
  useState,
  createContext,
  useCallback,
  useEffect,
  useMemo,Component
} from 'react';
import { useTheme } from '@mui/material';
import { useIsBreakpoint } from 'hooks/useBreakpoint';

type ISidebarContext = {
  isSidebarOpen: any;
  toggleSidebar: () => void;
  sidebarWidth?: string;
  submenu: {
    label?: string;
    collapsedSubmenu?: Component;
    setCollapsedSubmenu: (label?: string, subMenus?: Component) => void;
  };
};

export const SidebarContext = createContext<ISidebarContext>(
  {} as ISidebarContext,
);

export const SidebarProvider: FC = ({ children }) => {
  const theme = useTheme();
  const isUpLg = useIsBreakpoint('lg', 'up');

  // true: open, false: close
  const [isSidebarOpen, setIsSidebarOpen] = useState(isUpLg);
  const sidebarWidth = useMemo(
    () => (isSidebarOpen ? theme.sidebar.width : theme.sidebar.collapsedWidth),
    [isSidebarOpen, theme.sidebar],
  );
  useEffect(() => {
    setIsSidebarOpen(isUpLg);
  }, [isUpLg, theme.breakpoints.values]);
  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen]);

  const [collapsedSubmenu, setCollapsedSubmenu] = useState<Component | undefined>();
  const [subMenuLabel, setSubMenuLabel] = useState<string | undefined>();
  const setSubMenu = useCallback((label?: string, subMenus?: Component) => {
    setSubMenuLabel(label);
    setCollapsedSubmenu(subMenus);
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        sidebarWidth,
        submenu: {
          label: subMenuLabel,
          collapsedSubmenu,
          setCollapsedSubmenu: setSubMenu,
        },
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
