import { FC, useState } from 'react';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
import { ISavedFilterItem } from 'types/project';
import { useCreateSavedFilter } from 'requests/audiences';
import Dialog from 'components/Dialog';
import { IDiscoveryGroup } from '../../../../types/discovery-group';

interface IProps extends Omit<DialogProps, 'onClose'> {
  onClose: () => void;
  onSuccess: (data: IDiscoveryGroup) => void;
  data: ISavedFilterItem;
}
interface IFormValues {
  name: string;
}

const CreateDiscoveryGroupDialog: FC<IProps> = ({
  onSuccess,
  onClose,
  data,
  ...dialogProps
}) => {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const defaultValues: IFormValues = {
    name: '',
  };
  const { handleSubmit, register, formState, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { idProject } = useParams();
  const createSavedFilter = useCreateSavedFilter();
  const { errors } = formState;

  const onSubmit = async (values: IFormValues) => {
    setIsRequesting(true);
    try {
      const res = await createSavedFilter({
        idProject,
        name: values.name,
        data,
      });
      if (typeof onSuccess === 'function') {
        res.isDefault = false;
        onSuccess(res);
      }
      handleClose();
    } catch (e) {
      console.error(e);
    }
    setIsRequesting(false);
  };

  const handleClose = (
    event?: any,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (onClose && reason !== 'backdropClick') {
      onClose();
      reset();
    }
  };

  return (
    <Dialog
      title="New Discovery Group"
      fullWidth
      maxWidth="xs"
      {...dialogProps}
      onClose={handleClose}
      hasClose={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component="span" variant="body2" mb={1} width={128}>
              Group Name *
            </Typography>
            <Box width="100%">
              <TextField
                fullWidth
                size="small"
                multiline
                minRows={1}
                error={!!errors.name}
                helperText={errors.name?.message}
                InputProps={{ ...register('name') }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  maxLength: 255,
                }}
                placeholder="Group name..."
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ py: 2, px: 3 }}>
          <Button variant="text" color="neutral" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="neutral"
            loading={isRequesting}
            disabled={!!errors.name}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
const schema = yup.object().shape({
  name: yup.string().required('Group name is required').min(1).max(100),
});

export default CreateDiscoveryGroupDialog;
