import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

interface DataType {
  idProject: string;
  idKocCustomer: string;
  idContent: string;
  idPhoto: number;
}

export const useCreatePublicLinkMixedContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function createPublicLink(props: DataType) {
    const apiPath = `/admin/projects/${props.idProject}/koc/official-customers/${props.idKocCustomer}/contents/${props.idContent}/photos/${props.idPhoto}/public-link`;
    return requester({
      method: 'post',
      path: apiPath,
      getToken: getAccessTokenSilently,
    }).then((response) => {
      Promise.resolve<any>(response.data);
      return response?.data;
    });
  };
};

export default useCreatePublicLinkMixedContent;
