import { useAuth0 } from '@auth0/auth0-react';
import _format from 'date-fns/format';
import _map from 'lodash/map';
import { requester } from '../index';

interface DataType {
  idProject: string;
  name: string;
  phone: string;
  email: string;
  idCity: number | null;
  facebookUid: string;
  instagramUid: string;
  tiktokUid: string;
  youtubeUid: string;
  facebookFollow: number | null;
  instagramFollow: number | null;
  tiktokFollow: number | null;
  youtubeFollow: number | null;
  idKocCustomer: string;
  socialInteractions: string[];
  professional: string;
  birthday: Date | null;
  recommendedPlatforms: any;
  contentOverview: string;
  formats: any;
  cost: number | null;
  socialViews: string;
  idProduct: number | null;
  idUseCase: number | null;
  other: string;
  idKocScheme: number | null;
  valuekocScheme: number | null;
}

export const useAddAnotherPotentialKOC = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function addAnotherPotentialKOC(data: DataType) {
    const convertSocialInteractions = _map(data?.socialInteractions, (item) => {
      return item;
    });

    const convertRecommendedPlatforms = _map(
      data?.recommendedPlatforms,
      (item) => {
        return item;
      },
    );

    const convertFormats = _map(data?.formats, (item) => {
      return item;
    });

    return requester({
      method: 'post',
      path: `/admin/projects/${data.idProject}/koc/potential-customers/new`,
      data: {
        name: data?.name,
        phone: data?.phone,
        email: data?.email,
        id_city: data?.idCity,
        facebook_uid: data?.facebookUid,
        instagram_uid: data?.instagramUid,
        tiktok_uid: data?.tiktokUid,
        youtube_uid: data?.youtubeUid,
        facebook_follow: data?.facebookFollow,
        instagram_follow: data?.instagramFollow,
        tiktok_follow: data?.tiktokFollow,
        youtube_follow: data?.youtubeFollow,
        id_koc_customer: data?.idKocCustomer,
        birthday:
          data?.birthday && _format(new Date(data?.birthday), 'yyyy-MM-dd'),
        professional: data?.professional,
        social_interactions: convertSocialInteractions?.toString(),
        social_views: data?.socialViews,
        content_overview: data?.contentOverview,
        recommended_platforms: convertRecommendedPlatforms,
        formats: convertFormats,
        cost: data?.cost,
        id_product: data?.idProduct,
        id_use_case: data?.idUseCase,
        other: data?.other,
        id_koc_scheme: data.idKocScheme,
        value_koc_scheme: data.valuekocScheme,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useAddAnotherPotentialKOC;
