import { IProjectScheme } from 'types/project';
import { IMetaPagination, IListResponse } from 'types/response';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  pageSize: number;
  pageIndex?: number;
  idProject: string;
}

interface Result
  extends IRequestHookResult<{
    data: IProjectScheme[];
    pagination: IMetaPagination;
  }> {}

export const useLoadProjectSchemeList: IRequestHook<Props, Result> = ({
  pageSize,
  pageIndex = 0,
  stopWhen,
  idProject,
}) => {
  const path = `/admin/projects/${idProject}/koc/schemes?page=${pageIndex + 1}&limit=${pageSize}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  let outputData;
  if (response) {
    const responseData = response.data as IListResponse;
    outputData = {
      data: responseData.data || responseData,
      pagination: responseData?.meta?.pagination,
    };
  }

  return {
    mutate,
    result: outputData,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadProjectSchemeList;
