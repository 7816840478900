import { IKOCCategory } from 'types/koc';
import { IRequestHook, IRequestHookParams, IRequestHookResult } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  keyword: string;
}

interface Result extends IRequestHookResult<Array<IKOCCategory>> {}

export const useLoadKOCCateSystemByKeyword: IRequestHook<Props, Result> = ({
  keyword,
  stopWhen,
}) => {
  const path = `/admin/koc/system-categories?autocomplete=1&q=${keyword}`;

  const { data: response, error } = useCustomSWR(path, {
    stopWhen,
  });

  const finalData = response ? response.data as IKOCCategory[] : [];

  return {
    result: finalData,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadKOCCateSystemByKeyword;
