import { Box, Typography, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuth0 } from '@auth0/auth0-react';
import loginBackgroundImage from 'assets/images/login-bg.webp';
import logo from 'assets/logo.png';

const Login = () => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <Box className={classes.root}>
      <Box className={classes.loginBox}>
        <img src={logo} alt="logo" className={classes.logo} />
        <Typography mt={2} variant="h3" sx={{ fontWeight: 400 }}>
          Log in to Admin Portal
        </Typography>
        <Button
          type="button"
          size="large"
          color="neutral"
          onClick={loginWithRedirect}
          fullWidth
          variant="contained"
          sx={{ mt: 5 }}
        >
          Let’s get started
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${loginBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  loginBox: {
    width: 380,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(7, 4),
    backgroundColor: theme.palette.common.white,
    borderRadius: '16px',
    opacity: 0.8,
  },
  logo: {
    width: 150,
  },
}));

export default Login;
