import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import buildUrl from 'build-url';

const API_HOST = process.env.REACT_APP_API_HOST || '';

const axiosInstance = applyCaseMiddleware(axios.create());

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        window.location.href = '/403';
      }
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

interface FetcherParams {
  url?: string;
  path?: string;
  data?: object;
  method?: 'get' | 'post' | 'put' | 'delete' | 'patch';
  responseType?: string;
  accept?: string;
  contentType?: string;
}

export const fetcher =
  (getToken?: () => Promise<string>) =>
  async (key: string, params: FetcherParams = {}) => {
    const {
      url,
      path: paramsPath,
      method = 'get',
      data,
      responseType,
      contentType,
      accept,
    } = params;

    const path = paramsPath || key;

    const requestUrl = url || buildUrl(API_HOST, { path });

    const token = getToken ? await getToken() : '';

    const headers = {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(contentType ? { 'content-type': contentType } : {}),
      ...(accept ? { accept } : {}),
    };
    return axiosInstance({
      method,
      url: requestUrl,
      data,
      headers,
      responseType: responseType ? 'blob' : 'json',
    });
  };

interface RequesterParams extends FetcherParams {
  getToken?: () => Promise<string>;
}

export const requester = async (params: RequesterParams) => {
  const { url, path, method = 'post', data, getToken } = params;

  const requestUrl = url || buildUrl(API_HOST, { path });

  const token = getToken ? await getToken() : '';

  const headers = {
    'Content-Type': 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
  return axiosInstance({ method, url: requestUrl, data, headers });
};
