import { alpha, createTheme, lighten, darken } from '@mui/material';
import '@mui/lab/themeAugmentation';
import spaceGroteskBold from 'assets/fonts/space-grotesk/SpaceGrotesk-Bold.ttf';
import spaceGroteskMedium from 'assets/fonts/space-grotesk/SpaceGrotesk-Medium.ttf';
import spaceGroteskLight from 'assets/fonts/space-grotesk/SpaceGrotesk-Light.ttf';
import spaceGroteskSemiBold from 'assets/fonts/space-grotesk/SpaceGrotesk-SemiBold.ttf';
import spaceGrotesk from 'assets/fonts/space-grotesk/SpaceGrotesk-Regular.ttf';

import IBMPlexSansBold from 'assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf';
import IBMPlexSansMedium from 'assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf';
import IBMPlexSansLight from 'assets/fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf';
import IBMPlexSansSemiBold from 'assets/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf';
import IBMPlexSans from 'assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf';
import { typoXl, typoLg, typoMd, typoSm } from 'theme/typography';

const themeColors = {
  primary: '#000000',
  secondary: '#6E759F',
  success: '#44D600',
  warning: '#FFA319',
  error: '#ff643c',
  info: '#33C2FF',
  black: '#223354',
  white: '#ffffff',
  primaryAlt: '#000C57',
};

export const designThemeColors = {
  neutral: {
    '100': '#1A1A1A',
    '90': '#424242',
    '80': '#616161',
    '70': '#757575',
    '60': '#9E9E9E',
    '50': '#C2C2C2',
    '40': '#E0E0E0',
    '30': '#EDEDED',
    '20': '#F5F5F5',
    '10': '#FFFFFF',
  },
  primary: {
    pressed: '#4B19AF',
    hover: '#6B39CD',
    main: '#8C64DC',
    border: '#BCA2F1',
    focus: '#E4D9FB',
    background: '#F6F3FD',
  },
  success: {
    pressed: '#006C58',
    hover: '#008D5E',
    main: '#00BD7E',
    border: '#17E5A1',
    focus: '#A9FBE0',
    background: '#EBFFF8',
  },
  warning: {
    pressed: '#B77D09',
    hover: '#E39F17',
    main: '#FFB41F',
    border: '#F7C86A',
    focus: '#FBE0A9',
    background: '#FFF8EB',
  },
  danger: {
    pressed: '#920122',
    hover: '#C60833',
    main: '#EF1446',
    border: '#F35370',
    focus: '#FDABB9',
    background: '#FFF1F4',
  },
  info: {
    pressed: '#023480',
    hover: '#0F4EAE',
    main: '#2668CB',
    border: '#679EF2',
    focus: '#C1D9FD',
    background: '#F2F7FF',
  },
  orange: {
    focus: '#FFDCC0',
    main: '#FA7E1E',
  },
};

const getTypoResponsive = (variant: string) => ({
  ...typoXl[variant],
  '@media (max-width:1600px)': {
    ...typoLg[variant],
  },
  '@media (max-width:1280px)': {
    ...typoMd[variant],
  },
  '@media (max-width:992px)': {
    ...typoSm[variant],
  },
});

export const designTypo = {
  h1: getTypoResponsive('h1'),
  h2: getTypoResponsive('h2'),
  h3: getTypoResponsive('h3'),
  h4: getTypoResponsive('h4'),
  h5: getTypoResponsive('h5'),
  title1: getTypoResponsive('title1'),
  title2: getTypoResponsive('title2'),
  subtitle1: getTypoResponsive('subtitle1'),
  subtitle2: getTypoResponsive('subtitle2'),
  label: getTypoResponsive('label'),
  body1: getTypoResponsive('body1'),
  body2: getTypoResponsive('body2'),
  caption1: getTypoResponsive('caption1'),
  caption2: getTypoResponsive('caption2'),
  caption3: getTypoResponsive('caption3'),
  caption4: getTypoResponsive('caption4'),
  pageHeader: getTypoResponsive('pageHeader'),
};

const colors = {
  grey: {
    50: '#FBFBFB',
    100: '#F3F5F6',
    200: '#E8EAED',
    300: '#DCE0E5',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  gradients: {
    blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
    blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
    blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
    orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
    orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
    purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
    pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
    green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
    black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
  },
  shadows: {
    success:
      '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
    error:
      '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
    info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
    primary:
      '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
    warning:
      '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
    card: '0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32)',
    cardSm:
      '0px 2px 3px rgba(159, 162, 191, 0.18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
    cardLg:
      '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
  },
  layout: {
    general: {
      bodyBg: designThemeColors.neutral['20'],
    },
    sidebar: {
      background: designThemeColors.neutral['10'],
      textColor: designThemeColors.neutral['100'],
      dividerBg: '#f2f5f9',
      menuItemColor: designThemeColors.neutral['100'],
      menuItemIconColor: designThemeColors.neutral['100'],
      menuItemBg: designThemeColors.neutral['10'],
      menuItemColorActive: designThemeColors.primary.main,
      menuItemIconColorActive: designThemeColors.primary.main,
      menuItemBgActive: designThemeColors.primary.background,
      menuItemHeadingColor: designThemeColors.neutral['100'],
    },
  },
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white,
    },
    trueWhite: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white,
    },
    black: {
      5: alpha(designThemeColors.neutral['100'], 0.02),
      ...designThemeColors.neutral,
    },
  },
  secondary: {
    lighter: lighten(themeColors.secondary, 0.85),
    light: lighten(themeColors.secondary, 0.25),
    main: themeColors.secondary,
    dark: darken(themeColors.secondary, 0.2),
  },
  neutral: {
    light: designThemeColors.neutral['70'],
    main: designThemeColors.neutral['100'],
    dark: darken(designThemeColors.neutral['100'], 0.2),
  },
  primary: {
    lighter: designThemeColors.primary.background,
    light: designThemeColors.primary.focus,
    main: designThemeColors.primary.main,
    dark: designThemeColors.primary.hover,
    darker: designThemeColors.primary.pressed,
    border: designThemeColors.primary.border,
  },
  success: {
    lighter: designThemeColors.success.background,
    light: designThemeColors.success.focus,
    main: designThemeColors.success.main,
    dark: designThemeColors.success.hover,
    darker: designThemeColors.success.pressed,
    border: designThemeColors.success.border,
  },
  warning: {
    lighter: designThemeColors.warning.background,
    light: designThemeColors.warning.focus,
    main: designThemeColors.warning.main,
    dark: designThemeColors.warning.hover,
    darker: designThemeColors.warning.pressed,
    border: designThemeColors.warning.border,
  },
  error: {
    lighter: designThemeColors.danger.background,
    light: designThemeColors.danger.focus,
    main: designThemeColors.danger.main,
    dark: designThemeColors.danger.hover,
    darker: designThemeColors.danger.pressed,
    border: designThemeColors.danger.border,
  },
  info: {
    lighter: designThemeColors.info.background,
    light: designThemeColors.info.focus,
    main: designThemeColors.info.main,
    dark: designThemeColors.info.hover,
    darker: designThemeColors.info.pressed,
    border: designThemeColors.info.border,
  },
};

export const PurpleLightTheme = createTheme({
  // direction: i18n.dir(),
  colors: {
    gradients: {
      blue1: colors.gradients.blue1,
      blue2: colors.gradients.blue2,
      blue3: colors.gradients.blue3,
      orange1: colors.gradients.orange1,
      orange2: colors.gradients.orange2,
      purple1: colors.gradients.purple1,
      pink1: colors.gradients.pink1,
      pink2: colors.gradients.pink2,
      green1: colors.gradients.green1,
      black1: colors.gradients.black1,
    },
    shadows: {
      success: colors.shadows.success,
      error: colors.shadows.error,
      primary: colors.shadows.primary,
      warning: colors.shadows.warning,
    },
    alpha: colors.alpha,
    secondary: colors.secondary,
    primary: colors.primary,
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    info: colors.info,
  },

  general: {
    reactFrameworkColor: '#00D8FF',
    borderRadiusSm: '4px',
    borderRadius: '12px',
    borderRadiusLg: '10px',
    borderRadiusXl: '18px',
  },
  sidebar: {
    background: colors.layout.sidebar.background,
    textColor: colors.layout.sidebar.textColor,
    dividerBg: colors.layout.sidebar.dividerBg,
    menuItemColor: colors.layout.sidebar.menuItemColor,
    menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
    menuItemBg: colors.layout.sidebar.menuItemBg,
    menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
    menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
    menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
    menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
    // boxShadow: '2px 0 3px rgba(159, 162, 191, 0.18), 1px 0 1px rgba(159, 162, 191, 0.32)',
    boxShadow: 'none',
    width: '289px',
    collapsedWidth: '65px',
  },
  header: {
    height: '80px',
    background: colors.alpha.white[100],
    // boxShadow: colors.shadows.cardSm,
    boxShadow: 'none',
    textColor: colors.secondary.main,
  },
  spacing: 8,
  palette: {
    common: {
      black: colors.alpha.black[100],
      white: colors.alpha.white[100],
    },
    mode: 'light',
    neutral: {
      light: colors.neutral.light,
      main: colors.neutral.main,
      dark: colors.neutral.dark,
      contrastText: colors.alpha.white[100],
    },
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
    },
    error: {
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
      contrastText: colors.alpha.white[100],
    },
    success: {
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
      contrastText: colors.alpha.white[100],
    },
    info: {
      light: colors.info.light,
      main: colors.info.main,
      dark: colors.info.dark,
      contrastText: colors.alpha.white[100],
    },
    warning: {
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
      contrastText: colors.alpha.white[100],
    },
    grey: {
      50: '#FBFBFB',
      100: '#F3F5F6',
      200: '#EFEFEF',
      300: '#DCE0E5',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    text: {
      primary: colors.alpha.black[100],
      secondary: colors.alpha.black[80],
      disabled: colors.alpha.black[80],
    },
    background: {
      paper: colors.alpha.white[100],
      default: colors.layout.general.bodyBg,
    },
    action: {
      active: colors.alpha.black[100],
      hover: colors.primary.lighter,
      hoverOpacity: 0.1,
      selected: colors.alpha.black[10],
      selectedOpacity: 0.1,
      disabled: colors.alpha.black[50],
      disabledBackground: colors.alpha.black[5],
      disabledOpacity: 0.38,
      focus: colors.alpha.black[10],
      focusOpacity: 0.05,
      activatedOpacity: 0.12,
    },
    tonalOffset: 0.5,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(darken(themeColors.primaryAlt, 0.4), 0.2),
          backdropFilter: 'blur(2px)',

          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(2px)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginLeft: 8,
          marginRight: 8,
          fontWeight: 'bold',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Space Grotesk';
          font-weight: 400;
          src:  url(${spaceGrotesk}) format('truetype');
        }
        @font-face {
          font-family: 'Space Grotesk';
          font-weight: 700;
          src: url(${spaceGroteskBold}) format('truetype');
        }
        @font-face {
          font-family: 'Space Grotesk';
          font-weight: 600;
          src:  url(${spaceGroteskSemiBold}) format('truetype');
        }
        @font-face {
          font-family: 'Space Grotesk';
          font-weight: 500;
          src: url(${spaceGroteskMedium}) format('truetype');
        }
        @font-face {
          font-family: 'Space Grotesk';
          font-weight: 300;
          src: url(${spaceGroteskLight}) format('truetype');
        }

        @font-face {
          font-family: 'IBM Plex Sans';
          font-weight: 400;
          src:  url(${IBMPlexSans}) format('truetype');
        }
        @font-face {
          font-family: 'IBM Plex Sans';
          font-weight: 700;
          src: url(${IBMPlexSansBold}) format('truetype');
        }
        @font-face {
          font-family: 'IBM Plex Sans';
          font-weight: 600;
          src:  url(${IBMPlexSansSemiBold}) format('truetype');
        }
        @font-face {
          font-family: 'IBM Plex Sans';
          font-weight: 500;
          src: url(${IBMPlexSansMedium}) format('truetype');
        }
        @font-face {
          font-family: 'IBM Plex Sans';
          font-weight: 300;
          src: url(${IBMPlexSansLight}) format('truetype');
        }

        html, body, #root {
          width: 100%;
          height: 100%;
        }

        @media (max-width:1280px) {
          html, body {
            font-size: 12px;
          }
        }
        @media (max-width:1600px) {
          html, body {
            font-size: 14px;
          }
        }
        @media (min-width:1600px) {
          html, body {
            font-size: 16px;
          }
        }

        .child-popover .MuiPaper-root .MuiList-root {
          flex-direction: column;
        }
        #nprogress .bar {
          background: ${colors.primary.main};
        }
        #nprogress .spinner-icon {
          border-top-color: ${colors.primary.main};
          border-left-color: ${colors.primary.main};
        }
        #nprogress .peg {
          boxShadow: 0 0 10px ${colors.primary.main}, 0 0 5px ${colors.primary.main};
        }
        :root {
          --swiper-theme-color: ${colors.primary.main};
        }
        code {
          background: ${colors.info.lighter};
          color: ${colors.info.dark};
          border-radius: 4px;
          padding: 4px;
        }
        @keyframes ripple {
          0% {
            transform: scale(.8);
            opacity: 1;
          }
          100% {
            transform: scale(2.8);
            opacity: 0;
          }
        }
        @keyframes float {
          0% {
            transform: translate(0%, 0%);
          }
          100% {
            transform: translate(3%, 3%);
          }
        }
      `,
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: colors.alpha.black[50],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: designThemeColors.neutral[40],
          },
          '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
            paddingRight: 6,
          },
          '&:hover': {
            backgroundColor: designThemeColors.primary.background,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: designThemeColors.primary.hover,
          },
          '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary.main,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.error.main} !important`,
          },

          '&.Mui-disabled': {
            color: designThemeColors.neutral[80],
            backgroundColor: designThemeColors.neutral[20],
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: designThemeColors.neutral[40],
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: designThemeColors.neutral[40],
            },
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        colorPrimary: {
          fontWeight: 'bold',
          background: colors.alpha.black[5],
          color: colors.alpha.black[70],
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: -5,
          marginBottom: -5,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
        },
      },
    },
    MuiChip: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: designThemeColors.neutral[90],
          background: designThemeColors.neutral[20],
          '&:hover': {
            background: designThemeColors.neutral[30],
          },
          ...designTypo.caption1,
          '& .MuiChip-label': {
            padding: '4px 12px',
          },
          '&.Mui-disabled': {
            opacity: 0.8,
          },
        },
        colorPrimary: {
          color: designThemeColors.primary.main,
          background: designThemeColors.primary.background,
          '&:hover': {
            background: darken(designThemeColors.primary.background, 0.1),
          },
        },
        colorSecondary: {
          background: colors.alpha.black[5],
          color: colors.alpha.black[100],

          '&:hover': {
            background: colors.alpha.black[10],
          },
        },
        deleteIcon: {
          color: colors.error.main,

          '&:hover': {
            color: colors.error.main,
          },
        },
        deleteIconColorPrimary: {
          color: colors.primary.main,

          '&:hover': {
            color: colors.primary.dark,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.Mui-expanded': {
            margin: 0,
          },
          '&::before': {
            display: 'none',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 'bold',
        },
        colorDefault: {
          background: colors.alpha.black[30],
          color: colors.alpha.white[100],
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        avatar: {
          background: colors.alpha.black[10],
          fontSize: '1rem',
          color: colors.alpha.black[70],
          fontWeight: 'bold',
          marginLeft: '-12px',

          '&:first-of-type': {
            border: 0,
            background: 'transparent',
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        alignItemsFlexStart: {
          marginTop: 0,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          fontSize: '1rem',
          fontWeight: 'bold',
          transition: 'all .2s',
        },
        textPrimary: {
          '&.Mui-selected': {
            boxShadow: colors.shadows.primary,
          },
          '&.MuiButtonBase-root:hover': {
            background: colors.alpha.black[5],
          },
          '&.Mui-selected.MuiButtonBase-root:hover': {
            background: colors.primary.main,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        color: 'neutral',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          path: {
            fill: 'currentcolor',
          },

          '.MuiSvgIcon-root': {
            transition: 'all .2s',
          },

          '&.MuiButton-containedNeutral': {
            backgroundColor: designThemeColors.neutral[100],
            color: designThemeColors.neutral[10],
            border: `1px solid transparent`,
            '&:hover': {
              backgroundColor: designThemeColors.primary.main,
            },
            '&:focus': {
              backgroundColor: designThemeColors.neutral[100],
              borderColor: designThemeColors.primary.border,
            },
            '&:disabled': {
              backgroundColor: designThemeColors.neutral[20],
              color: designThemeColors.neutral[50],
            },
          },
          '&.MuiButton-outlinedNeutral': {
            border: `1px solid ${designThemeColors.neutral[100]}`,
            backgroundColor: designThemeColors.neutral[10],
            color: designThemeColors.neutral[100],
            '&:hover': {
              backgroundColor: designThemeColors.neutral[20],
              borderColor: designThemeColors.primary.main,
            },
            '&:focus': {
              backgroundColor: designThemeColors.primary.background,
              borderColor: designThemeColors.primary.border,
            },
            '&:disabled': {
              backgroundColor: designThemeColors.neutral[20],
              borderColor: designThemeColors.neutral[40],
              color: designThemeColors.neutral[50],
            },
          },
          '&.MuiButton-textNeutral': {
            background: 'none',
            padding: 0,
            color: designThemeColors.neutral[100],
            '&:hover': {
              background: 'none',
              color: designThemeColors.primary.main,
            },
            '&:focus': {
              background: 'none',
              color: designThemeColors.primary.pressed,
            },
            '&:disabled': {
              background: 'none',
              color: designThemeColors.neutral[50],
            },
          },
        },
        // Extra small in design
        sizeSmall: { padding: '6px 16px' },
        textSizeSmall: { ...designTypo.label },
        // Small in design
        sizeMedium: { padding: '10px 24px' },
        textSizeMedium: { ...designTypo.label },
        // Medium in design
        sizeLarge: { padding: '12px 28px' },
        textSizeLarge: { ...designTypo.label },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          ...designTypo.label,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: colors.primary.main,
          background: colors.alpha.white[100],
          transition: 'all .2s',

          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            color: colors.alpha.white[100],
            background: colors.primary.main,
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: 4,

          '& .MuiTouchRipple-root': {
            borderRadius: 4,
          },
          '&:hover path': {
            fill: 'currentcolor',
          },
        },
        sizeSmall: {
          padding: '8px',
        },
        sizeMedium: {
          padding: '12px',
        },
        sizeLarge: {
          padding: '20px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: designThemeColors.neutral[40],
          border: 0,
          height: 1,
        },
        vertical: {
          height: 'auto',
          width: 1,

          '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
            height: 'auto',
          },
          '&.MuiDivider-absolute.MuiDivider-fullWidth': {
            height: '100%',
          },
        },
        withChildren: {
          '&:before, &:after': {
            border: 0,
          },
        },
        wrapper: {
          background: colors.alpha.white[100],
          fontWeight: 'bold',
          height: 24,
          lineHeight: '24px',
          marginTop: -12,
          color: 'inherit',
          textTransform: 'uppercase',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        elevation0: {
          boxShadow: 'none',
        },
        elevation: {
          boxShadow: colors.shadows.card,
        },
        elevation2: {
          boxShadow: colors.shadows.cardSm,
        },
        elevation24: {
          boxShadow: colors.shadows.cardLg,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 6,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
            transform: 'none',
          },
          '& .MuiSlider-valueLabel': {
            borderRadius: 6,
            background: colors.alpha.black[100],
            color: colors.alpha.white[100],
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,

          '& .MuiListItem-button': {
            transition: 'all .2s',

            '& > .MuiSvgIcon-root': {
              minWidth: 34,
            },

            '& .MuiTouchRipple-root': {
              opacity: 0.2,
            },
          },
          '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
            backgroundColor: colors.alpha.black[10],
          },
        },
        padding: {
          padding: '12px',

          '& .MuiListItem-button': {
            borderRadius: 6,
            margin: '1px 0',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 38,
          minHeight: 38,
          overflow: 'visible',
        },
        indicator: {
          height: 38,
          minHeight: 38,
          borderBottom: `3px solid ${colors.primary.main}`,
          background: 'transparent',
          zIndex: 5,
        },
        scrollableX: {
          overflow: 'visible !important',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 38,
          minHeight: 38,
          borderRadius: 6,
          transition: 'color .2s',
          textTransform: 'capitalize',

          '&.MuiButtonBase-root': {
            minWidth: 'auto',
            paddingLeft: 20,
            paddingRight: 20,
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            color: colors.primary.main,
            zIndex: 5,
          },
          '&:hover': {
            color: colors.alpha.black[100],
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: 12,
        },
        list: {
          padding: 12,

          '& .MuiMenuItem-root.MuiButtonBase-root': {
            fontSize: '1rem',
            marginTop: 1,
            marginBottom: 1,
            transition: 'all .2s',
            color: colors.alpha.black[70],

            '& .MuiTouchRipple-root': {
              opacity: 0.2,
            },

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: lighten(colors.primary.lighter, 0.5),
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            color: colors.secondary.main,

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: lighten(colors.primary.lighter, 0.5),
            },
          },
          '&.Mui-focused': {
            color: colors.alpha.black[100],
            background: lighten(colors.primary.lighter, 0.2),
            cursor: 'pointer',
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        ChipProps: {
          color: 'primary',
        },
      },
      styleOverrides: {
        tag: {
          margin: 1,
        },
        root: {
          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
            {
              right: 14,
            },
        },
        clearIndicator: {
          background: colors.error.lighter,
          color: colors.error.main,
          marginRight: 8,

          '&:hover': {
            background: colors.error.lighter,
            color: colors.error.dark,
          },
        },
        popupIndicator: {
          color: colors.alpha.black[50],

          '&:hover': {
            background: colors.primary.lighter,
            color: colors.primary.main,
          },
        },
        option: {
          ...designTypo.body2,
        },
        noOptions: {
          ...designTypo.body2,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '& .MuiIconButton-root': {
            padding: 8,
          },
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          padding: '0 !important',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          background: colors.alpha.black[5],
        },
        root: {
          transition: 'background-color .2s',

          '&.MuiTableRow-hover:hover': {
            backgroundColor: lighten(colors.alpha.black[5], 0.5),
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: colors.alpha.black[30],
          ...designTypo.body2,
        },
        head: {
          textTransform: 'uppercase',
          fontSize: '1rem',
          fontWeight: 'bold',
          color: colors.alpha.black[70],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          lineHeight: 1.5,
          fontSize: '1rem',
        },
        standardInfo: {
          color: colors.info.main,
        },
        action: {
          color: colors.alpha.black[70],
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: 0,
          zIndex: 5,
          position: 'absolute',
          top: '50%',
          marginTop: -6,
          left: -6,
        },
        outlined: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: `0 0 0 6px ${colors.alpha.white[100]}`,
        },
        outlinedPrimary: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: `0 0 0 6px ${colors.alpha.white[100]}`,
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          position: 'absolute',
          height: '100%',
          top: 0,
          borderRadius: 50,
          backgroundColor: colors.alpha.black[10],
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: '8px 0',

          '&:before': {
            display: 'none',
          },
        },
        missingOppositeContent: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(colors.alpha.black['100'], 0.95),
          padding: '8px 16px',
          fontSize: '1rem',
        },
        arrow: {
          color: alpha(colors.alpha.black['100'], 0.95),
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 28,
          height: 16,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(12px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: designThemeColors.primary.main,
                opacity: 1,
                border: 0,
              },
              '& .MuiSwitch-thumb': {
                backgroundColor: designThemeColors.neutral[10],
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: designThemeColors.neutral[50],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.7,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 12,
            height: 12,
            boxShadow: 'none',
          },
          '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            backgroundColor: designThemeColors.neutral[40],
            opacity: 1,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
          background: colors.alpha.black[5],
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-completed': {
            color: colors.success.main,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          title1: 'div',
          title2: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          label: 'div',
          body1: 'div',
          body2: 'div',
          caption1: 'div',
          caption2: 'div',
          caption3: 'div',
          caption4: 'div',
          pageHeader: 'h1',
        },
        variant: 'body2',
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 4,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 20px',

          '.MuiButton-root': {
            padding: '10px 16px',
            ...designTypo.label,
          },

          '>:not(:first-of-type)': {
            marginLeft: '16px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...designTypo.body2,
          color: designThemeColors.neutral[100],
          '&:hover': {
            backgroundColor: designThemeColors.primary.background,
          },
          '.Mui-focus': {
            borderColor: designThemeColors.primary.main,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          ...designTypo.subtitle2,
          color: colors.grey[700],
          '&:last-of-type': {
            color: colors.alpha.black[100],
          },
          '.MuiTypography-root': {
            color: 'inherit',
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: [
      '"Space Grotesk"',
      '"IBM Plex Sans"',
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    ].join(','),
    ...designTypo,
    h6: {
      fontSize: '1rem',
    },
    button: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    caption: {
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      color: colors.alpha.black[50],
    },
    overline: {
      fontSize: '0.875rem',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
});
