import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

export const useDeleteProject = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function editProject(idProject: string) {
    return requester({
      method: 'delete',
      path: `/admin/projects/${idProject}`,
      getToken: getAccessTokenSilently,
    });
  };
};

export default useDeleteProject;
