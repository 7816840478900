export interface IUser {
  id: string;
  name: string;
  email: string;
}

export interface ITemplate {
  id: string;
  title: string;
  description: string;
  content: string;
  campaignGuideline: string;
  contentGuideline: string;
  requireDeliveryInfo: number;
  status: TemplateStatus;
  createdBy: IUser;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}

export enum TemplateStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  PREVIEW = 'PREVIEW',
}
