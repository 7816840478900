import { useAuth0 } from "@auth0/auth0-react";
import { requester } from "../index";

export const useDeleteSavedFilter = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function deleteSavedFilter(idProject: string, id?: number) {
    return requester({
      method: "delete",
      path: `/admin/projects/${idProject}/koc/discovery/groups/${id}`,
      getToken: getAccessTokenSilently,
    });
  };
};

export default useDeleteSavedFilter;
