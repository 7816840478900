import {
  Badge,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles } from '@mui/styles';
import isToday from 'date-fns/isToday';
import { useSnackbar } from 'notistack';
import { useVisible } from 'react-hooks-visible';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { convertTimeToString, getErrorMessage } from 'utils/common';
import { INotification, IImportData, IMessageData } from 'types/notification';
import {
  useLoadNumberUnreadNotifications,
  useLoadUserNotificationsInfinite,
  useMarkAllAsReadNotification,
  useMarkAsReadNotification,
} from 'requests/notifications';
import { ReactComponent as NotificationIcon } from 'assets/icons/no-notification.svg';
import { useIsBreakpoint } from 'hooks/useBreakpoint';

enum NotificationFilter {
  ALL = 'all',
  UNREAD = 'unread',
}
interface IActionNotification {
  onMarkAsRead: (id: string) => void;
  onClickItem: (idNotification: string, link: string) => void;
}
interface IGroupNotificationProps extends IActionNotification {
  title: string;
  data: INotification[];
  sx?: any;
}
interface INotificationItemProp extends INotification, IActionNotification {}
interface IMessageNotification {
  message: IMessageData;
  dataImport: IImportData;
  isRead: boolean;
  link: string;
  onClickItem: () => void;
}
interface IFilter {
  text: string;
  selected?: boolean | undefined;
  onClick?: () => void;
}

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `

    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        top: 4px;
        right: 4px;
    }
`,
);
const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`,
);

const getDataNotification = (data: any = []) => {
  return data && Array.isArray(data.result) ? data.result : [];
};

const filterIsTodayFunction = (notification) =>
  isToday(new Date(notification.createdAt));
const filterIsNotTodayFunction = (notification) =>
  !isToday(new Date(notification.createdAt));

const extractNotification = (data, filterFunction) => {
  let result = [];
  try {
    const notificationData = getDataNotification(data);
    result = notificationData.filter(filterFunction);
  } catch (e) {
    console.error(e);
  }
  return result;
};

const extractTodayNotification = (data: any = []) => {
  return extractNotification(data, filterIsTodayFunction);
};

const extractEarlierNotification = (data: any = []) => {
  return extractNotification(data, filterIsNotTodayFunction);
};

const GroupNotification: FC<IGroupNotificationProps> = ({
  title,
  data,
  sx,
  onMarkAsRead,
  onClickItem,
}) => {
  return (
    <Box sx={sx}>
      <Typography variant="h6">{title}</Typography>
      {Array.isArray(data) &&
        data.map((notification) => (
          <NotificationItem
            onClickItem={onClickItem}
            onMarkAsRead={onMarkAsRead}
            key={notification.id}
            {...notification}
          />
        ))}
    </Box>
  );
};

const NotificationMessage: FC<IMessageNotification> = ({
  dataImport,
  message,
  isRead,
  link,
  onClickItem,
}) => {
  const readMessageTextColor: string = '#9699a5';

  const genText = () => {
    let fileInfo: any = '';
    let content: any = '';
    switch (dataImport.status) {
      case 'COMPLETED':
      case 'PARTIALLY_COMPLETED':
        fileInfo = (
          <>
            <Typography component="span">
              <a target="_blank" rel="noreferrer" href={link}>
                MW{dataImport.id}:
              </a>
            </Typography>
            <Typography
              component="span"
              sx={{
                wordBreak: 'break-word',
                color: isRead ? readMessageTextColor : 'inherit',
              }}
            >
              &nbsp;File&nbsp;<b>{dataImport.fileName}</b>&nbsp;
            </Typography>
          </>
        );
        break;
      case 'FAILED':
        fileInfo = (
          <>
            <Typography component="span">
              <a target="_blank" rel="noreferrer" href={link}>
                MW{dataImport.id}
              </a>
            </Typography>
            <Typography
              component="span"
              sx={{
                wordBreak: 'break-word',
                color: isRead ? readMessageTextColor : 'inherit',
              }}
            >
              &nbsp;we encountered an issue while importing&nbsp;
              <b>{dataImport.fileName}</b>
              .&nbsp;
            </Typography>
          </>
        );
        break;
      default:
        fileInfo = (
          <Typography component="span">
            <Typography component="span">
              <a target="_blank" rel="noreferrer" href={link}>
                MW{dataImport.id}:
              </a>
            </Typography>
            <Typography
              component="span"
              sx={{
                wordBreak: 'break-word',
                color: isRead ? readMessageTextColor : 'inherit',
              }}
            >
              &nbsp;File&nbsp;<b>{dataImport.fileName}</b>&nbsp;
            </Typography>
          </Typography>
        );
        break;
    }
    switch (dataImport.status) {
      case 'COMPLETED':
        content = 'has been imported successfully';
        break;
      case 'PARTIALLY_COMPLETED':
        content = (
          <Typography
            component="span"
            sx={{
              wordBreak: 'break-word',
              color: isRead ? readMessageTextColor : 'inherit',
            }}
          >
            has been imported <b>partially</b>. Please review the file and
            ensure all data meets our requirement
          </Typography>
        );
        break;
      case 'FAILED':
        content =
          'Please review the file and ensure all data meets our requirement"';
        break;
      default:
        content = message.body || 'The status is not defined';
        break;
    }
    return (
      <Typography component="span">
        {fileInfo}
        {content}
      </Typography>
    );
  };

  return (
    <Typography
      component="span"
      onClick={onClickItem}
      sx={{
        wordBreak: 'break-word',
        color: isRead ? readMessageTextColor : 'inherit',
      }}
    >
      {genText()}
    </Typography>
  );
};

const NotificationItem: FC<INotificationItemProp> = ({
  data,
  createdAt,
  id,
  isRead,
  link,
  onMarkAsRead,
  onClickItem,
}) => {
  const classes = useStyles();
  const Icon = styled(FiberManualRecordIcon)(
    ({ theme }) => `color: ${theme.colors.primary.main};`,
  );

  return (
    <ListItemButton
      onClick={() => {
        onClickItem(id, link || '');
      }}
      sx={{
        padding: 0,
        justifyContent: 'space-between',
        marginBottom: '3px',
      }}
      className={classes.notificationItem}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-betwwen',
          alignItems: 'start',
          pl: 2,
        }}
      >
        <Box
          className={`${classes.center} ${classes.avatar}`}
          sx={{ borderColor: isRead ? '#565d6d' : '#000', mt: 2 }}
        >
          <FileUploadOutlinedIcon
            sx={{ color: isRead ? '#565d6d' : '#000', margin: 'auto' }}
          />
        </Box>
        <Box className={classes.messageWrapper}>
          <Typography variant="inherit">
            <NotificationMessage
              dataImport={data.dataImport}
              message={data.message}
              isRead={isRead}
              link={link}
              onClickItem={() => {
                onClickItem(id, link || '');
              }}
            />
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              color: '#444444',
              opacity: '0.5',
              marginRight: '5px',
            }}
          >
            {createdAt ? convertTimeToString(createdAt) : ''}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.statusSection} sx={{ minWidth: 31 }}>
        {!isRead && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onMarkAsRead(id);
            }}
            sx={{ borderRadius: '50%' }}
          >
            <Icon sx={{ width: 15, height: 15 }} />
          </IconButton>
        )}
      </Box>
    </ListItemButton>
  );
};

const Filter: FC<IFilter> = ({ text, selected, onClick }) => {
  let baseStyle: any = { mr: 0.5, cursor: 'pointer', borderWidth: 0 };
  if (selected) {
    baseStyle = { ...baseStyle, color: '#6557d8', bgcolor: '#f3f2fc' };
  }
  return (
    <Chip
      onClick={onClick}
      size="small"
      sx={baseStyle}
      label={text}
      variant={selected ? 'filled' : 'outlined'}
    />
  );
};

function HeaderNotifications() {
  const hasNotification = true;
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>(NotificationFilter.ALL);
  const { result: noUnread, mutate: mutateUnread } =
    useLoadNumberUnreadNotifications({});
  const swr = useLoadUserNotificationsInfinite({
    pageSize: 20,
    isRead: filter === NotificationFilter.ALL ? undefined : '0',
    resolved: '',
  });
  const todayNotification = extractTodayNotification(swr);
  const earlierNotification = extractEarlierNotification(swr);
  const markAsReadNotification = useMarkAsReadNotification();
  const markAllAsReadNotification = useMarkAllAsReadNotification();
  const { enqueueSnackbar } = useSnackbar();
  const isXl = useIsBreakpoint('xl');
  const iconWidth = isXl ? '30px' : '20px';

  const handleOpen = (): void => {
    setOpen(true);
  };

  const clearNoUnreadTitle = (title) => {
    let result = title;
    try {
      const separater = ')';
      if (
        result &&
        result.includes(separater) &&
        result.split(separater).length > 0
      ) {
        const splitResult = result.split(separater);
        result = splitResult[splitResult.length - 1];
      }
    } catch (e) {
      console.error(e);
    }
    return result;
  };
  const updateTitle = (noOfUnread) => {
    try {
      const doc: any = document;
      if (noOfUnread > 0) {
        doc.title = `(${noOfUnread}) ${clearNoUnreadTitle(doc.title)}`;
      } else {
        doc.title = clearNoUnreadTitle(doc.title);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateTitle(noUnread ? noUnread.count : 0);
      try {
        const win: any = window;
        win.channel = new BroadcastChannel('mighty-wow-on-notification');
        win.channel.addEventListener('message', () => {
          mutateUnread();
        });
      } catch (e) {
        console.error(e);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noUnread]);

  const onClickNotificationItem = (idNotification, link) => {
    onMarkAsRead(idNotification);
    window.location.replace(link);
    setOpen(false);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const onMarkAsRead = async (id: string) => {
    try {
      await markAsReadNotification(id);
      swr.mutate();
      mutateUnread();
    } catch (e) {
      enqueueSnackbar(getErrorMessage(e, 'Something went wrong'));
    }
  };
  const onMarkAllAsRead = async () => {
    try {
      await markAllAsReadNotification();
      swr.mutate();
      mutateUnread();
    } catch (e) {
      enqueueSnackbar(getErrorMessage(e, 'Something went wrong'));
    }
  };

  const handleLoadMoreVisible = () => {
    if (!swr.isLoadingMore && swr.hasMore) {
      swr.loadMore();
    }
  };

  return (
    <>
      <IconButtonWrapper
        color="primary"
        ref={ref}
        onClick={handleOpen}
        sx={{ svg: { width: iconWidth, height: iconWidth } }}
      >
        <NotificationsBadge
          // variant="dot"
          sx={{ color: 'white' }}
          badgeContent={
            noUnread && typeof noUnread === 'object' ? noUnread.count : null
          }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <NotificationIcon />
        </NotificationsBadge>
      </IconButtonWrapper>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2 }} display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3">Notifications</Typography>
            {hasNotification && (
              <Link
                onClick={onMarkAllAsRead}
                variant="caption"
                sx={{ textTransform: 'none', cursor: 'pointer' }}
              >
                Mark all as read
              </Link>
            )}
          </Box>
          <Box sx={{ mb: 2, mt: 1, minWidth: 492.33 }} display="flex">
            <Filter
              text="All"
              selected={filter === NotificationFilter.ALL}
              onClick={() => {
                setFilter(NotificationFilter.ALL);
              }}
            />
            <Filter
              text="Unread"
              selected={filter === NotificationFilter.UNREAD}
              onClick={() => {
                setFilter(NotificationFilter.UNREAD);
              }}
            />
          </Box>
          <Box sx={{ maxHeight: '70vh', overflow: 'auto' }}>
            {swr.result.length === 0 && (
              <List>
                <ListItem>
                  <ListItemText
                    sx={{ textAlign: 'center' }}
                    primary="No data"
                  />
                </ListItem>
              </List>
            )}
            {todayNotification.length > 0 && (
              <GroupNotification
                onClickItem={onClickNotificationItem}
                onMarkAsRead={onMarkAsRead}
                title="Today"
                data={todayNotification}
              />
            )}
            {earlierNotification.length > 0 && (
              <GroupNotification
                onClickItem={onClickNotificationItem}
                onMarkAsRead={onMarkAsRead}
                sx={{ mt: 1 }}
                title="Earlier"
                data={earlierNotification}
              />
            )}
            {!swr.isLoadingMore && swr.hasMore && (
              <LoadMore onVisible={handleLoadMoreVisible} />
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
}

const useStyles = makeStyles({
  avatar: {
    position: 'relative',
    width: 36,
    height: 36,
    border: '1px solid',
    borderRadius: 8,
  },
  avatarIcon: {
    position: 'absolute',
    bottom: -6,
    right: -6,
    height: 25,
    width: 25,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ed5d56',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationItem: {
    display: 'flex',
  },
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    paddingLeft: 16,
    paddingBottom: 16,
    maxWidth: 421,
  },
  statusSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const LoadMore = ({ onVisible }: any) => {
  const [ref, isVisible] = useVisible((vi) => vi > 0);

  useEffect(() => {
    if (isVisible) {
      onVisible(isVisible);
    }
  }, [isVisible, onVisible]);

  return <div ref={ref as any} style={{ height: '1px' }} />;
};

export default HeaderNotifications;
