import { IProduct } from 'types/product';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  keyword: string;
  hasMixContent?: boolean;
  refreshInterval?: number;
}

interface Result extends IRequestHookResult<IProduct[]> {}

export const useLoadProductList: IRequestHook<Props, Result> = ({
  idProject,
  keyword,
  hasMixContent,
  stopWhen,
  refreshInterval = 0,
}) => {
  let path = `/admin/projects/${idProject}/products?keyword=${keyword}`;
  if (hasMixContent) {
    path = `/admin/projects/${idProject}/products?keyword=${keyword}&has_mix_content=true`;
  }
  const {
    data: response,
    error,
    mutate,
  } = useCustomSWR(path, { stopWhen, refreshInterval });

  const products = response ? (response.data as IProduct[]) : [];
  return {
    result: products,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadProductList;
