import { IKOCAudiencePageGroup } from 'types/koc';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import { IListResponse, IMetaPagination } from 'types/response';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  pageSize?: number;
  pageIndex?: number;
  refreshInterval?: number;
}

interface Result
  extends IRequestHookResult<{
    data: IKOCAudiencePageGroup[];
    pagination: IMetaPagination;
  }> {}

export const useLoadNTypeGroupList: IRequestHook<Props, Result> = ({
  idProject,
  pageSize = 10,
  pageIndex = 0,
  refreshInterval = 0,
  stopWhen,
}) => {
  const path = `/admin/projects/${idProject}/koc/social-pages?type=group&limit=${pageSize}&page=${
    pageIndex + 1
  }`;
  const {
    data: response,
    error,
    mutate,
    isValidating,
  } = useCustomSWR(path, { stopWhen, refreshInterval });
  let outputData;
  if (response) {
    const responseData = response.data as IListResponse;
    outputData = {
      data: responseData.data,
      pagination: responseData.meta?.pagination,
    };
  }

  return {
    mutate,
    result: outputData,
    error,
    isValidating,
    isLoading: !error && !response && !stopWhen,
  };
};

export default useLoadNTypeGroupList;
