import { IProjectBriefing } from 'types/project';
import { IMetaCursorPagination, ICursorListResponse } from 'types/response';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  pageSize: number;
  curPage?: number;
  cursor?: string;
}

interface Result
  extends IRequestHookResult<{
    data: IProjectBriefing[];
    pagination: IMetaCursorPagination;
  }> {}

export const useLoadProjectBriefing: IRequestHook<Props, Result> = ({
  idProject,
  pageSize,
  stopWhen,
  curPage = 0,
  cursor,
}) => {
  const path = `/admin/projects/${idProject}/briefs?page=${
    curPage + 1
  }&limit=${pageSize}&cursor=${cursor}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  let outputData;
  if (response) {
    const responseData = response.data as ICursorListResponse;

    outputData = {
      data: responseData.data,
      pagination: responseData.meta.pagination,
    };
  }
  return {
    mutate,
    result: outputData,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadProjectBriefing;
