import {
  Box,
  Button,
  DialogContent,
  IconButton,
  Skeleton,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Dialog, { DialogActions } from 'components/Dialog';
import { useLoadReasons, useUpdateReasons } from 'requests/reason';
import { getErrorMessage } from 'utils/common';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

function ManageReasonDialog({ isOpen, onClose, onSuccess }: IProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const { result: originReasons, isLoading } = useLoadReasons({});
  const updateReasons = useUpdateReasons();
  const [reasons, setReasons] = useState<
    {
      id?: number;
      name: string;
    }[]
  >([]);
  const [isValidate, setIsValidate] = useState(false);

  useEffect(() => {
    setReasons(
      originReasons ? originReasons.map(({ id, name }) => ({ id, name })) : [],
    );
  }, [originReasons]);

  const handleClose = (
    event?: any,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (onClose && (!reason || reason !== 'backdropClick')) {
      onClose();
    }
  };

  const handleSubmit = () => {
    if (reasons.some((r) => !r.name)) {
      setIsValidate(true);
    } else {
      setIsRequesting(true);
      updateReasons(reasons)
        .then(() => {
          onSuccess();

          enqueueSnackbar(`Update successfully`, {
            variant: 'success',
          });
          setIsRequesting(false);
          handleClose();
        })
        .catch((error) => {
          console.error('Manage reasons: ', error);

          enqueueSnackbar(getErrorMessage(error, 'Something went wrong'), {
            variant: 'error',
          });
          setIsRequesting(false);
        });
    }
  };

  const inputs = reasons.map((reason, index) => (
    <Box key={reason.id || index} display="flex" alignItems="start" mb={2}>
      <TextField
        fullWidth
        size="small"
        placeholder="New reason"
        value={reason.name}
        error={isValidate && !reason.name}
        helperText={isValidate && !reason.name && 'Required'}
        onChange={(event) => {
          setReasons((r) => {
            const newReasons = [...r];
            newReasons.splice(index, 1, {
              id: reason.id,
              name: event?.target?.value as string,
            });
            return newReasons;
          });
        }}
        inputProps={{ maxLength: 255 }}
      />
      <IconButton
        sx={{ padding: 1, marginLeft: '24px' }}
        color="error"
        onClick={() => {
          setReasons((r) => {
            const newReasons = [...r];
            newReasons.splice(index, 1);
            return newReasons;
          });
        }}
      >
        <DeleteForeverOutlinedIcon />
      </IconButton>
    </Box>
  ));

  return (
    <Dialog open={isOpen} title="Add/Edit Reason" onClose={handleClose}>
      <DialogContent>
        <Box my={3}>
          {isLoading ? (
            <Skeleton variant="rectangular" animation="wave" />
          ) : (
            <>
              {inputs}
              <Button
                sx={{ mt: 1, ml: 2 }}
                startIcon={<AddOutlinedIcon />}
                onClick={() => {
                  setReasons([...reasons, { name: '' }]);
                }}
              >
                New reason
              </Button>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        onCancel={handleClose}
        onOk={handleSubmit}
        isLoading={isRequesting}
      />
    </Dialog>
  );
}
export default ManageReasonDialog;
