import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';
import { IResponseBodyUpload } from './types';

export interface IFileWithPath extends File {
  path?: string;
  fileName?: string;
  thumbnail?: File;
  isCustom?: boolean;
}

const useUploadFile = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function uploadFile(
    file: IFileWithPath,
    type?: string,
    isCustom?: boolean,
    forceDownload?: boolean,
  ) {
    const formData = new FormData();

    const pathMedia = isCustom
      ? '/admin/upload/media?ignore_filename=1'
      : '/admin/upload/media';

    formData.append('file', file);
    formData.append('auto_download', forceDownload ? '1' : '0');
    if (file.fileName && !isCustom) {
      formData.append('filename', file.fileName);
    }
    if (file.thumbnail) {
      formData.append('thumbnail', file.thumbnail);
    }
    if (type && type === 'csv') {
      return requester({
        method: 'post',
        path: '/admin/upload/csv',
        data: formData,
        getToken: getAccessTokenSilently,
      }).then((response) =>
        Promise.resolve<IResponseBodyUpload>(response.data),
      );
    }
    if (type && type === 'excel') {
      return requester({
        method: 'post',
        path: '/admin/upload/excel',
        data: formData,
        getToken: getAccessTokenSilently,
      }).then((response) =>
        Promise.resolve<IResponseBodyUpload>(response.data),
      );
    }
    if (file && file?.type === 'application/pdf') {
      return requester({
        method: 'post',
        path: '/admin/upload/pdf',
        data: formData,
        getToken: getAccessTokenSilently,
      }).then((response) =>
        Promise.resolve<IResponseBodyUpload>(response.data),
      );
    }
    return requester({
      method: 'post',
      path: pathMedia,
      data: formData,
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IResponseBodyUpload>(response.data));
  };
};

export default useUploadFile;
