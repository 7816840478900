import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

export const useRefreshNTypePost = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function refreshNTypePost(
    idProject: string,
    ids: string[],
    type: string,
  ) {
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}/koc/social-posts/refresh`,
      data: {
        ids,
        type,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useRefreshNTypePost;
