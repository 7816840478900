import { IProjectReports } from 'types/project';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';

import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  id: string;
  isAllow: boolean;
}

interface Result extends IRequestHookResult<IProjectReports> {}

export const useLoadProjectReports: IRequestHook<Props, Result> = ({ id, isAllow, stopWhen }) => {
  const path = `/admin/projects/${id}/reports`;

  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useCustomSWR(isAllow ? path : '', { stopWhen });

  const reports = response ? (response.data as IProjectReports) : undefined;

  return {
    result: reports,
    error,
    isLoading: !error && !response,
    isValidating,
    mutate,
  };
};

export default useLoadProjectReports;
