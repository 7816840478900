/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Typography,
  Alert,
  Select,
  Theme,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Button,
  Stack,
  RadioGroup,
  Radio,
  FormControlLabel,
  Collapse,
  IconButtonProps,
  Menu,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import InfoIcon from '@mui/icons-material/Info';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import _map from 'lodash/map';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IMetaCursorPagination } from 'types/response';
import {
  useDeletePotentialKoc,
  useAddKOCPotential,
  useLoadKOCCustomerList,
  useLoadKOCCustomerUnique,
  useLoadProjectKOCRankFilter,
  useLoadKOCCateSystemByKeyword,
  useLoadDiscoveryGroupCustomerList,
  useLoadDiscoveryGroupUnique,
  useLoadHometownList,
  useRejectCustomer,
} from 'requests/koc';
import {
  useLoadFirstAudienceList,
  useLoadDTypeAudienceList,
  useLoadNTypePageList,
  useLoadNTypeGroupList,
  useLoadPostList,
} from 'requests/data-source';
import { useLoadAudienceMetaData } from 'requests/audiences';
import AccordionCard from 'components/AccordionCard';
import { CircularLoading } from 'components/loading';
import { ICustomerAudience, IKOCCustomer } from 'types/customer';
import ConfirmDialog from 'components/ConfirmDialog';
import Permission from 'components/Permission/Permission';
import { IKOCAudience, IKOCAudiencePost } from 'types/koc';
import KOCAudiencePostDialog from 'pages/KOCNurturing/KOCAudiencePage/components/KOCAudiencePostDialog';
import { useLoadProductList } from 'requests/products';
import { useLoadUseCaseList } from 'requests/use-cases';
import {
  IDataFilter,
  IDiscoveryGroup,
  IDiscoveryGroupOverview,
} from 'types/discovery-group';
import CustomChip from 'components/CustomChip';
import { getErrorMessage } from 'utils/common';
import FilterConditions from './FilterConditions';
import { IProfileAction, RenderCustomerCell } from './RenderCustomerCell';
import AddTagDialog from './AddTagDialog';
import RejectDialog from './RejectDialog';

interface IProps {
  borderBox?: boolean;
  filterAudience?: boolean;
  isInDetail?: boolean;
  onChangeDiscoveryGroup?: (data: IDiscoveryGroup) => void;
  groupSelected?: IDiscoveryGroup;
  isShowRejectedCustomers: boolean;
  setIsShowRejectedCustomers: (isShow: boolean) => void;
  reloadOverview: () => void;
  discoveryGroupOverview?: IDiscoveryGroupOverview;
}

interface IColumn {
  Header: string;
  accessor: string;
}

interface IResultCustomer {
  data: IKOCCustomer[];
  pagination: IMetaCursorPagination;
}

const defaultConditions = {
  cities: [],
  friends: [],
  follows: [],
  genders: [],
  ages: [],
  relations: [],
  uids: [],
  phones: [],
  cdpCities: [],
  cdpGenders: [],
  cdpAges: [],
  cdpRecencies: [],
  cdpFrequencies: [],
  cdpMonetaries: [],
  kocCategories: [],
  firstAudiences: [],
  pages: [],
  posts: [],
  averageEngagement: { from: null, to: null },
  totalPost: { from: null, to: null },
  totalEngagement: { from: null, to: null },
  totalReaction: { from: null, to: null },
  totalComment: { from: null, to: null },
  totalShare: { from: null, to: null },
  lastPostedTime: undefined,
  hometowns: [],
  products: [],
  useCases: [],
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(-90deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const isNotDefault = (
  audienceFilters: IDataFilter,
  fakeDTypeAudience: IKOCAudience,
) =>
  _map(audienceFilters, (value, key) => {
    if (key === 'dTypeAudiences' && value.length > 0) {
      return value.length === 1
        ? _map(
            value,
            (audienceValue, audienceKey) =>
              audienceValue !== fakeDTypeAudience[audienceKey],
          ).some((isChange) => isChange)
        : true;
    }
    return value?.length > 0;
  }).some((value) => value);

const socialPower = [
  'follow',
  'friends',
  'averageEngagement',
  'totalPost',
  'totalEngagement',
  'lastPostedTime',
];
const dataSourceType = [
  'dTypeAudiences',
  'nTypePage',
  'nTypePost',
  'firstAudiences',
];
const others = [
  'phone',
  'age',
  'idCity',
  'idHometown',
  'sex',
  'idRelationship',
  'totalReaction',
  'totalComment',
  'totalShare',
  'product',
  'useCase',
  'otherTag',
];
const groupViews = { socialPower, dataSourceType, others };
const columns: IColumn[] = [
  {
    Header: 'Actions',
    accessor: 'actions',
  },
  {
    Header: 'Social Profile',
    accessor: 'uid',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Reason',
    accessor: 'reason',
  },
  {
    Header: 'Product',
    accessor: 'product',
  },
  {
    Header: 'Use Case',
    accessor: 'useCase',
  },
  {
    Header: 'Other Tag',
    accessor: 'otherTag',
  },
  {
    Header: 'Follow',
    accessor: 'follow',
  },
  {
    Header: 'Friends',
    accessor: 'friends',
  },
  {
    Header: 'Average Engagements',
    accessor: 'averageEngagement',
  },
  {
    Header: 'Total Post',
    accessor: 'totalPost',
  },
  {
    Header: 'Total Engagement',
    accessor: 'totalEngagement',
  },
  {
    Header: 'Last Posted Time',
    accessor: 'lastPostedTime',
  },
  {
    Header: 'D-type Audience',
    accessor: 'dTypeAudiences',
  },
  {
    Header: 'N-type page/ group',
    accessor: 'nTypePage',
  },
  {
    Header: 'N-type post',
    accessor: 'nTypePost',
  },
  {
    Header: '1P Data',
    accessor: 'firstAudiences',
  },
  {
    Header: 'Phone Number',
    accessor: 'phone',
  },
  {
    Header: 'Age',
    accessor: 'age',
  },
  {
    Header: 'City',
    accessor: 'idCity',
  },
  {
    Header: 'Hometown',
    accessor: 'idHometown',
  },
  {
    Header: 'Gender',
    accessor: 'sex',
  },
  {
    Header: 'Relationship',
    accessor: 'idRelationship',
  },
  {
    Header: 'Total Reaction',
    accessor: 'totalReaction',
  },
  {
    Header: 'Total Comment',
    accessor: 'totalComment',
  },
  {
    Header: 'Total Share',
    accessor: 'totalShare',
  },
];
const remapGroupName = (groupKey: string) => {
  const map: Record<string, string> = {
    socialPower: 'Social Power',
    dataSourceType: 'Data Source Type',
    others: 'Others',
  };
  return map[groupKey];
};

const Customers: FC<IProps> = ({
  borderBox,
  filterAudience,
  isInDetail,
  onChangeDiscoveryGroup,
  groupSelected,
  isShowRejectedCustomers,
  setIsShowRejectedCustomers,
  reloadOverview,
  discoveryGroupOverview,
}) => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies([
    'Group-View-Discovery-Groups',
    'Display-Column-Discovery-Groups',
  ]);
  const { idProject, id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const getCustomerUnique = useLoadKOCCustomerUnique();
  const getDiscoveryGroupUnique = useLoadDiscoveryGroupUnique();
  const addPotentialKOC = useAddKOCPotential();
  const removePotentialKoc = useDeletePotentialKoc();
  const [rowPerpage, setRowPerPage] = useState<string>('10');
  const [cursorHistory, setCursorHistory] = useState<string[]>([]);
  const [cursor, setCursor] = useState<string>('');
  const [curPage, setCurpage] = useState<number>(0);
  const [uniqueData, setUniqueData] = useState<number | null>(null);
  const [errorUnique, setErrorUnique] = useState<any>(null);
  const [isLoadingUnique, setIsLoadingUnique] = useState<boolean>(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] =
    useState<boolean>(false);
  const [removingKOC, setRemovingKOC] = useState<IKOCCustomer | null>(null);
  const [checkboxState, setCheckboxState] = useState({});
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [isAllowSaveFilter, setIsAllowSaveFilter] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedPost, setSelectedPost] = useState<IKOCAudiencePost | null>(
    null,
  );
  const [dTypeAudienceState, setDTypeAudienceState] = useState({});
  const [nTypePageState, setNTypePageState] = useState({});
  const [nTypePostState, setNTypePostState] = useState({});
  const [firstAudienceState, setFirstAudienceState] = useState({});
  const fakeDTypeAudience: IKOCAudience = {
    socialId: id,
    id,
    name: 'string',
    type: 'string',
    size: 0,
    isDraft: false,
    isNew: false,
    status: 'string',
    actualSize: 0,
    totalProcessedProfiles: 0,
  };
  const [selectedView, setSelectedView] = useState<string>('defaultView');
  const [groupViewState, setGroupViewState] = useState({
    socialPower: true,
    dataSourceType: true,
    others: true,
  });
  const [audienceFilters, setAudienceFilters] = useState<IDataFilter>({
    ...defaultConditions,
    dTypeAudiences: filterAudience ? [fakeDTypeAudience] : [],
  });
  const [addTagCustomer, setAddTagCustomer] = useState<IKOCCustomer>();
  const [rejectCustomer, setRejectCustomer] = useState<IKOCCustomer>();
  const [reloadUnique, setReloadUnique] = useState<boolean>(false);
  const undoRejectCustomer = useRejectCustomer();
  const onUndoRejectCustomer = (customer: IKOCCustomer) => {
    undoRejectCustomer({
      idProject,
      idCustomer: customer.id,
      reason: customer.reason,
      idDiscoveryGroup: groupSelected?.id || '',
      isRejected: false,
    })
      .then(() => {
        reloadOverview();
        handleCloseProfileActions();
        mutateDiscoveryGroup();

        enqueueSnackbar(`Undo rejection successfully`, {
          variant: 'success',
        });
      })
      .catch((error) => {
        console.error('Reject customer: ', error);

        enqueueSnackbar(getErrorMessage(error, 'Something went wrong'), {
          variant: 'error',
        });
      });
  };

  const defaultView = {
    actions: !isInDetail,
    uid: true,
    name: true,
    product: true,
    useCase: true,
    otherTag: true,
    follow: true,
    friends: true,
    averageEngagement: false,
    totalPost: false,
    totalEngagement: false,
    lastPostedTime: false,
    dTypeAudiences: true,
    nTypePage: true,
    nTypePost: true,
    firstAudiences: true,
    phone: false,
    age: false,
    idCity: false,
    idHometown: false,
    sex: true,
    idRelationship: true,
    totalReaction: false,
    totalComment: false,
    totalShare: false,
  };

  const [columnsDisplay, setColumnsDisplay] = useState<any>(defaultView);

  useEffect(() => {
    if (
      cookies &&
      cookies['Display-Column-Discovery-Groups'] === 'customView' &&
      cookies['Group-View-Discovery-Groups'] &&
      !isInDetail
    ) {
      setSelectedView('customView');
      setColumnsDisplay({
        ...cookies['Group-View-Discovery-Groups'],
        follow: true,
      });
    } else {
      setSelectedView('defaultView');
      setColumnsDisplay(defaultView);
    }
  }, []);

  const loadUniqueAudience = () => {
    setIsLoadingUnique(true);
    setReloadUnique(false);
    if (groupSelected?.id && !isInDetail) {
      getDiscoveryGroupUnique({ idProject, idDiscoveryGroup: groupSelected.id })
        .then((res: { total: number }) => {
          setUniqueData(res.total);
          setIsLoadingUnique(false);
        })
        .catch((err) => {
          setIsLoadingUnique(false);
          setErrorUnique(err);
          console.error('unique err', err);
        });
    }
    if (isInDetail) {
      getCustomerUnique({
        idProject,
        ...defaultConditions,
        dTypeAudiences: filterAudience ? [id] : [],
      })
        .then((res: { total: number }) => {
          setUniqueData(res.total);
          setIsLoadingUnique(false);
        })
        .catch((err) => {
          setIsLoadingUnique(false);
          setErrorUnique(err);
          console.error('unique err', err);
        });
    }
  };
  const {
    result: dTypeAudiencesPollingResponse,
    isLoading: isLoadingDTypeAudiences,
    error: errorDTypeAudiences,
  } = useLoadDTypeAudienceList({ idProject, stopWhen: isInDetail });
  const {
    result: pagesPollingResponse,
    isLoading: isLoadingNTypePages,
    error: errorNTypePages,
  } = useLoadNTypePageList({ idProject, stopWhen: isInDetail });
  const {
    result: groupsPollingResponse,
    isLoading: isLoadingNTypeGroups,
    error: errorNTypeGroups,
  } = useLoadNTypeGroupList({ idProject, stopWhen: isInDetail });
  const {
    result: postsPollingResponse,
    isLoading: isLoadingNTypePosts,
    error: errorNTypePosts,
  } = useLoadPostList({ idProject, stopWhen: isInDetail });
  const {
    result: firstAudiencesPollingResponse,
    isLoading: isLoadingFirstAudiences,
    error: errorFirstAudiences,
  } = useLoadFirstAudienceList({ idProject, stopWhen: isInDetail });
  const { result, isLoading, error, mutate } = useLoadKOCCustomerList({
    idProject,
    pageSize: parseInt(rowPerpage, 10),
    cursor,
    ...defaultConditions,
    dTypeAudiences: filterAudience ? [id] : [],
    stopWhen: Boolean(groupSelected?.id || !isInDetail),
  });
  const {
    result: resultDiscoveryGroup,
    isLoading: isLoadingDiscoveryGroup,
    error: errorDiscoveryGroup,
    mutate: mutateDiscoveryGroup,
  } = useLoadDiscoveryGroupCustomerList({
    idProject,
    idDiscoveryGroup: groupSelected?.id || 0,
    pageSize: parseInt(rowPerpage, 10),
    cursor,
    stopWhen: Boolean(!groupSelected?.id || isInDetail),
    isRejected: isShowRejectedCustomers,
  });
  const {
    result: metaData,
    isLoading: isLoadingMetaData,
    error: errorMetaData,
  } = useLoadAudienceMetaData({});
  const {
    result: hometowns,
    isLoading: isLoadingHometowns,
    error: errorHometowns,
  } = useLoadHometownList({});
  const { result: rankData, isLoading: isLoadingRankData } =
    useLoadProjectKOCRankFilter({
      idProject,
    });
  const { result: categories, isLoading: isLoadingCategories } =
    useLoadKOCCateSystemByKeyword({
      keyword: '',
    });
  const { result: productList, isLoading: isLoadingProducts } =
    useLoadProductList({
      idProject,
      keyword: '',
    });
  const { result: useCaseList, isLoading: isLoadingUseCases } =
    useLoadUseCaseList({
      idProject,
      keyword: '',
    });

  useEffect(() => {
    if (groupSelected) {
      setAudienceFilters(groupSelected?.data?.dataFilter);
      setIsAllowSaveFilter(false);
    } else if (isNotDefault(audienceFilters, fakeDTypeAudience)) {
      setAudienceFilters({
        ...defaultConditions,
        dTypeAudiences: filterAudience ? [fakeDTypeAudience] : [],
      });
    }
    if (groupSelected?.status === 'PROCESSED') {
      if (isInDetail) {
        loadUniqueAudience();
      }
      mutateDiscoveryGroup();
      reloadOverview();
    }
    setCheckboxState({});
    setCurpage(0);
    setCursor('');
    setCursorHistory(['']);
  }, [groupSelected]);
  useEffect(() => {
    setCurpage(0);
    setCursor('');
  }, [rowPerpage]);

  useEffect(() => {
    checkIsSelectAll(groupSelected?.id ? resultDiscoveryGroup : result);
  }, [checkboxState, result, resultDiscoveryGroup]);

  useEffect(() => {
    if (reloadUnique && groupSelected?.status === 'PROCESSED') {
      loadUniqueAudience();
      setCurpage(0);
      setCursorHistory(['']);
    }
  }, [reloadUnique, groupSelected]);

  const [profileActions, setProfileActions] = useState<null | {
    anchorEl: HTMLElement;
    actions: IProfileAction[];
  }>(null);
  const openProfileActions = Boolean(profileActions);
  const handleClickProfileActions = (
    event: React.MouseEvent<HTMLButtonElement>,
    actions: IProfileAction[],
  ) => {
    setProfileActions({ anchorEl: event.currentTarget, actions });
  };
  const handleCloseProfileActions = () => {
    setProfileActions(null);
  };

  if (
    error ||
    errorDiscoveryGroup ||
    errorMetaData ||
    errorDTypeAudiences ||
    errorNTypePages ||
    errorNTypeGroups ||
    errorNTypePosts ||
    errorFirstAudiences ||
    errorUnique ||
    errorMetaData ||
    errorHometowns
  ) {
    console.error(
      error,
      errorDiscoveryGroup,
      errorMetaData,
      errorDTypeAudiences,
      errorNTypePages,
      errorNTypeGroups,
      errorNTypePosts,
      errorFirstAudiences,
      errorUnique,
      errorMetaData,
    );
  }

  const handleChange = (event: SelectChangeEvent) => {
    setRowPerPage(event.target.value);
  };

  const onPrevClick = () => {
    setCurpage(curPage ? curPage - 1 : 0);
    setCursor(cursorHistory[curPage ? curPage - 1 : 0]);
  };

  const onNextClick = (resultData: IResultCustomer) => {
    setCurpage(curPage + 1);
    if (resultData?.pagination?.nextCursor) {
      setCursor(resultData.pagination.nextCursor);
      const tempCursorHistory = [...cursorHistory];
      tempCursorHistory[curPage + 1] = resultData.pagination.nextCursor;
      setCursorHistory(tempCursorHistory);
    }
  };

  const renderCell = (
    selector: string,
    value: string | number | ICustomerAudience[] | undefined,
    row: IKOCCustomer,
  ) =>
    RenderCustomerCell(
      selector,
      value,
      row,
      isAllowSaveFilter,
      idProject,
      metaData,
      (data: IKOCCustomer) => {
        setRemovingKOC(data);
      },
      (data: boolean) => {
        setIsOpenConfirmDialog(data);
      },
      (data: IKOCCustomer) => {
        savePotentialKOC(data);
      },
      (data: boolean) => {
        setIsOpen(data);
      },
      dTypeAudienceState,
      (data: any) => {
        setDTypeAudienceState(data);
      },
      nTypePageState,
      (data: any) => {
        setNTypePageState(data);
      },
      nTypePostState,
      (data: any) => {
        setNTypePostState(data);
      },
      firstAudienceState,
      (data: any) => {
        setFirstAudienceState(data);
      },
      (data: IKOCAudiencePost) => {
        setSelectedPost(data);
      },
      (data: IKOCCustomer) => {
        setAddTagCustomer(data);
      },
      (data: IKOCCustomer) => {
        setRejectCustomer(data);
      },
      isShowRejectedCustomers,
      onUndoRejectCustomer,
      handleClickProfileActions,
    );

  const savePotentialKOC = (selectedItem?: IKOCCustomer) => {
    setIsRequesting(true);
    let castedValues: string[] = [];
    if (selectedItem) {
      castedValues.push(selectedItem.id);
    } else {
      castedValues = Object.values({ ...checkboxState });
    }
    addPotentialKOC({
      idProject,
      ids: [...castedValues],
      discoveryGroup: groupSelected,
    })
      .then(() => {
        if (!selectedItem) {
          setCheckboxState({});
        } else if (
          Object.prototype.hasOwnProperty.call(
            { ...checkboxState },
            selectedItem.idRow,
          )
        ) {
          delete checkboxState[selectedItem.idRow];
        }
        setIsRequesting(false);
        if (groupSelected && groupSelected.id) {
          mutateDiscoveryGroup();
          reloadOverview();
        } else {
          mutate();
        }
        enqueueSnackbar('Add KOC to Potential list successfully', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        });
      })
      .catch((err) => {
        console.error(err);
        setIsRequesting(false);
        let message =
          'Something went wrong, failed to Add KOC to Potential list';
        if (err?.status < 500) {
          message =
            err.data?.messages?.map((item) => item.message)?.join('\n') ||
            message;
        }
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        });
      });
  };

  const unSelectPotentialKOC = () => {
    if (removingKOC) {
      setIsRequesting(true);
      removePotentialKoc(idProject, removingKOC.idKocCustomer)
        .then(() => {
          setRemovingKOC(null);
          setIsRequesting(false);
          setIsOpenConfirmDialog(false);
          if (groupSelected && groupSelected.id) {
            mutateDiscoveryGroup();
            reloadOverview();
          } else {
            mutate();
          }
          enqueueSnackbar(`Remove Potential KOC successfully`, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
        })
        .catch((err) => {
          setIsRequesting(false);
          setIsOpenConfirmDialog(false);
          console.error(err);
          let message = 'Something went wrong, failed to Remove Potential KOC';
          if (err?.status < 500) {
            message =
              err.data?.messages?.map((item) => item.message)?.join('\n') ||
              message;
          }
          enqueueSnackbar(message, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          });
        });
    }
  };

  const handleCancel = () => {
    setRemovingKOC(null);
    setIsOpenConfirmDialog(false);
  };

  const checkIsSelectAll = (resultData: IResultCustomer) => {
    if (resultData?.data && checkboxState) {
      const boo = resultData.data
        .filter(
          (element: IKOCCustomer) =>
            !element.isProjectKoc && !element.isPotentialKoc,
        )
        .every((item: IKOCCustomer) =>
          Object.prototype.hasOwnProperty.call(
            { ...checkboxState },
            item.idRow,
          ),
        );
      setIsSelectAll(boo);
    } else {
      setIsSelectAll(false);
    }
  };

  const handleSelectAll = (resultData: IResultCustomer, value: boolean) => {
    if (value && resultData?.data && checkboxState) {
      const addCheckboxState = {};
      resultData.data
        .filter(
          (element: IKOCCustomer) =>
            !element.isProjectKoc && !element.isPotentialKoc,
        )
        .forEach((item: IKOCCustomer) => {
          if (
            !Object.prototype.hasOwnProperty.call(
              { ...checkboxState },
              item.idRow,
            )
          ) {
            addCheckboxState[item.idRow] = item.id;
          }
        });
      setCheckboxState({ ...checkboxState, ...addCheckboxState });
    }

    if (!value && resultData?.data && checkboxState) {
      const finalCheckboxState = { ...checkboxState };
      resultData.data.forEach((item: IKOCCustomer) => {
        if (
          Object.prototype.hasOwnProperty.call({ ...checkboxState }, item.idRow)
        ) {
          delete finalCheckboxState[item.idRow];
        }
      });
      setCheckboxState(finalCheckboxState);
    }
  };

  const handleSelectItem = (value: boolean, item: IKOCCustomer) => {
    if (value && checkboxState) {
      if (
        !Object.prototype.hasOwnProperty.call({ ...checkboxState }, item.idRow)
      ) {
        const tempObj = { ...checkboxState, [item.idRow]: item.id };
        setCheckboxState(tempObj);
      }
    }

    if (!value && checkboxState) {
      if (
        Object.prototype.hasOwnProperty.call({ ...checkboxState }, item.idRow)
      ) {
        const tempObj = { ...checkboxState };
        delete tempObj[item.idRow];
        setCheckboxState(tempObj);
      }
    }
  };

  const handleViewChange = (event: any) => {
    if (event?.target?.value !== 'defaultView') {
      setSelectedView('customView');

      setCookie('Display-Column-Discovery-Groups', 'customView', {
        domain: 'mightywow.ai',
        path: '/',
      });
      setColumnsDisplay({
        actions: !isInDetail,
        uid: true,
        name: true,
        follow: true,
      });
      setCookie(
        'Group-View-Discovery-Groups',
        {
          actions: !isInDetail,
          uid: true,
          name: true,
          follow: true,
        },
        {
          domain: 'mightywow.ai',
          path: '/',
        },
      );
    } else {
      setSelectedView('defaultView');
      setCookie('Display-Column-Discovery-Groups', 'defaultView', {
        domain: 'mightywow.ai',
        path: '/',
      });
      setColumnsDisplay(defaultView);
      setCookie('Group-View-Discovery-Groups', defaultView, {
        domain: 'mightywow.ai',
        path: '/',
      });
      setGroupViewState({
        socialPower: true,
        dataSourceType: true,
        others: true,
      });
    }
  };

  const handleExpandClick = (groupKey: string) => {
    const tempGroupViewState = { ...groupViewState };
    tempGroupViewState[groupKey] = !tempGroupViewState[groupKey];
    setGroupViewState({ ...tempGroupViewState });
  };

  const isGroupChecked = (groupKey: string) => {
    if (
      groupViews[groupKey]
        ?.filter((item) => item !== 'follow')
        ?.every((fieldName: string) => columnsDisplay[fieldName])
    ) {
      return true;
    }
    if (
      groupViews[groupKey]
        ?.filter((item) => item !== 'follow')
        ?.some((fieldName: string) => columnsDisplay[fieldName])
    ) {
      return null;
    }
    return false;
  };

  const handleGroupChange = (groupKey: string, checked: boolean) => {
    const tempColumnsDisplay = { ...columnsDisplay };
    groupViews[groupKey]?.forEach((column: string) => {
      if (column !== 'follow') {
        tempColumnsDisplay[column] = checked;
      } else {
        tempColumnsDisplay[column] = true;
      }
    });
    setColumnsDisplay({ ...tempColumnsDisplay });
    setCookie(
      'Group-View-Discovery-Groups',
      { ...tempColumnsDisplay },
      {
        domain: 'mightywow.ai',
        path: '/',
      },
    );
  };

  const CheckboxIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 20,
    height: 20,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(57,75,89,.5)'
          : 'rgba(206,217,224,.5)',
    },
  }));

  const rejectedChip =
    discoveryGroupOverview && isShowRejectedCustomers ? (
      <CustomChip
        sx={{ mb: 2 }}
        color="error"
        label={`Rejected (${discoveryGroupOverview.totalRejectedProfile})`}
        onDelete={() => {
          setIsShowRejectedCustomers(false);
        }}
      />
    ) : (
      ''
    );

  const onActionSuccess = () => {
    if (groupSelected && groupSelected.id) {
      mutateDiscoveryGroup();
    } else {
      mutate();
    }
    reloadOverview();
    handleCloseProfileActions();
  };

  return (
    <Box
      p={4}
      className={borderBox ? classes.borderBox : ''}
      sx={{ borderRadius: 1 }}
    >
      {isInDetail && (
        <Box sx={{ display: 'flex', justifyContent: 'end', marginBottom: 2 }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            fontSize="1rem"
          >
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Tooltip title="based on phone number">
                <InfoIcon fontSize="inherit" color="info" />
              </Tooltip>
              <Typography variant="subtitle2" component="span" pl={1} mr={2}>
                Total Unique Profiles:
              </Typography>
            </Stack>

            <Typography variant="subtitle2" component="span">
              {uniqueData === null || isLoadingUnique
                ? '--'
                : new Intl.NumberFormat('en-US', {}).format(uniqueData)}
            </Typography>
            <IconButton
              size="medium"
              disabled={
                isLoadingUnique ||
                (groupSelected &&
                  ['PENDING', 'PROCESSING'].includes(groupSelected.status))
              }
              onClick={loadUniqueAudience}
            >
              <RefreshIcon />
            </IconButton>
          </Stack>
        </Box>
      )}

      {(error ||
        errorMetaData ||
        errorDTypeAudiences ||
        errorNTypePages ||
        errorNTypeGroups ||
        errorNTypePosts ||
        errorFirstAudiences) && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Something went wrong.
        </Alert>
      )}

      {!isInDetail && (
        <Permission
          permissions={[
            'project.koc_discovery.discovery_group.view_custom_view',
          ]}
        >
          <AccordionCard
            title="CUSTOM VIEW"
            sx={{
              borderRadius: 1,
              border: '1px solid',
              borderColor: (theme: Theme) => theme.palette.grey[400],
              width: '100%',
              mb: '24px !important',
            }}
          >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={selectedView}
              onChange={handleViewChange}
            >
              <FormControlLabel
                disabled={isShowRejectedCustomers}
                value="defaultView"
                control={<Radio />}
                label="Default View"
              />
              <FormControlLabel
                disabled={isShowRejectedCustomers}
                value="customView"
                control={<Radio />}
                label="Custom View"
              />
            </RadioGroup>
            <Box sx={{ mx: 2, display: 'flex' }}>
              {Object.keys(groupViews).map((group: string) => (
                <Box key={group}>
                  <Box alignItems="center" display="flex" width={260}>
                    <ExpandMore
                      sx={{ m: 0, p: 0 }}
                      expand={groupViewState[group]}
                      disabled={selectedView !== 'customView'}
                      onClick={() => {
                        handleExpandClick(group);
                      }}
                      aria-expanded={groupViewState[group]}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                    <Checkbox
                      indeterminate={
                        isGroupChecked(group) === null &&
                        selectedView === 'customView'
                      }
                      disabled={
                        selectedView !== 'customView' || isShowRejectedCustomers
                      }
                      checked={
                        Boolean(isGroupChecked(group)) &&
                        selectedView === 'customView'
                      }
                      onChange={(e: any) => {
                        handleGroupChange(group, e?.target?.checked);
                      }}
                    />
                    <Typography sx={{ color: '#20202099' }}>
                      {remapGroupName(group)}
                    </Typography>
                  </Box>
                  <Collapse
                    in={groupViewState[group]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {columns
                      .filter(
                        (item: IColumn) =>
                          !['actions', 'uid', 'name', 'follow'].includes(
                            item.accessor,
                          ) && groupViews[group].includes(item.accessor),
                      )
                      .map((column) => (
                        <Box
                          key={column.accessor}
                          ml={6}
                          display="flex"
                          alignItems="center"
                        >
                          <Checkbox
                            disabled={
                              selectedView !== 'customView' ||
                              isShowRejectedCustomers
                            }
                            checked={
                              columnsDisplay[column.accessor] &&
                              selectedView === 'customView'
                            }
                            onChange={() => {
                              const cols = { ...columnsDisplay };
                              cols[column.accessor] = !cols[column.accessor];
                              setColumnsDisplay(cols);
                              setCookie(
                                'Group-View-Discovery-Groups',
                                { ...cols },
                                {
                                  domain: 'mightywow.ai',
                                  path: '/',
                                },
                              );
                            }}
                          />
                          <Typography sx={{ color: '#20202099' }}>
                            {column.Header}
                          </Typography>
                        </Box>
                      ))}
                  </Collapse>
                </Box>
              ))}
            </Box>
          </AccordionCard>
        </Permission>
      )}

      {!isInDetail &&
        metaData &&
        rankData &&
        categories &&
        dTypeAudiencesPollingResponse &&
        dTypeAudiencesPollingResponse.data &&
        pagesPollingResponse &&
        groupsPollingResponse &&
        postsPollingResponse &&
        firstAudiencesPollingResponse &&
        firstAudiencesPollingResponse.data &&
        audienceFilters && (
          <FilterConditions
            groupSelected={groupSelected}
            metaData={metaData}
            hometowns={hometowns || []}
            isLoadingMetaData={isLoadingMetaData || isLoadingHometowns}
            rankData={rankData}
            categories={categories}
            isLoadingRankData={isLoadingRankData}
            isLoadingCategories={isLoadingCategories}
            dTypeAudiences={dTypeAudiencesPollingResponse.data}
            isLoadingDTypeAudiences={isLoadingDTypeAudiences}
            pages={[
              ...(pagesPollingResponse?.data || []),
              ...(groupsPollingResponse?.data || []),
            ]}
            isLoadingNTypePages={isLoadingNTypePages || isLoadingNTypeGroups}
            posts={postsPollingResponse.data || []}
            isLoadingNTypePosts={isLoadingNTypePosts}
            firstAudiences={firstAudiencesPollingResponse.data}
            isLoadingFirstAudiences={isLoadingFirstAudiences}
            audienceFilters={audienceFilters}
            displayFilterByAudience={!filterAudience}
            filterChange={(conditions: IDataFilter) => {
              const cantSave =
                conditions &&
                Object.keys(conditions).length > 0 &&
                Object.keys(conditions).every(
                  (key: string) =>
                    conditions[key] === null ||
                    conditions[key] === undefined ||
                    conditions[key]?.length === 0 ||
                    (conditions[key]?.from === null &&
                      conditions[key]?.to === null),
                );
              setAudienceFilters(conditions);
              setIsAllowSaveFilter(!cantSave);
            }}
            isAllowSaveFilter={isAllowSaveFilter}
            onChangeDiscoveryGroup={onChangeDiscoveryGroup}
            onSaveFilter={(conditions: IDataFilter) => {
              setAudienceFilters(conditions);
              setReloadUnique(true);
            }}
            products={productList || []}
            isLoadingProducts={isLoadingProducts}
            useCases={useCaseList || []}
            isLoadingUseCases={isLoadingUseCases}
            disabled={isShowRejectedCustomers}
          />
        )}

      {rejectedChip}

      {((isLoading && isInDetail) ||
        (groupSelected?.id && isLoadingDiscoveryGroup && !isInDetail) ||
        isLoadingMetaData ||
        isLoadingHometowns ||
        isLoadingDTypeAudiences ||
        isLoadingFirstAudiences ||
        isLoadingNTypePages ||
        isLoadingNTypeGroups ||
        isLoadingNTypePosts ||
        isRequesting ||
        isLoadingRankData ||
        isLoadingCategories) && <CircularLoading />}
      {groupSelected?.id &&
        ((resultDiscoveryGroup && resultDiscoveryGroup.data?.length < 1) ||
          ['PENDING', 'PROCESSING'].includes(groupSelected.status)) && (
          <Alert severity="info">No data found.</Alert>
        )}
      {((isInDetail && result && result.data?.length < 1) ||
        (!isInDetail && !groupSelected?.id)) && (
        <Alert severity="info">No data found.</Alert>
      )}
      {isInDetail && result && result.data?.length > 0 && (
        <Box
          className="fix-table-header"
          sx={{
            overflowX: 'auto',
            maxHeight: '700px',
            '& table td:first-child': {
              left: 0,
              zIndex: 0,
              position: 'sticky',
            },
            '& table td:nth-child(2)': {
              left: !isInDetail ? 78 : 150,
              zIndex: 0,
              position: 'sticky',
            },
            '& table td:nth-child(3)': {
              left: !isInDetail ? 357 : 332,
              zIndex: 0,
              position: 'sticky',
              borderRight: !isInDetail
                ? 'solid 3px white'
                : 'solid 3px #e6e7eb',
              borderRightWidth: !isInDetail ? 0 : 3,
              borderColor: '#e6e7eb',
            },
            '& table td:nth-child(4)': {
              left: !isInDetail ? 507 : 698,
              zIndex: 0,
              position: !isInDetail ? 'sticky' : 'static',
            },
            '& table td:nth-child(5)': {
              left: !isInDetail ? 689 : 908,
              zIndex: 0,
              position: !isInDetail ? 'sticky' : 'static',
              borderRight: !isInDetail
                ? 'solid 3px #e6e7eb'
                : 'solid 3px white',
              borderRightWidth: !isInDetail ? 3 : 0,
              borderColor: '#e6e7eb',
            },
            '& table thead th:first-child': {
              zIndex: 2,
              left: 0,
            },
            '& table thead th:nth-child(2)': {
              zIndex: 2,
              left: !isInDetail ? 78 : 150,
            },
            '& table thead th:nth-child(3)': {
              zIndex: 2,
              left: !isInDetail ? 357 : 332,
              borderRight: !isInDetail
                ? 'solid 3px white'
                : 'solid 3px #e6e7eb',
              borderRightWidth: !isInDetail ? 0 : 3,
              borderColor: '#e6e7eb',
            },
            '& table thead th:nth-child(4)': {
              // left: !isInDetail ? 317 : 482,
              left: !isInDetail ? 507 : 'unset',
              top: 0,
              zIndex: !isInDetail ? 2 : 1,
              // position: !isInDetail ? 'sticky' : 'static',
              position: 'sticky',
            },
            '& table thead th:nth-child(5)': {
              // left: !isInDetail ? 499 : 682,
              left: !isInDetail ? 689 : 'unset',
              top: 0,
              zIndex: !isInDetail ? 3 : 1,
              // position: !isInDetail ? 'sticky' : 'static',
              position: 'sticky',
              borderRight: !isInDetail
                ? 'solid 3px #e6e7eb'
                : 'solid 3px white',
              borderRightWidth: !isInDetail ? 3 : 0,
              borderColor: '#e6e7eb',
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {!isInDetail && (
                  <TableCell
                    sx={{
                      position: 'sticky',
                      left: 0,
                      backgroundColor: '#fbfbfc',
                      minWidth: 78,
                    }}
                  >
                    {!isAllowSaveFilter && (
                      <Checkbox
                        checked={isSelectAll}
                        onClick={(event: any) =>
                          handleSelectAll(
                            groupSelected?.id ? resultDiscoveryGroup : result,
                            event.target.checked,
                          )
                        }
                      />
                    )}
                    {isAllowSaveFilter && (
                      <Checkbox
                        icon={<CheckboxIcon sx={{ m: '4px' }} />}
                        checked={isSelectAll}
                        disabled
                      />
                    )}
                  </TableCell>
                )}
                {columns
                  .filter(
                    (column) =>
                      columnsDisplay[column.accessor] ||
                      (isShowRejectedCustomers && column.accessor === 'reason'),
                  )
                  .map((column, idx) => {
                    return (
                      <TableCell
                        sx={
                          (idx === 0 && {
                            whiteSpace: 'nowrap',
                            position: 'sticky',
                            left: !isInDetail ? 78 : 0,
                            backgroundColor: '#fbfbfc',
                            minWidth: !isInDetail ? 80 : 0,
                            textAlign: !isInDetail ? 'center' : 'left',
                          }) ||
                          (idx === 1 && {
                            position: 'sticky',
                            left: !isInDetail ? 160 : 0,
                            backgroundColor: '#fbfbfc',
                            minWidth: 150,
                          }) ||
                          (idx === 2 && {
                            whiteSpace: 'nowrap',
                            position: 'sticky',
                            left: !isInDetail ? 357 : 332,
                            backgroundColor: '#fbfbfc',
                            minWidth: !isInDetail ? 182 : 150,
                            textAlign: !isInDetail ? 'left' : 'center',
                          }) || {
                            minWidth: 200,
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                          }
                        }
                        key={`${column.accessor}-header`}
                      >
                        {column.Header}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {result.data.map((row: IKOCCustomer) => (
                <TableRow
                  key={row.uid + row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {!isInDetail && (
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        backgroundColor: '#fbfbfc',
                        minWidth: 78,
                      }}
                    >
                      {(row.isProjectKoc ||
                        row.isPotentialKoc ||
                        isAllowSaveFilter) && (
                        <Checkbox
                          icon={<CheckboxIcon sx={{ m: '4px' }} />}
                          disabled
                        />
                      )}
                      {!row.isProjectKoc &&
                        !row.isPotentialKoc &&
                        !isAllowSaveFilter && (
                          <Checkbox
                            checked={Object.prototype.hasOwnProperty.call(
                              { ...checkboxState },
                              row.idRow,
                            )}
                            onClick={(event: any) =>
                              handleSelectItem(event.target.checked, row)
                            }
                          />
                        )}
                    </TableCell>
                  )}
                  {columns
                    .filter(
                      (column) =>
                        columnsDisplay[column.accessor] ||
                        (isShowRejectedCustomers &&
                          column.accessor === 'reason'),
                    )
                    .map((column, idx) => {
                      return (
                        <TableCell
                          sx={
                            (idx === 0 && {
                              whiteSpace: 'nowrap',
                              position: 'sticky',
                              left: !isInDetail ? 78 : 0,
                              backgroundColor: '#fbfbfc',
                              minWidth: !isInDetail ? 80 : 150,
                              textAlign: !isInDetail ? 'center' : 'left',
                            }) ||
                            (idx === 1 && {
                              position: 'sticky',
                              left: !isInDetail ? 160 : 0,
                              backgroundColor: '#fbfbfc',
                              minWidth: 150,
                            }) ||
                            (idx === 2 && {
                              whiteSpace: 'nowrap',
                              position: 'sticky',
                              left: !isInDetail ? 357 : 332,
                              backgroundColor: '#fbfbfc',
                              minWidth: !isInDetail ? 182 : 150,
                              textAlign: !isInDetail ? 'left' : 'center',
                            }) || {
                              minWidth: 200,
                              whiteSpace: 'nowrap',
                              textAlign: 'center',
                            }
                          }
                          key={`${row.id + column.accessor}-body`}
                        >
                          {renderCell(
                            column.accessor,
                            row[column.accessor],
                            row,
                          )}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      {groupSelected?.id &&
        resultDiscoveryGroup &&
        resultDiscoveryGroup.data?.length > 0 &&
        groupSelected.status !== 'PENDING' &&
        groupSelected.status !== 'PROCESSING' && (
          <Box
            className="fix-table-header"
            sx={{
              overflowX: 'auto',
              maxHeight: '700px',
              '& table td:first-child': {
                left: 0,
                zIndex: 0,
                position: 'sticky',
              },
              '& table td:nth-child(2)': {
                left: !isInDetail ? 78 : 150,
                zIndex: 0,
                position: 'sticky',
              },
              '& table td:nth-child(3)': {
                left: !isInDetail ? 357 : 332,
                zIndex: 0,
                position: 'sticky',
                borderRight: !isInDetail
                  ? 'solid 3px white'
                  : 'solid 3px #e6e7eb',
                borderRightWidth: !isInDetail ? 0 : 3,
                borderColor: '#e6e7eb',
              },
              '& table td:nth-child(4)': {
                left: !isInDetail ? 507 : 698,
                zIndex: 0,
                position: !isInDetail ? 'sticky' : 'static',
              },
              '& table td:nth-child(5)': {
                left: !isInDetail ? 689 : 908,
                zIndex: 0,
                position: !isInDetail ? 'sticky' : 'static',
                borderRight: !isInDetail
                  ? 'solid 3px #e6e7eb'
                  : 'solid 3px white',
                borderRightWidth: !isInDetail ? 3 : 0,
                borderColor: '#e6e7eb',
              },
              '& table thead th:first-child': {
                zIndex: 2,
                left: 0,
              },
              '& table thead th:nth-child(2)': {
                zIndex: 2,
                left: !isInDetail ? 78 : 150,
              },
              '& table thead th:nth-child(3)': {
                zIndex: 2,
                left: !isInDetail ? 357 : 332,
                borderRight: !isInDetail
                  ? 'solid 3px white'
                  : 'solid 3px #e6e7eb',
                borderRightWidth: !isInDetail ? 0 : 3,
                borderColor: '#e6e7eb',
              },
              '& table thead th:nth-child(4)': {
                // left: !isInDetail ? 317 : 482,
                left: !isInDetail ? 507 : 'unset',
                top: 0,
                zIndex: !isInDetail ? 2 : 1,
                // position: !isInDetail ? 'sticky' : 'static',
                position: 'sticky',
              },
              '& table thead th:nth-child(5)': {
                // left: !isInDetail ? 499 : 682,
                left: !isInDetail ? 689 : 'unset',
                top: 0,
                zIndex: !isInDetail ? 3 : 1,
                // position: !isInDetail ? 'sticky' : 'static',
                position: 'sticky',
                borderRight: !isInDetail
                  ? 'solid 3px #e6e7eb'
                  : 'solid 3px white',
                borderRightWidth: !isInDetail ? 3 : 0,
                borderColor: '#e6e7eb',
              },
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {!isInDetail && (
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 0,
                        backgroundColor: '#fbfbfc',
                        minWidth: 78,
                      }}
                    >
                      {!isAllowSaveFilter && (
                        <Checkbox
                          checked={isSelectAll}
                          onClick={(event: any) =>
                            handleSelectAll(
                              groupSelected?.id ? resultDiscoveryGroup : result,
                              event.target.checked,
                            )
                          }
                          disabled={isShowRejectedCustomers}
                        />
                      )}
                      {isAllowSaveFilter && (
                        <Checkbox
                          icon={<CheckboxIcon sx={{ m: '4px' }} />}
                          checked={isSelectAll}
                          disabled
                        />
                      )}
                    </TableCell>
                  )}
                  {columns
                    .filter(
                      (column) =>
                        columnsDisplay[column.accessor] ||
                        (isShowRejectedCustomers &&
                          column.accessor === 'reason'),
                    )
                    .map((column, idx) => {
                      return (
                        <TableCell
                          sx={
                            (idx === 0 && {
                              whiteSpace: 'nowrap',
                              position: 'sticky',
                              left: !isInDetail ? 78 : 0,
                              backgroundColor: '#fbfbfc',
                              minWidth: !isInDetail ? 80 : 0,
                              textAlign: 'left',
                            }) ||
                            (idx === 1 && {
                              position: 'sticky',
                              left: !isInDetail ? 160 : 0,
                              backgroundColor: '#fbfbfc',
                              minWidth: 150,
                            }) ||
                            (idx === 2 && {
                              whiteSpace: 'nowrap',
                              position: 'sticky',
                              left: !isInDetail ? 357 : 332,
                              backgroundColor: '#fbfbfc',
                              minWidth: !isInDetail ? 182 : 150,
                              textAlign: !isInDetail ? 'left' : 'center',
                            }) ||
                            (idx === 3 && {
                              whiteSpace: 'nowrap',
                              position: !isInDetail ? 'sticky' : 'static',
                              left: !isInDetail ? 507 : 698,
                              backgroundColor: '#fbfbfc',
                              minWidth: !isInDetail ? 150 : 200,
                              textAlign: 'center',
                            }) || {
                              minWidth: 200,
                              whiteSpace: 'nowrap',
                              textAlign: 'center',
                            }
                          }
                          key={`${column.accessor}-header`}
                        >
                          {column.Header}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {resultDiscoveryGroup.data.map((row: IKOCCustomer) => (
                  <TableRow
                    key={row.uid + row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {!isInDetail && (
                      <TableCell
                        sx={{
                          position: 'sticky',
                          left: 0,
                          backgroundColor: '#fbfbfc',
                          minWidth: 78,
                        }}
                      >
                        {(row.isProjectKoc ||
                          row.isPotentialKoc ||
                          isAllowSaveFilter) && (
                          <Checkbox
                            icon={
                              isShowRejectedCustomers ? (
                                <CheckBoxOutlineBlankIcon />
                              ) : (
                                <CheckboxIcon sx={{ m: '4px' }} />
                              )
                            }
                            disabled
                          />
                        )}
                        {!row.isProjectKoc &&
                          !row.isPotentialKoc &&
                          !isAllowSaveFilter && (
                            <Checkbox
                              checked={Object.prototype.hasOwnProperty.call(
                                { ...checkboxState },
                                row.idRow,
                              )}
                              onClick={(event: any) =>
                                handleSelectItem(event.target.checked, row)
                              }
                              disabled={isShowRejectedCustomers}
                            />
                          )}
                      </TableCell>
                    )}
                    {columns
                      .filter(
                        (column) =>
                          columnsDisplay[column.accessor] ||
                          (isShowRejectedCustomers &&
                            column.accessor === 'reason'),
                      )
                      .map((column, idx) => {
                        return (
                          <TableCell
                            sx={
                              (idx === 0 && {
                                whiteSpace: 'nowrap',
                                position: 'sticky',
                                left: !isInDetail ? 78 : 0,
                                backgroundColor: '#fbfbfc',
                                minWidth: !isInDetail ? 80 : 150,
                                textAlign: !isInDetail ? 'center' : 'left',
                              }) ||
                              (idx === 1 && {
                                position: 'sticky',
                                left: !isInDetail ? 160 : 0,
                                backgroundColor: '#fbfbfc',
                                minWidth: 150,
                              }) ||
                              (idx === 2 && {
                                whiteSpace: 'nowrap',
                                position: 'sticky',
                                left: !isInDetail ? 357 : 332,
                                backgroundColor: '#fbfbfc',
                                minWidth: !isInDetail ? 182 : 150,
                                textAlign: !isInDetail ? 'left' : 'center',
                              }) ||
                              (idx === 3 && {
                                whiteSpace: 'break-spaces',
                                position: !isInDetail ? 'sticky' : 'static',
                                left: !isInDetail ? 507 : 698,
                                backgroundColor: !isInDetail
                                  ? '#fbfbfc'
                                  : 'transparent',
                                minWidth: !isInDetail ? 150 : 200,
                                textAlign: 'center',
                                maxWidth: 300,
                                wordWrap: 'break-word',
                              }) || {
                                minWidth: 200,
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                              }
                            }
                            key={`${row.id + column.accessor}-body`}
                          >
                            {renderCell(
                              column.accessor,
                              row[column.accessor],
                              row,
                            )}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}
      >
        <Box>
          Show
          <Select
            sx={{ ml: 2 }}
            value={rowPerpage}
            onChange={handleChange}
            size="small"
          >
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
          </Select>
        </Box>
        <Box>
          <IconButton
            onClick={onPrevClick}
            color="primary"
            size="small"
            sx={{ p: 0, pt: 2, pb: 2, mr: 2 }}
            disabled={
              !curPage ||
              (uniqueData !== null && uniqueData < parseInt(rowPerpage, 10))
            }
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              onNextClick(groupSelected?.id ? resultDiscoveryGroup : result)
            }
            color="primary"
            size="small"
            sx={{ p: 0, pt: 2, pb: 2 }}
            disabled={
              groupSelected?.id
                ? !resultDiscoveryGroup?.pagination?.hasMorePages
                : !result?.pagination?.hasMorePages
            }
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </Box>
      </Box>
      <Permission
        permissions={['project.koc_discovery.discovery_group.add_to_discovery']}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            marginTop: 2,
            marginLeft: '-16px',
          }}
        >
          {checkboxState &&
            Object.keys(checkboxState).length > 0 &&
            !isShowRejectedCustomers && (
              <>
                <Button
                  variant="text"
                  onClick={() => setCheckboxState({})}
                  type="button"
                  sx={{ mr: 1 }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isRequesting}
                  onClick={() => savePotentialKOC()}
                >
                  <BookmarkIcon />
                  Add to Discovery list ({Object.keys(checkboxState).length})
                </LoadingButton>
              </>
            )}
        </Box>
      </Permission>
      <ConfirmDialog
        open={isOpenConfirmDialog}
        message="Are you sure you want to remove this profile from Discovery List?"
        onConfirm={unSelectPotentialKOC}
        onCancel={handleCancel}
      />
      {isOpen && selectedPost && (
        <KOCAudiencePostDialog
          open={isOpen}
          post={selectedPost}
          pagePhoto={selectedPost?.page?.url || ''}
          pageName={selectedPost?.page?.name || ''}
          onClose={() => {
            setSelectedPost(null);
            setIsOpen(false);
          }}
        />
      )}
      {addTagCustomer && (
        <AddTagDialog
          isOpen={!!addTagCustomer}
          onClose={() => {
            setAddTagCustomer(undefined);
          }}
          onSuccess={onActionSuccess}
          customer={addTagCustomer}
        />
      )}
      {rejectCustomer && (
        <RejectDialog
          isOpen={!!rejectCustomer}
          onClose={() => {
            setRejectCustomer(undefined);
          }}
          onSuccess={() => {
            handleSelectItem(false, rejectCustomer);
            onActionSuccess();
          }}
          customer={rejectCustomer}
          groupSelected={groupSelected!}
        />
      )}
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={profileActions?.anchorEl}
        open={openProfileActions}
        onClose={handleCloseProfileActions}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        {profileActions?.actions &&
          profileActions.actions.map((action) => {
            return (
              <MenuItem
                key={action.text}
                onClick={action.onClick}
                color={action.color}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {action.icon}
                &nbsp; {action.text}
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  borderBox: {
    border: '1px solid',
    borderColor: theme.palette.grey[400],
  },
}));

export default Customers;
