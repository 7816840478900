import { useAuth0 } from '@auth0/auth0-react';
import { requester } from 'requests';
import { IRequestBodyContentAddMoreFile } from 'types/koc';

const useAddMoreContentFile = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function uploadFile(requestBody: IRequestBodyContentAddMoreFile) {
    const medias = requestBody?.medias?.map((item) => {
      return { file_path: item?.filePath, title: item?.title, description: item?.description };
    });
    return requester({
      method: 'post',
      path: `/admin/projects/${requestBody.idProject}/koc/official-customers/${requestBody.idKocCustomer}/contents/${requestBody.id}/medias`,
      data: {
        medias,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useAddMoreContentFile;
