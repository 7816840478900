export interface IDataImportCreatedBy {
  id: string;
  name: string;
  email: string;
}
export enum DataImportStatus {
  PENDING = 'PENDING',
  IMPORTING = 'IMPORTING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
}
export interface IDataImport {
  id: string;
  fileName: string;
  status: DataImportStatus;
  totalRecord: number;
  failedRecord: number;
  importedRecord: number;
  error: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: IDataImportCreatedBy;
  originFileUrl: string;
  processedFileUrl?: string;
}
