import { IProject } from 'types/project';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';

import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  id: string;
}

interface Result extends IRequestHookResult<IProject> {}

export const useLoadProjectDetails: IRequestHook<Props, Result> = ({ id, stopWhen }) => {
  const path = `/admin/projects/${id}`;

  const { data: response, error, isValidating, mutate } = useCustomSWR(path, { stopWhen });

  const project = response ? (response.data as IProject) : undefined;

  return {
    result: project,
    error,
    isLoading: !error && !response,
    isValidating,
    mutate,
  };
};

export default useLoadProjectDetails;
