import qs from 'qs';
import { IKOCAudiencePost } from 'types/koc';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import { IListResponse, IMetaPagination } from 'types/response';
import { IFilter } from 'pages/KOCNurturing/KOCDiscovery/components/FilterMenu';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  idPage: string;
  pageSize?: number;
  pageIndex?: number;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  searchKeyword?: string;
  createdTime?: string;
  fromCreatedTime?: string;
  toCreatedTime?: string;
  refreshInterval?: number;
  reactions?: IFilter;
  comments?: IFilter;
  shares?: IFilter;
  status?: string;
}

interface Result
  extends IRequestHookResult<{
    data: IKOCAudiencePost[];
    pagination: IMetaPagination;
  }> {}

export const useLoadPostList: IRequestHook<Props, Result> = ({
  idProject,
  idPage,
  pageSize = 10,
  pageIndex = 0,
  searchKeyword,
  createdTime,
  fromCreatedTime,
  toCreatedTime,
  reactions,
  comments,
  shares,
  status,
  refreshInterval = 0,
  stopWhen,
}) => {
  const queryString = qs.stringify({
    limit: pageSize,
    page: pageIndex + 1,
    keyword: searchKeyword,
    created_time: createdTime,
    from_created_time: fromCreatedTime,
    to_created_time: toCreatedTime,
    to_reaction: reactions?.to,
    from_reaction: reactions?.from,
    to_comment: comments?.to,
    from_comment: comments?.from,
    to_share: shares?.to,
    from_share: shares?.from,
    status,
  });
  const path = `/admin/projects/${idProject}/koc/social-pages/${idPage}/posts?${queryString}`;
  const {
    data: response,
    error,
    mutate,
    isValidating,
  } = useCustomSWR(path, { stopWhen, refreshInterval });
  let outputData;
  if (response) {
    const responseData = response.data as IListResponse;
    outputData = {
      data: responseData.data,
      pagination: responseData.meta?.pagination,
    };
  }

  return {
    mutate,
    result: outputData,
    error,
    isValidating,
    isLoading: !error && !response && !stopWhen,
  };
};

export default useLoadPostList;
