import { useAuth0 } from '@auth0/auth0-react';
import { IProjectReport } from 'types/project';
import { requester } from '../index';

interface DataType {
  clientReport?: Array<IProjectReport>;
  adminReport?: Array<IProjectReport>;
}

export const useEditProjectReports = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function editProjectReports(idProject: string, data: DataType) {
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}/reports`,
      data: {
        client_report: data.clientReport,
        admin_report: data.adminReport,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IProjectReport>(response.data));
  };
};

export default useEditProjectReports;
