import { useAuth0 } from '@auth0/auth0-react';
import { IReason } from 'types/reason';
import { requester } from '../index';

export const useUpdateReasons = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function updateReasons(
    reasons: { name: string; id?: number }[],
  ) {
    return requester({
      method: 'post',
      path: `/admin/reasons`,
      data: reasons,
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IReason>(response.data));
  };
};

export default useUpdateReasons;
