import { useAuth0 } from '@auth0/auth0-react';
import qs from 'qs';
import { useCallback, useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { fetcher } from 'requests';
import { IRequestHookParams } from 'types/request';
import { ICursorListResponse } from 'types/response';
import { IProductPhoto } from 'types/product';

interface Props extends IRequestHookParams {
  idProject: string;
  idProduct: string;
  notAssigned?: boolean;
  type?: string;
  pageSize: number;
}

export const useLoadProductPhotoList = ({
  idProject,
  idProduct,
  notAssigned,
  type,
  pageSize,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();

  const customFetcher = useCallback(
    (url: string) =>
      fetcher(getAccessTokenSilently)(url).then(
        (response) => response.data as ICursorListResponse,
      ),
    [getAccessTokenSilently],
  );

  const getKey = useCallback(
    (pageIndex: number, previousPageData: any) => {
      if (previousPageData && !previousPageData.meta.pagination.hasMorePages)
        return null;

      const { nextCursor } =
        (previousPageData as ICursorListResponse)?.meta?.pagination || {};
      const queryString = qs.stringify({
        limit: pageSize,
        cursor: nextCursor,
        ...(notAssigned ? { not_assigned: true } : {}),
        ...(type ? { type } : {}),
      });

      return `/admin/projects/${idProject}/products/${idProduct}/photos?${queryString}`;
    },
    [idProject, idProduct, type, notAssigned, pageSize],
  );

  const {
    data: pages,
    error,
    size,
    setSize,
    isValidating,
    mutate,
  } = useSWRInfinite(getKey, customFetcher);

  const isLoadingInitialData = !pages && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && !!pages && typeof pages[size - 1] === 'undefined');
  const hasMore = !!pages && pages[size - 1]?.meta.pagination.hasMorePages;
  const isRefreshing = isValidating && pages && pages.length === size;
  const result = useMemo(() => {
    const pagesResult = getResult(pages);
    return pagesResult;
  }, [pages]);
  const loadMore = useCallback(() => setSize((s) => s + 1), [setSize]);

  return {
    result,
    loadMore,
    isLoadingMore,
    error,
    hasMore,
    isRefreshing,
    mutate,
  };
};

const getResult = (pages?: ICursorListResponse<IProductPhoto>[]) => {
  if (!pages || pages.length <= 0) {
    return [];
  }

  const result = pages.reduce<IProductPhoto[]>((acc, page) => {
    const histories = page.data;
    return [acc, histories].flat();
  }, []);

  return result;
};

export default useLoadProductPhotoList;
