import qs from 'qs';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import { IKOCListOverview } from 'types/koc';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  metric: KOCListOverviewMetric;
}

interface Result extends IRequestHookResult<IKOCListOverview[] | number> {}

export enum KOCListOverviewMetric {
  avgLeadTime = 'avg_lead_time',
  groupByStatus = 'group_by_status',
  groupByScheme = 'group_by_scheme',
  groupByProduct = 'group_by_product',
  groupByUseCase = 'group_by_use_case',
}

export const useLoadKOCListOverview: IRequestHook<Props, Result> = ({
  stopWhen,
  idProject,
  metric,
}) => {
  const queryString = qs.stringify({
    metric,
  });
  const path = `/admin/projects/${idProject}/koc/official-customers/overview?${queryString}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const result = response
    ? (response.data as IKOCListOverview[] | number)
    : undefined;

  return {
    mutate,
    result,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadKOCListOverview;
