import qs from 'qs';
import { IKOCCustomer } from 'types/customer';
import { IMetaCursorPagination, ICursorListResponse } from 'types/response';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import useCustomSWR from 'requests/useCustomSWR';
import { IFilter } from 'pages/KOCNurturing/KOCDiscovery/components/FilterMenu';

interface Props extends IRequestHookParams {
  idProject: string;
  pageSize: number;
  cursor: string;
  cities: number[];
  friends: string[];
  follows: string[];
  genders: string[];
  ages: string[];
  relations: string[];
  uids: string[];
  phones: string[];
  dTypeAudiences?: string[];
  firstAudiences?: string[];
  pages?: string[];
  posts?: string[];
  cdpCities?: number[];
  cdpGenders?: string[];
  cdpAges?: string[];
  cdpRecencies?: string[];
  cdpFrequencies?: string[];
  cdpMonetaries?: string[];
  kocCategories?: number[];
  totalPost?: IFilter;
  totalEngagement?: IFilter;
  totalReaction?: IFilter;
  totalComment?: IFilter;
  totalShare?: IFilter;
  postedTime?: string;
  fromPostedTime?: string;
  toPostedTime?: string;
  averageEngagement?: IFilter;
  hometowns: number[];
}

interface Result
  extends IRequestHookResult<{
    data: IKOCCustomer[];
    pagination: IMetaCursorPagination;
  }> {}

export const useLoadKOCCustomerList: IRequestHook<Props, Result> = ({
  idProject,
  pageSize,
  cursor,
  cities,
  friends,
  follows,
  genders,
  ages,
  relations,
  uids,
  phones,
  dTypeAudiences,
  firstAudiences,
  pages,
  posts,
  cdpCities,
  cdpGenders,
  cdpAges,
  cdpRecencies,
  cdpFrequencies,
  cdpMonetaries,
  kocCategories,
  totalPost,
  totalEngagement,
  totalReaction,
  totalComment,
  totalShare,
  postedTime,
  fromPostedTime,
  toPostedTime,
  averageEngagement,
  hometowns,
  stopWhen,
}) => {
  const queryString = qs.stringify({
    limit: pageSize,
    uids,
    phones,
    cursor,
    cities,
    friends,
    follows,
    genders,
    ages,
    relations,
    d_type_audiences: dTypeAudiences,
    first_audiences: firstAudiences,
    n_type_pages: pages,
    n_type_posts: posts,
    cdp_cities: cdpCities,
    cdp_genders: cdpGenders,
    cdp_ages: cdpAges,
    cdp_recencies: cdpRecencies,
    cdp_frequencies: cdpFrequencies,
    cdp_monetaries: cdpMonetaries,
    koc_categories: kocCategories,
    from_total_post: totalPost?.from,
    to_total_post: totalPost?.to,
    from_total_engagement: totalEngagement?.from,
    to_total_engagement: totalEngagement?.to,
    from_total_reaction: totalReaction?.from,
    to_total_reaction: totalReaction?.to,
    from_total_comment: totalComment?.from,
    to_total_comment: totalComment?.to,
    from_total_share: totalShare?.from,
    to_total_share: totalShare?.to,
    posted_time: postedTime,
    from_posted_time: fromPostedTime,
    to_posted_time: toPostedTime,
    from_average_engagement: averageEngagement?.from,
    to_average_engagement: averageEngagement?.to,
    hometowns,
  });
  const path = `/admin/projects/${idProject}/koc/customers?${queryString}`;
  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  let result;

  if (response) {
    const responseData = response.data as ICursorListResponse;
    result = {
      data: responseData.data,
      pagination: responseData.meta.pagination,
      error,
    };
  }

  return {
    result,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadKOCCustomerList;
