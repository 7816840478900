import { useAuth0 } from '@auth0/auth0-react';
import { IProject, IProjectTimelineEvent, ITeamMember } from 'types/project';
import { requester } from '../index';

interface DataType {
  name: string;
  notes?: string;
  idBrand: string;
  image?: string;
  activities?: string;
  timeline?: Array<IProjectTimelineEvent>;
  clientCustomerSegments?: string;
  adminCustomerSegments?: string;
  team?: Array<ITeamMember>;
  media: string;
  enableRefreshPerformance?: boolean;
  prefixHashtag?: string;
  showMixedContent?: number;
  platformMixContent?: string;
  brandInfo?: string;
  campaignInfo?: string;
  campaignPhotoTmp?: string;
}

export const useEditProject = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function editProject(idProject: string, data: DataType) {
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}`,
      data: {
        name: data.name,
        notes: data.notes,
        id_brand: data.idBrand,
        image: data.image,
        activities: data.activities,
        timeline: data.timeline,
        client_customer_segments: data.clientCustomerSegments,
        admin_customer_segments: data.adminCustomerSegments,
        team: data.team,
        media: data.media,
        enable_refresh_performance: data.enableRefreshPerformance,
        ...(data.prefixHashtag ? { prefix_hashtag: data.prefixHashtag } : {}),
        show_mixed_content: data.showMixedContent ? 1 : 0,
        ...(data.platformMixContent
          ? {
              platform_mix_content: data.showMixedContent
                ? data.platformMixContent
                : '',
            }
          : {}),
        brand_info: data.brandInfo,
        campaign_info: data.campaignInfo,
        ...(data.campaignPhotoTmp
          ? { campaign_photo_tmp: data.campaignPhotoTmp }
          : {}
        ),
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IProject>(response.data));
  };
};

export default useEditProject;
