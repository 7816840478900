import { useMemo, useState } from 'react';
import {
  ICreatedTimeOption,
  getOption,
} from 'components/CustomDateRangeFilter';

const useDateRangeFilter = () => {
  const filterOptions: ICreatedTimeOption[] = useMemo(() => {
    return [
      getOption(7, 'days'),
      getOption(30, 'days'),
      getOption(3, 'months'),
      getOption(6, 'months'),
    ];
  }, []);
  const [filter, setFilter] = useState<ICreatedTimeOption | undefined>(
    filterOptions[0],
  );
  const filterTime = useMemo(() => {
    if (!filter) {
      return {};
    }
    if (filterOptions.some(({ id }) => id === filter.id)) {
      return { time: filter.id };
    }
    const from = filter.from ? new Date(filter.from).toISOString() : undefined;
    const to = filter.to ? new Date(filter.to).toISOString() : undefined;

    return { from, to };
  }, [filter, filterOptions]);

  return { filterTime, filter, setFilter, filterOptions };
};

export default useDateRangeFilter;
