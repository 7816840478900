import { CircularProgress, Box } from '@mui/material';
import { FC } from 'react';

interface IProp {
  sx?: any
}
export const CircularLoading: FC<IProp> = ({sx}) => {
  return (
    <Box sx={{ display: 'flex', py: 2, justifyContent: 'center' , ...sx}}>
      <CircularProgress />
    </Box>
  );
};
