import { IProjectDashboardInfo } from 'types/project';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';

import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  id: string;
  refreshInterval: number;
}

interface Result extends IRequestHookResult<IProjectDashboardInfo> {}

export const useLoadProjectDashboardInfo: IRequestHook<Props, Result> = ({
  id,
  stopWhen,
  refreshInterval,
}) => {
  const path = `/admin/projects/${id}/dashboard`;

  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useCustomSWR(path, { stopWhen, refreshInterval });

  return {
    result: response?.data || {
      totalEstimatedAudienceSize: 0,
      totalOfficialKoc: 0,
      totalPotentialKoc: 0,
    },
    error,
    isLoading: !error && !response,
    isValidating,
    mutate,
  };
};

export default useLoadProjectDashboardInfo;
