import { useAuth0 } from '@auth0/auth0-react';
import { IKOCAudience } from 'types/koc';
import { requester } from '../index';

export const useChangeParentKOCNTypePost = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function changeParentKOCNTypePost(
    idProject: string,
    ids: string[],
    selectedAudienceId: string | null,
  ) {
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}/koc/social-posts/change-parent`,
      data: {
        ids,
        id_parent: selectedAudienceId,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IKOCAudience>(response.data));
  };
};

export default useChangeParentKOCNTypePost;
