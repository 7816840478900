import { IBrief } from 'types/file';
import { IRequestHook, IRequestHookParams, IRequestHookResult } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  keyword: string;
}

interface Result extends IRequestHookResult<IBrief[]> {}

export const useLoadBriefsByKeyword: IRequestHook<Props, Result> = ({
  idProject,
  keyword,
  stopWhen,
}) => {
  const path = `/admin/projects/${idProject}/briefs/auto-complete?q=${keyword}`;

  const { data: response, error } = useCustomSWR(path, {
    stopWhen,
  });

  let outputData;
  if (response) {
    outputData = response.data;
  }

  return {
    result: outputData,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadBriefsByKeyword;
