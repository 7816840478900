import { useAuth0 } from '@auth0/auth0-react';
import { IScheme } from 'types/scheme';
import { requester } from '../index';
import { IDiscoveryGroup } from '../../types/discovery-group';

interface DataType {
  ids: string[];
  idProject: string;
  discoveryGroup?: IDiscoveryGroup;
}

export const useAddKOCPotential = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function createScheme(data: DataType) {
    return requester({
      method: 'post',
      path: `/admin/projects/${data.idProject}/koc/potential-customers`,
      data: {
        ids: data.ids,
        discovery_group: data.discoveryGroup,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IScheme>(response.data));
  };
};

export default useAddKOCPotential;
