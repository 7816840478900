import React, { FC, ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogProps,
  DialogActions,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface IConfirmDialogProps extends DialogProps {
  title?: string;
  message?: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  disableBtn?: boolean;
  hasLoadingButton?: boolean;
  isRequesting?: boolean;
  confirmLabelButton?: string;
  cancelLabelButton?: string;
}

const ConfirmDialog: FC<IConfirmDialogProps> = ({
  title,
  message,
  disableBtn,
  hasLoadingButton,
  isRequesting,
  confirmLabelButton,
  cancelLabelButton,
  onConfirm,
  onCancel,
  ...props
}) => {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...props}
      onClose={(_, reason) => {
        if (onCancel && reason === 'escapeKeyDown') {
          setTimeout(() => {
            onCancel();
          }, 300);
        }
      }}
    >
      {title && (
        <Typography
          component={DialogTitle}
          variant="h4"
          id="alert-dialog-title"
        >
          {title}
        </Typography>
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button onClick={onCancel} color="secondary" disabled={disableBtn}>
            {cancelLabelButton || 'Cancel'}
          </Button>
        )}
        {onConfirm && !hasLoadingButton && (
          <Button
            variant="contained"
            onClick={onConfirm}
            disabled={disableBtn}
            autoFocus
          >
            {confirmLabelButton || 'Confirm'}
          </Button>
        )}
        {onConfirm && hasLoadingButton && (
          <LoadingButton
            variant="contained"
            color="neutral"
            onClick={onConfirm}
            loading={isRequesting}
          >
            {confirmLabelButton || 'Confirm'}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
