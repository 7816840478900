import { useAuth0 } from '@auth0/auth0-react';
import { IKOCAudience } from 'types/koc';
import { requester } from '../index';

export const useCreateUseCase = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function createUseCase(idProject: string, usecases: string[]) {
    return requester({
      method: 'post',
      path: `/admin/projects/${idProject}/use-cases`,
      data: {
        names: usecases,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IKOCAudience>(response.data));
  };
};

export default useCreateUseCase;
