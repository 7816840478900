import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export const useCurrentBreakpoint = (): Breakpoint => {
  const theme = useTheme();
  const result: { key: Breakpoint; value: boolean }[] = [];
  result.push({
    value: useMediaQuery(theme.breakpoints.only('xs')),
    key: 'xs',
  });
  result.push({
    value: useMediaQuery(theme.breakpoints.only('sm')),
    key: 'sm',
  });
  result.push({
    value: useMediaQuery(theme.breakpoints.only('md')),
    key: 'md',
  });
  result.push({
    value: useMediaQuery(theme.breakpoints.only('lg')),
    key: 'lg',
  });
  result.push({
    value: useMediaQuery(theme.breakpoints.only('xl')),
    key: 'xl',
  });

  const currentSize = result.find(({ value }) => value);
  return currentSize?.key || 'xs';
};

export const useIsBreakpoint = (
  breakpoint: Breakpoint,
  calc: 'up' | 'down' | 'only' = 'only',
): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints[calc](breakpoint));
};
