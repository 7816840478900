import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

interface DataType {
  idProject: string;
  idKocCustomer: string;
  note: string;
}

export const useUpdateKOCNote = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function updateNote(data: DataType) {
    return requester({
      method: 'put',
      path: `/admin/projects/${data.idProject}/koc/official-customers/${data.idKocCustomer}/note`,
      data: {
        note: data.note,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useUpdateKOCNote;
