import { useAuth0 } from '@auth0/auth0-react';
import qs from 'qs';
import { fetcher } from 'requests';

const useExportCustomerList = () => {
  const { getAccessTokenSilently } = useAuth0();
  return function fetchCsvData(idProject: string, idDiscovery: number) {
    const queryString = qs.stringify({ id_discovery: idDiscovery });

    return fetcher(getAccessTokenSilently)(
      `/admin/projects/${idProject}/koc/customers/export?${queryString}`,
      {
        responseType: 'blob',
        accept: 'text/csv; charset=utf-8',
        contentType: 'text/csv; charset=utf-8',
      },
    );
  };
};

export default useExportCustomerList;
