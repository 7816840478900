import {
  IRequestHook,
  IRequestHookParams,
  IRequestHookResult,
} from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Result extends IRequestHookResult<any> {}

export const useLoadNumberUnreadNotifications: IRequestHook<
  IRequestHookParams,
  Result
> = ({ stopWhen }) => {
  const path = `/admin/notifications/unread/count`;

  const {
    data: response,
    error,
    mutate,
  } = useCustomSWR(path, {
    stopWhen,
  });

  return {
    mutate,
    result: response?.data,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadNumberUnreadNotifications;
