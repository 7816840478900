/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Link,
  Skeleton,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import { formatDateTime } from 'utils/common';
import { designThemeColors } from 'theme/schemes/PurpleLightTheme';
import Chip from 'components/CustomChip';
import Permission, { checkIsAllow } from 'components/Permission/Permission';
import { NTypeStatus } from 'types/koc';
import { useLoadDiscoveryGroupOverview } from 'requests/koc';
import { useModalsContext } from '../../../contexts/modalsContext';
import PageTitle from '../../../components/PageTitle';
import AccordionCard from '../../../components/AccordionCard';
import { useLoadSavedFilter } from '../../../requests/audiences';
import useDeleteSavedFilter from '../../../requests/audiences/useDeleteSavedFilter';
import { IDiscoveryGroup } from '../../../types/discovery-group';
import { usePermissionContext } from '../../../contexts/permissionContext';
import Customers from './components/Customers';
import ExportDialog from './components/ExportDialog';

const ProjectDiscoveryGroup: FC = () => {
  const { idProject } = useParams();
  const { showConfirm } = useModalsContext();
  const deleteSavedFilter = useDeleteSavedFilter();
  const { enqueueSnackbar } = useSnackbar();
  const [isFirstTimes, setIsFirstTimes] = useState<boolean>(true);
  const { permissions } = usePermissionContext();
  const canDeleteGroup = checkIsAllow(permissions, [
    'project.koc_discovery.discovery_group.delete',
  ]);
  const [openExportDataDialog, setOpenExportDataDialog] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState<number>(0);
  const [needWatchGroupStatus, setNeedWatchGroupStatus] =
    useState<boolean>(false);

  const [groupSelected, setGroupSelected] = useState<
    IDiscoveryGroup | undefined
  >(undefined);
  const { result: savedFilters, mutate: loadGroups } = useLoadSavedFilter({
    idProject,
    refreshInterval,
  });
  const {
    result: discoveryGroupOverview,
    mutate: reloadOverview,
    isLoading,
  } = useLoadDiscoveryGroupOverview({
    stopWhen: !groupSelected || !idProject,
    idProject,
    id: `${groupSelected?.id}`,
  });
  const [isShowRejectedCustomers, setIsShowRejectedCustomers] = useState(false);

  useEffect(() => {
    if (savedFilters?.length && !groupSelected && isFirstTimes) {
      const defaultGroup = savedFilters.find((item) => item.isDefault);
      if (defaultGroup) {
        setGroupSelected(defaultGroup);
      }
      setIsFirstTimes(false);
    }
    if (savedFilters?.length && groupSelected) {
      const group = savedFilters.find((item) => item.id === groupSelected.id);
      if (['PENDING', 'PROCESSING'].includes(group?.status)) {
        setNeedWatchGroupStatus(true);
        setGroupSelected(group);
      } else if (needWatchGroupStatus) {
        setNeedWatchGroupStatus(false);
        setGroupSelected(group);
      }
    }
    if (
      savedFilters?.length > 0 &&
      savedFilters.find(
        (item) => item.status === 'PENDING' || item.status === 'PROCESSING',
      )
    ) {
      setRefreshInterval(3000);
    } else {
      setRefreshInterval(0);
    }
  }, [savedFilters]);
  const handleDeleteDiscoveryGroup = (id: number) => {
    showConfirm({
      title: 'Confirm',
      message: 'Are you sure you want to delete this group?',
      labelConfirm: 'Confirm',
      buttonConfirmProps: { variant: 'contained' },
      onConfirm: () => confirmDeleteDiscoveryGroup(id),
    });
  };
  const confirmDeleteDiscoveryGroup = (id: number) => {
    deleteSavedFilter(idProject, id)
      .then(() => {
        enqueueSnackbar(`Delete successfully`, {
          variant: 'success',
        });
        loadGroups();
        setGroupSelected(undefined);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(`Something went wrong`, {
          variant: 'error',
        });
      });
  };

  const getGroupColor = (groupInfo: IDiscoveryGroup) => {
    if (groupInfo.id === groupSelected?.id) {
      return 'primary';
    }
    if (groupInfo.status === 'PENDING' || groupInfo.status === 'PROCESSING') {
      return 'warning';
    }
    if (groupInfo.status === 'PROCESSED_FAIL') {
      return 'error';
    }
    if (groupInfo.status !== 'PENDING' && groupInfo.status !== 'PROCESSING') {
      return 'default';
    }
    return 'default';
  };

  return (
    <>
      <Helmet>
        <title>Discovery Groups</title>
      </Helmet>
      <Box p={4}>
        <PageTitle
          title="Discovery Groups"
          actionButtons={
            <Permission
              permissions={['project.koc_discovery.discovery_group.export']}
            >
              <Button
                variant="contained"
                startIcon={<SaveAltIcon />}
                onClick={() => {
                  setOpenExportDataDialog(true);
                }}
              >
                Export
              </Button>
            </Permission>
          }
        />
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Link
            underline="none"
            color="grey.700"
            variant="subtitle2"
            sx={{ ':hover': { cursor: 'default' } }}
          >
            KOC Discovery
          </Link>
          <Link
            variant="subtitle2"
            underline="none"
            color="text.primary"
            sx={{ ':hover': { cursor: 'default' } }}
          >
            Discovery Groups
          </Link>
        </Breadcrumbs>
        <Box width="100%" pb={3}>
          <Permission
            permissions={[
              'project.koc_discovery.discovery_group.view_custom_view',
            ]}
          >
            <AccordionCard
              title="DISCOVERY GROUPS"
              expanded
              sx={{
                borderColor: (theme: Theme) => theme.palette.grey[400],
                width: '100%',
              }}
            >
              {savedFilters?.length === 0 && (
                <Box sx={{ mt: 1 }}>No discovery group to show</Box>
              )}
              <Stack
                direction="row"
                spacing={1}
                flexWrap="wrap"
                justifyContent="start"
                alignItems="center"
              >
                {savedFilters
                  ?.sort((a, b) => b.isDefault - a.isDefault)
                  .map((filter: any) =>
                    canDeleteGroup ? (
                      <Chip
                        key={filter.id}
                        label={
                          filter.status === 'PROCESSED'
                            ? filter.name
                            : `${filter.name}(${NTypeStatus[filter.status]})`
                        }
                        color={getGroupColor(filter)}
                        onClick={() =>
                          setGroupSelected(
                            groupSelected && filter.id === groupSelected?.id
                              ? null
                              : filter,
                          )
                        }
                        sx={{
                          margin: '5px !important',
                          cursor: 'pointer',
                          '& .MuiChip-deleteIcon': {
                            color: '#333',
                          },
                        }}
                        onDelete={() => handleDeleteDiscoveryGroup(filter.id)}
                      />
                    ) : (
                      <Chip
                        key={filter.id}
                        label={
                          filter.status === 'PROCESSED'
                            ? filter.name
                            : `${filter.name}(${NTypeStatus[filter.status]})`
                        }
                        color={getGroupColor(filter)}
                        onClick={() =>
                          setGroupSelected(
                            groupSelected && filter.id === groupSelected?.id
                              ? null
                              : filter,
                          )
                        }
                      />
                    ),
                  )}
              </Stack>
            </AccordionCard>
          </Permission>
        </Box>

        <Box width="100%" pb={3}>
          <AccordionCard
            title={`OVERVIEW ${
              groupSelected ? `FOR ${groupSelected.name.toUpperCase()}` : ''
            }`}
            expanded
            sx={{
              borderColor: (theme: Theme) => theme.palette.grey[400],
              width: '100%',
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              flexWrap="wrap"
              justifyContent="start"
              alignItems="center"
            >
              {!groupSelected && (
                <Typography sx={{ mt: 1 }}>
                  No discovery group to show
                </Typography>
              )}
              {groupSelected && isLoading && (
                <Box width="100%">
                  <Skeleton
                    variant="text"
                    sx={{ width: '100%', fontSize: '2rem' }}
                  />
                  <Skeleton
                    variant="text"
                    sx={{ width: '100%', fontSize: '2rem' }}
                  />
                </Box>
              )}
              {discoveryGroupOverview && (
                <Box
                  display="flex"
                  sx={{
                    '& > div > *': {
                      display: 'flex',
                      alignItems: 'start',
                    },
                    '& > div > * > svg': {
                      mr: 1,
                    },
                  }}
                >
                  <Box mr={10}>
                    <Typography>
                      <PeopleAltOutlinedIcon />
                      <Box>
                        {`${discoveryGroupOverview.totalUniqueProfile.toLocaleString()} total unique profiles`}
                        <Typography
                          variant="caption2"
                          color={designThemeColors.neutral[90]}
                        >
                          {`* includes ${discoveryGroupOverview.totalActivated.toLocaleString()} activated KOCs`}
                        </Typography>
                      </Box>
                    </Typography>
                    <Typography mt={2}>
                      <PersonOutlineOutlinedIcon />
                      <Box>
                        {`${discoveryGroupOverview.totalActivated.toLocaleString()} activated KOCs`}
                        <Typography
                          variant="caption2"
                          color={designThemeColors.neutral[90]}
                        >
                          * Profiles not displayed below. Refer to KOC List for
                          details
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                  <Box mr={10}>
                    <Typography whiteSpace="nowrap">
                      <LocalOfferOutlinedIcon />
                      <Box>
                        {`${discoveryGroupOverview.totalTaggedProfile.toLocaleString()} tagged profiles`}
                        <Typography variant="caption2" color="white">
                          hidden text
                        </Typography>
                      </Box>
                    </Typography>
                    <Typography mt={2} whiteSpace="nowrap">
                      <HourglassEmptyOutlinedIcon />{' '}
                      {`${discoveryGroupOverview.totalRemainingProfile.toLocaleString()} remaining profiles`}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <PersonRemoveAlt1OutlinedIcon />
                      <Box display="flex" flexDirection="column">
                        <Typography
                          component={Link}
                          sx={{ '&:hover': { cursor: 'pointer' } }}
                          onClick={() => {
                            setIsShowRejectedCustomers(true);
                          }}
                        >
                          {`${discoveryGroupOverview.totalRejectedProfile.toLocaleString()} rejected profiles`}
                        </Typography>
                        <Typography
                          variant="caption2"
                          color={designThemeColors.neutral[90]}
                        >
                          {`* Number of rejected profiles before the latest update (${
                            groupSelected?.updatedAt
                              ? formatDateTime(groupSelected.updatedAt)
                              : ''
                          }) on Discovery group: `}
                          <b>
                            {groupSelected?.oldTotalRejected?.toLocaleString() ||
                              '--'}
                          </b>
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Stack>
          </AccordionCard>
        </Box>

        <Box width="100%" pb={3}>
          <Permission
            permissions={[
              'project.koc_discovery.discovery_group.view_custom_view',
            ]}
          >
            <Box>
              <Card sx={{ marginBottom: 2 }}>
                <Customers
                  groupSelected={groupSelected}
                  onChangeDiscoveryGroup={(data) => {
                    loadGroups();
                    setGroupSelected(data);
                  }}
                  isShowRejectedCustomers={isShowRejectedCustomers}
                  setIsShowRejectedCustomers={setIsShowRejectedCustomers}
                  reloadOverview={reloadOverview}
                  discoveryGroupOverview={discoveryGroupOverview}
                />
              </Card>
            </Box>
          </Permission>
        </Box>
        <ExportDialog
          open={openExportDataDialog}
          groupSelected={groupSelected}
          handleClose={() => {
            setOpenExportDataDialog(false);
          }}
        />
      </Box>
    </>
  );
};

export default ProjectDiscoveryGroup;
