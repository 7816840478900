import {
  Accordion,
  AccordionProps,
  AccordionDetailsProps,
  AccordionSummary,
  Typography,
  AccordionDetails,
  AccordionActions,
  AccordionActionsProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface AccordionCardProps extends AccordionProps {
  childrenSummary?: ReactNode;
  detailsProps?: AccordionDetailsProps;
  actionsProps?: AccordionActionsProps;
  actionButtons?: any;
  title?: string;
  titleVariant?: any;
}
const AccordionCard = ({
  title,
  titleVariant = 'label',
  children,
  childrenSummary,
  detailsProps = {},
  actionsProps = {},
  actionButtons,
  ...accordionProps
}: AccordionCardProps) => {
  const classes = useStyles();
  return (
    <Accordion disableGutters {...accordionProps}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="primary" />}
        className={classes.accordionSummary}
      >
        {title && <Typography variant={titleVariant}>{title}</Typography>}
        {childrenSummary}
      </AccordionSummary>
      <AccordionDetails {...detailsProps} className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
      {actionButtons && (
        <AccordionActions {...actionsProps}>{actionButtons}</AccordionActions>
      )}
    </Accordion>
  );
};

const useStyles = makeStyles({
  accordionDetails: {
    borderTop: '1px solid rgba(34, 51, 84, 0.1)',
    width: '100%',
  },
  accordionSummary: {
    width: '100%',
  },
});

export default AccordionCard;
