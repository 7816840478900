/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { usePermissionContext } from 'contexts/permissionContext';
import { checkIsAllow } from 'components/Permission/Permission';

type IProps = {
  requirePermissions?: string[] | [];
  requireRoles?: any[];
};

const RoleRoute: FC<IProps> = ({ children, requirePermissions = [], requireRoles = [] }) => {
  const { permissions, role } = usePermissionContext();
  useEffect(() => {
    if (
      Array.isArray(permissions) &&
      permissions.length > 0 &&
      Array.isArray(requirePermissions) &&
      requirePermissions.length > 0
    ) {
      if (!checkIsAllow(permissions, requirePermissions)) {
        redirectTo403();
      }
    }
  }, [permissions]);

  useEffect(() => {
    if (
      role !== undefined &&
      requireRoles &&
      Array.isArray(requireRoles) &&
      requireRoles.length > 0 &&
      !requireRoles.includes(role)
    ) {
      redirectTo403();
    }
  }, [role]);

  const redirectTo403 = () => {
    window.location.href = '/403';
  };

  return <>{children}</>;
};

export default RoleRoute;
