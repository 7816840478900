import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { themeCreator } from './base';

interface IContextState {
  (themeName: string): void;
}

export const ThemeContext = React.createContext<IContextState>(() => {});

const ThemeProviderWrapper: React.FC = (props) => {
  const curThemeName = localStorage.getItem('appTheme') || 'PurpleLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  useEffect(() => {
    if (theme?.components?.MuiAutocomplete?.defaultProps?.ChipProps) {
      theme.components.MuiAutocomplete.defaultProps.ChipProps.deleteIcon = (
        <HighlightOffIcon />
      );
    }
    if (theme?.components?.MuiChip?.defaultProps) {
      theme.components.MuiChip.defaultProps.deleteIcon = <HighlightOffIcon />;
    }
  }, [theme]);
  const setThemeName = (newThemeName: string): void => {
    localStorage.setItem('appTheme', newThemeName);
    _setThemeName(newThemeName);
  };

  return (
    <StylesProvider injectFirst>
      {/* <CacheProvider value={cacheRtl}> */}
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
      {/* </CacheProvider> */}
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
