import {
  Button,
  ButtonProps,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';

interface Props extends ButtonProps {
  isIconButton: boolean;
  name: string;
  component?: any;
  href?: string;
  target?: string;
  activeClassName?: string;
  to?: string;
  end?: any;
}

const MenuButton: React.FC<Props> = ({ isIconButton, name, ...props }) => {
  return !isIconButton ? (
    <Button {...props}>
      <Typography variant="label">{name}</Typography>
    </Button>
  ) : (
    <Tooltip placement="right" title={name} arrow>
      <IconButton {...props}>{props.startIcon}</IconButton>
    </Tooltip>
  );
};

export default MenuButton;
