import { IKOCAudiencePost } from 'types/koc';
import {
  IRequestHook,
  IRequestHookParams,
  IRequestHookResult,
} from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  socialPostId: string;
}

interface Result extends IRequestHookResult<IKOCAudiencePost> {}

export const useLoadNTypePostDetails: IRequestHook<Props, Result> = ({
  idProject,
  socialPostId,
  stopWhen,
}) => {
  const path = `/admin/projects/${idProject}/koc/social-posts/${socialPostId}`;

  const {
    data: response,
    error,
    isValidating,
  } = useCustomSWR(path, { stopWhen });

  const result = response ? (response.data as IKOCAudiencePost) : undefined;

  return {
    result,
    error,
    isLoading: !error && !response,
    isValidating,
  };
};

export default useLoadNTypePostDetails;
