import { FC } from 'react';
import { usePermissionContext } from 'contexts/permissionContext';
import { useLoadUserProfile } from 'requests/profile';

interface IProps {
  createdById?: string | undefined;
  permissions?: any[];
  role?: string;
  children?: any;
  replacement?: JSX.Element;
}

export const checkIsAllow = (
  grantedPermissions: any[] = [],
  permissions: any[] = [],
  isOwn?
): boolean => {
  let isAllow: boolean = false;
  if (Array.isArray(grantedPermissions) && Array.isArray(permissions)) {
    for (let i = 0; i < permissions.length; i += 1) {
      for (let j = 0; j < grantedPermissions.length; j += 1) {
        if (
          typeof grantedPermissions[j] === 'object' &&
          grantedPermissions[j]?.id === permissions[i]
        ) {
          // Check permission with extras filter
          if (
            grantedPermissions[j]?.extras &&
            grantedPermissions[j]?.extras.filter === 'own' &&
            isOwn !== undefined
          ) {
            if (isOwn) {
              isAllow = true;
            } else {
              isAllow = false;
            }
          } else {
            isAllow = true;
          }
          break;
        }
      }
      if (isAllow === true) {
        break;
      }
    }
  }
  return isAllow;
};

export const checkIsOwn = (
  userProfile: any,
  createdById: string | undefined
): boolean | undefined => {
  let isOwn: boolean | undefined;
  if (createdById !== undefined && userProfile?.id) {
    if (userProfile?.id === createdById) {
      isOwn = true;
    } else {
      isOwn = false;
    }
  }
  return isOwn;
};

const Permission: FC<IProps> = ({ permissions, children, role, replacement, createdById }) => {
  const { permissions: p = [], role: r } = usePermissionContext();
  const { result: res } = useLoadUserProfile({});
  let isAllow: boolean = false;
  if (Array.isArray(p) && Array.isArray(permissions)) {
    isAllow = checkIsAllow(p, permissions, checkIsOwn(res, createdById));
  } else {
    // allow view incase not permission config
    isAllow = true;
  }
  if (role !== undefined) {
    isAllow = r === role;
  }

  if (!isAllow && replacement) {
    return replacement;
  }

  return isAllow ? <>{children}</> : <></>;
};

export default Permission;
