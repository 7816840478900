import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { useLoadSavedFilter } from 'requests/audiences';
import useExportCustomerList from 'requests/koc/useExportCustomerList';
import { downloadFile } from 'requests/koc/useDownloadFile';
import { IDiscoveryGroup } from 'types/discovery-group';
import { useLoadProjectDetails } from 'requests/projects';
import { getErrorMessage } from 'utils/common';

interface ExportDialogProps {
  open: boolean;
  handleClose: () => void;
  groupSelected?: IDiscoveryGroup | null;
}

const ExportDialog: FC<ExportDialogProps> = ({
  open,
  handleClose,
  groupSelected = null,
}) => {
  const { idProject } = useParams();
  const [selectedGroup, setSelectedGroup] = useState<IDiscoveryGroup | null>(
    groupSelected,
  );
  const { result: savedFilters, isLoading } = useLoadSavedFilter({ idProject });
  const { result: projectDetail } = useLoadProjectDetails({ id: idProject });
  const [isExporting, setIsExporting] = useState(false);

  const exportCustomerList = useExportCustomerList();

  useEffect(() => {
    if (selectedGroup?.id !== groupSelected?.id) {
      setSelectedGroup(groupSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, groupSelected]);

  const onClickExport = () => {
    if (selectedGroup) {
      setIsExporting(true);
      exportCustomerList(idProject, selectedGroup.id)
        .then((result: any) => {
          const lastModified = result.headers?.lastModified || '';

          const fileName = `[${projectDetail?.name}] ${
            selectedGroup.name
          }.${format(
            lastModified
              ? new Date(lastModified.toLocaleString())
              : new Date().getTime(),
            'dd-MM-yyy',
          )}.csv`;
          downloadFile(result.data, fileName);
        })
        .then(() => {
          setIsExporting(false);
          setSelectedGroup(null);
          handleClose();
        })
        .catch((error) => {
          setIsExporting(false);
          enqueueSnackbar(getErrorMessage(error, 'Something went wrong'), {
            variant: 'error',
          });
        });
    } else {
      console.error('No selected group yet');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <Typography variant="h2" gutterBottom>
          Export data
        </Typography>
        <Typography variant="body2" sx={{ color: '#404357' }} gutterBottom>
          Select discovery groups you want to export
        </Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography style={{ fontWeight: 700 }} mb={0.5}>
          Discovery Group
        </Typography>

        <Autocomplete
          fullWidth
          value={selectedGroup}
          onChange={(_, newValue) => setSelectedGroup(newValue)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                size="small"
                placeholder="Select a discovery group to export"
              />
            );
          }}
          getOptionLabel={(option) => option.name}
          loading={isLoading}
          selectOnFocus
          options={savedFilters}
          popupIcon={<ExpandMoreTwoToneIcon sx={{ fill: '#1A1A1A' }} />}
        />
      </DialogContent>

      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button variant="text" color="neutral" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          onClick={onClickExport}
          variant="contained"
          color="neutral"
          disabled={!selectedGroup}
          loading={isExporting}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
