import { FC } from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { CustomOption } from 'components/CustomAutocomplete';
import { IKOCAudience } from 'types/koc';
import CustomChip from 'components/CustomChip';
import { IDataFilter } from '../../../../types/discovery-group';
import { getLabelText } from '../utils';
import CustomAutocomplete from './CustomAutocomplete';

interface IProps {
  label: string;
  objKey: string;
  values: any[];
  options: any[];
  isLoading: boolean;
  audienceFilters: IDataFilter;
  limitTags?: number;
  filterChange: (conditions: IDataFilter) => void;
  setConditionsToFilter: (conditions: IDataFilter) => void;
  removeFilterField: (type: string) => void;
  disabled?: boolean;
}

const DFirstAudienceAutocomplete: FC<IProps> = ({
  label,
  objKey,
  values,
  options,
  isLoading,
  audienceFilters,
  filterChange,
  setConditionsToFilter,
  removeFilterField,
  disabled = false,
}) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="end" mb={1}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
      >
        <CustomAutocomplete
          disabled={disabled}
          label={label}
          objKey={objKey}
          values={values}
          limitTags={3}
          options={options}
          isLoading={isLoading}
          audienceFilters={audienceFilters}
          filterChange={(data: IDataFilter) => {
            filterChange(data);
            setConditionsToFilter(data);
          }}
          renderCustomOption={(props, option, { selected }) => (
            <CustomOption
              key={option.socialId}
              label={
                <Typography>
                  {option.name} (<b>{option.actualSize || 0}</b>)
                </Typography>
              }
              selected={selected}
              {...props}
            />
          )}
          renderCustomTags={(value: readonly IKOCAudience[], getTagProps) =>
            value.map((option: IKOCAudience, index) => (
              <CustomChip
                {...getTagProps({ index })}
                color="primary"
                label={getLabelText(
                  <>
                    {option.name}(<b>{option.actualSize || 0}</b>)
                  </>,
                  options.some(({ socialId }) => socialId === option.socialId),
                )}
              />
            ))
          }
        />
      </Box>
      <IconButton
        onClick={() => removeFilterField(objKey)}
        color="secondary"
        size="small"
        sx={{
          m: 0,
          ml: 1,
          p: 1,
          pr: 0,
          '&:hover': { background: 'white' },
        }}
      >
        <CloseTwoToneIcon color="error" />
      </IconButton>
    </Box>
  );
};

export default DFirstAudienceAutocomplete;
