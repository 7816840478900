import { Box, List, ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useContext, useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import Permission, { checkIsAllow } from 'components/Permission/Permission';
import {
  useLoadProjectDetails,
  useLoadProjectReports,
} from 'requests/projects';
import { usePermissionContext } from 'contexts/permissionContext';
import { SidebarContext } from 'contexts/SidebarContext';
import { MENU_ITEM } from 'constants/menu-items';
import SidebarMenuItem from './item';
import mainMenuItems, { createProjectMenuItems, MenuItem } from './items';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    margin-bottom: ${theme.spacing(0.5)};
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(11)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`,
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      margin: 0;
      border-radius: 8px;

      .MuiListItem-root {
        padding: 1px;
        padding-left: ${theme.spacing(0)};
        padding-right: ${theme.spacing(0)};

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(4)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(9)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          border-radius: 4px;
          justify-content: flex-start;
          font-size: 1rem;
          margin-bottom: ${theme.spacing(1.5)};
          padding: ${theme.spacing(1.5)};
          font-weight: 500;

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            margin-right: ${theme.spacing(2)};
            path {
              fill: ${theme.sidebar.menuItemIconColor};
            }
          }

          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              path {
                fill: ${theme.sidebar.menuItemIconColorActive};
              }
            }
          }

          &.Mui-active {
            &:after {
              width: 5px;
              opacity: 1;
            }
          }
        }

        .MuiIconButton-root {
          margin: 0 auto;
          margin-bottom: ${theme.spacing(1.5)};

          svg {
            width: 20px;
            height: 20px;
          }

          path {
            fill: ${theme.sidebar.menuItemIconColor};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            path {
              fill: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;

          & > .MuiButton-root {
            .MuiBadge-root {
              right: ${theme.spacing(7)};
            }
          }

          .MuiButton-root.Mui-active {
            &:after {
              opacity: 0;
            }
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            margin-top: ${theme.spacing(1)};
            margin-bottom: ${theme.spacing(1)};
            padding-left: ${theme.spacing(2.5)};
            border-left: 1px solid ${theme.colors.alpha.black[40]};
            border-radius: 0;
          }

          .MuiListItem-root {
            padding: 1px;
            padding-left: ${theme.spacing(0)};
            padding-right: ${theme.spacing(0)};

            .MuiButton-root {
              font-size: 1rem;
              padding: ${theme.spacing(1.5, 2)};
              font-weight: normal;

              &:before {
                content: none;
                background: ${theme.sidebar.menuItemIconColor};
                opacity: .8;
                transition: ${theme.transitions.create(['background'])};
                width: 6px;
                height: 6px;
                border-radius: 20px;
                margin-right: ${theme.spacing(2.9)};
              }

            }
          }
        }
      }
    }
`,
);

const SidebarMenuItems = ({
  items,
  path,
  query,
}: {
  items: MenuItem[];
  path: string;
  query: string;
}): JSX.Element => {
  const { isSidebarOpen } = useContext(SidebarContext);
  return (
    <SubMenuWrapper mx={isSidebarOpen ? '20px' : 0}>
      <List component="div">
        {items.reduce<JSX.Element[]>((ev, item) => {
          return reduceChildRoutes({ ev, item, path, query });
        }, [])}
      </List>
    </SubMenuWrapper>
  );
};

const reduceChildRoutes = ({
  ev,
  path,
  item,
  query,
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
  query: string;
}): Array<JSX.Element> => {
  const key = item.name;
  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true,
        },
        path + query,
      )
    : false;

  if (item.items) {
    let partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false,
          },
          path + query,
        )
      : item.items.some(
          ({ link: childLink }) =>
            !!childLink &&
            !!matchPath(
              {
                path: childLink,
                end: false,
              },
              path + query,
            ),
        );
    if (item.name === MENU_ITEM.GROW) {
      const realActive = item.items.filter(
        (children) => children.link === path + query,
      );
      partialMatch = realActive?.length > 0;
    }
    ev.push(
      <Permission role={item.role} permissions={item.permissions}>
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          link={item.link}
          isComingSoon={item.isComingSoon}
          fakeLink={item.fakeLink}
          isRenderFull={!!item.isRenderFull}
        >
          <SidebarMenuItems path={path} items={item.items} query={query} />
        </SidebarMenuItem>
      </Permission>,
    );
  } else {
    ev.push(
      <Permission role={item.role} permissions={item.permissions}>
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          name={item.name}
          link={item.link}
          isComingSoon={item.isComingSoon}
          icon={item.icon}
          fakeLink={item.fakeLink}
          isExternalLink={item.isExtenalLink}
          isRenderFull={!!item.isRenderFull}
        />
      </Permission>,
    );
  }

  return ev;
};

interface IProps {
  isProject?: boolean;
}

function SidebarMenu({ isProject }: IProps) {
  const location = useLocation();
  const { permissions: p = [] } = usePermissionContext();
  const { isSidebarOpen } = useContext(SidebarContext);
  let isAllow: boolean = false;
  if (Array.isArray(p) && Array.isArray(['project.report.view'])) {
    isAllow = checkIsAllow(p, ['project.report.view']);
  } else {
    // allow view incase not permission config
    isAllow = true;
  }

  const { idProject } = useParams();
  const { result: project } = useLoadProjectDetails({
    id: idProject,
    stopWhen: !idProject || !isProject,
  });
  const { result: projectReports } = useLoadProjectReports({
    id: idProject,
    stopWhen: !idProject || !isProject,
    isAllow,
  });
  const menuItems = useMemo(() => {
    return isProject
      ? createProjectMenuItems(idProject, project, projectReports)
      : mainMenuItems;
  }, [project, projectReports, idProject, isProject]);

  return (
    <>
      {menuItems.map((section, index) => {
        const key = String(index);

        return (
          <React.Fragment key={key}>
            <MenuWrapper key={`${section.heading}-wrapper`}>
              <List
                component="div"
                subheader={
                  isSidebarOpen &&
                  !isProject && (
                    <Permission permissions={section.permissions}>
                      <ListSubheader component="div" disableSticky>
                        {section.heading}
                      </ListSubheader>
                    </Permission>
                  )
                }
              >
                <SidebarMenuItems
                  path={location.pathname}
                  items={section.items}
                  query={location.search}
                />
              </List>
            </MenuWrapper>
          </React.Fragment>
        );
      })}
    </>
  );
}

export default SidebarMenu;
