import { FC, useState } from 'react';

import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';

interface IOption {
  name: string;
  key: string;
  group: string;
}

interface IProps {
  items: IOption[];
  itemsToFilter: string[];
  onDataChange: (values: string[]) => void;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(-90deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SelectField3RDDataTab: FC<IProps> = ({
  items,
  itemsToFilter,
  onDataChange,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    itemsToFilter || [],
  );
  const [isPopularExpand, setIsPopularExpand] = useState<boolean>(true);
  const [isOthersExpand, setIsOthersExpand] = useState<boolean>(true);

  const onChange = (checked: boolean, element: IOption) => {
    const index = selectedValues.findIndex(
      (item: string) => item === element.key,
    );

    let resultValues = [...selectedValues];
    if (index === -1 && checked) {
      resultValues = [...resultValues, element.key];
    }
    if (index > -1 && !checked) {
      resultValues.splice(index, 1);
    }
    setSelectedValues([...resultValues]);
    onDataChange([...resultValues]);
  };

  const isGroupChecked = (groupKey: string) => {
    if (
      items
        ?.filter((item) => item.group === groupKey)
        ?.every((item) => selectedValues.find((el) => el === item.key))
    ) {
      return true;
    }
    if (
      items
        ?.filter((item) => item.group === groupKey)
        ?.some((item) => selectedValues.find((el) => el === item.key))
    ) {
      return null;
    }
    return false;
  };

  const handleGroupChange = (groupKey: string, checked: boolean) => {
    let resultValues = [...selectedValues];
    items
      ?.filter((item) => item.group === groupKey)
      ?.forEach((item) => {
        const index = resultValues.findIndex(
          (field: string) => field === item.key,
        );
        if (index === -1 && checked) {
          resultValues = [...resultValues, item.key];
        }
        if (index > -1 && !checked) {
          resultValues.splice(index, 1);
        }
      });
    setSelectedValues([...resultValues]);
    onDataChange([...resultValues]);
  };

  const renderSection = (groupKey: string) => {
    const expandValue =
      groupKey === 'Popular' ? isPopularExpand : isOthersExpand;
    return (
      <>
        <Box alignItems="center" display="flex" width={240}>
          <ExpandMore
            sx={{ m: 0, p: 0 }}
            expand={expandValue}
            onClick={
              groupKey === 'Popular'
                ? () => {
                    setIsPopularExpand(!expandValue);
                  }
                : () => {
                    setIsOthersExpand(!expandValue);
                  }
            }
            aria-expanded={expandValue}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Checkbox
            indeterminate={isGroupChecked(groupKey) === null}
            checked={Boolean(isGroupChecked(groupKey))}
            onChange={(e: any) => {
              handleGroupChange(groupKey, e?.target?.checked);
            }}
          />
          <Typography sx={{ color: '#20202099' }}>{groupKey}</Typography>
        </Box>
        <Collapse in={expandValue} timeout="auto" unmountOnExit>
          {items
            .filter((item) => item.group === groupKey)
            .map((element) => (
              <Box key={element.key} ml={6} display="flex" alignItems="center">
                <Checkbox
                  checked={selectedValues.includes(element.key)}
                  onClick={(event: any) =>
                    onChange(event.target?.checked, element)
                  }
                />
                <Typography component="span" sx={{ color: '#20202099' }}>
                  {element.name}
                </Typography>
              </Box>
            ))}
        </Collapse>
      </>
    );
  };

  return (
    <Box mt={2}>
      {renderSection('Popular')}
      {renderSection('Others')}
    </Box>
  );
};

export default SelectField3RDDataTab;
