import IFile from 'types/file';
import { ICustomerAudience } from 'types/customer';
import { IProject, IRequestBodyAsset, IUser } from './project';
import { IDiscoveryGroup } from './discovery-group';
import { ICity, IDistrict, IWard } from './audience';
import { IProduct } from './product';
import { IActiveScheme } from './scheme';

export interface IKOCCategory {
  id: number;
  name: string;
  inputValue?: string;
}

export interface ICategory {
  createdAt: string;
  id: number;
  laravelThroughKey: string;
  name: string;
  updatedAt: string;
}

export enum AudienceStatus {
  PENDING = 0,
  PROCESSING = 1,
  PROCESSED = 2,
  IMPORTING = 3,
}

export interface IKOCAudiencePostProcess {
  active: number;
  crawlProcessedAt: string;
  crawlRequestAt: string;
  createdAt: string;
  error: string;
  idModel: string;
  idQueue?: number;
  processName: string;
  retriedAt: null;
  retryTimes: number;
  status: string;
  totalFailed: number;
  totalProcessedProfiles: number;
  totalProfiles: number;
  updatedAt: string;
  nextRefreshTime?: string;
}
export interface IKOCAudiencePost {
  id: string;
  caption: string;
  url: string;
  status: string;
  totalUniqueProfiles: number;
  totalReactions: number;
  totalComments: number;
  totalShares: number;
  crawlProcessedAt?: string;
  createdAt?: string;
  hashtags?: string[];
  keywords?: string[];
  crawlRequestTime?: string;
  process: IKOCAudiencePostProcess[];
  updatedAt: string;
  postImg: string;
  totalProcessedProfiles: number;
  error?: string;
  idParent?: string;
  idPage: string;
  pageInfo?: IKOCAudiencePostPageInfo;
  totalUniqueProfilesReaction: number;
  totalUniqueProfilesComment: number;
  totalUniqueProfilesShare: number;
  totalProfilesReaction: number;
  totalProfilesComment: number;
  totalProfilesShare: number;
  page?: IKOCAudiencePageGroup;
}

export interface IModel {
  id: string;
  name: string;
  url: string;
  platform: string;
  status: string;
  totalPosts: number | null;
  totalProcessedPosts: number | null;
  totalFollows: number | null;
  totalLikes: number | null;
  pagePhoto: string;
  createdAt: string;
  crawlProcessedAt: string;
  // eslint-disable-next-line camelcase
  engagement_30Days: number | null;
  engagement: number | null;
  totalProcessedProfiles: number | null;
  totalProfiles: number | null;
  totalUniqueProfiles: number | null;
  crawlRequestTime: string;
  posts: IKOCAudiencePost[];
  error: string;
}

export interface IPostModel {
  id: string;
  url: string;
  status: string;
  totalComments: number | null;
  totalProcessedProfiles: number | null;
  totalReactions: number | null;
  totalShares: number | null;
  totalUniqueProfiles: number | null;
  createdAt: string;
  crawlProcessedAt: string;
  crawlRequestTime: string;
  caption: string;
  error: any;
  hashtags: string[];
  keywords: string[];
  process: any;
  reaction: number | null;
}

export interface IKOCAudience {
  socialId: string;
  id: string;
  name: string;
  type: string;
  size: number;
  isDraft: boolean;
  isNew: boolean;
  status: string;
  actualSize: number;
  filePath?: string;
  category?: ICategory;
  group?: string;
  crawlRequestTime?: string;
  source?: string;
  url?: string;
  platform?: string;
  totalPosts?: number;
  totalFollows?: number;
  totalLikes?: number;
  pagePhoto?: string;
  totalProcessedPosts?: number;
  totalUniqueProfiles?: number;
  crawlProcessedAt?: string;
  posts?: IKOCAudiencePost[];
  engagement?: number;
  // eslint-disable-next-line camelcase
  engagement_30Days?: number;
  totalProcessedUsers?: number;
  totalEngagement?: number;
  idModel?: string;
  model?: IModel | IKOCAudiencePost;
  totalProcessedProfiles: number;
  import?: IImport;
}

export interface IImport {
  totalRecord: number;
  failedRecord: number;
  importedRecord: number;
  status: string;
  error: any;
  createdAt: string;
}

export interface IKOCAudiencePageGroup {
  id: string;
  name: string;
  url: string;
  platform: string;
  status: string;
  totalPosts: number;
  totalProcessedPosts: number;
  totalFollows: number;
  totalLikes: number;
  totalUniqueProfiles: number;
  pagePhoto: string;
  createdAt: string;
  crawlProcessedAt: string;
  // eslint-disable-next-line camelcase
  engagement_30Days: number | string;
  engagement: number;
  type: string;
  totalProcessedProfiles: number;
  error: string;
  crawlRequestTime: string;
  posts: IKOCAudiencePost[];
  import?: IImport;
}

export interface IKOCCustomer {
  id: string;
  phone: string;
  name: string;
  facebookUid: string;
  instagramUid: string;
  tiktokUid: string;
  youtubeUid: string;
  email: string;
  idCity: number | null;
  friends: number | null;
  facebookFollow: number | null;
  instagramFollow: number | null;
  tiktokFollow: number | null;
  youtubeFollow: number | null;
  birthday: string;
  sex: string;
  idRelationship: number | null;
  createdAt: string;
  updatedAt: string;
  age: string;
  recommendedPlatforms?: string[];
  professional?: string;
  socialViews?: string;
  contentOverview?: string;
  formats?: any;
  cost?: number | null;
  socialInteractions?: string;
  discoveryGroup?: IDiscoveryGroup;
  averageEngagement?: number;
  totalPost?: number;
  totalEngagement?: number;
  lastPostedTime?: string;
  hometown?: number;
  totalReaction?: number;
  totalComment?: number;
  totalShare?: number;
  product?: { id: number; name: string };
  useCase?: { id: number; name: string };
  other?: string;
}

export interface IPotentialAudience {
  socialId: string;
  name: string;
}

export interface IOffer {
  id: string;
  label: string;
  status: string;
  updatedAt: string;
  offset: number;
  reason: string;
  idProduct: number;
  idProjectKocScheme: number;
}

export interface IOfferDetails {
  id: string;
  title: string;
  project: IProject;
  schemeValue: string;
  schemeDescription: string;
  schemeName: string;
  schemeType: string;
  schemeTypeName: string;
  product: IProduct;
  productName: string;
  productDescription: string;
  projectKocScheme: IActiveScheme;
  updatedAt: string;
  status: string;
  reason: string;
}

export interface IDeliveryInfo {
  address?: string;
  name?: string;
  note?: string;
  phone?: string;
}

export interface ILatestAgreement {
  id: string;
  label: string;
  status: string;
  updatedAt: string;
  offset: number;
  reason: string;
  deliveryInfo: IDeliveryInfo;
}

export interface IBaseKoc {
  idProject: string | number;
  idKocCustomer: string;
  isOfficial: boolean;
  idKocScheme: number | null;
  nameKocScheme: string;
  valueKocScheme: number | null;
  typeKocScheme: string;
  noteKocScheme: string;
  idKocCategories: number[];
  idAudiences: string[];
  note: string;
  formats?: string[] | any;
  cost?: number | null;
  createdAt: string;
  updatedAt: string;
  dTypeAudiences: ICustomerAudience[];
  firstAudiences: ICustomerAudience[];
  pages: IKOCAudiencePageGroup[];
  posts: IKOCAudiencePost[];
  kocCategories: IKOCCategory[];
  kocCustomer: IKOCCustomer;
  contactStage?: string;
  contactStatus?: string;
  contactChannel?: string;
  contactRequestDate?: string;
  contactUpdatedAt?: string;
  contactDeclineReason?: string;
  statusDuration: string;
  projectKocScheme?: IProjectKocScheme;
  audiences?: IPotentialAudience[];
  anotherProjects?: AnotherProject[];
  deliveryIdWard?: number;
  deliveryWard?: IWard;
  deliveryIdDistrict?: number;
  deliveryDistrict?: IDistrict;
  deliveryIdCity?: number;
  deliveryCity?: ICity;
  deliveryNote?: string;
  deliveryStreet?: string;
  deliveryPhone?: string;
  deliveryName?: string;
}

export interface IPotentialKoc extends IBaseKoc {
  professional?: string;
  birthday?: Date | null;
  recommendedPlatforms?: any;
  socialViews?: string;
  contentOverview?: string;
  socialInteractions?: string;
  latestOffer?: IOffer;
  needSync?: boolean;
  latestAgreement?: ILatestAgreement;
  contactStatusReason?: 'no_agreement' | 'many_agreements';
}

export interface IAnotherKoc {
  idKocCustomer: string;
  isProjectKoc: boolean;
  kocCustomer: IKOCCustomer;
  kocCategories: IKOCCategory[];
  audiences: IPotentialAudience[];
  cost?: number | null;
  formats?: any;
}
export type AnotherProject = Pick<
  IProject,
  'id' | 'name' | 'image' | 'imageUrl'
>;
export enum KOCDeliveryStage {
  NEW = 'New',
  PRODUCT_DELIVERY = 'Product Delivery',
  CONTENT_PUBLISHING = 'Content Publishing',
}

export enum KOCDeliveryStatus {
  NEW = 'New',
  ORDER_PLACED = 'Order Placed',
  OUT_OF_DELIVERY = 'Out for Delivery',
  DELIVERED = 'Delivered',
}

export enum KOCPublishedContentStatus {
  NEW_CONTENT = 'New',
  PUBLISHED_CONTENT = 'Published',
}

export interface IOfficialKoc extends IBaseKoc {
  isActive: boolean;
  deliveryQty?: number;
  deliveryIdProduct?: number;
  deliveryProduct?: IProduct;
  deliveryDate: string;
  deliveryStage: string;
  deliveryStatus: string;
  idBrand: number;
  deliveryStatusDuration?: string;
  deliveryLeadTime?: string;
  latestContent: {
    id: string;
    label: string;
  };
  kocNote?: string;
}

export interface ITopContentItem {
  id: string;
  idKocCustomer: string;
  idProject: string;
  imageUrl: string;
  platform: string;
  postContent: string;
  postLink: string;
  refReUp: boolean;
  refReUserForAd: boolean;
  refContentCredit: boolean;
  status: ContentStatus;
  createdAt: string;
  updatedAt: string;
  airedDate: string;
  comment: number;
  reaction: number;
  view: number;
  engagement?: number;
  interaction?: number;
  fileType: 'VIDEO' | 'IMAGE';
  kocCustomer: {
    email: string;
    name: string;
  };
}

export interface IContentItem {
  id: string;
  idKocCustomer: string;
  idProject: string;
  imageUrl: string;
  platform: string;
  postContent: string;
  refReUp: boolean;
  refReUserForAd: boolean;
  refContentCredit: boolean;
  status: ContentStatus;
  createdAt: string;
  updatedAt: string;
  airedDate: string;
  comment: number;
  reaction: number;
  view: number;
  fileType: 'VIDEO' | 'IMAGE';
  label: string;
}

export interface IKOCRankValue {
  rank1: any;
  rank2: any;
  rank3: any;
  rank4: any;
  rank5: any;
}

export interface IKOCRankType {
  createdAt: string;
  updatedAt: string;
  status: string;
  data: IKOCRankValue;
}

export interface IKOCRank {
  frequency: IKOCRankType;
  monetary: IKOCRankType;
  recency: IKOCRankType;
}
export interface IAnotherPotentialKoc {
  idKocCustomer: string;
  isProjectPotentialKoc: boolean;
  isProjectKoc: boolean;
  kocCustomer: IKOCCustomer;
  kocCategories: IKOCCategory[];
  audiences: IPotentialAudience[];
  formats: string[];
  cost: number | null;
}
export interface IPhoto {
  createdAt: string;
  id: number;
  idProduct: number;
  title: string;
  updatedAt: string;
  tyle: string;
  url: string;
}
export interface IMixContent {
  assignedTo: string;
  content: string;
  createdAt: string;
  hashtag: string;
  id: number;
  idImportMixContent: number;
  idProduct: number;
  idProject: number;
  mixDate: string;
  partner: string;
  pic: string;
  photos?: IPhoto[];
}
export interface IContent {
  id: string;
  idKocCustomer: string;
  idProject: string;
  platform: string;
  postContent: string;
  postLink: string;
  postPermanentLink: string;
  status: ContentStatus;
  createdAt: string;
  updatedAt: string;
  airedDate: string;
  medias: IFile[];
  editPostContentHistories: IPostContentHistory[];
  refReUp: boolean;
  refReUserForAd: boolean;
  refContentCredit: boolean;
  comment: number;
  reaction: number;
  view: number;
  share: number;
  refreshStatus?: 'PROCESSING' | 'FAIL' | 'DONE';
  project?: IProject;
  refreshedAt?: string;
  label: string;
  mixContent?: IMixContent;
  author?: IAuthor;
}

export interface IRequestBodyContentAddMoreFile {
  idProject: string;
  idKocCustomer: string;
  id: string;
  medias: IRequestBodyAsset[];
}

export interface IPostContentHistory {
  id: string;
  description: string;
  event: string;
  createdAt: string;
  params: IParamsPostContent;
}

export interface IParamsPostContent {
  user: IUser;
  asset: IParamsAssetPostContent;
}

export interface IParamsAssetPostContent {
  id: string;
  postContent: string;
}

export interface ITopKOCItem {
  id: string;
  idKocCustomer: string;
  interaction: number;
  engagement: number;
  totalContent: number;
  rank: number;
  kocCustomer: {
    id: string;
    phone: string;
    name: string;
    professional: string;
    facebookUid: string;
    instagramUid: string;
    tiktokUid: string;
    youtubeUid: string;
  };
  projectKocCustomer: {
    createdAt: string;
  };
  totalClick?: number;
  totalLink?: number;
}

export interface IKOCAudiencePostPageInfo {
  id: string;
  name: string;
}

export enum ContentStatus {
  New = 'New',
  Published = 'Published',
}

export enum OriginStatus {
  New = 'NEW',
  Published = 'AIRED',
}

export enum NTypeStatus {
  PENDING = 'Pending',
  NEW = 'New',
  PROCESSING = 'Processing',
  PROCESSED = 'Processed',
  PROCESSED_FAIL = 'Processed Fail',
  IMPORTING = 'Importing',
}

export enum PotentialKOCStatus {
  NEW = 'New',
  WAITING_RESPONSE = 'Waiting for response',
  ACCEPTED = 'Accepted',
  INVALID_PHONE = 'Invalid phone number',
  UNANSWERED = 'Unanswered',
  DECLINED = 'Declined',
}

export enum PotentialKOCOfferStatus {
  OFFER_SENT = 'Offer Sent',
  OFFER_VIEWED = 'Viewed',
  OFFER_REJECTED = 'Rejected',
  OFFER_APPROVED = 'Accepted',
}

export enum PotentialKOCAgreementStatus {
  AGREEMENT_SENT = 'Agreement Sent',
  AGREEMENT_VIEWED = 'Viewed',
  AGREEMENT_REJECTED = 'Rejected',
  AGREEMENT_APPROVED = 'Accepted',
  AGREEMENT_FAILED_TO_SENT = 'Failed to send'
}

export enum PotentialKOCStage {
  NEW = 'New',
  OFFER_DISCUSSION = 'Offer Discussion',
  AGREEMENT = "Agreement",
  FRIEND_REQUEST = 'Friend Request',
  INITIAL_CONTACT = 'Initial Contact',
  '2ND_CONTACT' = '2nd Contact',
  '3RD_CONTACT' = '3rd Contact',
  '4TH_CONTACT' = '4th Contact',
}
export interface IDiscoveryListOverview {
  afterInitialContact?: number;
  after2ndContact?: number;
  after3rdContact?: number;
  after4thContact?: number;
  initialContact?: number;
  secondContact?: number;
  thirdContact?: number;
  fourthContact?: number;
  // legit
  total?: number;
  new?: number;
  invalid?: number;
  unanswered?: number;
  waiting?: number;
  accepted?: number;
  declined?: number;
  '2nd'?: number;
  '3rd'?: number;
  '4th'?: number;
  offerSent: number;
  offerApproved: number;
  offerRejected: number;
  offerViewed: number;
  friendRequest?: number;
  initial?: number;
  // eslint-disable-next-line camelcase
  source_1st?: number;
  // eslint-disable-next-line camelcase
  source_3rd?: number;
}

export interface IKOCListOverview {
  deliveryStatus?: string;
  nameKocScheme?: string;
  name?: string;
  total: number;
}

export interface IAuthor {
  createdAt: string;
  id: number;
  name: string;
  platform: string;
  type: string;
  uid: string;
  updatedAt: string;
}

export interface IProjectKocScheme {
  description: string;
  id: number;
  name: string;
  note: number;
  type: string;
  value: number;
}

export interface IRecruitmentRecord {
  id: string;
  type: 'offer' | 'agreement';
  label: string;
  status: string;
  updatedAt: string;
  reason: string;
  product: string;
  scheme: string;
}