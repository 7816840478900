import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

interface DataType {
  idProject: string;
  idKocCustomer: string;
  id: string;
}

export const useDeletePublicLinkKOCContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function deletePublicLink(props: DataType) {
    const apiPath = `/admin/projects/${props.idProject}/koc/official-customers/${props.idKocCustomer}/contents/${props.id}/public-link`;

    return requester({
      method: 'delete',
      path: apiPath,
      getToken: getAccessTokenSilently,
    }).then((response) => {
      Promise.resolve<any>(response.data);
      return response?.data;
    });
  };
};

export default useDeletePublicLinkKOCContent;
