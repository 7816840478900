import React, { FC, MouseEvent } from 'react';
import {
  Box,
  Dialog as MuiDialog,
  IconButton,
  Typography,
  DialogProps,
  Divider,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Breakpoint } from '@mui/system';

interface IDialogProps extends DialogProps {
  title?: string;
  subtitle?: string;
  hasClose?: boolean;
  titleComponent?: any;
  customTitle?: React.ReactNode;
  maxWidth?: Breakpoint | false;
}

const Dialog: FC<IDialogProps> = ({
  title,
  customTitle,
  subtitle,
  hasClose = true,
  children,
  titleComponent,
  maxWidth = "sm",
  ...props
}) => {
  const { onClose } = props;
  return (
    <MuiDialog fullWidth maxWidth={maxWidth} {...props}>
      <Box
        display="flex"
        px={2.5}
        pt={2}
        pb={subtitle ? 0 : 2}
        alignItems="center"
        justifyContent="space-between"
      >
        {customTitle ?? <Typography variant={titleComponent || 'title1'}>{title}</Typography>}
        {hasClose && (
          <IconButton
            color="neutral"
            sx={{ padding: 0.5 }}
            onClick={(event: MouseEvent) => {
              if (onClose) {
                onClose(event, 'escapeKeyDown');
              }
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        )}
      </Box>
      {subtitle && (
        <Typography variant="caption2" pb={2} px={2.5}>
          {subtitle}
        </Typography>
      )}
      <Divider sx={{ background: 'colors.alpha.black[30]' }} />
      {children}
    </MuiDialog>
  );
};

export default Dialog;
export * from './DialogActions';
