import { useAuth0 } from '@auth0/auth0-react';
import { IRequestBodyAsset } from 'types/project';
import { requester } from '../index';

interface DataType {
  postLink?: string;
  airedDate?: string;
  postContent?: string;
  assetArray?: IRequestBodyAsset[];
  refReUp: boolean;
  refReUserForAd: boolean;
  refContentCredit: boolean;
  comment: number;
  reaction: number;
  view: number;
  share: number;
}

export const useEditKOCContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function editKOCContent(
    idProject: string,
    idKocCustomer: string,
    id: string,
    data: DataType,
  ) {
    const medias = data.assetArray?.map((item) => {
      return {
        file_path: item.filePath,
        title: item.title,
        description: item.description,
        id: item.id,
      };
    });
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}/koc/official-customers/${idKocCustomer}/contents/${id}`,
      data: {
        ...(data.postLink ? { post_link: data.postLink } : {}),
        ...(data.airedDate ? { aired_date: data.airedDate } : {}),
        ...(data.postContent ? { post_content: data.postContent } : {}),
        ...(data.assetArray ? { medias } : {}),
        ref_re_up: data.refReUp,
        ref_re_user_for_ad: data.refReUserForAd,
        ref_content_credit: data.refContentCredit,
        comment: data.comment
          ? parseInt(data.comment.toString() || '0', 10)
          : null,
        reaction: data.reaction
          ? parseInt(data.reaction.toString() || '0', 10)
          : null,
        view: data.view ? parseInt(data.view.toString() || '0', 10) : null,
        share: data.share ? parseInt(data.share.toString() || '0', 10) : null,
      },
      getToken: getAccessTokenSilently,
    });
  };
};

export default useEditKOCContent;
