import { Chip, ChipProps, Theme, darken } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { FC, forwardRef } from 'react';
import { designThemeColors } from 'theme/schemes/PurpleLightTheme';

enum Color {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}
const chipColors: {
  [key in Color]: SxProps<Theme>;
} = {
  default: {
    color: designThemeColors.neutral[90],
    backgroundColor: designThemeColors.neutral[20],
    '&:hover': {
      backgroundColor: designThemeColors.neutral[30],
    },
  },
  primary: {
    color: designThemeColors.primary.main,
    backgroundColor: designThemeColors.primary.background,
    '&:hover': {
      backgroundColor: darken(designThemeColors.primary.background, 0.05),
    },
  },
  secondary: {
    color: designThemeColors.neutral[90],
    backgroundColor: designThemeColors.neutral[20],
    '&:hover': {
      backgroundColor: designThemeColors.neutral[30],
    },
  },
  info: {
    color: designThemeColors.info.main,
    backgroundColor: designThemeColors.info.background,
    '&:hover': {
      backgroundColor: darken(designThemeColors.info.background, 0.05),
    },
  },
  warning: {
    color: designThemeColors.warning.main,
    backgroundColor: designThemeColors.warning.background,
    '&:hover': {
      backgroundColor: darken(designThemeColors.warning.background, 0.05),
    },
  },
  success: {
    color: designThemeColors.success.main,
    backgroundColor: designThemeColors.success.background,
    '&:hover': {
      backgroundColor: darken(designThemeColors.success.background, 0.05),
    },
  },
  error: {
    color: designThemeColors.danger.main,
    backgroundColor: designThemeColors.danger.background,
    '&:hover': {
      backgroundColor: darken(designThemeColors.danger.background, 0.05),
    },
  },
};

const CustomChip: FC<ChipProps> = forwardRef((props, ref) => {
  const { color, sx } = props;
  return (
    <Chip {...props} sx={{ ...sx, ...chipColors[color as Color] }} ref={ref} />
  );
});

export default CustomChip;
