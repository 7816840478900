import { useAuth0 } from '@auth0/auth0-react';
import { IKOCAudience } from 'types/koc';
import { requester } from '../index';

interface DataType {
  id: string;
  source: string;
}

export const useDeleteKOCAudience = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function createAudience(idProject: string, data: DataType) {
    const {source, id} = data;
    let url  = `/admin/projects/${idProject}/koc/audiences/remove`
    if(source === "N_TYPE_PAGE" || source === "N_TYPE_GROUP") {
      url  = `/admin/projects/${idProject}/koc/social-pages/${id}`;
    } else if (source === "N_TYPE_POST") {
      url  = `/admin/projects/${idProject}/koc/social-posts/${id}`;
    }

    return requester({
      method: 'delete',
      path: url,
      data: {
        id: data.id,
        source: data.source,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IKOCAudience>(response.data));
  };
};

export default useDeleteKOCAudience;