import { IProject } from 'types/project';
import { IRequestHook, IRequestHookParams, IRequestHookResult } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  keyword: string;
}

interface Result extends IRequestHookResult<IProject[]> {}

export const useLoadProjectbyKeyword: IRequestHook<Props, Result> = ({ keyword, stopWhen }) => {
  const path = `/admin/projects/auto-complete?q=${keyword}`;

  const { data: response, error, mutate } = useCustomSWR(path, {
    stopWhen,
  });

  let outputData;
  if (response) {
    outputData = response.data;
  }

  return {
    result: outputData,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadProjectbyKeyword;
