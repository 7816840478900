import { ReactElement, FC } from "react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { SxProps } from "@mui/system";
import { Box, IconButton, Tooltip } from "@mui/material";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { PageHeader } from "components/PageHeader";

// import RouterBreadcrumbs from 'components/RouterBreadcrumbs';

interface Props {
  title: string;
  actionButtons?: ReactElement | null | false;
  className?: string;
  backTo?: string;
  backToTooltip?: string;
  sx?: SxProps;
  params?: any;
}

const PageTitle: FC<Props> = ({
  title,
  actionButtons,
  className,
  backTo,
  backToTooltip,
  sx = {},
  params,
}) => {
  return (
    <Box
      className={className}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: "8px",
        ...sx,
      }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {backTo && (
          <Tooltip
            arrow
            placement="top"
            title={backToTooltip || "Back to list"}>
            <IconButton
              component={RouterLink}
              to={generatePath(backTo, params)}
              color="primary"
              sx={{ mr: 1.5 }}>
              <ArrowBackTwoToneIcon />
            </IconButton>
          </Tooltip>
        )}
        <PageHeader whiteSpace="nowrap">
          {title}
        </PageHeader>

        {/* <RouterBreadcrumbs sx={{ mt: 1 }} /> */}
      </Box>

      {actionButtons}
    </Box>
  );
};
export default PageTitle;
