import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ThemeSettings from 'components/ThemeSettings';

import Sidebar from './Sidebar';
import Header from './Header';

interface AccentHeaderLayoutProps {
  children?: ReactNode;
  isProject?: boolean;
}

const MainWrapper = styled(Box)(
  () => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
`
);

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = ({ children, isProject }) => {
  return (
    <>
      <MainWrapper>
        <Header />
        <Sidebar isProject={isProject} />
        <MainContent>
          {children || <Outlet />}
          {false && <ThemeSettings />}
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default AccentHeaderLayout;
