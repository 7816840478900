import { IReason } from 'types/reason';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {}

interface Result extends IRequestHookResult<IReason[]> {}

export const useLoadReasons: IRequestHook<Props, Result> = ({ stopWhen }) => {
  const path = `/admin/reasons`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const result = response ? (response.data as IReason[]) : [];
  return {
    result,
    error,
    isLoading: !error && !response,
    mutate,
  };
};

export default useLoadReasons;
