import React, { FC, useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  AutocompleteRenderOptionState,
  AutocompleteRenderGetTagProps,
} from '@mui/material';
import { renderOption } from 'components/CustomAutocomplete';
import { IDataFilter } from '../../../../types/discovery-group';

interface IProps {
  label: string;
  objKey: string;
  values: any[];
  options: any[];
  isLoading: boolean;
  audienceFilters: IDataFilter;
  limitTags?: number;
  filterChange: (conditions: IDataFilter) => void;
  renderCustomOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: IOption,
    state: AutocompleteRenderOptionState,
  ) => React.ReactNode;
  renderCustomTags?: (
    value: any,
    getTagProps: AutocompleteRenderGetTagProps,
  ) => React.ReactNode;
  disabled?: boolean;
}

interface IOption {
  id?: string;
  socialId?: string;
  name: string;
  actualSize?: number;
  totalUniqueProfiles?: number;
  caption?: string;
}

const CustomAutocomplete: FC<IProps> = ({
  label,
  objKey,
  values,
  options,
  isLoading,
  audienceFilters,
  limitTags = 3,
  filterChange,
  renderCustomOption,
  renderCustomTags,
  disabled = false,
}) => {
  const [selectedValues, setSelectedValues] = useState<IOption[]>(values);
  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography>{label}</Typography>
      {!renderCustomTags && (
        <Autocomplete
          disabled={disabled}
          size="small"
          multiple
          fullWidth
          limitTags={limitTags}
          disableClearable
          value={selectedValues}
          inputValue={inputValue}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setInputValue('');
            } else if (reason !== 'reset') {
              setInputValue(value);
            }
          }}
          options={options}
          loading={isLoading}
          getOptionLabel={(option) => option.name}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => {
            return option.id
              ? option.id === value.id
              : option.socialId === value.socialId;
          }}
          onChange={(event, newValue) => {
            setSelectedValues(newValue);
            setInputValue(inputValue);
            const conditions = {
              ...audienceFilters,
              [objKey]: newValue,
            };
            filterChange(conditions);
          }}
          renderInput={(params) => <TextField {...params} />}
          renderOption={
            renderCustomOption
              ? (optionProps, option, state) => {
                  return renderCustomOption(optionProps, option, state);
                }
              : renderOption
          }
        />
      )}
      {renderCustomTags && (
        <Autocomplete
          disabled={disabled}
          size="small"
          multiple
          fullWidth
          limitTags={limitTags}
          disableClearable
          value={selectedValues}
          inputValue={inputValue}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setInputValue('');
            } else if (reason !== 'reset') {
              setInputValue(value);
            }
          }}
          options={options}
          loading={isLoading}
          getOptionLabel={(option) => option.name}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => {
            return option.id
              ? option.id === value.id
              : option.socialId === value.socialId;
          }}
          onChange={(event, newValue) => {
            setSelectedValues(newValue);
            setInputValue(inputValue);
            const conditions = {
              ...audienceFilters,
              [objKey]: newValue,
            };
            filterChange(conditions);
          }}
          renderInput={(params) => <TextField {...params} />}
          renderOption={
            renderCustomOption
              ? (optionProps, option, state) => {
                  return renderCustomOption(optionProps, option, state);
                }
              : renderOption
          }
          renderTags={(value, getTagProps) => {
            return renderCustomTags(value, getTagProps);
          }}
        />
      )}
    </Box>
  );
};

export default CustomAutocomplete;
