import { createContext, FC, useMemo, useContext, useState, useCallback } from 'react';

interface ContextProps {
  permissions: any[];
  role?: string;
  setPermissions: (permissions: any[]) => void;
  setRole: (role?: string) => void;
}

const PermissionContext = createContext<ContextProps>({
  permissions: [],
  setPermissions: () => undefined,
  setRole: () => undefined,
});

interface IState {
  permissions: any[];
  role?: string;
}

export const PermissionContextProvider: FC = ({ children }) => {
  const [{ permissions = [], role }, setState] = useState<IState>({
    permissions: [],
  });
  const setPermissions = useCallback((p) => {
    setState((oldState) => ({
      ...oldState,
      permissions: p,
    }));
  }, []);

  const setRole = useCallback((r) => {
    setState((oldState) => ({
      ...oldState,
      role: r,
    }));
  }, []);

  const contextValue = useMemo(
    () => ({
      permissions,
      role,
      setPermissions,
      setRole,
    }),
    [permissions, role, setPermissions, setRole]
  );

  return <PermissionContext.Provider value={contextValue}>{children}</PermissionContext.Provider>;
};

export const usePermissionContext = () => useContext(PermissionContext);
