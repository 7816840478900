import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

export const useMarkAsReadNotification = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function markAsReadNotification(idNotification: string) {
    return requester({
      method: 'patch',
      path: `/admin/notifications/${idNotification}/read`,
      getToken: getAccessTokenSilently,
    });
  };
};

export default useMarkAsReadNotification;
