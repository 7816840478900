import {
  Button,
  ButtonTypeMap,
  ExtendButtonBase,
  Typography,
} from '@mui/material';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

export const PageHeaderEditButton: ExtendButtonBase<
  ButtonTypeMap<{}, 'button'>
> = (btnProps) => {
  const { children, sx, ...props } = btnProps;
  return (
    <Button
      variant="outlined"
      startIcon={<EditIcon />}
      size="small"
      sx={{
        height: 'fit-content',

        ml: 2,
        '&.MuiButton-outlinedNeutral': {
          color: (theme) => theme.palette.neutral?.main,
          backgroundColor: (theme) => theme.colors.alpha.trueWhite[100],
          borderColor: (theme) => theme.colors.alpha.black[40],
        },

        '&.MuiButton-outlinedNeutral:hover': {
          backgroundColor: (theme) => theme.colors.alpha.trueWhite[100],
        },
        borderRadius: '4px',
        ...sx,
      }}
      {...props}
    >
      <Typography variant="caption1">{children}</Typography>
    </Button>
  );
};
