import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const CustomLink = styled(Link)`
  width: fit-content;
  margin: 0 auto 8px;
`;

export const TopSection = styled(Box)(
  ({ theme }) => `
          margin-left: ${theme.spacing(2.5)};
          height: ${theme.spacing(11)};
  `,
);

export const SidebarWrapper = styled(Box)(
  ({ theme, width }) => `
          width: ${width};
          min-width: ${width};
          color: ${theme.sidebar.textColor};
          background: ${theme.sidebar.background};
          box-shadow: ${theme.sidebar.boxShadow};
          position: relative;
          z-index: 5;
          height: 100%;
          display: flex;
          flex-direction: column;
          border-right: 1px solid ${theme.colors.alpha.black[30]};
  `,
);

export const SubSidebarWrapper = styled(Box)`
  .MuiList-root {
    padding: 0;

    .MuiListItem-root .MuiButton-root {
      padding-left: 16px; 
      margin-bottom: 0;
      border-radius: 0;
    }
  }
`;
