import qs from 'qs';
import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';
import { IDiscoveryListOverview } from 'types/koc';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  metric: string;
  status: string;
}

interface Result extends IRequestHookResult<IDiscoveryListOverview> {}

export const useLoadDiscoveryListOverview: IRequestHook<Props, Result> = ({
  stopWhen,
  idProject,
  metric,
  status,
}) => {
  const queryString = qs.stringify({
    metric,
    ...(status ? { status } : {}),
  });
  const path = `/admin/projects/${idProject}/koc/potential-customers/overview?${queryString}`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const result = response
    ? (response.data as IDiscoveryListOverview)
    : undefined;

  return {
    mutate,
    result,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadDiscoveryListOverview;
