import { FC, ReactNode, useState, useContext, Component } from 'react';
import {
  NavLink as RouterLink,
  useNavigate,
  useLocation,
  generatePath,
} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem, Link, Typography } from '@mui/material';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { SidebarContext } from 'contexts/SidebarContext';
import { ROUTE_COMING_SOON } from 'constants/routes';
import MenuButton from '../MenuButton';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  isComingSoon?: boolean;
  open?: boolean;
  active?: boolean;
  name: string;
  fakeLink?: boolean;
  isExternalLink?: boolean;
  isRenderFull?: boolean;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  isComingSoon,
  open: openParent,
  name,
  fakeLink,
  isExternalLink,
  active,
  isRenderFull,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuToggle, setMenuToggle] = useState<boolean>(
    openParent || link === location.pathname,
  );

  const { isSidebarOpen, submenu } = useContext(SidebarContext);
  const isIconButton = !isSidebarOpen && !isRenderFull;

  const onCloseSubmenu = () => {
    if (submenu.collapsedSubmenu && isRenderFull) {
      submenu.setCollapsedSubmenu(undefined, undefined);
    }
  };

  const toggleMenu = (): void => {
    onCloseSubmenu();
    setMenuToggle((Open) => !Open);
  };

  const addParamsToLocation = (pathName: string) => {
    let queryFull = '';
    if (location.search.length === 0) {
      queryFull = `?types=${pathName}`;
    } else {
      const query = location.search;
      const indexToReplace = query.indexOf('types=');
      if (indexToReplace > -1) {
        const assestType = new URLSearchParams(location.search).get('types');
        queryFull = `${query.substr(
          0,
          indexToReplace,
        )}types=${pathName}${query.substr(
          indexToReplace + 6 + (assestType?.length || 0),
        )}`;
      } else {
        queryFull = `?types=${pathName}`;
      }
      const indexToReplaceIdVersion = queryFull.indexOf('idVersion=');
      if (indexToReplaceIdVersion > -1) {
        const idVersion = new URLSearchParams(location.search).get('idVersion');
        queryFull = `${queryFull.substr(
          0,
          indexToReplaceIdVersion,
        )}idVersion=${queryFull.substr(
          indexToReplaceIdVersion + 10 + (idVersion?.length || 0),
        )}`;
      }
      const indexToReplaceIdMedia = queryFull.indexOf('idMedia=');
      if (indexToReplaceIdMedia > -1) {
        const idMedia = new URLSearchParams(location.search).get('idMedia');
        queryFull = `${queryFull.substr(
          0,
          indexToReplaceIdMedia,
        )}idMedia=${queryFull.substr(
          indexToReplaceIdMedia + 8 + (idMedia?.length || 0),
        )}`;
      }
    }

    return generatePath(link + queryFull);
  };

  const isActive = () => {
    const assestType = new URLSearchParams(location.search).get('types');
    if (!assestType && name === 'All') {
      return true;
    }

    if (
      assestType === 'SOCIAL_POST' &&
      name.toLocaleLowerCase() === 'social post'
    )
      return true;

    if (assestType && assestType === name.toLocaleLowerCase()) return true;
    return false;
  };

  if (children) {
    return (
      <>
        {name && name !== 'Assets' && (
          <ListItem
            component="div"
            className="Mui-children"
            key={name}
            {...rest}
          >
            {isSidebarOpen || isRenderFull ? (
              <>
                <Button
                  className={clsx({ 'Mui-active': menuToggle })}
                  startIcon={Icon && <Icon />}
                  endIcon={
                    menuToggle ? (
                      <ExpandLessTwoToneIcon />
                    ) : (
                      <ExpandMoreTwoToneIcon />
                    )
                  }
                  sx={{ '& svg': { height: 20, width: 20 } }}
                  onClick={() => {
                    toggleMenu();
                    sessionStorage.setItem('selectedTab', 'firstAudiences');
                  }}
                >
                  <Typography variant="label">{name}</Typography>
                </Button>
                <Collapse in={menuToggle}>{children}</Collapse>
              </>
            ) : (
              <MenuButton
                isIconButton
                name={name}
                startIcon={Icon && <Icon />}
                className={clsx({
                  'Mui-active': active,
                })}
                onClick={() => {
                  toggleMenu();
                  sessionStorage.setItem('selectedTab', 'firstAudiences');
                  submenu.setCollapsedSubmenu(name, children as Component);
                }}
                sx={{ '& svg': { height: 20, width: 20 } }}
              />
            )}
          </ListItem>
        )}
        {name && name === 'Assets' && (
          <ListItem
            component="div"
            className="Mui-children"
            key={name}
            {...rest}
          >
            {isSidebarOpen || isRenderFull ? (
              <>
                <Button
                  // className={clsx({ 'Mui-active': menuToggle || link === location.pathname })}
                  className={clsx({ 'Mui-active': menuToggle })}
                  startIcon={Icon && <Icon />}
                  endIcon={
                    menuToggle ? (
                      <ExpandLessTwoToneIcon />
                    ) : (
                      <ExpandMoreTwoToneIcon />
                    )
                  }
                  sx={{ '& svg': { height: 20, width: 20 } }}
                  onClick={() => {
                    toggleMenu();
                    sessionStorage.setItem('selectedTab', 'firstAudiences');
                    submenu.setCollapsedSubmenu(name, children as Component);
                  }}
                >
                  <Typography variant="label">{name}</Typography>
                </Button>
                <Collapse in={menuToggle}>{children}</Collapse>
              </>
            ) : (
              <MenuButton
                isIconButton
                name={name}
                startIcon={Icon && <Icon />}
                className={clsx({
                  'Mui-active': active,
                })}
                sx={{ '& svg': { height: 20, width: 20 } }}
                onClick={() => {
                  toggleMenu();
                  sessionStorage.setItem('selectedTab', 'firstAudiences');
                }}
              />
            )}
          </ListItem>
        )}
      </>
    );
  }

  if (isComingSoon) {
    return (
      <ListItem component="div" key={`${name}-menu`} {...rest}>
        <MenuButton
          isIconButton={isIconButton}
          name={name}
          startIcon={Icon && <Icon />}
          onClick={() => navigate(ROUTE_COMING_SOON)}
        />
      </ListItem>
    );
  }

  if (fakeLink) {
    return (
      <ListItem component="div" key={name} {...rest}>
        <MenuButton
          isIconButton={isIconButton}
          name={name}
          startIcon={Icon && <Icon />}
          sx={{ '& svg': { height: 20, width: 20 } }}
        />
      </ListItem>
    );
  }
  if (isExternalLink) {
    return (
      <ListItem component="div" key={name} {...rest}>
        <MenuButton
          isIconButton={isIconButton}
          name={name}
          startIcon={Icon && <Icon />}
          component={Link}
          href={link || '#'}
          target="__blank"
          sx={{ '& svg': { height: 20, width: 20 } }}
        />
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest}>
      {name &&
        name !== 'All' &&
        name !== 'Image' &&
        name !== 'Video' &&
        name !== 'Briefing' &&
        name !== 'Social Post' && (
          <MenuButton
            isIconButton={isIconButton}
            name={name}
            startIcon={Icon && <Icon />}
            activeClassName={active ? 'Mui-active' : undefined}
            component={RouterLink}
            to={link || '#'}
            end
            sx={{ '& svg': { height: 20, width: 20 } }}
            onClick={isIconButton ? undefined : onCloseSubmenu}
          />
        )}
      {name &&
        (name === 'All' ||
          name === 'Image' ||
          name === 'Video' ||
          name === 'Social Post') && (
          <MenuButton
            isIconButton={isIconButton}
            name={name}
            activeClassName={isActive() ? 'Mui-active' : undefined}
            component={RouterLink}
            to={
              addParamsToLocation(
                (name && name === 'Social Post' && 'SOCIAL_POST') ||
                  (name && name !== 'All' ? name.toLocaleLowerCase() : ''),
              ) || '#'
            }
            startIcon={Icon && <Icon />}
            end
            sx={{ '& svg': { height: 20, width: 20 } }}
            onClick={isIconButton ? undefined : onCloseSubmenu}
          />
        )}
      {name && name === 'Briefing' && (
        <MenuButton
          isIconButton={isIconButton}
          name={name}
          activeClassName={
            name.toLocaleLowerCase() === 'briefing' ? 'Mui-active' : undefined
          }
          component={RouterLink}
          to={generatePath(link || '')}
          startIcon={Icon && <Icon />}
          end
          sx={{ '& svg': { height: 20, width: 20 } }}
          onClick={isIconButton ? undefined : onCloseSubmenu}
        />
      )}
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  isComingSoon: PropTypes.bool,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  isExternalLink: PropTypes.bool,
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default SidebarMenuItem;
