import { useAuth0 } from "@auth0/auth0-react";
import { IKOCAudience } from "types/koc";
import { requester } from "../index";

export const useDeleteKOCPost = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function deleteKOCPost(idProject: string, idSocialPost: string) {
    return requester({
      method: "delete",
      path: `/admin/projects/${idProject}/koc/social-posts/${idSocialPost}`,
      data: {},
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IKOCAudience>(response.data));
  };
};

export default useDeleteKOCPost;
