import { FC, useMemo, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import { useCookies } from 'react-cookie';

import { LoadingButton } from '@mui/lab';

interface IOption {
  name: string;
  key: string;
  group: string;
}

interface IProps extends Omit<DialogProps, 'onClose'> {
  type: string;
  items: IOption[];
  itemsToFilter: string[];
  onClose: () => void;
  onSuccess: (values: string[], type: string) => void;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(-90deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SelectFieldDialog: FC<IProps> = ({
  type,
  items,
  itemsToFilter,
  onSuccess,
  onClose,
  ...dialogProps
}) => {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [isPopularExpand, setIsPopularExpand] = useState<boolean>(true);
  const [isOthersExpand, setIsOthersExpand] = useState<boolean>(true);
  const [cookies] = useCookies(['Display-Column-Discovery-Groups']);
  const remapSocialItemKey = (key: string) => {
    switch (key) {
      case 'follows':
        return 'follow';
      case 'ages':
        return 'age';
      case 'cities':
        return 'idCity';
      case 'genders':
        return 'sex';
      case 'relations':
        return 'idRelationship';
      case 'pages':
        return 'nTypePage';
      case 'posts':
        return 'nTypePost';
      case 'friends':
      case 'averageEngagement':
      case 'idHometown':
      case 'dTypeAudiences':
      case 'firstAudiences':
      case 'totalPost':
      case 'totalEngagement':
      case 'totalReaction':
      case 'totalComment':
      case 'totalShare':
      case 'lastPostedTime':
        return key;
      default:
        return key;
    }
  };

  const itemList = useMemo(
    () =>
      cookies && cookies['Group-View-Discovery-Groups']
        ? items.filter(
            (item) =>
              cookies['Group-View-Discovery-Groups'][
                remapSocialItemKey(item.key)
              ] || ['kocCategories'].includes(item.key),
          )
        : items,
    [cookies, items],
  );

  const onSubmitSuccess = () => {
    setIsRequesting(true);
    onSuccess(selectedValues, type);
    setIsRequesting(false);
  };

  const onChange = (event: any, element: IOption) => {
    const resultValues = [...selectedValues];
    const index = selectedValues.findIndex(
      (item: string) => item === element.key,
    );
    if (index === -1 && event.target.checked) {
      setSelectedValues([...resultValues, element.key]);
    }
    if (index > -1 && !event.target.checked) {
      resultValues.splice(index, 1);
      setSelectedValues(resultValues);
    }
  };

  const isGroupChecked = (groupKey: string) => {
    if (
      itemList
        ?.filter((item) => item.group === groupKey)
        ?.every((item) =>
          [...itemsToFilter, ...selectedValues].find((el) => el === item.key),
        )
    ) {
      return true;
    }
    if (
      items
        ?.filter((item) => item.group === groupKey)
        ?.some((item) =>
          [...itemsToFilter, ...selectedValues].find((el) => el === item.key),
        )
    ) {
      return null;
    }
    return false;
  };

  const handleGroupChange = (groupKey: string, checked: boolean) => {
    let resultValues = [...selectedValues];
    itemList
      ?.filter((item) => item.group === groupKey)
      ?.forEach((item) => {
        const index = resultValues.findIndex(
          (field: string) => field === item.key,
        );
        if (index === -1 && checked) {
          resultValues = [...resultValues, item.key];
        }
        if (index > -1 && !checked) {
          resultValues.splice(index, 1);
        }
      });
    setSelectedValues([...resultValues]);
  };

  const renderSection = (groupKey: string) => {
    const expandValue =
      groupKey === 'Popular' ? isPopularExpand : isOthersExpand;
    return (
      <>
        <Box alignItems="center" display="flex" width={240}>
          <ExpandMore
            sx={{ m: 0, p: 0 }}
            expand={expandValue}
            onClick={
              groupKey === 'Popular'
                ? () => {
                    setIsPopularExpand(!expandValue);
                  }
                : () => {
                    setIsOthersExpand(!expandValue);
                  }
            }
            aria-expanded={expandValue}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Checkbox
            indeterminate={isGroupChecked(groupKey) === null}
            checked={Boolean(isGroupChecked(groupKey))}
            onChange={(e: any) => {
              handleGroupChange(groupKey, e?.target?.checked);
            }}
          />
          <Typography sx={{ color: '#20202099' }}>{groupKey}</Typography>
        </Box>
        <Collapse in={expandValue} timeout="auto" unmountOnExit>
          {itemList
            .filter((item) => item.group === groupKey)
            .map((element) => (
              <Box key={element.key} ml={6} display="flex" alignItems="center">
                <Checkbox
                  disabled={itemsToFilter.includes(element.key)}
                  checked={
                    selectedValues.includes(element.key) ||
                    itemsToFilter.includes(element.key)
                  }
                  onClick={(event: any) => onChange(event, element)}
                />
                <Typography component="span" sx={{ color: '#20202099' }}>
                  {element.name}
                </Typography>
              </Box>
            ))}
        </Collapse>
      </>
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          {type === 'social'
            ? 'Filter for 3rd Party Data'
            : 'Filter for 1st Party data'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {type === 'social' && (
          <Box mt={2}>
            {renderSection('Popular')}
            {renderSection('Others')}
          </Box>
        )}
        {type === 'cdp' && (
          <Box mt={2}>
            {items.map((element) => (
              <Box key={element.key}>
                <Checkbox
                  disabled={itemsToFilter.includes(element.key)}
                  checked={
                    selectedValues.includes(element.key) ||
                    itemsToFilter.includes(element.key)
                  }
                  onClick={(event) => onChange(event, element)}
                />
                <Typography component="span" sx={{ color: '#20202099' }}>
                  {element.name}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          onClick={onSubmitSuccess}
          loading={isRequesting}
          variant="contained"
          type="submit"
        >
          Apply
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SelectFieldDialog;
