import { Box, Tooltip, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
// import ProjectSelectBox from 'components/ProjectSelectBox';
import { useContext } from 'react';
import ProjectSearchBox from 'components/ProjectSearchBox';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';

import Permission from 'components/Permission/Permission';
import { useIsBreakpoint } from 'hooks/useBreakpoint';
import { SidebarContext } from 'contexts/SidebarContext';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';

const HeaderWrapper = styled(Box)(
  ({ theme, width }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        border-bottom: 1px solid ${theme.colors.alpha.black[30]};

        left: ${width};
        width: calc(100% - ${width});
`,
);
const VerticalDivider = styled('div')(
  ({ theme }) => `
        width: 1px;
        height: 50px;
        background-color: ${theme.colors.alpha.black[30]};
`,
);

function Header() {
  const isXl = useIsBreakpoint('xl');
  const iconWidth = isXl ? '30px' : '20px';
  const { sidebarWidth } = useContext(SidebarContext);

  return (
    <HeaderWrapper display="flex" alignItems="center" width={sidebarWidth}>
      <Box display="flex" alignItems="center">
        <Permission
          permissions={[
            'project.dashboard.view',
            'system.projects.organizations.view',
            'system.projects.brands.view',
            'system.projects.projects.view',
          ]}
        >
          <ProjectSearchBox />
        </Permission>
      </Box>
      <Box display="flex" alignItems="center">
        <Tooltip arrow title="Give feedback" placement="top-end">
          <Link
            display="flex"
            mr={isXl ? 5 : 2}
            href="https://docs.google.com/spreadsheets/d/14KM2D4QzWELGpit4RzOm-2oZIAu1uLWQarfdtSOLOq8/edit"
            target="__blank"
            sx={{ svg: { width: iconWidth, height: iconWidth } }}
          >
            <HelpIcon />
          </Link>
        </Tooltip>
        <HeaderButtons />
        <VerticalDivider sx={{ mx: isXl ? 5 : 3 }} />
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
