import { useAuth0 } from '@auth0/auth0-react';
import { IProjectBriefing, IUser } from 'types/project';
import { requester } from '../index';

interface DataType {
  title: string;
  type: string;
  deliverableDueDate?: string;
  priority?: string;
  assignees?: IUser[];
}

export const useEditProjectBrief = ({ idP, id }) => {
  const { getAccessTokenSilently } = useAuth0();

  return function editProjectBrief(data: DataType) {
    
    const assigneesArray = data?.assignees?.map((item) => {
      return item?.id;
    } )

    return requester({
      method: 'put',
      path: `/admin/projects/${idP}/briefs/${id}`,
      data: {
        title: data?.title,
        type: data?.type,
        deliverableDueDate: data?.deliverableDueDate,
        priority: data?.priority,
        assignees: assigneesArray,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IProjectBriefing>(response.data));
  };
};

export default useEditProjectBrief;
