import { IProjectCustomerSegment } from 'types/project';
import { IRequestHook, IRequestHookParams, IRequestHookResult } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  keyword: string;
}

interface Result extends IRequestHookResult<IProjectCustomerSegment[]> {}

export const useLoadSegmentByKeyword: IRequestHook<Props, Result> = ({ idProject, keyword, stopWhen }) => {
  const path = `/admin/projects/${idProject}/customer-segments/auto-complete?q=${keyword}`;

  const { data: response, error } = useCustomSWR(path, {
    stopWhen,
  });

  let outputData;
  if (response) {
    outputData = response.data;
  }

  return {
    result: outputData,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadSegmentByKeyword;
