import { useAuth0 } from '@auth0/auth0-react';
import { IKOCAudience } from 'types/koc';
import { requester } from '../index';

export const useEditProduct = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function editProduct(
    idProject: string,
    id: number,
    name: string,
    description: string,
  ) {
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}/products`,
      data: {
        description,
        name,
        id,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IKOCAudience>(response.data));
  };
};

export default useEditProduct;
