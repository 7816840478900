import {
  IRequestHookParams,
  IRequestHookResult,
  IRequestHook,
} from 'types/request';

import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {
  idProject: string;
  refreshInterval: number;
}
interface IResult {
  totalClick: number;
}
interface Result extends IRequestHookResult<IResult> {}

export const useLoadProjectTotalClick: IRequestHook<Props, Result> = ({
  idProject,
  stopWhen,
  refreshInterval,
}) => {
  const path = `/admin/projects/${idProject}/links/total-click`;

  const {
    data: response,
    error,
    isValidating,
    mutate,
  } = useCustomSWR(path, { stopWhen, refreshInterval });

  return {
    result: response?.data || {
      IResult: 0,
    },
    error,
    isLoading: !error && !response,
    isValidating,
    mutate,
  };
};

export default useLoadProjectTotalClick;
