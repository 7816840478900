import { FC, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { DialogProps } from "@mui/material/Dialog/Dialog";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { LoadingButton, DatePicker } from "@mui/lab";

interface FormValues {
  from: string | null;
  to: string | null;
}

const defaultValues = {
  from: null,
  to: null,
};

interface IProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  onSuccess: (from: string, to: string) => void;
}

const schema = yup.object().shape({});

const CreateDateRangeDialog: FC<IProps> = ({
  onSuccess,
  onClose,
  ...dialogProps
}) => {
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [formError, setFormError] = useState<string>("");

  const { handleSubmit, watch, control, reset } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues },
  });

  const fromValue = watch("from");
  const toValue = watch("to");

  useEffect(() => {
    if (!isFirstLoad) {
      if (!fromValue || !toValue) {
        setFormError("From and To are required");
      } else if (
        fromValue &&
        toValue &&
        new Date(fromValue).getTime() > new Date(toValue).getTime()
      ) {
        setFormError("From must be equal or less than To");
      } else setFormError("");
    } else {
      setFormError("");
    }
  }, [fromValue, toValue, isFirstLoad]);

  const onSubmitSuccess = (values: FormValues) => {
    setIsRequesting(true);
    if (values.from && values.to && !formError) {
      onSuccess(values.from, values.to);
      setIsRequesting(false);
      resetValue();
    } else {
      setIsFirstLoad(false);
      setIsRequesting(false);
    }
  };

  const onSubmitError = (err) => {
    console.error("err", err);
  };

  const resetValue = () => {
    setIsFirstLoad(true);
    reset();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps} onClose={resetValue}>
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          Custom range
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 2 }}>From</Typography>
              <Controller
                name="from"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label=""
                    value={field.value}
                    onChange={field.onChange}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        error={Boolean(formError)}
                        helperText={formError}
                        InputLabelProps={{ shrink: true }}
                        sx={
                          !watch("from")
                            ? {
                                "& input": {
                                  color: "rgba(34, 51, 84, 0.7)",
                                },
                              }
                            : {}
                        }
                      />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ mr: 2 }}>To</Typography>
              <Controller
                name="to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label=""
                    value={field.value}
                    onChange={field.onChange}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        error={Boolean(formError)}
                        helperText={formError}
                        InputLabelProps={{ shrink: true }}
                        sx={
                          !watch("to")
                            ? {
                                "& input": {
                                  color: "rgba(34, 51, 84, 0.7)",
                                },
                              }
                            : {}
                        }
                      />
                    )}
                    inputFormat="dd/MM/yyyy"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetValue}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmitSuccess, onSubmitError)}
          loading={isRequesting}
          variant="contained"
          type="submit">
          Apply
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDateRangeDialog;
