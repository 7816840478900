import { FC, useState } from 'react';
import {
  Box,
  DialogContent,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Dialog, { DialogActions } from 'components/Dialog';
import { getErrorMessage } from 'utils/common';
import { useLoadProductList } from 'requests/products';
import { useLoadUseCaseList } from 'requests/use-cases';
import { IKOCCustomer } from 'types/customer';
import { useAddTag } from 'requests/koc/useAddTag';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customer: IKOCCustomer;
}

interface FormValues {
  other?: string | null;
  idProduct?: number | null;
  idUseCase?: number | null;
}
const schema = yup.object().shape({});

const AddTagDialog: FC<Props> = ({ isOpen, onClose, onSuccess, customer }) => {
  const { idProject } = useParams();
  const addTag = useAddTag();
  const { enqueueSnackbar } = useSnackbar();
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const { result: productList } = useLoadProductList({
    idProject,
    keyword: '',
  });
  const { result: useCaseList } = useLoadUseCaseList({
    idProject,
    keyword: '',
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      other: customer.other || null,
      idProduct: customer.idProduct || null,
      idUseCase: customer.idUseCase || null,
    },
  });

  const handleClose = (
    event?: any,
    reason?: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (onClose && reason !== 'backdropClick') {
      resetValue();
      onClose();
    }
  };

  const resetValue = () => {
    reset({ other: null, idProduct: null, idUseCase: null });
  };

  const onSubmitSuccess = (values: FormValues) => {
    setIsRequesting(true);
    addTag({
      idProject,
      idCustomer: customer.id,
      other: values.other,
      idProduct: values.idProduct,
      idUseCase: values.idUseCase,
    })
      .then(() => {
        onSuccess();

        enqueueSnackbar(`Add tag successfully`, {
          variant: 'success',
        });
        setIsRequesting(false);
        handleClose();
      })
      .catch((error) => {
        console.error('Add tag: ', error);

        enqueueSnackbar(getErrorMessage(error, 'Something went wrong'), {
          variant: 'error',
        });
        setIsRequesting(false);
      });
  };

  const onSubmitError = (err) => {
    console.error('err', err);
  };

  return (
    <Dialog
      open={isOpen}
      title={
        customer.other || customer.idUseCase || customer.idProduct
          ? 'Edit Tag'
          : 'Add Tag'
      }
      onClose={handleClose}
    >
      <DialogContent>
        <Box>
          <Typography variant="label" mb={0.5}>
            Product
          </Typography>
          <Controller
            name="idProduct"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                options={productList || []}
                fullWidth
                value={(productList || []).find((item) => item.id === value)}
                onChange={(_, newValue) => {
                  onChange(newValue?.id || null);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      placeholder="Select a Product"
                    />
                  );
                }}
              />
            )}
          />
        </Box>
        <Box my={3}>
          <Typography variant="label" mb={0.5}>
            Use Case
          </Typography>
          <Controller
            name="idUseCase"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                options={useCaseList || []}
                fullWidth
                value={(useCaseList || []).find((item) => item.id === value)}
                onChange={(_, newValue) => {
                  onChange(newValue?.id || null);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      placeholder="Select a Use Case"
                    />
                  );
                }}
              />
            )}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="label" mb={0.5}>
            Other
          </Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="Enter Other"
            error={!!errors.other}
            helperText={errors.other?.message}
            InputProps={{ ...register('other') }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        onCancel={handleClose}
        onOk={handleSubmit(onSubmitSuccess, onSubmitError)}
        isLoading={isRequesting}
      />
    </Dialog>
  );
};

export default AddTagDialog;
