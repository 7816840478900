import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

interface DataType {
  idProject: string;
  name: string;
  phone: string;
  email: string;
  idCity: number | null;
  idKocScheme: number | null;
  valuekocScheme: number | null;
  facebookUid: string;
  instagramUid: string;
  tiktokUid: string;
  youtubeUid: string;
  facebookFollow: number | null;
  instagramFollow: number | null;
  tiktokFollow: number | null;
  youtubeFollow: number | null;
  noteKocScheme: string;
  idKocCategories: number[];
  idKocCustomer: string;
  birthday: string;
  professional: string;
  recommendedPlatforms: string[];
  contentOverview: string;
  socialInteractions: string;
  socialViews: string;
  formats: string[];
  cost?: number;
  deliveryName: string;
  deliveryStreet: string;
  deliveryIdWard: number | null;
  deliveryIdDistrict: number | null;
  deliveryIdCity: number | null;
  deliveryNote: string;
  deliveryPhone: string;
  idProduct: number | null;
  idUseCase: number | null;
  other: string;
}

export const useAddKOC = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function addKOC(data: DataType) {
    return requester({
      method: 'post',
      path: `/admin/projects/${data.idProject}/koc/official-customers`,
      data: {
        name: data.name,
        phone: data.phone,
        email: data.email,
        id_city: data.idCity,
        id_koc_scheme: data.idKocScheme,
        value_koc_scheme: data.valuekocScheme,
        facebook_uid: data.facebookUid,
        instagram_uid: data.instagramUid,
        tiktok_uid: data.tiktokUid,
        youtube_uid: data.youtubeUid,
        facebook_follow: data.facebookFollow,
        instagram_follow: data.instagramFollow,
        tiktok_follow: data.tiktokFollow,
        youtube_follow: data.youtubeFollow,
        note_koc_scheme: data.noteKocScheme,
        id_koc_categories: data.idKocCategories,
        id_koc_customer: data.idKocCustomer,
        birthday: data.birthday,
        professional: data.professional,
        recommended_platforms: data.recommendedPlatforms,
        content_overview: data.contentOverview,
        social_interactions: data.socialInteractions,
        social_views: data.socialViews,
        formats: data.formats,
        cost: data.cost,
        delivery_name: data.deliveryName,
        delivery_street: data.deliveryStreet,
        delivery_id_ward: data.deliveryIdWard,
        delivery_id_district: data.deliveryIdDistrict,
        delivery_id_city: data.deliveryIdCity,
        delivery_note: data.deliveryNote,
        delivery_phone: data.deliveryPhone,
        id_product: data.idProduct,
        id_use_case: data.idUseCase,
        other: data.other,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useAddKOC;
