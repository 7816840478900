import qs from 'qs';
import { useAuth0 } from '@auth0/auth0-react';
import { fetcher } from 'requests';
import { IFilter } from 'pages/KOCNurturing/KOCDiscovery/components/FilterMenu';

interface DataType {
  idProject: string;
  cities?: number[];
  friends?: string[];
  follows?: string[];
  genders?: string[];
  ages?: string[];
  relations?: string[];
  uids?: string[];
  phones?: string[];
  dTypeAudiences?: string[];
  firstAudiences?: string[];
  pages?: string[];
  posts?: string[];
  cdpCities?: number[];
  cdpGenders?: string[];
  cdpAges?: string[];
  cdpRecencies?: string[];
  cdpFrequencies?: string[];
  cdpMonetaries?: string[];
  kocCategories?: number[];
  averageEngagement?: IFilter;
  totalPost?: IFilter;
  totalEngagement?: IFilter;
  totalReaction?: IFilter;
  totalComment?: IFilter;
  totalShare?: IFilter;
  postedTime?: string;
  fromPostedTime?: string;
  toPostedTime?: string;
  hometowns?: number[];
  useCases?: number[];
  products?: number[];
}

export const useLoadKOCCustomerUnique = () => {
  const { getAccessTokenSilently } = useAuth0();
  return function handelLoadTotal(data: DataType) {
    const queryString = qs.stringify({
      cities: data.cities,
      friends: data.friends,
      follows: data.follows,
      genders: data.genders,
      ages: data.ages,
      relations: data.relations,
      uids: data.uids,
      phones: data.phones,
      d_type_audiences: data.dTypeAudiences,
      first_audiences: data.firstAudiences,
      n_type_pages: data.pages,
      n_type_posts: data.posts,
      cdp_cities: data.cdpCities,
      cdp_genders: data.cdpGenders,
      cdp_ages: data.cdpAges,
      cdp_recencies: data.cdpRecencies,
      cdp_frequencies: data.cdpFrequencies,
      cdp_monetaries: data.cdpMonetaries,
      koc_categories: data.kocCategories,
      from_total_post: data.totalPost?.from,
      to_total_post: data.totalPost?.to,
      from_total_engagement: data.totalEngagement?.from,
      to_total_engagement: data.totalEngagement?.to,
      from_total_reaction: data.totalReaction?.from,
      to_total_reaction: data.totalReaction?.to,
      from_total_comment: data.totalComment?.from,
      to_total_comment: data.totalComment?.to,
      from_total_share: data.totalShare?.from,
      to_total_share: data.totalShare?.to,
      posted_time: data.postedTime,
      from_posted_time: data.fromPostedTime,
      to_posted_time: data.toPostedTime,
      from_average_engagement: data?.averageEngagement?.from,
      to_average_engagement: data?.averageEngagement?.to,
      hometowns: data.hometowns,
      use_cases: data.useCases,
      products: data.products,
    });
    const path = `/admin/projects/${data.idProject}/koc/customers/unique-total?${queryString}`;
    return fetcher(getAccessTokenSilently)(path).then(
      (response) => response.data,
    );
  };
};

export default useLoadKOCCustomerUnique;
