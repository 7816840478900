import { Suspense, lazy } from 'react';
import { PartialRouteObject } from 'react-router';

import SuspenseLoader from 'components/SuspenseLoader';
import BaseLayout from 'components/layouts/BaseLayout';
import AccentHeaderLayout from 'components/layouts/AccentHeaderLayout';

import * as ROUTES from 'constants/routes';

import Authenticated from 'components/Authenticated';
import Guest from 'components/Guest';
import RoleRoute from 'RoleRoute';
import ProjectDiscoveryGroup from './pages/KOCNurturing/DiscoveryGroup';

const Loader = (Component: any) => (props: any) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Dashboard = Loader(lazy(() => import('pages/Dashboard')));
const Employees = Loader(lazy(() => import('pages/employees/Employees')));
const CreateEmployee = Loader(
  lazy(() => import('pages/employees/CreateEmployee')),
);
const EditEmployee = Loader(lazy(() => import('pages/employees/EditEmployee')));
const EmployeeDetails = Loader(
  lazy(() => import('pages/employees/EmployeeDetails')),
);
const DataImport = Loader(lazy(() => import('pages/DataImportList')));
const Projects = Loader(lazy(() => import('pages/projects/Projects')));
const ProjectDetails = Loader(
  lazy(() => import('pages/projects/ProjectDetails')),
);
const CreateProject = Loader(
  lazy(() => import('pages/projects/CreateProject')),
);

const DuplicateProject = Loader(
  lazy(() => import('pages/projects/DuplicateProject')),
);
const EditProject = Loader(lazy(() => import('pages/projects/EditProject')));
const ProjectAccounts = Loader(lazy(() => import('pages/ProjectAccounts')));
const EditProjectReports = Loader(
  lazy(() => import('pages/projects/EditProject/EditProjectReports')),
);
const Brands = Loader(lazy(() => import('pages/brands/Brands')));
const BrandDetails = Loader(lazy(() => import('pages/brands/BrandDetails')));
const CreateBrand = Loader(lazy(() => import('pages/brands/CreateBrand')));
const EditBrand = Loader(lazy(() => import('pages/brands/EditBrand')));
const Organizations = Loader(
  lazy(() => import('pages/organizations/Organizations')),
);
const OrganizationDetails = Loader(
  lazy(() => import('pages/organizations/OrganizationDetails')),
);
const CreateOrganization = Loader(
  lazy(() => import('pages/organizations/CreateOrganization')),
);
const EditOrganization = Loader(
  lazy(() => import('pages/organizations/EditOrganization')),
);
const ComingSoon = Loader(lazy(() => import('pages/ComingSoon')));
const Login = Loader(lazy(() => import('pages/Login')));
const Assets = Loader(
  lazy(() => import('pages/ShootingAndContentProduction/Assets')),
);
const ProjectReport = Loader(lazy(() => import('pages/ProjectReport')));
const NoReport = Loader(lazy(() => import('pages/ProjectReport/NoReport')));
const ProjectMedia = Loader(lazy(() => import('pages/ProjectMedia')));

const ProjectBriefing = Loader(lazy(() => import('pages/ProjectBriefing')));
const ProjectBriefingDetails = Loader(
  lazy(() => import('pages/briefings/BriefingDetails')),
);
const ProjectKOCDiscoveryList = Loader(
  lazy(() => import('pages/KOCNurturing/KOCDiscovery/ProjectKOCDiscoveryList')),
);
const ProjectKOCDiscoveryDetail = Loader(
  lazy(() => import('pages/KOCNurturing/KOCPotentialDetail')),
);
const ProjectKOCDiscoveryDataSources = Loader(
  lazy(
    () =>
      import('pages/KOCNurturing/KOCDiscovery/ProjectKOCDiscoveryDataSources'),
  ),
);
const ProjectKOCAudienceDetails = Loader(
  lazy(() => import('pages/KOCNurturing/KOCAudience/KOCAudienceDetails')),
);
const ProjectKOCAudiencePageDetails = Loader(
  lazy(
    () => import('pages/KOCNurturing/KOCAudiencePage/KOCAudiencePageDetails'),
  ),
);
const ProjectKOCAudienceGroupDetails = Loader(
  lazy(
    () => import('pages/KOCNurturing/KOCAudiencePage/KOCAudienceGroupDetails'),
  ),
);
const ProjectKOCList = Loader(
  lazy(() => import('pages/KOCNurturing/KOCOfficial')),
);
const ProjectKOCDetail = Loader(
  lazy(() => import('pages/KOCNurturing/KOCOfficialDetail')),
);
const KOCSchemeType = Loader(
  lazy(() => import('pages/KOCManagement/KOCSchemeType')),
);
const ProjectKOCScheme = Loader(lazy(() => import('pages/ProjectKOCScheme')));
const KOCCategory = Loader(
  lazy(() => import('pages/KOCManagement/KOCCategory')),
);
const FBCategory = Loader(lazy(() => import('pages/KOCManagement/FBCategory')));
const KOCCategoryDetail = Loader(
  lazy(() => import('pages/KOCManagement/KOCCategoryDetail')),
);
const ContentGuide = Loader(
  lazy(() => import('pages/ContentHUB/ContentGuide')),
);
const ProjectAgreementManagement = Loader(
  lazy(() => import('pages/AgreementManagement')),
);
const PublicLink = Loader(lazy(() => import('pages/PublicLink')));
const RolesPermissions = Loader(
  lazy(() => import('pages/roles/RolesNPermissions')),
);
const ContentLibrary = Loader(
  lazy(() => import('pages/contents/ContentLibrary')),
);
const ContentDetails = Loader(
  lazy(() => import('pages/KOCNurturing/KOCOfficial/content')),
);
const UGCContentDetails = Loader(
  lazy(
    () =>
      import('pages/contents/ContentLibrary/UGCContentDetails/ContentDetails'),
  ),
);
const ContentMixer = Loader(lazy(() => import('pages/ContentMixer')));
const LinkManagement = Loader(lazy(() => import('pages/LinkManagement')));

const UseCaseProductManagement = Loader(
  lazy(() => import('pages/usecases-products')),
);

const ProductDetails = Loader(
  lazy(
    () => import('pages/usecases-products/ProductManagement/ProductDetails'),
  ),
);

const AgreementTemplate = Loader(
  lazy(() => import('pages/TemplateManagement/AgreementTemplate')),
);

const MessageTemplate = Loader(
  lazy(() => import('pages/TemplateManagement/MessageTemplate')),
);

// Status

const Status404 = Loader(lazy(() => import('pages/status/Status404')));
const Status403 = Loader(lazy(() => import('pages/status/Status403')));

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '404',
        element: <Status404 />,
      },
      {
        path: '403',
        element: <Status403 />,
      },
      {
        path: ROUTES.ROUTE_LOGIN,
        element: (
          <Guest>
            <Login />
          </Guest>
        ),
      },
      {
        path: ROUTES.ROUTE_PUBLIC_LINK,
        element: <PublicLink />,
      },
      {
        path: '*',
        element: (
          <Authenticated>
            <AccentHeaderLayout />
          </Authenticated>
        ),
        children: [
          {
            path: '/',
            element: (
              <RoleRoute requirePermissions={[]}>
                <Dashboard />
              </RoleRoute>
            ),
          },
          { path: ROUTES.ROUTE_COMING_SOON, element: <ComingSoon /> },
          {
            path: ROUTES.ROUTE_ORGANIZATIONS,
            element: (
              <RoleRoute
                requirePermissions={['system.projects.organizations.view']}
              >
                <Organizations />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_ORGANIZATIONS_CREATE,
            element: (
              <RoleRoute
                requirePermissions={['system.projects.organizations.create']}
              >
                <CreateOrganization />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_ORGANIZATIONS_DETAILS,
            element: (
              <RoleRoute
                requirePermissions={['system.projects.organizations.view']}
              >
                <OrganizationDetails />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_ORGANIZATIONS_EDIT,
            element: (
              <RoleRoute
                requirePermissions={['system.projects.organizations.edit']}
              >
                <EditOrganization />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_BRANDS,
            element: (
              <RoleRoute requirePermissions={['system.projects.brands.view']}>
                <Brands />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_BRANDS_CREATE,
            element: (
              <RoleRoute requirePermissions={['system.projects.brands.create']}>
                <CreateBrand />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_BRANDS_DETAILS,
            element: (
              <RoleRoute requirePermissions={['system.projects.brands.view']}>
                <BrandDetails />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_BRANDS_EDIT,
            element: (
              <RoleRoute requirePermissions={['system.projects.brands.edit']}>
                <EditBrand />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_EMPLOYEES,
            element: (
              <RoleRoute requirePermissions={['system.account.accounts.view']}>
                <Employees />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_EMPLOYEE_CREATE,
            element: (
              <RoleRoute
                requirePermissions={['system.account.accounts.create']}
              >
                <CreateEmployee />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_EMPLOYEE_EDIT,
            element: (
              <RoleRoute requirePermissions={['system.account.accounts.edit']}>
                <EditEmployee />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_EMPLOYEE_DETAILS,
            element: (
              <RoleRoute requirePermissions={['system.account.accounts.view']}>
                <EmployeeDetails />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_DATA_CUSTOMER_IMPORT,
            element: (
              <RoleRoute
                requirePermissions={['system.data_management.data_import.view']}
              >
                <DataImport />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_PROJECTS,
            element: (
              <RoleRoute requirePermissions={['system.projects.projects.view']}>
                <Projects />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_PROJECTS_CREATE,
            element: (
              <RoleRoute
                requirePermissions={['system.projects.projects.create']}
              >
                <CreateProject />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_PROJECTS_DUPPLICATE,
            element: (
              <RoleRoute
                requirePermissions={['system.projects.projects.create']}
              >
                <DuplicateProject />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_KOC_SCHEME_TYPE,
            element: (
              <RoleRoute
                requirePermissions={[
                  'system.koc_management.koc_scheme_type.view',
                ]}
              >
                <KOCSchemeType />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_KOC_CATEGORY,
            element: (
              <RoleRoute
                requirePermissions={['system.koc_management.koc_category.view']}
              >
                <KOCCategory />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_KOC_CATEGORY_DETAIL,
            element: (
              // <RoleRoute requireRoles={['Administrator']}>
              <KOCCategoryDetail />
              // </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_FB_CATEGORY_MAPPING,
            element: (
              <RoleRoute
                requirePermissions={[
                  'system.koc_management.facebook_category_mapping.view',
                ]}
              >
                <FBCategory />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_CONTENT_GUIDE,
            element: (
              <RoleRoute
                requirePermissions={['system.content_hub.content_guide.view']}
              >
                <ContentGuide />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_ROLES_PERMISSIONS,
            element: (
              <RoleRoute requireRoles={['ADMIN']} requirePermissions={[]}>
                <RolesPermissions />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_AGREEMENT_TEMPLATE,
            element: (
              <RoleRoute
                requirePermissions={[
                  'system.template_management.agreement_template.view',
                ]}
              >
                <AgreementTemplate />
              </RoleRoute>
            ),
          },
          {
            path: ROUTES.ROUTE_MESSAGE_TEMPLATE,
            element: (
              <RoleRoute
                requirePermissions={[
                  'system.template_management.sms_template.view',
                ]}
              >
                <MessageTemplate />
              </RoleRoute>
            ),
          },
        ],
      },
      {
        path: 'projects/:idProject',
        element: (
          <Authenticated>
            <AccentHeaderLayout isProject />
          </Authenticated>
        ),
        children: [
          { path: '/', element: <ProjectDetails /> },
          {
            path: '/edit',
            element: (
              <RoleRoute requirePermissions={['project.dashboard.view']}>
                <EditProject />
              </RoleRoute>
            ),
          },
          { path: '/edit/reports', element: <EditProjectReports /> },
          {
            path: '/accounts',
            element: (
              <RoleRoute
                requirePermissions={['project.project_setting.accounts.view']}
              >
                <ProjectAccounts />
              </RoleRoute>
            ),
          },
          { path: '/assets', element: <Assets /> },
          {
            path: '/report',
            element: (
              <RoleRoute requirePermissions={['project.report.view']}>
                <NoReport />
              </RoleRoute>
            ),
          },
          {
            path: '/report/:idReport',
            element: (
              <RoleRoute requirePermissions={['project.report.view']}>
                <ProjectReport />
              </RoleRoute>
            ),
          },
          { path: '/media', element: <ProjectMedia /> },
          { path: '/briefing', element: <ProjectBriefing /> },
          { path: '/briefing/:id', element: <ProjectBriefingDetails /> },
          {
            path: '/koc-discovery',
            element: (
              <RoleRoute
                requirePermissions={[
                  'project.koc_discovery.discovery_list.view_potential_koc',
                ]}
              >
                <ProjectKOCDiscoveryList />
              </RoleRoute>
            ),
          },
          {
            path: '/koc-discovery/:id',
            element: (
              <RoleRoute
                requirePermissions={[
                  'project.koc_discovery.discovery_list.view_potential_koc_profile'
                ]}
              >
                <ProjectKOCDiscoveryDetail />
              </RoleRoute>
            ),
          },

          {
            path: '/agreement-management',
            element: (
              <RoleRoute
                requirePermissions={['project.recruitment.agreement.view']}
              >
                <ProjectAgreementManagement />
              </RoleRoute>
            ),
          },
          {
            path: '/koc-data-sources',
            element: (
              <RoleRoute
                requirePermissions={[
                  'project.koc_discovery.search.view_audience',
                ]}
              >
                <ProjectKOCDiscoveryDataSources />
              </RoleRoute>
            ),
          },
          {
            path: '/discovery-group',
            element: (
              <RoleRoute
                requirePermissions={[
                  'project.koc_discovery.discovery_group.view_discovery_group',
                ]}
              >
                <ProjectDiscoveryGroup />
              </RoleRoute>
            ),
          },
          {
            path: '/koc-data-sources/page/:idPage',
            element: (
              <RoleRoute
                requirePermissions={[
                  'project.koc_discovery.search.view_audience',
                ]}
              >
                <ProjectKOCAudiencePageDetails />
              </RoleRoute>
            ),
          },
          {
            path: '/koc-data-sources/group/:idGroup',
            element: (
              <RoleRoute
                requirePermissions={[
                  'project.koc_discovery.search.view_audience',
                ]}
              >
                <ProjectKOCAudienceGroupDetails />
              </RoleRoute>
            ),
          },
          {
            path: '/koc-discovery/audience/:id',
            element: (
              <RoleRoute
                requirePermissions={[
                  'project.koc_discovery.search.view_audience',
                ]}
              >
                <ProjectKOCAudienceDetails />
              </RoleRoute>
            ),
          },
          {
            path: '/koc-list',
            element: (
              <RoleRoute
                requirePermissions={['project.koc_list.view_koc_list']}
              >
                <ProjectKOCList />
              </RoleRoute>
            ),
          },
          {
            path: '/koc-list/:id',
            element: (
              <RoleRoute
                requirePermissions={['project.koc_list.view_koc_profile']}
              >
                <ProjectKOCDetail />
              </RoleRoute>
            ),
          },
          { path: '/coming-soon', element: <ComingSoon /> },
          { path: '/koc-scheme', element: <ProjectKOCScheme /> },
          {
            path: '/content-library',
            element: (
              <RoleRoute requirePermissions={['project.content_library.view']}>
                <ContentLibrary />
              </RoleRoute>
            ),
          },
          {
            path: '/content-mixer',
            element: (
              <RoleRoute
                requirePermissions={['project.content_mixer.view_mix_content']}
              >
                <ContentMixer />
              </RoleRoute>
            ),
          },
          {
            path: '/koc-list/:id/content-details/:idContent',
            element: (
              <RoleRoute
                requirePermissions={['project.koc_list.view_koc_content']}
              >
                <ContentDetails />
              </RoleRoute>
            ),
          },
          {
            path: '/content-details/:idContent',
            element: (
              <RoleRoute requirePermissions={[]}>
                <UGCContentDetails />
              </RoleRoute>
            ),
          },
          {
            path: '/link-management',
            element: (
              <RoleRoute
                requirePermissions={['project.koc_list.view_tracking']}
              >
                <LinkManagement />
              </RoleRoute>
            ),
          },
          {
            path: '/usecase-product-management',
            element: (
              <RoleRoute
                requirePermissions={[
                  'project.use_case_product_management.use_case.view',
                  'project.use_case_product_management.product.view',
                ]}
              >
                <UseCaseProductManagement />
              </RoleRoute>
            ),
          },
          {
            path: '/products/:idProduct',
            element: (
              <RoleRoute
                requirePermissions={[
                  'project.use_case_product_management.product.view',
                ]}
              >
                <ProductDetails />
              </RoleRoute>
            ),
          },
        ],
      },
    ],
  },
];

export default routes;
