import { useCallback, useEffect, useMemo, useState } from 'react';
import { CUSTOM_RANGE_OPTION } from 'components/CustomAutocomplete';

export const useCustomAutocomplete = <T>(options: T[], values: T[]) => {
  const [selectedValues, setSelectedValues] = useState<T[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const customRangeItem = useMemo(() => {
    return {
      id: CUSTOM_RANGE_OPTION,
      name: CUSTOM_RANGE_OPTION,
      label: CUSTOM_RANGE_OPTION,
    } as unknown as T;
  }, []);
  const [items, setItems] = useState<T[]>([...options, customRangeItem]);

  useEffect(() => {
    setItems([...options, customRangeItem]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    setSelectedValues(values);
  }, [values]);

  const onOpenDialog = useCallback(() => setIsOpen(true), []);
  const onCloseDialog = useCallback(() => setIsOpen(false), []);

  return {
    isOpenDialog: isOpen,
    onOpenDialog,
    onCloseDialog,
    selectedValues,
    setSelectedValues,
    items,
    setItems
  };
};
