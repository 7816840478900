export interface IOption {
  id: string;
  name: string;
}

export const socialInteractionOptions: IOption[] = [
  {
    id: '100-500',
    name: '100-500',
  },
  {
    id: '500-1000',
    name: '500-1000',
  },
  {
    id: '1000-2000',
    name: '1000-2000',
  },
  {
    id: '>2000',
    name: '>2000',
  },
];

export const mapOptions = (values: string) =>
  values.split(',')?.map((option) => {
    return { id: option, name: option };
  });
  
export const renderName = (from: number | null, to: number | null) => {
  if (from && to) {
    return `${from || ''}-${to || ''}`;
  }
  if (from && !to) {
    return `>${from}`;
  }
  if (!from && to) {
    return `<${to}`;
  }
  return `${from || ''}-${to || ''}`;
};
