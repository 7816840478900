import { useAuth0 } from '@auth0/auth0-react';
import { IKOCAudience } from 'types/koc';
import { requester } from '../index';

export const useEditUseCase = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function editUseCase(idProject: string, id: number, name: string) {
    return requester({
      method: 'put',
      path: `/admin/projects/${idProject}/use-cases`,
      data: {
        name,
        id,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<IKOCAudience>(response.data));
  };
};

export default useEditUseCase;
