import { Box, Button, Link, Typography, styled } from '@mui/material';

export const UserBoxButton = styled(Button)(
  ({ theme }) => `
          padding: ${theme.spacing(0, 1)};
          height: ${theme.spacing(7)};
  `,
);

export const MenuUserBox = styled(Box)(
  ({ theme }) => `
          background: ${theme.colors.alpha.black[5]};
          padding: ${theme.spacing(2)};
  `,
);

export const UserBoxText = styled(Box)(
  ({ theme }) => `
          text-align: left;
          padding-left: ${theme.spacing(1)};
  `,
);

export const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
          color: ${theme.palette.text.primary};
          display: block;
  `,
);

export const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
          color: ${theme.colors.alpha.black[70]}
  `,
);

export const CustomIconButton = styled(Link)(
  ({ theme }) => `
      line-height: 0;
      border: 1px solid ${theme.colors.alpha.black['40']};
      border-radius: 50%;
      padding: ${theme.spacing(0.5)};
  
      path {
          fill: #000000
      }
  
      &:hover{
          border-color: ${theme.colors.primary.main}; 
          path {
              fill: ${theme.colors.primary.main}
          }
      }
    `,
);
