import { IAudienceMetaData } from 'types/audience';
import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface Props extends IRequestHookParams {}

interface Result extends IRequestHookResult<IAudienceMetaData> {}

export const useLoadAudienceMetaData: IRequestHook<Props, Result> = ({stopWhen}) => {
  const path = `/admin/datalytis/audiences/filters`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const metaData = response ? (response.data as IAudienceMetaData) : undefined;

  return {
    mutate,
    result: metaData,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadAudienceMetaData;
