import { useAuth0 } from "@auth0/auth0-react";
import { requester } from "../index";

interface DataType {
  name: string;
  type: string;
  filePath?: string;
}

export const useImport1stKOCAudiences = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function createProject(idProject: string, data: DataType) {
    return requester({
      method: "post",
      path: `/admin/projects/${idProject}/koc/audiences/import`,
      data: {
        file_path: data.filePath,
        name: data.name,
        source: data.type,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<any>(response.data));
  };
};

export default useImport1stKOCAudiences;
