import { useAuth0 } from '@auth0/auth0-react';
import { fetcher } from 'requests';

export const useLoadFirstAudiencesUnique = () => {
  const { getAccessTokenSilently } = useAuth0();
  return function handelLoadTotal(idProject: string) {
    const path = `/admin/projects/${idProject}/koc/first-audiences/unique-total`;
    return fetcher(getAccessTokenSilently)(path).then(
      (response) => response.data,
    );
  };
};

export default useLoadFirstAudiencesUnique;
