import { useAuth0 } from '@auth0/auth0-react';
import { fetcher } from 'requests';

interface DataType {
  idProject: string;
  idDiscoveryGroup: number;
}

export const useLoadDiscoveryGroupUnique = () => {
  const { getAccessTokenSilently } = useAuth0();
  return function handelLoadTotal(data: DataType) {
    const path = `/admin/projects/${data.idProject}/koc/customers/discovery-group/${data.idDiscoveryGroup}/unique-total`;
    return fetcher(getAccessTokenSilently)(path).then(
      (response) => response.data,
    );
  };
};

export default useLoadDiscoveryGroupUnique;
