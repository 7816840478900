import { ReactNode } from 'react';
import { generatePath } from 'react-router';
import WorkIcon from '@mui/icons-material/Work';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
// import AppsIcon from '@mui/icons-material/Apps';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { ReactComponent as KocDiscoveryIcon } from 'assets/icons/klcDiscovery.svg';
import { ReactComponent as RecruitmentIcon } from 'assets/icons/recruitment.svg';
import { ReactComponent as AvtivationIcon } from 'assets/icons/activation.svg';
import { IProject, IProjectReports } from 'types/project';
import * as ROUTES from 'constants/routes';
import { MENU_ITEM } from 'constants/menu-items';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  items?: MenuItem[];
  role?: string;
  permissions?: any[];
  name: string;
  fakeLink?: boolean;
  isExtenalLink?: boolean;
  isComingSoon?: boolean;
  isRenderFull?: boolean;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  permissions?: any[];
}

const mainMenuItems: MenuItems[] = [
  {
    heading: MENU_ITEM.ACCOUNT_MENU,
    permissions: ['system.account.accounts.view'],
    items: [
      {
        role: 'ADMIN',
        name: MENU_ITEM.ROLES_PERMISSIONS,
        link: ROUTES.ROUTE_ROLES_PERMISSIONS,
        icon: VerifiedUserOutlinedIcon,
      },
      {
        name: MENU_ITEM.ACCOUNTS_MENU,
        link: ROUTES.ROUTE_EMPLOYEES,
        icon: AccountBoxIcon,
        permissions: ['system.account.accounts.view'],
      },
    ],
  },
  {
    heading: MENU_ITEM.PROJECT_MENU,
    permissions: [
      'system.projects.organizations.view',
      'system.projects.brands.view',
      'system.projects.projects.view',
    ],
    items: [
      {
        name: MENU_ITEM.ORGANIZATIONS_MENU,
        link: ROUTES.ROUTE_ORGANIZATIONS,
        icon: ApartmentIcon,
        permissions: ['system.projects.organizations.view'],
      },
      {
        name: MENU_ITEM.BRANDS_MENU,
        link: ROUTES.ROUTE_BRANDS,
        icon: AccountTreeIcon,
        permissions: ['system.projects.brands.view'],
      },
      {
        name: MENU_ITEM.PROJECT_MENU,
        link: ROUTES.ROUTE_PROJECTS,
        icon: WorkIcon,
        permissions: ['system.projects.projects.view'],
      },
    ],
  },
  {
    heading: MENU_ITEM.DATA_MANAGEMENT,
    permissions: ['system.data_management.data_import.view'],
    items: [
      {
        name: MENU_ITEM.DATA_IMPORT,
        link: ROUTES.ROUTE_DATA_CUSTOMER_IMPORT,
        icon: AccountBoxIcon,
        permissions: ['system.data_management.data_import.view'],
      },
    ],
  },
  {
    heading: MENU_ITEM.TEMPLATE_MANAGEMENT,
    permissions: [
      'system.template_management.agreement_template.view',
      'system.template_management.sms_template.view'
    ],
    items: [
      {
        name: MENU_ITEM.AGREEMENT_MANAGEMENT,
        link: ROUTES.ROUTE_AGREEMENT_TEMPLATE,
        icon: InsertDriveFileOutlinedIcon,
        permissions: ['system.template_management.agreement_template.view'],
      },
      {
        name: MENU_ITEM.MESSAGE_TEMPLATE,
        link: ROUTES.ROUTE_MESSAGE_TEMPLATE,
        icon: TextsmsOutlinedIcon,
        permissions: ['system.template_management.sms_template.view'],
      },
    ],
  },
  {
    heading: MENU_ITEM.KOC_MENU,
    permissions: [
      'system.koc_management.koc_scheme_type.view',
      'system.koc_management.koc_category.view',
      'system.koc_management.facebook_category_mapping.view',
    ],
    items: [
      {
        name: MENU_ITEM.TYPE_KOC_MENU,
        link: ROUTES.ROUTE_KOC_SCHEME_TYPE,
        icon: ListAltIcon,
        permissions: ['system.koc_management.koc_scheme_type.view'],
      },
      {
        name: MENU_ITEM.CATEGORY_KOC_MENU,
        link: ROUTES.ROUTE_KOC_CATEGORY,
        icon: DonutSmallIcon,
        permissions: ['system.koc_management.koc_category.view'],
      },
      {
        name: MENU_ITEM.FB_KOC_MENU,
        link: ROUTES.ROUTE_FB_CATEGORY_MAPPING,
        icon: ContentPasteIcon,
        permissions: ['system.koc_management.facebook_category_mapping.view'],
      },
    ],
  },
  {
    heading: MENU_ITEM.CONTENT_HUB_MENU,
    permissions: ['system.content_hub.content_guide.view'],
    items: [
      {
        name: MENU_ITEM.CONTENT_GUIDE_MENU,
        link: ROUTES.ROUTE_CONTENT_GUIDE,
        icon: InfoOutlinedIcon,
        permissions: ['system.content_hub.content_guide.view'],
      },
    ],
  },
];

type IFunction = (
  idProject: string,
  project?: IProject,
  projectReports?: IProjectReports,
) => MenuItems[];

export const createProjectMenuItems: IFunction = (
  idProject,
  project,
  projectReports,
) => {
  const adminReports = projectReports?.adminReport || [];
  return [
    {
      heading: MENU_ITEM.PROJECT_MENU,
      items: [
        {
          icon: KocDiscoveryIcon,
          permissions: [
            'project.koc_discovery.search.view_audience',
            'project.koc_discovery.discovery_group.view_discovery_group',
          ],
          name: MENU_ITEM.KOC_DISCOVERY,
          items: [
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_KOC_DATA_SOURCES, {
                idProject,
              }),
              name: MENU_ITEM.KOC_DATA_SOURCE,
              permissions: ['project.koc_discovery.search.view_audience'],
              isRenderFull: true,
            },
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_DISCOVERY_GROUP, {
                idProject,
              }),
              name: MENU_ITEM.DISCOVERY_GROUP,
              permissions: [
                'project.koc_discovery.discovery_group.view_discovery_group',
              ],
              isRenderFull: true,
            },
          ],
        },
        {
          icon: RecruitmentIcon,
          permissions: [
            'project.briefing.view',
            'project.koc_discovery.discovery_list.view_potential_koc',
          ],
          name: MENU_ITEM.RECRUITMENT,
          items: [
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_AGREEMENT_MANAGEMENT, { idProject }),
              name: MENU_ITEM.PROJECT_AGREEMENT_MANAGEMENT,
              permissions: ['project.recruitment.agreement.view'],
              isRenderFull: true,
            },
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_BRIEFING, { idProject }),
              name: MENU_ITEM.BRIEFING_MENU,
              permissions: ['project.briefing.view'],
              isRenderFull: true,
            },
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_KOC_DISCOVERY, {
                idProject,
              }),
              name: MENU_ITEM.KOC_DISCOVERY_LIST,
              permissions: [
                'project.koc_discovery.discovery_list.view_potential_koc',
              ],
              isRenderFull: true,
            },
          ],
        },
        {
          icon: AvtivationIcon,
          permissions: [
            'project.koc_list.view_koc_list',
            'project.content_mixer.view_mix_content',
            'project.content_library.view',
            'project.link_management.view_tracking',
            'project.use_case_product_management.use_case.view',
            'project.use_case_product_management.product.view',
          ],
          name: MENU_ITEM.ACTIVATION,
          items: [
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_KOC_LIST, { idProject }),
              name: MENU_ITEM.KOC_LIST,
              permissions: ['project.koc_list.view_koc_list'],
              isRenderFull: true,
            },
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_CONTENT_MIXER, {
                idProject,
              }),
              name: MENU_ITEM.CONTENT_MIXER_MENU,
              permissions: ['project.content_mixer.view_mix_content'],
              isRenderFull: true,
            },
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_CONTENT_LIBRARY, {
                idProject,
              }),
              name: MENU_ITEM.CONTENT_LIBRARY_MENU,
              permissions: ['project.content_library.view'],
              isRenderFull: true,
            },
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_LINK_MANAGEMENT, {
                idProject,
              }),
              name: MENU_ITEM.LINK_MANAGEMENT,
              permissions: ['project.link_management.view_tracking'],
              isRenderFull: true,
            },
            {
              link: generatePath(
                ROUTES.ROUTE_PROJECT_USECASE_PRODUCT_MANAGEMENT,
                {
                  idProject,
                },
              ),
              name: MENU_ITEM.USECASE_PRODUCT_MANAGEMENT,
              permissions: [
                'project.use_case_product_management.use_case.view',
                'project.use_case_product_management.product.view',
              ],
              isRenderFull: true,
            },
          ],
        },
        {
          icon: GridViewIcon,
          permissions: ['project.dashboard.view', 'project.report.view'],
          name: MENU_ITEM.GROW,
          items: [
            {
              link: generatePath(ROUTES.ROUTE_PROJECTS_DETAILS, {
                idProject,
              }),
              name: MENU_ITEM.DASHBOARD_MENU,
              permissions: ['project.dashboard.view'],
              isRenderFull: true,
            },
            {
              link: generatePath(ROUTES.ROUTE_PROJECT_REPORT, {
                idProject,
                idReport: '',
              }),
              name: MENU_ITEM.REPORT_MENU,
              permissions: ['project.report.view'],
              isRenderFull: true,
              items:
                adminReports.length <= 0
                  ? undefined
                  : adminReports.map((report) => ({
                    name: report.name,
                    link: generatePath(ROUTES.ROUTE_PROJECT_REPORT, {
                      idProject,
                      idReport: report.id,
                    }),
                  })),
            },
          ],
        },
      ],
    },
  ];
};

export default mainMenuItems;
