import { useAuth0 } from '@auth0/auth0-react';
import { ILink } from 'types/link';
import { requester } from '../index';

interface DataType {
  idProject: string;
  idCustomer: string;
  idProduct?: number | null;
  idUseCase?: number | null;
  other?: string | null;
}

export const useAddTag = () => {
  const { getAccessTokenSilently } = useAuth0();

  return (data: DataType) => {
    return requester({
      method: 'put',
      path: `/admin/projects/${data.idProject}/koc/customers/${data.idCustomer}/tag`,
      data: {
        id_product: data.idProduct,
        id_use_case: data.idUseCase,
        other: data.other,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve<ILink>(response.data));
  };
};
