import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

export const useDeletePhoto = () => {
  const { getAccessTokenSilently } = useAuth0();

  return function deletePhoto(
    idProject: string,
    idProduct: number,
    ids: number[],
  ) {
    return requester({
      method: 'delete',
      path: `/admin/projects/${idProject}/products/${idProduct}/photos`,
      data: {
        ids,
      },
      getToken: getAccessTokenSilently,
    });
  };
};

export default useDeletePhoto;
