import { FC, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { LoadingButton } from '@mui/lab';
import { useCookies } from 'react-cookie';
import SelectField1PDataTab from './SelectField1PDataTab';
import SelectField3RDDataTab from './SelectField3RDDataTab';

interface IOption {
  key: string;
  name: string;
  group: string;
}

interface IProps extends Omit<DialogProps, 'onClose'> {
  selectedSocialFields: string[];
  selectedCDPFields: string[];
  socialItems: IOption[];
  cdpItems: IOption[];
  filterType: string[];
  onClose: () => void;
  onSelectedFilterFields: (
    selectedSocialFields: string[],
    selectedCDPFields: string[],
  ) => void;
}

const AddFilterTypeDialog: FC<IProps> = ({
  socialItems,
  cdpItems,
  selectedSocialFields,
  selectedCDPFields,
  onSelectedFilterFields,
  onClose,
  ...dialogProps
}) => {
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>('social');
  const [socialFields, setSocialFields] =
    useState<string[]>(selectedSocialFields);
  const [cdpFields, setCDPFields] = useState<string[]>(selectedCDPFields);
  const [cookies] = useCookies(['Display-Column-Discovery-Groups']);

  const remapSocialItemKey = (key: string) => {
    switch (key) {
      case 'follows':
        return 'follow';
      case 'ages':
        return 'age';
      case 'cities':
        return 'idCity';
      case 'genders':
        return 'sex';
      case 'relations':
        return 'idRelationship';
      case 'pages':
        return 'nTypePage';
      case 'posts':
        return 'nTypePost';
      case 'friends':
      case 'averageEngagement':
      case 'idHometown':
      case 'dTypeAudiences':
      case 'firstAudiences':
      case 'totalPost':
      case 'totalEngagement':
      case 'totalReaction':
      case 'totalComment':
      case 'totalShare':
      case 'lastPostedTime':
        return key;
      default:
        return key;
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4" gutterBottom>
          Add Filter Type
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Chip
          size="small"
          label="3rd Party Data"
          sx={{
            backgroundColor: '#F5F5F5',
            color: selectedType === 'social' ? '#8C64DC' : '#424242',
            mr: 1,
          }}
          onClick={() => {
            setSelectedType('social');
          }}
        />
        <Chip
          size="small"
          label="1st Party Data"
          sx={{
            backgroundColor: '#F5F5F5',
            color: selectedType === 'cdp' ? '#8C64DC' : '#424242',
          }}
          onClick={() => {
            setSelectedType('cdp');
          }}
        />
        {selectedType === 'social' && (
          <SelectField3RDDataTab
            items={
              cookies && cookies['Group-View-Discovery-Groups']
                ? socialItems.filter(
                    (item) =>
                      cookies['Group-View-Discovery-Groups'][
                        remapSocialItemKey(item.key)
                      ] || ['kocCategories'].includes(item.key),
                  )
                : socialItems
            }
            itemsToFilter={socialFields}
            onDataChange={(values) => {
              setSocialFields(Array.from(new Set([...values])));
            }}
          />
        )}
        {selectedType === 'cdp' && (
          <SelectField1PDataTab
            items={cdpItems}
            itemsToFilter={cdpFields}
            onDataChange={(values) => {
              setCDPFields(Array.from(new Set([...values])));
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={Boolean(
            (!socialFields && !cdpFields) ||
              (socialFields &&
                socialFields.length < 1 &&
                cdpFields &&
                cdpFields.length < 1),
          )}
          onClick={() => onSelectedFilterFields(socialFields, cdpFields)}
          variant="contained"
          type="submit"
        >
          Apply
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddFilterTypeDialog;
