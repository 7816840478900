import { IRequestHookParams, IRequestHookResult, IRequestHook } from 'types/request';
import useCustomSWR from '../useCustomSWR';

interface ICity {
  id: number;
  name: string;
}

interface Props extends IRequestHookParams {}

interface Result extends IRequestHookResult<ICity[]> {}

export const useLoadAllCity: IRequestHook<Props, Result> = ({stopWhen}) => {
  const path = `/admin/sample/cities`;

  const { data: response, error, mutate } = useCustomSWR(path, { stopWhen });

  const result = response ? (response.data as ICity[]) : [];

  return {
    mutate,
    result,
    error,
    isLoading: !error && !response,
  };
};

export default useLoadAllCity;
