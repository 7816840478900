import { useAuth0 } from '@auth0/auth0-react';
import { requester } from '../index';

interface DataType {
  idProject: string;
  idCustomer: string;
  reason?: string | null;
  isRejected: boolean;
  idDiscoveryGroup: string | number;
}

export const useRejectCustomer = () => {
  const { getAccessTokenSilently } = useAuth0();

  return (data: DataType) => {
    return requester({
      method: 'put',
      path: `/admin/projects/${data.idProject}/koc/customers/${data.idCustomer}/reject`,
      data: {
        reason: data.reason,
        id_discovery_group: data.idDiscoveryGroup,
        is_rejected: data.isRejected,
      },
      getToken: getAccessTokenSilently,
    }).then((response) => Promise.resolve(response.data));
  };
};
