import {
  AutocompleteRenderOptionState,
  Checkbox,
  Typography,
} from '@mui/material';
import React, { FC, ReactElement } from 'react';

type Props = {
  [key: string]: any;
  label: string | ReactElement;
  selected?: boolean;
};
export const CustomOption: FC<Props> = ({ label, selected, ...props }) => {
  return (
    <li {...props}>
      <Checkbox sx={{ mr: 1, ml: -1 }} checked={selected} />
      {label}
    </li>
  );
};

export const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: any,
  { selected }: AutocompleteRenderOptionState,
) => (
  <CustomOption
    label={<Typography>{option.name}</Typography>}
    selected={selected}
    {...props}
  />
);
