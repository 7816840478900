import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularLoading } from 'components/loading';

interface Props {
  children: ReactNode;
}

const AuthWrapper = ({ children }: Props) => {
  const { isLoading, error } = useAuth0();

  if (error) {
    console.error('Login error', error);
  }

  if (isLoading) {
    return (
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="lightgray"
      >
        <CircularLoading />
      </Box>
    );
  }

  return <>{children}</>;
};

export default AuthWrapper;
