export const MENU_ITEM = {
  APPLICATION_MENU: 'Applications',
  PROJECT_MENU: 'Projects',
  DASHBOARD_MENU: 'Dashboard',
  BRIEFING_MENU: 'Briefing',
  PLANNING_MENU: 'Planning',
  CUSTOMER_SEGMENT_MENU: 'Customer Segment',
  CONTENT_PLANNING_MENU: 'Content planning',
  AUDIENCE_PLANNING_MENU: 'Audience Group',
  PRODUCTION_MENU: 'Production',
  ASSETS_MENU: 'Asset Library',
  MEDIA_MENU: 'Media',
  REPORT_MENU: 'Report',
  ORGANIZATIONS_MENU: 'Organizations',
  BRANDS_MENU: 'Brands',
  ACCOUNT_MENU: 'Account',
  ACCOUNTS_MENU: 'Accounts',
  DATA_MANAGEMENT: 'Data Management',
  DATA_IMPORT: 'Data Import',
  ALL_ASSET: 'All',
  IMAGE_ASSET: 'Image',
  VIDEO_ASSET: 'Video',
  SOCIAL_POST_ASSET: 'Social Post',
  KOC_NURTURING: 'KOC Nurturing',
  KOC_DISCOVERY: 'DISCOVERY',
  KOC_DISCOVERY_LIST: 'Discovery List',
  KOC_DATA_SOURCE: 'Data Source',
  KOC_SEGMENT: 'Segment',
  DISCOVERY_GROUP: 'Discovery Group',
  KOC_MENU: 'KOC Management',
  TYPE_KOC_MENU: 'KOC Scheme Type',
  KOC_LIST: 'KOC List',
  CATEGORY_KOC_MENU: 'KOC Category',
  FB_KOC_MENU: 'Facebook Category Mapping',
  CONTENT_HUB_MENU: 'Content Hub',
  CONTENT_GUIDE_MENU: 'Content Guide',
  ROLES_PERMISSIONS: 'Roles & Permissions',
  CONTENT_LIBRARY_MENU: 'Content Library',
  CONTENT_MIXER_MENU: 'Content Mixer',
  LINK_MANAGEMENT: 'Link Management',
  USECASE_PRODUCT_MANAGEMENT: 'Use Case & Product Management',
  RECRUITMENT: 'RECRUITMENT',
  ACTIVATION: 'ACTIVATION',
  GROW: 'GROW',
  TEMPLATE_MANAGEMENT: 'Template Management',
  AGREEMENT_MANAGEMENT: 'Agreement Management',
  PROJECT_AGREEMENT_MANAGEMENT: 'Agreement Management',
  MESSAGE_TEMPLATE: 'Message Template'
};
