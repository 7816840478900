import { useRoutes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import Pusher from 'pusher-js';
import useSignedCookies from 'hooks/useSignedCookies';
import { useLoadUserProfile } from 'requests/profile';
import routes from './router';
import { usePermissionContext } from './contexts/permissionContext';

const addDevice = async (routeNotification) => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      const win: any = window;
      if (!win.beamsClient) {
        try {
          win.beamsClient = new PusherPushNotifications.Client({
            instanceId:
              process.env.REACT_APP_BEAMS_INSTANCE_ID ||
              '1dd42c29-103a-410c-847e-8f73a054ce9',
            serviceWorkerRegistration,
          });

          win.beamsClient
            .start()
            .then(() => {
              win.beamsClient
                .addDeviceInterest(routeNotification)
                .catch(console.error);
            })
            .catch(console.error);
        } catch (e) {
          console.error(e);
        }
      }
      if (win && !win.Pusher) {
        win.Pusher = Pusher;
      }
    });
  }
};

const App = () => {
  useSignedCookies(window?.location?.pathname?.includes('public-link'));
  const content = useRoutes(routes);
  const { result: res } = useLoadUserProfile({});
  const { setPermissions, setRole } = usePermissionContext();
  const [deviceInterestID, setDeviceInterestID] = useState(null);

  useEffect(() => {
    if (deviceInterestID) {
      addDevice(deviceInterestID);
    }
  }, [deviceInterestID]);

  useEffect(() => {
    if (
      res &&
      res.routeNotification &&
      res.routeNotification !== deviceInterestID
    ) {
      setDeviceInterestID(res.routeNotification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);

  if (typeof res === 'object') {
    const {
      role: { permissions, alias },
    } = res;
    setPermissions(permissions);
    setRole(alias);
  }
  return (
    <SnackbarProvider
      maxSnack={6}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {content}
    </SnackbarProvider>
  );
};

export default App;
